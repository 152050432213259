interface ChartInformation {
  title?: string;
  description?: any;
  enTourId?: number | null;
  jaTourId?: number | null;
}

/* TODO: Make code for this file shorter. It is like a config file with a lot of repeated logic.
   We can make it more modular and easier to manage, for example, by generating title and description
   based on db name and chartId
   Need to remember to still allow config like set up for charts which might not be from any dashboard and therefore
   won't fit this structure
*/

export class ChartDescriptionStore {
  public chartIds = {
    headcountByMonth: 'headcount-by-month',
    headcountByYear: 'headcount-by-year',
    headcountByEmploymentType: 'headcount-by-employment-type',
    headcountByOrganization: 'headcount-by-organization',
    headcountByLocation: 'headcount-by-locationn',
    headcountByFunction: 'headcount-by-function',
    headcountByPosition: 'headcount-by-position',
    headcountByTenure: 'headcount-by-tenure',
    headcountByGender: 'headcount-by-gender',
    headcountByJobGrade: 'headcount-by-jobgrade',
    headcountByNationalities: 'headcount-by-nationalities',
    headcountByAge: 'headcount-by-age',
    headcountFactbox: 'headcount-factbox',
    keyMetricsFactbox: 'key-metrics-factbox',
    attritionOverTime: 'attrition-over-time',
    attritionByEmploymentType: 'attrition-by-employment-type',
    attritionByOrganization: 'attrition-by-organization',
    attritionByLocation: 'attrition-by-location',
    attritionByFunction: 'attrition-by-function',
    attritionByPosition: 'attrition-by-position',
    attritionByTenure: 'attrition-by-tenure',
    attritionByGender: 'attrition-by-gender',
    attritionByJobGrade: 'attrition-by-jobgrade',
    attritionByLocalOrNonLocal: 'attrition-by-local-or-nonlocal',
    attritionByAge: 'attrition-by-age',
    attritionCheckBox: 'attrition-check-box',
    genderGradeOverTime: 'gender-grade-over-time',
    genderSplitOverTime: 'gender-split-over-time',
    genderSplitByOrganization: 'gender-split-by-organization',
    genderSplitByLocation: 'gender-split-by-location',
    genderSplitByFunction: 'gender-split-by-function',
    genderSplitByTenure: 'gender-split-by-tenure',
    genderSplitByJobGrade: 'gender-split-by-jobgrade',
    genderSplitByLocalOrNonLocal: 'gender-split-by-local-or-nonlocal',
    genderSplitByAge: 'gender-split-by-age',
    attritionByGenderOverTime: 'attrition-by-gender-over-time',
    attritionByGenderAndFunction: 'attrition-by-gender-function',
    attritionByGenderAndOrganization: 'attrition-by-gender-organization',
    attritionByGenderAndLocation: 'attrition-by-gender-location',
    attritionByGenderAndTenure: 'attrition-by-gender-tenure',
    attritionByGenderAndLocalOrNonLocal: 'attrition-by-gender-local-or-nonlocal',
    attritionByGenderAndAge: 'attrition-by-gender-age',
    joinersAndLeaversOverTime: 'joiners-and-leavers-over-time',
    joinersAndLeaversByEmploymentType: 'joiners-and-leavers-by-employment-type',
    joinersAndLeaversByOrganization: 'joiners-and-leavers-by-organization',
    joinersAndLeaversByJobTitle: 'joiners-and-leavers-by-job-title',
    joinersAndLeaversByLocation: 'joiners-and-leavers-by-locationn',
    joinersAndLeaversByTenure: 'joiners-and-leavers-by-tenure',
    joinersAndLeaversByGender: 'joiners-and-leavers-by-gender',
    joinersAndLeaversByJobGrade: 'joiners-and-leavers-by-jobgrade',
    joinersAndLeaversByLocalOrNonLocal: 'joiners-and-leavers-by-local-or-nonlocal',
    joinersAndLeaversByAge: 'joiners-and-leavers-by-age',
    diversityGenderPaygap: 'diversity-gender-paygap', // this is a factbox
    payGapByGrade: 'pay-gap-by-grade',
    payGapOverTime: 'pay-gap-by-over-time',
    payGapByLocation: 'pay-gap-by-locationn',
    payGapByOrganization: 'pay-gap-by-organization',
    payGapByFunction: 'pay-gap-by-function',
    payGapByTenure: 'pay-gap-by-tenure',
    payGapByLocalOrNonLocal: 'pay-gap-by-local-or-nonlocal',
    payGapByAge: 'pay-gap-by-age',
    payGapByNationality: 'pay-gap-by-nationality',
    acrSplitByGender: 'acr-split-gender-percentage',
    acrByGradeAndGender: 'acr-by-jobgrade-gender',
    acrOverTime: 'acr-over-time',
    acrByLocationAndGender: 'acr-by-location-and-gender',
    acrByOrganizationAndGender: 'acr-by-organization-and-gender',
    acrByFunctionAndGender: 'acr-by-function-and-gender',
    acrByAgeAndGender: 'acr-by-age-and-gender',
    acrByNationalityAndGender: 'acr-by-nationality-and-gender',
    acrByLocalOrNonLocalAndGender: 'acr-by-local-or-nonlocal-and-gender',
    acrByTenureAndGender: 'acr-by-tenure-and-gender',
    medianBaseByMonth: 'mdb-by-month',
    medianBaseByYear: 'mdb-by-year',
    medianBaseByEmploymentType: 'mdb-by-employment-type',
    medianBaseByOrganization: 'mdb-by-organization',
    medianBaseByLocation: 'mdb-by-locationn',
    medianBaseByTenure: 'mdb-by-tenure',
    medianBaseByGender: 'mdb-by-gender',
    medianBaseByJobGrade: 'mdb-by-jobgrade',
    medianBaseByLocalOrNonLocal: 'mdb-by-local-or-nonlocal',
    medianBaseByAge: 'mdb-by-age',
    totalCompByMonth: 'totb-by-month',
    totalCompByYear: 'totb-by-year',
    totalCompByEmploymentType: 'totb-by-employment-type',
    totalCompByOrganization: 'totb-by-organization',
    totalCompByLocation: 'totb-by-locationn',
    totalCompByTenure: 'totb-by-tenure',
    totalCompByGender: 'totb-by-gender',
    totalCompByJobGrade: 'totb-by-jobgrade',
    totalCompByLocalOrNonLocal: 'totb-by-local-or-nonlocal',
    totalCompByNationality: 'totb-by-nationality',
    totalCompByAge: 'totb-by-age',
    acrByLocation: 'acr-by-location',
    acrByOrganization: 'acr-by-organization',
    acrByGender: 'acr-by-gender',
    acrByGradeAndLocation: 'acr-by-location-jobgrade',
    acrByGenderAndGrade: 'acr-by-gender-jobgrade',
    acrByGradeAndOrganization: 'acr-by-organization-jobgrade',
    recruitmentOverviewTimeToFillHireReject: 'recruitment-overview-time-to-fill-hire-reject',
    attendanceOverTime: 'attendance-over-time',
    overtimeByLocation: 'overtime-by-location',
    overtimeByOrganization: 'overtime-by-organization',
    overtimeByTenure: 'overtime-by-tenure',

    peopleSnapshot: {
      headcountByMonth: 'headcount-by-month',
      headcountByYear: 'headcount-by-year',
      headcountByEmploymentType: 'headcount-by-employment-type',
      headcountByOrganization: 'headcount-by-organization',
      headcountByLocation: 'headcount-by-locationn',
      headcountByFunction: 'headcount-by-function',
      headcountByPosition: 'headcount-by-position',
      headcountByTenure: 'headcount-by-tenure',
      headcountByGender: 'headcount-by-gender',
      headcountByJobGrade: 'headcount-by-jobgrade',
      headcountByNationalities: 'headcount-by-nationalities',
      headcountByAge: 'headcount-by-age',
      headcountFactbox: 'headcount-factbox',
      keyMetricsFactbox: 'key-metrics-factbox',
      spanOfControlFactbox: 'span-of-control-factbox',
    },

    attrition: {
      overview: {
        attritionOverTime: 'attrition-over-time',
        attritionByEmploymentType: 'attrition-by-employment-type',
        attritionByOrganization: 'attrition-by-organization',
        attritionByLocation: 'attrition-by-location',
        attritionByFunction: 'attrition-by-function',
        attritionByPosition: 'attrition-by-position',
        attritionByTenure: 'attrition-by-tenure',
        attritionByGender: 'attrition-by-gender',
        attritionByJobGrade: 'attrition-by-jobgrade',
        attritionByLocalOrNonLocal: 'attrition-by-local-or-nonlocal',
        attritionByNationality: 'attrition-by-nationality',
        attritionByAge: 'attrition-by-age',
        attritionCheckBox: 'attrition-check-box',
      },
    },
    diversity: {
      demographics: {
        genderGradeOverTime: 'gender-grade-over-time',
        genderSplitOverTime: 'gender-split-over-time',
        genderSplitByOrganization: 'gender-split-by-organization',
        genderSplitByLocation: 'gender-split-by-location',
        genderSplitByFunction: 'gender-split-by-function',
        genderSplitByTenure: 'gender-split-by-tenure',
        genderSplitByJobGrade: 'gender-split-by-jobgrade',
        genderSplitByLocalOrNonLocal: 'gender-split-by-local-or-nonlocal',
        genderSplitByNationality: 'gender-split-by-nationality',
        genderSplitByAge: 'gender-split-by-age',
      },
      joinersAndLeavers: {
        attritionByGenderOverTime: 'attrition-by-gender-over-time',
        attritionByGenderAndFunction: 'attrition-by-gender-function',
        attritionByGenderAndOrganization: 'attrition-by-gender-organization',
        attritionByGenderAndLocation: 'attrition-by-gender-location',
        attritionByGenderAndTenure: 'attrition-by-gender-tenure',
        attritionByGenderAndLocalOrNonLocal: 'attrition-by-gender-local-or-nonlocal',
        attritionByNationality: 'attrition-by-gender-nationality',
        attritionByGenderAndAge: 'attrition-by-gender-age',
      },
      payGap: {
        diversityGenderPaygap: 'diversity-gender-paygap', // this is a factbox
        payGapByGrade: 'pay-gap-by-grade',
        payGapOverTime: 'pay-gap-by-over-time',
        payGapByLocation: 'pay-gap-by-locationn',
        payGapByOrganization: 'pay-gap-by-organization',
        payGapByFunction: 'pay-gap-by-function',
        payGapByTenure: 'pay-gap-by-tenure',
        payGapByLocalOrNonLocal: 'pay-gap-by-local-or-nonlocal',
        payGapByNationality: 'pay-gap-by-nationality',
        payGapByAge: 'pay-gap-by-age',
      },
      compaRatio: {
        acrSplitByGender: 'acr-split-gender-percentage',
        acrByGradeAndGender: 'acr-by-jobgrade-gender',
        acrOverTime: 'acr-over-time',
        acrByLocationAndGender: 'acr-by-location-and-gender',
        acrByOrganizationAndGender: 'acr-by-organization-and-gender',
        acrByFunctionAndGender: 'acr-by-function-and-gender',
        acrByAgeAndGender: 'acr-by-age-and-gender',
        acrByLocalOrNonLocalAndGender: 'acr-by-local-or-nonlocal-and-gender',
        acrByNationalityAndGender: 'acr-by-nationality-and-gender',
        acrByTenureAndGender: 'acr-by-tenure-and-gender',
      },
    },
    joinersAndLeavers: {
      overview: {
        joinersAndLeaversOverTime: 'joiners-and-leavers-over-time',
        joinersAndLeaversByEmploymentType: 'joiners-and-leavers-by-employment-type',
        joinersAndLeaversByJobTitle: 'joiners-and-leavers-by-job-title',
        joinersAndLeaversByOrganization: 'joiners-and-leavers-by-organization',
        joinersAndLeaversByLocation: 'joiners-and-leavers-by-locationn',
        joinersAndLeaversByTenure: 'joiners-and-leavers-by-tenure',
        joinersAndLeaversByGender: 'joiners-and-leavers-by-gender',
        joinersAndLeaversByJobGrade: 'joiners-and-leavers-by-jobgrade',
        joinersAndLeaversByLocalOrNonLocal: 'joiners-and-leavers-by-local-or-nonlocal',
        joinersAndLeaversByNationality: 'joiners-and-leavers-by-nationality',
        joinersAndLeaversByAge: 'joiners-and-leavers-by-age',
      },
    },
    totalComp: {
      overview: {
        totalCompByMonth: 'totb-by-month',
        totalCompByYear: 'totb-by-year',
        totalCompByEmploymentType: 'totb-by-employment-type',
        totalCompOverviewFactbox: 'totb-overview-factbox',
      },
      keyMetrics: {
        totalCompByOrganization: 'totb-by-organization',
        totalCompByLocation: 'totb-by-locationn',
        totalCompByTenure: 'totb-by-tenure',
        totalCompByGender: 'totb-by-gender',
        totalCompByJobGrade: 'totb-by-jobgrade',
        totalCompByLocalOrNonLocal: 'totb-by-local-or-nonlocal',
        totalCompByAge: 'totb-by-age',
        totalCompKeyMetricsFactbox: 'totb-key-metrics-factbox',
      },
    },
    medianBase: {
      overview: {
        medianBaseByMonth: 'mdb-by-month',
        medianBaseByYear: 'mdb-by-year',
        medianBaseByEmploymentType: 'mdb-by-employment-type',
        medianBaseOverviewFactbox: 'mdb-factbox',
      },
      keyMetrics: {
        medianBaseByOrganization: 'mdb-by-organization',
        medianBaseByLocation: 'mdb-by-locationn',
        medianBaseByTenure: 'mdb-by-tenure',
        medianBaseByGender: 'mdb-by-gender',
        medianBaseByJobGrade: 'mdb-by-jobgrade',
        medianBaseByLocalOrNonLocal: 'mdb-by-local-or-nonlocal',
        medianBaseByNationality: 'mdb-by-nationality',
        medianBaseByAge: 'mdb-by-age',
        medianBaseKpiFactbox: 'mdb-kpi-factbox',
      },
      compaRatio: {
        acrByLocation: 'acr-by-location',
        acrByOrganization: 'acr-by-organization',
        acrByGender: 'acr-by-gender',
        acrByGradeAndLocation: 'acr-by-location-jobgrade',
        acrByGenderAndGrade: 'acr-by-gender-jobgrade',
        acrByGradeAndOrganization: 'acr-by-organization-jobgrade',
      },
    },
    recruitment: {
      overview: {
        recruitmentOverviewFactbox: 'recruitment-overview-factbox',
        openRequisitionsAndHiresOverTime: 'recruitment-open-requisitions-and-hires-over-time',
        avgTimeToFillHireReject: 'recruitment-avg-time-to-fill-hire-reject',
        applications: 'applications',
        offersAndHires: 'offers-and-hires',
        agingRequisitions: 'recruitment-aging-requisitions',
      },
      activities: {
        activitiesOverTime: 'recruitment-activities-over-time',
      },
    },
    recruitmentFunnel: {
      recruitmentFunnel: {
        leftFactbox: 'recruitment-funnel-left-factbox',
        rightFactbox: 'recruitment-funnel-right-factbox',
        funnel: 'recruitment-funnel-funnel',
        applicationByOffice: 'recruitment-funnel-application-by-office',
        applicationByOrganization: 'recruitment-funnel-application-by-organization',
      },
    },
    attritionPrediction: {
      predictionOvertime: 'attrition-prediction-overtime',
      attritionRiskAvgOverTime: 'attrition-risk-avg-over-time',
      attritionRiskAtRiskHc: 'attrition-risk-at-risk-hc',
    },
    evaluation: {
      overview: {
        evaluationScoreByLocation: 'evaluation-evaluationOverview-location',
        evaluationScoreByOrganization: 'evaluation-evaluationOverview-organization',
        evaluationScoreByTenure: 'evaluation-evaluationOverview-tenureGroup',
        evaluationScoreByGrade: 'evaluation-evaluationOverview-jobGrade',
        evaluationScoreByGender: 'evaluation-evaluationOverview-gender',
        evaluationScoreByLocalNonLocal: 'evaluation-evaluationOverview-localOrNonLocal',
        evaluationScoreByNationality: 'evaluation-evaluationOverview-nationalityHierarchical',
        evaluationScoreByFunction: 'evaluation-evaluationOverview-function',
        evaluationScoreByPosition: 'evaluation-evaluationOverview-position',
        evaluationScoreByAge: 'evaluation-evaluationOverview-ageGroup',
        evaluationDistribution: 'evaluation-evaluationOverview-distribution',
      },
      multicycle: {
        evaluationDistribution: 'evaluation-multicycle-distribution',
        evaluationDistributionTable: 'evaluation-multicycle-distribution-table',
        evaluationSelectors: 'evaluation-multicycle-selectors',
        evaluationHeadcountTable: 'evaluation-headcount-table',
      },
    },
    revenueMetrics: {
      headCountByFunction: 'revenue-metrics-headcount-by-function',
      headcountByOrganization: 'revenue-metrics-headcount-by-organization',
      revenueByOrganization: 'revenue-metrics-revenue-by-organization',
      revenueByFunction: 'revenue-metrics-revenue-by-function',
      revenueOvertimeByOrganization: 'revenue-metrics-revenue-over-time-by-organization',
      revenueOvertimeByFunction: 'revenue-metrics-revenue-over-time-by-function',
      revenueOvertimeSalaryVsRevenueByFunction: 'revenue-metrics-salary-vs-revenue-by-function',
      revenueOvertimeSalaryVsRevenueByOrganization: 'revenue-metrics-salary-vs-revenue-by-organization',
      revenueDiversityByOrganization: 'revenue-metrics-revenue-on-diversity-by-organization',
      revenueDiversityByFunction: 'revenue-metrics-revenue-on-diversity-by-function',
      revenueOnAttritionByOrganization: 'revenue-metrics-revenue-on-attrition-by-organization',
      revenueOnAttritionByFunction: 'revenue-metrics-revenue-on-attrition-by-function',
    },
    relationshipToClients: {
      avgRelationshipScoreOverTime: 'avg-relationship-score-over-time',
      avgNumRelationshipsOverTime: 'avg-num-relationships-over-time',
    },
    timeAndAttendance: {
      workHoursOverTime: 'work-hours-over-time',
      overtimeHoursOverTime: 'overtimeHoursOverTime',
    },
    onaMetricsOverTime: {
      metricOverTime: 'metric-over-time',
      avgNumRelationships: 'avg-metric-over-time',
    },
  };

  // private icrDescription =
  //   'An Internal Compa-Ratio is a comparison of the salary you are paying your employees versus the median for similar positions in your companies. The definition we use is: Salary of Employee divided by the median salary of a group of employee with the same Job title, grade and location.';

  private chartDatabase: { [key: string]: ChartInformation } = {
    [this.chartIds.peopleSnapshot.headcountByMonth]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByMonth.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByMonth.description',
    },
    [this.chartIds.peopleSnapshot.headcountByYear]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByYear.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByYear.description',
    },
    [this.chartIds.peopleSnapshot.headcountByEmploymentType]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByEmploymentType.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByEmploymentType.description',
    },
    [this.chartIds.peopleSnapshot.headcountByOrganization]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByOrganization.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByOrganization.description',
    },
    [this.chartIds.peopleSnapshot.headcountByLocation]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByLocation.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByLocation.description',
    },
    [this.chartIds.peopleSnapshot.headcountByFunction]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByFunction.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByFunction.description',
    },
    [this.chartIds.peopleSnapshot.headcountByPosition]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountPosition.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountPosition.description',
    },
    [this.chartIds.peopleSnapshot.headcountByTenure]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByTenure.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByTenure.description',
    },
    [this.chartIds.peopleSnapshot.headcountByGender]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByGender.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByGender.description',
    },
    [this.chartIds.peopleSnapshot.headcountByJobGrade]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByJobGrade.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByJobGrade.description',
    },
    [this.chartIds.peopleSnapshot.headcountByNationalities]: {
      title: 'Headcount By Nationalities',
      description: 'Total headcount by Nationalities',
    },
    [this.chartIds.peopleSnapshot.headcountByAge]: {
      title: 'pages:dashboards.peopleSnapshot.info.headcountByAge.title',
      description: 'pages:dashboards.peopleSnapshot.info.headcountByAge.description',
    },
    [this.chartIds.attritionOverTime]: {
      title: 'pages:dashboards.attrition.info.attritionOverTime.title',
      description: 'pages:dashboards.attrition.info.attritionOverTime.description',
    },
    [this.chartIds.attritionByEmploymentType]: {
      title: 'pages:dashboards.attrition.info.attritionByEmploymentType.title',
      description: 'pages:dashboards.attrition.info.attritionByEmploymentType.description',
    },
    [this.chartIds.attritionByOrganization]: {
      title: 'pages:dashboards.attrition.info.attritionByOrganization.title',
      description: 'pages:dashboards.attrition.info.attritionByOrganization.description',
    },
    [this.chartIds.attritionByLocation]: {
      title: 'pages:dashboards.attrition.info.attritionByLocation.title',
      description: 'pages:dashboards.attrition.info.attritionByLocation.description',
    },
    [this.chartIds.attritionByFunction]: {
      title: 'pages:dashboards.attrition.info.attritionByFunction.title',
      description: 'pages:dashboards.attrition.info.attritionByFunction.description',
    },
    [this.chartIds.attritionByPosition]: {
      title: 'pages:dashboards.attrition.info.attritionByPosition.title',
      description: 'pages:dashboards.attrition.info.attritionByPosition.description',
    },
    [this.chartIds.attritionByTenure]: {
      title: 'pages:dashboards.attrition.info.attritionByTenure.title',
      description: 'pages:dashboards.attrition.info.attritionByTenure.description',
    },
    [this.chartIds.attritionByGender]: {
      title: 'pages:dashboards.attrition.info.attritionByGender.title',
      description: 'pages:dashboards.attrition.info.attritionByGender.description',
    },
    [this.chartIds.attritionByJobGrade]: {
      title: 'pages:dashboards.attrition.info.attritionByJobGrade.title',
      description: 'pages:dashboards.attrition.info.attritionByJobGrade.description',
    },
    [this.chartIds.attritionByLocalOrNonLocal]: {
      title: 'pages:dashboards.attrition.info.attritionByLocalOrNonLocal.title',
      description: 'pages:dashboards.attrition.info.attritionByLocalOrNonLocal.description',
    },
    [this.chartIds.attritionByAge]: {
      title: 'pages:dashboards.attrition.info.attritionByAge.title',
      description: 'pages:dashboards.attrition.info.attritionByAge.description',
    },
    [this.chartIds.attritionCheckBox]: {
      title: 'pages:dashboards.attrition.info.attritionCheckBox.title',
      description: 'pages:dashboards.attrition.info.attritionCheckBox.description',
    },
    [this.chartIds.genderSplitOverTime]: {
      title: 'pages:dashboards.diversity.info.genderSplitOverTime.title',
      description: 'pages:dashboards.diversity.info.genderSplitOverTime.description',
    },
    [this.chartIds.genderGradeOverTime]: {
      title: 'pages:dashboards.diversity.info.genderGradeOverTime.title',
      description: 'pages:dashboards.diversity.info.genderGradeOverTime.description',
    },
    [this.chartIds.genderSplitByLocation]: {
      title: 'pages:dashboards.diversity.info.genderSplitByLocation.title',
      description: 'pages:dashboards.diversity.info.genderSplitByLocation.description',
    },
    [this.chartIds.genderSplitByOrganization]: {
      title: 'pages:dashboards.diversity.info.genderSplitByOrganization.title',
      description: 'pages:dashboards.diversity.info.genderSplitByOrganization.description',
    },
    [this.chartIds.genderSplitByFunction]: {
      title: 'pages:dashboards.diversity.info.genderSplitByFunction.title',
      description: 'pages:dashboards.diversity.info.genderSplitByFunction.description',
    },
    [this.chartIds.genderSplitByJobGrade]: {
      title: 'pages:dashboards.diversity.info.genderSplitByJobGrade.title',
      description: 'pages:dashboards.diversity.info.genderSplitByJobGrade.description',
    },
    [this.chartIds.genderSplitByAge]: {
      title: 'pages:dashboards.diversity.info.genderSplitByAge.title',
      description: 'pages:dashboards.diversity.info.genderSplitByAge.description',
    },
    [this.chartIds.genderSplitByLocalOrNonLocal]: {
      title: 'pages:dashboards.diversity.info.genderSplitByLocalOrNonLocal.title',
      description: 'pages:dashboards.diversity.info.genderSplitByLocalOrNonLocal.description',
    },
    [this.chartIds.genderSplitByTenure]: {
      title: 'pages:dashboards.diversity.info.genderSplitByTenure.title',
      description: 'pages:dashboards.diversity.info.genderSplitByTenure.description',
    },
    [this.chartIds.attritionByGenderOverTime]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderOverTime.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderOverTime.description',
    },
    [this.chartIds.attritionByGenderAndLocation]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndLocation.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndLocation.description',
    },
    [this.chartIds.attritionByGenderAndOrganization]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndOrganization.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndOrganization.description',
    },
    [this.chartIds.attritionByGenderAndFunction]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndFunction.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndFunction.description',
    },
    [this.chartIds.attritionByGenderAndTenure]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndTenure.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndTenure.description',
    },
    [this.chartIds.attritionByGenderAndLocalOrNonLocal]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndLocalOrNonLocal.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndLocalOrNonLocal.description',
    },
    [this.chartIds.attritionByGenderAndAge]: {
      title: 'pages:dashboards.diversity.info.attritionByGenderAndAge.title',
      description: 'pages:dashboards.diversity.info.attritionByGenderAndAge.description',
    },
    [this.chartIds.diversityGenderPaygap]: {
      title: 'pages:dashboards.diversity.info.diversityGenderPaygap.title',
      description: 'pages:dashboards.diversity.info.diversityGenderPaygap.description',
    },
    [this.chartIds.payGapByGrade]: {
      title: 'pages:dashboards.diversity.info.payGapByGrade.title',
      description: 'pages:dashboards.diversity.info.payGapByGrade.description',
    },
    [this.chartIds.payGapOverTime]: {
      title: 'pages:dashboards.diversity.info.payGapOverTime.title',
      description: 'pages:dashboards.diversity.info.payGapOverTime.description',
    },
    [this.chartIds.payGapByOrganization]: {
      title: 'pages:dashboards.diversity.info.payGapByOrganization.title',
      description: 'pages:dashboards.diversity.info.payGapByOrganization.description',
    },
    [this.chartIds.payGapByLocation]: {
      title: 'pages:dashboards.diversity.info.payGapByLocation.title',
      description: 'pages:dashboards.diversity.info.payGapByLocation.description',
    },
    [this.chartIds.payGapByFunction]: {
      title: 'pages:dashboards.diversity.info.payGapByFunction.title',
      description: 'pages:dashboards.diversity.info.payGapByFunction.description',
    },
    [this.chartIds.payGapByTenure]: {
      title: 'pages:dashboards.diversity.info.payGapByTenure.title',
      description: 'pages:dashboards.diversity.info.payGapByTenure.description',
    },
    [this.chartIds.payGapByLocalOrNonLocal]: {
      title: 'pages:dashboards.diversity.info.payGapByLocalOrNonLocal.title',
      description: 'pages:dashboards.diversity.info.payGapByLocalOrNonLocal.description',
    },
    [this.chartIds.payGapByAge]: {
      title: 'pages:dashboards.diversity.info.payGapByAge.title',
      description: 'pages:dashboards.diversity.info.payGapByAge.description',
    },
    [this.chartIds.acrSplitByGender]: {
      title: 'pages:dashboards.diversity.info.acrSplitByGender.title',
      description: 'pages:dashboards.diversity.info.acrSplitByGender.description',
    },
    [this.chartIds.acrByGradeAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByGradeAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByGradeAndGender.description',
    },
    [this.chartIds.acrOverTime]: {
      title: 'pages:dashboards.diversity.info.acrOverTime.title',
      description: 'pages:dashboards.diversity.info.acrOverTime.description',
    },
    [this.chartIds.acrByLocationAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByLocationAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByLocationAndGender.description',
    },
    [this.chartIds.acrByOrganizationAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByOrganizationAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByOrganizationAndGender.description',
    },
    [this.chartIds.acrByFunctionAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByFunctionAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByFunctionAndGender.description',
    },
    [this.chartIds.acrByAgeAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByAgeAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByAgeAndGender.description',
    },
    [this.chartIds.acrByTenureAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByTenureAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByTenureAndGender.description',
    },
    [this.chartIds.acrByLocalOrNonLocalAndGender]: {
      title: 'pages:dashboards.diversity.info.acrByLocalOrNonLocalAndGender.title',
      description: 'pages:dashboards.diversity.info.acrByLocalOrNonLocalAndGender.description',
    },
    [this.chartIds.acrByGradeAndLocation]: {
      title: 'pages:dashboards.medianBase.info.acrByGradeAndLocation.title',
      description: 'pages:dashboards.medianBase.info.acrByGradeAndLocation.description',
    },
    [this.chartIds.acrByGenderAndGrade]: {
      title: 'pages:dashboards.medianBase.info.acrByGenderAndGrade.title',
      description: 'pages:dashboards.medianBase.info.acrByGenderAndGrade.description',
    },
    [this.chartIds.acrByGradeAndOrganization]: {
      title: 'pages:dashboards.medianBase.info.acrByGradeAndOrganization.title',
      description: 'pages:dashboards.medianBase.info.acrByGradeAndOrganization.description',
    },

    [this.chartIds.acrByLocation]: {
      title: 'pages:dashboards.medianBase.info.acrByLocation.title',
      description: 'pages:dashboards.medianBase.info.acrByLocation.description',
    },
    [this.chartIds.acrByGender]: {
      title: 'pages:dashboards.medianBase.info.acrByGender.title',
      description: 'pages:dashboards.medianBase.info.acrByGender.description',
    },
    [this.chartIds.acrByOrganization]: {
      title: 'pages:dashboards.medianBase.info.acrByOrganization.title',
      description: 'pages:dashboards.medianBase.info.acrByOrganization.description',
    },
    [this.chartIds.medianBaseByMonth]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByMonth.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByMonth.description',
    },
    [this.chartIds.medianBaseByYear]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByYear.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByYear.description',
    },
    [this.chartIds.medianBaseByEmploymentType]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByEmploymentType.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByEmploymentType.description',
    },
    [this.chartIds.medianBaseByOrganization]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByOrganization.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByOrganization.description',
    },
    [this.chartIds.medianBaseByLocation]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByLocation.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByLocation.description',
    },
    [this.chartIds.medianBaseByTenure]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByTenure.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByTenure.description',
    },
    [this.chartIds.medianBaseByGender]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByGender.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByGender.description',
    },
    [this.chartIds.medianBaseByJobGrade]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByJobGrade.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByJobGrade.description',
    },
    [this.chartIds.medianBaseByLocalOrNonLocal]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByLocalOrNonLocal.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByLocalOrNonLocal.description',
    },
    [this.chartIds.medianBaseByAge]: {
      title: 'pages:dashboards.medianBase.info.medianBaseByAge.title',
      description: 'pages:dashboards.medianBase.info.medianBaseByAge.description',
    },
    [this.chartIds.totalCompByMonth]: {
      title: 'pages:dashboards.totalComp.info.totalCompByMonth.title',
      description: 'pages:dashboards.totalComp.info.totalCompByMonth.description',
    },
    [this.chartIds.totalCompByYear]: {
      title: 'pages:dashboards.totalComp.info.totalCompByYear.title',
      description: 'pages:dashboards.totalComp.info.totalCompByYear.description',
    },
    [this.chartIds.totalCompByEmploymentType]: {
      title: 'pages:dashboards.totalComp.info.totalCompByEmploymentType.title',
      description: 'pages:dashboards.totalComp.info.totalCompByEmploymentType.description',
    },
    [this.chartIds.totalCompByOrganization]: {
      title: 'pages:dashboards.totalComp.info.totalCompByOrganization.title',
      description: 'pages:dashboards.totalComp.info.totalCompByOrganization.description',
    },
    [this.chartIds.recruitmentOverviewTimeToFillHireReject]: {
      title: 'pages:dashboards.recruitment.recruitmentOverviewTimeToFillHireReject.title',
      description: 'pages:dashboards.recruitment.recruitmentOverviewTimeToFillHireReject.description',
    },
    [this.chartIds.attendanceOverTime]: {
      title: 'pages:dashboards.attendance.first.attendanceOverTime.title',
      description: 'pages:dashboards.attendance.first.attendanceOverTime.description',
    },
    [this.chartIds.overtimeByLocation]: {
      title: 'pages:dashboards.attendance.first.overtimeByLocation.title',
      description: 'pages:dashboards.attendance.first.overtimeByLocation.description',
    },
    [this.chartIds.overtimeByOrganization]: {
      title: 'pages:dashboards.attendance.first.overtimeByOrganization.title',
      description: 'pages:dashboards.attendance.first.overtimeByOrganization.description',
    },
    [this.chartIds.overtimeByTenure]: {
      title: 'pages:dashboards.attendance.first.overtimeByTenure.title',
      description: 'pages:dashboards.attendance.first.overtimeByTenure.description',
    },

    [this.chartIds.evaluation.overview.evaluationScoreByLocation]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByLocation.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByLocation.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByAge]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByAge.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByAge.description',
    },
    [this.chartIds.evaluation.overview.evaluationDistribution]: {
      title: 'pages:dashboards.evaluation.info.evaluationDistribution.title',
      description: 'pages:dashboards.evaluation.info.evaluationDistribution.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByGender]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByGender.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByGender.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByGrade]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByGrade.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByGrade.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByLocalNonLocal]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByLocalNonLocal.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByLocalNonLocal.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByOrganization]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByOrganization.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByOrganization.description',
    },
    [this.chartIds.evaluation.overview.evaluationScoreByTenure]: {
      title: 'pages:dashboards.evaluation.info.evaluationScoreByTenure.title',
      description: 'pages:dashboards.evaluation.info.evaluationScoreByTenure.description',
    },
  };

  // private chartDatabase: Record<string, ChartInformation> = {};

  constructor() {
    const {
      headcountByMonth,
      headcountByYear,
      headcountByEmploymentType,
      headcountByOrganization,
      headcountByLocation,
      headcountByFunction,
      headcountByPosition,
      headcountByTenure,
      headcountByGender,
      headcountByJobGrade,
      headcountByNationalities,
      headcountByAge,
      keyMetricsFactbox,
    } = this.chartIds.peopleSnapshot;
    const {
      attritionByAge,
      attritionByEmploymentType,
      attritionByGender,
      attritionByJobGrade,
      attritionByLocalOrNonLocal,
      attritionByLocation,
      attritionByFunction,
      attritionByPosition,
      attritionByOrganization,
      attritionByTenure,
      attritionCheckBox,
      attritionOverTime,
    } = this.chartIds.attrition.overview;
    const {
      genderGradeOverTime,
      genderSplitByAge,
      genderSplitByFunction,
      genderSplitByJobGrade,
      genderSplitByLocalOrNonLocal,
      genderSplitByLocation,
      genderSplitByOrganization,
      genderSplitByTenure,
      genderSplitOverTime,
    } = this.chartIds.diversity.demographics;
    const {
      attritionByGenderAndAge,
      attritionByGenderAndFunction,
      attritionByGenderAndLocalOrNonLocal,
      attritionByGenderAndLocation,
      attritionByGenderAndOrganization,
      attritionByGenderAndTenure,
      attritionByGenderOverTime,
    } = this.chartIds.diversity.joinersAndLeavers;
    const {
      joinersAndLeaversByAge,
      joinersAndLeaversByEmploymentType,
      joinersAndLeaversByGender,
      joinersAndLeaversByJobGrade,
      joinersAndLeaversByLocalOrNonLocal,
      joinersAndLeaversByLocation,
      joinersAndLeaversByOrganization,
      joinersAndLeaversByJobTitle,
      joinersAndLeaversByTenure,
      joinersAndLeaversOverTime,
    } = this.chartIds.joinersAndLeavers.overview;
    const { medianBaseByEmploymentType, medianBaseByMonth, medianBaseByYear, medianBaseOverviewFactbox } =
      this.chartIds.medianBase.overview;
    const {
      medianBaseByAge,
      medianBaseByGender,
      medianBaseByJobGrade,
      medianBaseByLocalOrNonLocal,
      medianBaseByLocation,
      medianBaseByOrganization,
      medianBaseByTenure,
      medianBaseKpiFactbox,
    } = this.chartIds.medianBase.keyMetrics;
    const {
      acrByGender,
      acrByGenderAndGrade,
      acrByGradeAndLocation,
      acrByGradeAndOrganization,
      acrByLocation,
      acrByOrganization,
    } = this.chartIds.medianBase.compaRatio;
    const {
      recruitmentOverviewFactbox,
      agingRequisitions,
      avgTimeToFillHireReject,
      openRequisitionsAndHiresOverTime,
      applications,
      offersAndHires,
    } = this.chartIds.recruitment.overview;
    const { leftFactbox, rightFactbox, funnel, applicationByOffice, applicationByOrganization } =
      this.chartIds.recruitmentFunnel.recruitmentFunnel;
    const { totalCompByYear, totalCompByMonth, totalCompByEmploymentType, totalCompOverviewFactbox } =
      this.chartIds.totalComp.overview;
    const {
      totalCompByAge,
      totalCompByGender,
      totalCompByJobGrade,
      totalCompByLocalOrNonLocal,
      totalCompByLocation,
      totalCompByOrganization,
      totalCompByTenure,
    } = this.chartIds.totalComp.keyMetrics;
    this.chartDatabase = {
      [headcountByMonth]: { enTourId: 114595, jaTourId: 120696 },
      [headcountByYear]: { enTourId: 114603, jaTourId: 120695 },
      [headcountByEmploymentType]: {
        enTourId: 114606,
        jaTourId: 120694,
      },
      // [headcountFactbox]: { enTourId: 116067, jaTourId: 120635 },
      [headcountByOrganization]: { enTourId: 114610, jaTourId: 120693 },
      [headcountByLocation]: { enTourId: 114613, jaTourId: 120692 },
      [headcountByFunction]: { enTourId: null },
      [headcountByPosition]: { enTourId: null },
      [headcountByTenure]: { enTourId: 114616, jaTourId: 120691 },
      [headcountByGender]: { enTourId: 114623, jaTourId: 120690 },
      [headcountByJobGrade]: { enTourId: 114633, jaTourId: 120689 },
      [headcountByNationalities]: { enTourId: 114638, jaTourId: 120688 },
      [headcountByAge]: { enTourId: 114639, jaTourId: 120650 },
      [keyMetricsFactbox]: { enTourId: null },
      [attritionByAge]: { enTourId: 114720, jaTourId: 120637 },
      [attritionByEmploymentType]: {
        enTourId: 116087,
        jaTourId: 120633,
      },
      [attritionByGender]: { enTourId: 114716, jaTourId: 120640 },
      [attritionByJobGrade]: { enTourId: 114717, jaTourId: 120639 },
      [attritionByLocalOrNonLocal]: {
        enTourId: 114719,
        jaTourId: 120638,
      },
      [attritionByLocation]: { enTourId: 114713, jaTourId: 120642 },
      [attritionByFunction]: { enTourId: null },
      [attritionByPosition]: { enTourId: null },
      [attritionByOrganization]: { enTourId: 114708, jaTourId: 120645 },
      [attritionByTenure]: { enTourId: 114715, jaTourId: 120641 },
      [attritionCheckBox]: { enTourId: 116037, jaTourId: 120636 },
      [attritionOverTime]: { enTourId: 114704, jaTourId: 120646 },
      [genderGradeOverTime]: { enTourId: 116110, jaTourId: 120631 },
      [genderSplitByAge]: { enTourId: 116125, jaTourId: 120619 },
      [genderSplitByFunction]: { enTourId: 116118, jaTourId: 120612 },
      [genderSplitByJobGrade]: { enTourId: 116120, jaTourId: 120627 },
      [genderSplitByLocalOrNonLocal]: {
        enTourId: 116124,
        jaTourId: 120625,
      },
      [genderSplitByLocation]: { enTourId: 116116, jaTourId: 120629 },
      [genderSplitByOrganization]: {
        enTourId: 116114,
        jaTourId: 120630,
      },
      [genderSplitByTenure]: { enTourId: 116119, jaTourId: 120628 },
      [genderSplitOverTime]: { enTourId: 116101, jaTourId: 120632 },
      [attritionByGenderAndAge]: { enTourId: 116138, jaTourId: 120610 },
      [attritionByGenderAndFunction]: {
        enTourId: 116131,
        jaTourId: 120614,
      },
      [attritionByGenderAndLocalOrNonLocal]: {
        enTourId: 116140,
        jaTourId: 120412,
      },
      [attritionByGenderAndLocation]: {
        enTourId: 116134,
        jaTourId: 120613,
      },
      [attritionByGenderAndOrganization]: {
        enTourId: 116133,
        jaTourId: 120605,
      },
      [attritionByGenderAndTenure]: {
        enTourId: 116136,
        jaTourId: 120611,
      },
      [attritionByGenderOverTime]: {
        enTourId: 116127,
        jaTourId: 120615,
      },
      [joinersAndLeaversByAge]: { enTourId: 116161, jaTourId: 120396 },
      [joinersAndLeaversByEmploymentType]: { enTourId: null },
      [joinersAndLeaversByGender]: {
        enTourId: 116151,
        jaTourId: 120400,
      },
      [joinersAndLeaversByJobGrade]: {
        enTourId: 116157,
        jaTourId: 120399,
      },
      [joinersAndLeaversByLocalOrNonLocal]: {
        enTourId: 116159,
        jaTourId: 120398,
      },
      [joinersAndLeaversByLocation]: {
        enTourId: 116149,
        jaTourId: 120402,
      },
      [joinersAndLeaversByOrganization]: {
        enTourId: 116146,
        jaTourId: 120409,
      },
      [joinersAndLeaversByJobTitle]: { enTourId: null, jaTourId: null },
      [joinersAndLeaversByTenure]: {
        enTourId: 116150,
        jaTourId: 120401,
      },
      [joinersAndLeaversOverTime]: {
        enTourId: 116144,
        jaTourId: 120411,
      },
      [medianBaseByEmploymentType]: {
        enTourId: 116169,
        jaTourId: 119896,
      },
      [medianBaseByMonth]: { enTourId: 116165, jaTourId: 120395 },
      [medianBaseByYear]: { enTourId: 116167, jaTourId: 120389 },
      [medianBaseOverviewFactbox]: {
        enTourId: 116168,
        jaTourId: 120136,
      },
      [medianBaseByAge]: { enTourId: 116174, jaTourId: 119801 },
      [medianBaseByGender]: { enTourId: 116176, jaTourId: 119785 },
      [medianBaseByJobGrade]: { enTourId: 116177, jaTourId: 119776 },
      [medianBaseByLocalOrNonLocal]: {
        enTourId: 116178,
        jaTourId: 119764,
      },
      [medianBaseByLocation]: { enTourId: 116172, jaTourId: 119823 },
      [medianBaseByOrganization]: { enTourId: 116171, jaTourId: 119892 },
      [medianBaseByTenure]: { enTourId: 116173, jaTourId: 119806 },
      [medianBaseKpiFactbox]: { enTourId: null },
      [acrByGender]: { enTourId: 116183, jaTourId: 119744 },
      [acrByGenderAndGrade]: { enTourId: 116185, jaTourId: 119740 },
      [acrByGradeAndLocation]: { enTourId: 116184, jaTourId: 119743 },
      [acrByGradeAndOrganization]: {
        enTourId: 116186,
        jaTourId: 119739,
      },
      [acrByLocation]: { enTourId: 116181, jaTourId: 119762 },
      [acrByOrganization]: { enTourId: 116182, jaTourId: 119748 },
      [recruitmentOverviewFactbox]: { enTourId: null },
      [agingRequisitions]: { enTourId: null },
      [avgTimeToFillHireReject]: { enTourId: null },
      [openRequisitionsAndHiresOverTime]: { enTourId: null },
      [applications]: { enTourId: null },
      [offersAndHires]: { enTourId: null },
      [leftFactbox]: { enTourId: null },
      [rightFactbox]: { enTourId: null },
      [funnel]: { enTourId: null },
      [applicationByOffice]: { enTourId: null },
      [applicationByOrganization]: { enTourId: null },
      [totalCompByYear]: { enTourId: null },
      [totalCompByMonth]: { enTourId: null },
      [totalCompByEmploymentType]: { enTourId: null },
      [totalCompOverviewFactbox]: { enTourId: null },
      [totalCompByAge]: { enTourId: null },
      [totalCompByGender]: { enTourId: null },
      [totalCompByJobGrade]: { enTourId: null },
      [totalCompByLocalOrNonLocal]: { enTourId: null },
      [totalCompByLocation]: { enTourId: null },
      [totalCompByOrganization]: { enTourId: null },
      [totalCompByTenure]: { enTourId: null },
    };

    /**
     * Logic to flag any duplicated keys
     */

    const allChartIds = new Set();
    const duplicatedChartIds = new Set();

    Object.values(this.chartIds).forEach((db) => {
      Object.values(db).forEach((page) => {
        Object.values(page).forEach((chartId) => {
          if (allChartIds.has(chartId)) {
            duplicatedChartIds.add(chartId);
          }
          allChartIds.add(chartId);
        });
      });
    });
    // tslint:disable-next-line:no-console
    console.warn('Duplicate chart ids found: ', duplicatedChartIds);
  }

  public getChartDescription(id: string) {
    return (
      (id in this.chartDatabase && this.chartDatabase[id]) || {
        title: 'common:info.noInfoTitle',
        description: 'common:info.noInfoDescription',
        enTourId: null,
        jaTourId: null,
      }
    );
  }
}
