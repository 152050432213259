import mixpanel from 'mixpanel-browser';
import { environmentService } from '../environment/environment-service';
import { Environments } from '../environment/environments';

if (
  environmentService.environment.name === Environments.QA ||
  environmentService.environment.name === Environments.DEMO ||
  environmentService.environment.name === Environments.JAPAN ||
  environmentService.environment.name === Environments.EU ||
  environmentService.environment.name === Environments.PROD
) {
  mixpanel.init(environmentService.environment.mixpanelToken, { api_host: 'https://proxymixp.panalyt.com' });
} else {
  // Point to the new mixpanel proxy
  mixpanel.init(environmentService.environment.mixpanelToken, { api_host: 'https://panacloud-proxymixp.panalyt.com' });
}

const actions = {
  identify: (id: string) => {
    if (environmentService.isProd) {
      mixpanel.identify(id);
    }
  },
  track: (name: string, props: Record<string, any>) => {
    if (environmentService.isProd) {
      mixpanel.track(name, props);
    }
  },
  register: (props: Record<string, any>) => {
    if (environmentService.isProd) {
      mixpanel.register(props);
    }
  },
  people: () => mixpanel.people,
};

export const Mixpanel = actions;
