import { promisedComputed } from 'computed-async-mobx';
import isEqual from 'lodash.isequal';
import { action, autorun, computed, observable, runInAction, toJS } from 'mobx';
import moment from 'moment';
import { compareFilterToDim, DataFieldWithDataType } from '../../../common-types';
import { toggleFilterFromGivenFilters } from '../../common/filter/utils';
import { onaStore } from '../../pages/dashboard/ona-relational-analytics/ona-store';
import {
  ApiEmployeeSearchResponse,
  ApiEmployeeSearchResponseItem,
  ApiHierarchyItem,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataQuery,
  ApiMasterDataQueryFilterItem,
  MultiLevelEmployeeFieldKind,
} from '../api/api-interfaces';
import { employeeDataApiService } from '../api/employee-data-service/employee-data-service';
import { cachedMasterDataApiService } from '../api/master-data-service';
import { BenchmarkStore } from '../benchmark/benchmark-store';
import { InternalBenchmarkTypes } from '../benchmark/types';
import { CompanySettingsStore } from '../company-settings-store';
import { CompanyStore } from '../company/company-store';
import { DataFields, DataTypes, EmployeeDataFields } from '../constants/constants';
import { UndefinedValue } from '../constants/systemValuesTranslation';
import { DashboardSettingsStore } from '../dashboard/dashboard-settings-store';
import { Granularity, Months } from '../date-manager/date-manager-constants';
import { trackFilterReset } from '../helpers/trackers/filterTracker';
import { localStore } from '../local-store';
import { GetResponseForAdvancedMasterQueryService } from '../services/GetResponseForAdvancedQueryService';
import { GetResponseForMasterQueryService } from '../services/GetResponseForQueryService';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { TimeSliderStore } from '../timeslider/timeslider-store';
import { formatEmpIdToNameAndTitle } from '../utilFunctions/formatters';
import { hasUndefined } from '../utilFunctions/utils';
import { getVersionFilter, PAST_PRESENT_TEMPORALITY_FILTER } from './common-filters';
import { combineFilters } from './utils';

export type NonHierarchicalFilterValue = string | null;

export type HierarchicalFilterValue = NonHierarchicalFilterValue[];

export type FilterValue = HierarchicalFilterValue | NonHierarchicalFilterValue;

export type DrillDownLeafSets = Record<MultiLevelEmployeeFieldKind, Set<string>>;

export type PrefetchedEmployeeFields =
  | EmployeeDataFields.EMPLOYEE_ID
  | EmployeeDataFields.COMPANY_EMAIL
  | EmployeeDataFields.FULL_NAME
  | EmployeeDataFields.LOCAL_FULL_NAME
  | EmployeeDataFields.MANAGER_ID
  | EmployeeDataFields.JOB_TITLE
  | EmployeeDataFields.ORGANIZATION_LEVEL_1
  | EmployeeDataFields.ORGANIZATION_LEVEL_2
  | EmployeeDataFields.ORGANIZATION_LEVEL_3
  | EmployeeDataFields.LOCATION_LEVEL_1
  | EmployeeDataFields.LOCATION_LEVEL_2
  | EmployeeDataFields.LOCATION_LEVEL_3
  | EmployeeDataFields.FUNCTION_LEVEL_1
  | EmployeeDataFields.FUNCTION_LEVEL_2
  | EmployeeDataFields.FUNCTION_LEVEL_3;

export type EmployeeData = Record<string, Record<DataFields, string | number>>;

export type PrefetchedEmployeeData = Record<string, Record<PrefetchedEmployeeFields, string | number>>;
export interface TimeSliderConfig {
  startDate: string;
  endDate: string;
  granularity: Granularity;
  filterItemProperty?: (startDate: moment.Moment, endDate: moment.Moment) => ApiMasterDataQueryFilterItem[];
  rangeStartDate: string | null;
  rangeEndDate: string | null;
  specificPeriodBenchmarkStartDate: string | null;
  specificPeriodBenchmarkEndDate: string | null;
  firstMonthOfYear: Months;
}
export interface PeriodRange {
  startDate: string | null;
  endDate: string | null;
}

interface ToggleFilterOptions {
  filter: ApiMasterDataQueryFilterItem;
  isBenchmark?: boolean;
  customFilters?: ApiMasterDataQueryFilterItem[];
  afterApplyFilters?: any;
}

export type ToggleMultipleFilters = (
  filtersToToggle: ApiMasterDataQueryFilterItem[],
  givenFilters?: ApiMasterDataQueryFilterItem[]
) => void;

export type ToggleFilter = (options: ToggleFilterOptions) => void;

interface ResetFilterOptions {
  isBenchmark?: boolean;
  customFilters?: ApiMasterDataQueryFilterItem[];
  afterApplyFilters?: any;
}

export type ResetFilters = (options?: ResetFilterOptions) => void;

export const nullFilter = { name: UndefinedValue };
export class FilterStore {
  @computed
  private get currencyQuery(): ApiMasterDataQuery {
    return {
      dataType: DataTypes.EMPLOYEE,
      dimensions: ['LOCAL_SALARY_CURRENCY'],
      filterItems: [
        ...combineFilters([
          ...this.filters,
          {
            operation: 'EQUAL',
            property: 'EMPLOYMENT_TEMPORALITY',
            values: ['PRESENT', 'PAST'],
            dataType: 'EMPLOYEE',
          },
        ]),
      ],
    };
  }

  @observable
  public filters: ApiMasterDataQueryFilterItem[] = [];

  @observable
  public filtersHistory: ApiMasterDataQueryFilterItem[][] = [[]];

  @observable
  public filtersHistoryActiveIndex = 0;

  @observable
  public benchmarkFilters: ApiMasterDataQueryFilterItem[] = [];

  @observable
  public versions: string[] = [];

  @observable
  public employeeData: PrefetchedEmployeeData | null = null;

  @observable
  public currentEmployeeFilters: ApiHierarchyItem[] = [];

  @observable
  public employeeTerminationDates: Record<string, string> | null = null;

  @observable
  public onaFilter: Partial<ApiMasterDataQueryFilterItem> = {};

  public currencyToggleOptions = promisedComputed([], async () => {
    const response = await cachedMasterDataApiService.executeQuery(this.companyStore.domain, this.currencyQuery);
    let result =
      (response.dataPoints &&
        response.dataPoints.map((dp) => {
          const dim = dp.dimensions.find((d) => d.property === 'LOCAL_SALARY_CURRENCY');
          return (dim && dim.value) || '';
        })) ||
      [];
    result = result.filter((r) => r.length >= 1);
    if (result.length > 1 && this.dashboardSettingsStore.isLocalCurrency) {
      this.dashboardSettingsStore.toggleisLocalCurrency();
    }
    return result;
  });

  private companyStore: CompanyStore;
  private companySettingsStore: CompanySettingsStore;
  private dashboardSettingsStore: DashboardSettingsStore;
  private benchmarkStore: BenchmarkStore;
  private timeSliderStore: TimeSliderStore;
  private domainDependencyStore: DomainDependencyStore;

  public constructor(
    companyStore: CompanyStore,
    companySettingsStore: CompanySettingsStore,
    dashboardSettingsStore: DashboardSettingsStore,
    benchmarkStore: BenchmarkStore,
    timeSliderStore: TimeSliderStore,
    domainDependencyStore: DomainDependencyStore
  ) {
    this.companyStore = companyStore;
    this.companySettingsStore = companySettingsStore;
    this.dashboardSettingsStore = dashboardSettingsStore;
    this.benchmarkStore = benchmarkStore;
    this.timeSliderStore = timeSliderStore;
    this.domainDependencyStore = domainDependencyStore;

    let firstRun = true;
    autorun(() => {
      const storeData = JSON.stringify({
        filters: this.filters,
        timeSliderConfig: this.timeSliderStore.timeSliderConfig,
      });

      if (!firstRun) {
        localStore.setAsync('filterStore', storeData);
      }
      firstRun = false;
    });
  }

  @action
  public updateFilterHistory = () => {
    // I haven't taken into account benchmark filters and other such rare cases
    this.filtersHistory = this.filtersHistory.slice(0, this.filtersHistoryActiveIndex + 1);
    this.filtersHistory.push(toJS(this.filters));
    this.filtersHistoryActiveIndex += 1;
  };

  @action
  public undoFilters = () => {
    if (this.filtersHistoryActiveIndex > 0) {
      this.filters = [...this.filtersHistory[--this.filtersHistoryActiveIndex]];
    }
  };

  @action
  public redoFilters = () => {
    if (this.filtersHistoryActiveIndex < this.filtersHistory.length - 1) {
      this.filters = [...this.filtersHistory[++this.filtersHistoryActiveIndex]];
    }
  };

  @action
  public applyDomainSpecificFilters = () => {
    const filters = this.companySettingsStore.defaultFilters();
    for (const filter of filters) {
      const filterIndex = this.filters.findIndex((f) => isEqual(f, filter));
      if (filterIndex === -1) {
        // toggle only if it doesn't already exist, otherwise toggling would remove the filter
        this.toggleFilter({ filter });
      }
    }
  };

  @action
  public loadEmployeeData = async () => {
    const latestVersion = this.domainDependencyStore.getLatestVersion(DataTypes.EMPLOYEE);
    if (!latestVersion) {
      throw new Error(`latest version not found for datatype employee`);
    }
    const employeeDataQuery: ApiMasterDataAdvancedQuery = {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.EMPLOYEE_ID },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.COMPANY_EMAIL },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.MANAGER_ID },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FULL_NAME },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCAL_FULL_NAME },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.JOB_TITLE },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.ORGANIZATION_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.LOCATION_LEVEL_3 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_1 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_2 },
        { dataType: DataTypes.EMPLOYEE, property: EmployeeDataFields.FUNCTION_LEVEL_3 },
      ],
      filterItems: [getVersionFilter(latestVersion)],
    };
    const result = await GetResponseForAdvancedMasterQueryService(employeeDataQuery);
    const employeeData = result?.dataPoints?.reduce((acc: PrefetchedEmployeeData, dp) => {
      const key: string = dp.dimensions[0].value;
      (acc[key] as any) = Object.fromEntries(dp.dimensions.map((dim) => [dim.property, dim.value]));
      return acc;
    }, {});
    this.employeeData = employeeData;
  };

  @action
  public toggleFilter: ToggleFilter = ({ filter, isBenchmark = false, customFilters = [], afterApplyFilters }) => {
    const useCustomFilters = customFilters && afterApplyFilters;
    const filters = useCustomFilters ? customFilters : isBenchmark ? this.benchmarkFilters : this.filters;
    toggleFilterFromGivenFilters(filter, filters);
    if (useCustomFilters) {
      afterApplyFilters(filters);
    }
    this.updateFilterHistory();
  };

  @action
  public toggleMultipleFilters: ToggleMultipleFilters = (
    filtersToToggle: ApiMasterDataQueryFilterItem[],
    givenFilters?: ApiMasterDataQueryFilterItem[]
  ) => {
    const filters = givenFilters ? givenFilters : this.filters;

    filtersToToggle.forEach((filter) => {
      toggleFilterFromGivenFilters(filter, filters);
    });
    this.updateFilterHistory();
  };

  @action
  public toggleMultipleFiltersInLayer = (multipleFilters: ApiMasterDataQueryFilterItem[]) => {
    const filters = onaStore.tempOnaLayers.last()?.filters as ApiMasterDataQueryFilterItem[];
    multipleFilters.forEach((filter) => {
      toggleFilterFromGivenFilters(filter, filters);
    });
  };

  @action
  public setFilters = (filters: ApiMasterDataQueryFilterItem[]) => {
    this.filters = filters;
    this.updateFilterHistory();
  };

  @action
  public resetFilters: ResetFilters = ({ isBenchmark = false, customFilters = [], afterApplyFilters = null } = {}) => {
    if (this.filters.length === 0) {
      return;
    }
    if (customFilters && afterApplyFilters) {
      afterApplyFilters([]);
    } else {
      if (isBenchmark) {
        this.benchmarkFilters = [];
      } else {
        this.filters = [];
      }
    }

    trackFilterReset();
    this.updateFilterHistory();
  };

  @action
  public applyDrillDownFilters({
    filters,
    baseDim,
    afterApplyFilters,
  }: {
    filters: ApiMasterDataQueryFilterItem[];
    baseDim: DataFieldWithDataType;
    afterApplyFilters?: any;
  }) {
    const { selectedBenchmark } = this.benchmarkStore;
    const applyBenchmarkFilters = selectedBenchmark && selectedBenchmark.value === InternalBenchmarkTypes.MyPop;

    filters = filters.filter((f) => !hasUndefined(f));

    this.filters = this.filters.filter((f) => !compareFilterToDim(f, baseDim));
    this.filters = [...this.filters, ...filters];

    if (applyBenchmarkFilters) {
      this.benchmarkFilters = this.benchmarkFilters.filter((f) => !compareFilterToDim(f, baseDim));
      this.benchmarkFilters = [...this.benchmarkFilters, ...filters];
    }

    if (afterApplyFilters) {
      afterApplyFilters(this.filters);
    }
    this.updateFilterHistory();
  }

  @action
  public loadEmployeeFilters = async (searchQuery: string): Promise<ApiHierarchyItem[]> => {
    if (searchQuery.length < 2) {
      return [];
    }

    const employeeData: ApiEmployeeSearchResponse = await employeeDataApiService.searchForEmployees(
      this.companyStore.domain,
      searchQuery,
      false
    );
    const employeeFilters: ApiHierarchyItem[] = employeeData?.employees?.map(
      ({ employeeId }: ApiEmployeeSearchResponseItem) => ({
        name: employeeId,
        displayName: formatEmpIdToNameAndTitle(employeeId),
        isSelectable: true,
      })
    );

    runInAction(() => (this.currentEmployeeFilters = employeeFilters ?? []));

    return employeeFilters;
  };

  @action
  public loadEmployeeTerminationDates = async () => {
    const query: ApiMasterDataQuery = {
      dataType: DataTypes.EMPLOYEE,
      dimensions: [EmployeeDataFields.COMPANY_EMAIL, this.companySettingsStore.leaverDateField()],
      filterItems: [PAST_PRESENT_TEMPORALITY_FILTER],
    };
    const result = await GetResponseForMasterQueryService(query);
    const employeeTerminationDates: Record<string, string> = {};
    result.dataPoints &&
      result.dataPoints.forEach((dp) => {
        const email = dp.dimensions[0].value;
        const termDate = dp.dimensions[1].value;
        employeeTerminationDates[email] = termDate;
      });
    this.employeeTerminationDates = employeeTerminationDates;
  };
}
