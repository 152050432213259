import { DataFieldWithDataType, toDataFieldWithDataType } from '../../../common-types';
import { appCache, ICache } from '../cache/cache';
import {
  CohortMetricId,
  GroupingInput,
  MetricId,
  RegularMetricId,
  TimeSelectionInput,
} from '../graphql/generated/graphql-sdk';
import { rootStore } from '../store/root-store';
import { DataTypes, Domains } from './../constants/constants';
import {
  ApiHierarchyItemList,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataBatchQueryResponse,
  ApiMasterDataMovementQuery,
  ApiMasterDataQueryMeasure,
  ApiMasterDataQueryResponse,
  ApiMetricQueryResponse,
} from './api-interfaces';
import { BenchmarkConfig, DataService } from './data-service';
import { TimeSliderConfigStartAndEnd } from './employee-data-service/employee-data-service';
import { SQLFilters } from './master-data-service';

export class CachedMasterDataService implements DataService {
  private cache: ICache;

  constructor(cache: ICache = appCache) {
    this.cache = cache;
  }

  public listUsedFieldsForDataType = async (
    dataType: DataTypes,
    versionId?: string
  ): Promise<DataFieldWithDataType[]> => {
    const { domain } = rootStore.companyStore;
    const cacheKey = JSON.stringify(['listUsedFields', domain, dataType, versionId]);
    const result = await this.cache.getFromCacheOrRequest<string[]>(cacheKey, () => {
      return rootStore.masterDataApiService.listUsedFieldsForDataType(domain, dataType, versionId);
    });
    if (!result || (result as any).error) {
      console.error(`Error Fetching used fields for dataType ${dataType} and versionId ${versionId}`, result);
    }
    return result.map((r) => toDataFieldWithDataType(dataType, r));
  };

  public executeQuery = (
    domain: string,
    query: any,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataQueryResponse> => {
    const cacheKey = JSON.stringify(['executeQuery', domain, query, benchmarkConfig]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeQuery(domain, query, benchmarkConfig)
    );
  };

  public executeAdvancedQuery = (
    domain: string,
    query: ApiMasterDataAdvancedQuery
  ): Promise<ApiMasterDataQueryResponse> => {
    const cacheKey = JSON.stringify(['executeAdvancedQuery', domain, query]);
    return this.cache.getFromCacheOrRequest<any>(cacheKey, () =>
      rootStore.masterDataApiService.executeAdvancedQuery(domain, query)
    );
  };

  public executeCustomSqlQuery = (
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    query: string
  ): Promise<ApiMasterDataQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify(['executeCustomSqlQuery', domain, query]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeCustomSqlQuery(domain, dimensions, measures, query)
    );
  };

  public executeMovementQuery = (
    domain: string,
    query: ApiMasterDataMovementQuery
  ): Promise<ApiMasterDataQueryResponse> => {
    const cacheKey = JSON.stringify(['executeMovementQuery', domain, query]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeMovementQuery(domain, query)
    );
  };

  public executeQueriesInBatch = (
    domain: string,
    queries: any,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse> => {
    const cacheKey = JSON.stringify(['executeQueriesInBatch', queries, benchmarkConfig]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataBatchQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeQueriesInBatch(domain, queries, benchmarkConfig)
    );
  };

  public executeAdvancedQueriesInBatch = (
    domain: string,
    queries: { queries: ApiMasterDataAdvancedQuery[] },
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse> => {
    const cacheKey = JSON.stringify(['executeAdvancedQueriesInBatch', queries, benchmarkConfig]);
    return this.cache.getFromCacheOrRequest<ApiMasterDataBatchQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.executeAdvancedQueriesInBatch(domain, queries, benchmarkConfig)
    );
  };

  public listJobOrganizations = (
    domain: string,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobOrganizations', timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobOrganizations(domain, timeConfig)
    );
  };

  public listJobNames = (domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobNames', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobNames(domain, timeConfig)
    );
  };

  public listJobJobGrades = (
    domain: string,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobJobGrades', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobJobGrades(domain, timeConfig)
    );
  };

  public listJobOffices = (domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobOffices', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobOffices(domain, timeConfig)
    );
  };

  public listJobFunctions = (
    domain: string,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobFunctions', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobFunctions(domain, timeConfig)
    );
  };

  public listJobRecruitmentCategories = (
    domain: string,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listJobRecruitmentCategories', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listJobRecruitmentCategories(domain, timeConfig)
    );
  };

  public listApplicationSources = (
    domain: string,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listApplicationSources', domain, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listApplicationSources(domain, timeConfig)
    );
  };

  public listCustomField = (
    domain: Domains,
    fieldNumber: number,
    dataType: DataTypes,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> => {
    const cacheKey = JSON.stringify(['listCustomField', domain, fieldNumber, dataType, timeConfig]);
    return this.cache.getFromCacheOrRequest<ApiHierarchyItemList>(cacheKey, () =>
      rootStore.masterDataApiService.listCustomField(domain, fieldNumber, dataType, timeConfig)
    );
  };

  public queryCohortMetrics = (
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify([
      'queryCohortMetrics',
      domain,
      timeSelection,
      metrics,
      userFilters,
      disableNestLoop,
    ]);
    return this.cache.getFromCacheOrRequest<ApiMetricQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.queryCohortMetrics(
        domain,
        timeSelection,
        metrics,
        metricsAliases,
        userFilters,
        disableNestLoop
      )
    );
  };

  public queryMetrics = (
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> => {
    const domain = rootStore.companyStore.domain;
    const cacheKey = JSON.stringify([
      'queryMetrics',
      domain,
      timeSelection,
      metrics,
      userFilters,
      grouping,
      disableNestLoop,
    ]);
    return this.cache.getFromCacheOrRequest<ApiMetricQueryResponse>(cacheKey, () =>
      rootStore.masterDataApiService.queryMetrics(
        domain,
        timeSelection,
        metrics,
        metricsAliases,
        userFilters,
        grouping,
        disableNestLoop
      )
    );
  };
}
