import React from 'react';
import { useChat } from 'react-live-chat-loader';
import IntercomIcon from '../../../../assets/image/intercomIcon.png';
import { styled } from '../styled';

const StyledIcon = styled.img<{ src: any; onClick: <T>(value: T) => void }>`
  height: 20px;
  margin-left: 1.25em;
  cursor: pointer;
`;

export const IntercomHelp = () => {
  const [state, loadChat] = useChat();

  return (
    <>
      <StyledIcon src={IntercomIcon} onClick={() => loadChat({ open: true })} />
    </>
  );
};
