import { action, observable, when } from 'mobx';
import { ApiUser } from '../api/api-interfaces';
import { GraphQlRequestService } from '../api/graphql-request-service';
import { getGraphqlResult } from '../api/utils';
import { FrontendStartupQuery } from '../graphql/generated/graphql-sdk';
import { trackAppLogin } from '../helpers/trackers/userTracker';

// Not using an interface because of direct usage of graphql types, making it almost impossible to define the interface types
export class InitialDependencyStore {
  private graphQlRequestService: GraphQlRequestService;

  @observable
  public frontendStartupData!: FrontendStartupQuery;

  @observable
  public isFrontendStartupDataFetched: boolean = false;

  @observable
  public couldNotFetchFrontendStartupData: 'generalInitialDependenciesError' | 'networkError' | null = null;

  public initialDependenciesReady = () => {
    return this.isFrontendStartupDataFetched;
  };

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;

    when(
      () => this.graphQlRequestService.graphQlClient != null,
      () => {
        this.loadInitialDependencies().catch((error) => {
          const errorMessage: string = error.message ?? '';
          const networkRequestFailedRegex = /Network request failed/i;
          if (networkRequestFailedRegex.test(errorMessage)) {
            this.couldNotFetchFrontendStartupData = 'networkError';
          } else {
            // Optimally we should check the errorCode of the backend to see for what reason it failed
            this.couldNotFetchFrontendStartupData = 'generalInitialDependenciesError';
          }
        });
      }
    );
  }

  @action
  public async loadInitialDependencies() {
    const result = await this.graphQlRequestService.graphQlSdk.frontendStartup();
    this.frontendStartupData = result;
    this.isFrontendStartupDataFetched = true;
    trackAppLogin(result.currentUser?.email);
  }

  public getLatestUserRolePreferences() {
    return getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).preferencesLatestVersion;
  }

  public getExecutorRoles() {
    return getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).executorRoles;
  }

  public getAllowedDomainsWithLatestSettingsAndPreferences() {
    return getGraphqlResult(
      getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).allowedDomains
    );
  }

  public getUserRoleId() {
    return getGraphqlResult(this.frontendStartupData.currentUser).roleId;
  }

  public getUser() {
    const apiUser: ApiUser = { email: getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).email) };
    return apiUser;
  }

  public getAllPermissionsForCurrentUser() {
    return getGraphqlResult(
      getGraphqlResult(getGraphqlResult(this.frontendStartupData.currentUser).userRole).allPermissions
    );
  }

  public listAllDashboards() {
    return this.frontendStartupData.listAllDashboards;
  }
}
