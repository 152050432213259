import moment, { Moment } from 'moment';
import { Languages } from '../constants/constants';
import { apiDateFormatUtilityMethods } from './date-format-utility-methods/api-date-format';
import { englishDateFormatUtilityMethods } from './date-format-utility-methods/locale-settings/english-date-locale-settings';
import { japaneseDateFormatUtilityMethods } from './date-format-utility-methods/locale-settings/japanese-date-locale-settings';
import { API_FORMAT, dateFormats, Granularity, Months } from './date-manager-constants';
import {
  DateFormat,
  DateFormatUtilityMethods,
  DateFormatUtilityMethodsForLocale,
  LocaleToUtilityMethodsMap,
} from './date-manager-types';

class DateFormatManager {
  public name: DateFormat;
  private localeToUtilityMethodsMap: LocaleToUtilityMethodsMap;
  private locale: Languages;
  private firstMonthOfYear: Months;

  public constructor(
    name: DateFormat,
    localeToUtilityMethodsMap: LocaleToUtilityMethodsMap,
    locale: Languages,
    firstMonthOfYear: Months
  ) {
    this.name = name;
    this.localeToUtilityMethodsMap = localeToUtilityMethodsMap;
    this.locale = locale;
    this.firstMonthOfYear = firstMonthOfYear;
  }

  private get utilityMethods(): DateFormatUtilityMethods {
    return (
      this.localeToUtilityMethodsMap[this.locale] ??
      (this.localeToUtilityMethodsMap[Languages.EN] as DateFormatUtilityMethods)
    );
  }

  public formatDate = (date: Moment) => {
    if (!date.isValid()) {
      throw new Error(`Invalid date passed to formatting function. DateFormat = ${this.name}`);
    } else {
      return this.utilityMethods.formatter(date, this.firstMonthOfYear);
    }
  };

  public parseDate = (formattedDate: string) => {
    const parsedDate = this.utilityMethods.parser(formattedDate, this.firstMonthOfYear);
    if (!parsedDate.isValid()) {
      throw new Error(`Invalid date passed to parsing function - ${formattedDate}. DateFormat = ${this.name}`);
    } else {
      return parsedDate;
    }
  };
}

export class DateManagerService {
  private dateFormatUtilityMethods: Partial<Record<Languages, DateFormatUtilityMethodsForLocale>> = {
    [Languages.EN]: englishDateFormatUtilityMethods,
    [Languages.JP]: japaneseDateFormatUtilityMethods,
  };

  private internal_dateFormatManagers: Partial<Record<DateFormat, DateFormatManager>> | null = null;

  private get dateFormatManagers(): Partial<Record<DateFormat, DateFormatManager>> {
    if (!this.internal_dateFormatManagers) {
      throw new Error('DateFormatManager called before it was defined');
    }
    return this.internal_dateFormatManagers;
  }

  public get dateFormatManagerReady() {
    return this.internal_dateFormatManagers !== null;
  }

  public createDateFormatManagers = (language: Languages, firstMonthOfYear: Months) => {
    const dateFormatsWithConfig = dateFormats.filter((df) => this.dateFormatUtilityMethods?.[language]?.[df]);
    this.internal_dateFormatManagers = Object.fromEntries(
      dateFormatsWithConfig.map((df) => {
        const localeToUtilityMethodsMap = Object.fromEntries(
          Object.entries(this.dateFormatUtilityMethods).map(([language, dateFormatToUtilityMethods]) => {
            return [language, dateFormatToUtilityMethods[df]];
          })
        );
        return [df, new DateFormatManager(df, localeToUtilityMethodsMap, language, firstMonthOfYear)];
      })
    );
    this.internal_dateFormatManagers[API_FORMAT] = new DateFormatManager(
      API_FORMAT,
      {
        [Languages.EN]: apiDateFormatUtilityMethods,
      },
      language,
      firstMonthOfYear
    );
  };

  private getDateFormatManager = (dateFormat: DateFormat) => {
    const dateFormatManager = this.dateFormatManagers[dateFormat];
    if (!dateFormatManager) {
      throw new Error(`Unsupported date format - ${dateFormat}`);
    }
    return dateFormatManager;
  };

  public formatDate = (date: Moment, dateFormat: DateFormat) => {
    return this.getDateFormatManager(dateFormat).formatDate(date);
  };

  public formatApiDateToNewFormat = (apiFormattedDate: string, dateFormat: DateFormat) => {
    // This function is added to make it convenient to format Api Format dates
    // into a different format, without having to parse them into a moment first
    return this.formatDate(this.parseDate(apiFormattedDate, API_FORMAT), dateFormat);
  };

  public formatDateApi = (date: Moment) => {
    return this.formatDate(date, API_FORMAT);
  };

  public parseApiDate = (formattedDate: string) => {
    return this.parseDate(formattedDate, API_FORMAT);
  };

  public formatDateShort = (date: Moment) => {
    return this.formatDate(date, Granularity.MONTH);
  };

  public parseDate = (formattedDate: string, dateFormat: DateFormat) => {
    return this.getDateFormatManager(dateFormat).parseDate(formattedDate);
  };

  public parseUtc = (utcDate: string): Moment => {
    return moment.utc(utcDate, true);
  };
}

export const dateManagerService = new DateManagerService();
