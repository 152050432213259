import { rootStore } from '../store/root-store';
import { ApiCreateReport, ApiMasterDataAdvancedQuery, ApiReport, ApiReportList } from './api-interfaces';
import { GraphQlRequestService } from './graphql-request-service';
import { errorHandler, getGraphqlResult } from './utils';

export interface ReportApiService {
  listReports(domain: string): Promise<ApiReportList>;
  updateReport(domain: string, id: string, updatedReport: ApiReport): Promise<void>;
  createReport(domain: string, newReport: ApiCreateReport): Promise<ApiReport>;
  deleteReport(domain: string, id: string): Promise<void>;
}

export class DefaultReportApiService implements ReportApiService {
  private graphQlRequestService: GraphQlRequestService;

  constructor(graphqlRequestService: GraphQlRequestService) {
    this.graphQlRequestService = graphqlRequestService;
  }

  public listReports = async (domain: string): Promise<ApiReportList> => {
    try {
      const result = rootStore.domainDependencyStore.getAllowedReports() || [];

      const reports = result.map((report) => {
        return {
          id: report.id,
          report: {
            query: report.report.advancedQuery as ApiMasterDataAdvancedQuery,
            displayName: report.report.displayName,
            description: report.report.description,
          },
        };
      });
      return { reports };
    } catch (e) {
      return errorHandler(e, { request: 'listReports' });
    }
  };

  public updateReport = async (domain: string, id: string, updatedReport: ApiReport): Promise<void> => {
    try {
      getGraphqlResult(
        (
          await this.graphQlRequestService.graphQlSdk.updateReport({
            reportId: id,
            advancedQuery: updatedReport.report.query,
            displayName: updatedReport.report.displayName,
            description: updatedReport.report.description,
            simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
          })
        ).updateReport
      );
    } catch (e) {
      return errorHandler(e, { request: 'updateReport', id });
    }
  };

  public createReport = async (domain: string, newReport: ApiCreateReport): Promise<ApiReport> => {
    try {
      const result = getGraphqlResult(
        (
          await this.graphQlRequestService.graphQlSdk.createReport({
            domain: domain,
            advancedQuery: newReport.report.query,
            displayName: newReport.report.displayName,
            description: newReport.report.description,
            simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
          })
        ).createReport
      );
      return {
        id: result.id,
        report: {
          query: result.report.advancedQuery as ApiMasterDataAdvancedQuery,
          displayName: result.report.displayName,
          description: result.report.description,
        },
      };
    } catch (e) {
      return errorHandler(e, { request: 'createReport' });
    }
  };

  public deleteReport = async (domain: string, id: string): Promise<void> => {
    try {
      getGraphqlResult(
        (
          await this.graphQlRequestService.graphQlSdk.deleteReport({
            reportId: id,
            simulateRole: rootStore.permissionsStore.getSimulateRoleId(),
          })
        ).deleteReport
      );
    } catch (e) {
      return errorHandler(e, { request: 'deleteReport', id });
    }
  };
}
