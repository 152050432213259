import { action, computed, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../../common-types';
import { ApiMasterDataQueryFilterItem } from '../../../common/api/api-interfaces';
import {
  AttritionPredictionDataFields,
  ComputedEmployeeFields,
  DataTypes,
  EmployeeDataFields,
  Operations,
} from '../../../common/constants/constants';
import { rootStore } from '../../../common/store/root-store';

export enum AttritionRiskLevels {
  VERY_LOW = 'VERY_LOW',
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
  NOT_APPLICABLE = 'NOT_APPLICABLE',
}

export enum AttritionRiskFactboxKeys {
  RISK_AVG = 'RiskAvg',
  AT_RISK_HC = 'AtRiskHC',
}

export enum TablesForFactbox {
  EMPLOYEES = 'EMPLOYEES',
  MANAGERS = 'MANAGERS',
}

export const getAttritionRiskKey = (l: string) => `pages:dashboards.attritionPrediction.riskLevels.${l}`;

export const getRiskLevel = (num: number): AttritionRiskLevels => {
  if (num <= 25) return AttritionRiskLevels.VERY_LOW;
  if (num <= 50) return AttritionRiskLevels.LOW;
  if (num <= 80) return AttritionRiskLevels.MODERATE;
  if (num <= 90) return AttritionRiskLevels.HIGH;
  else return AttritionRiskLevels.VERY_HIGH;
};

export const MAX_TABLE_ROWS = 20;
class AttritionPredictionStore {
  public attritionPredictionIntroId = 'attrition-prediction--intro';
  public attritionOverviewId = 'attrition-prediction--overview';
  public attritionRiskId = 'attrition-prediction--risk';
  public attritionRiskAnalysisId = 'attrition-prediction--risk-analysis';
  public attritionPredictionId = 'attrition-prediction--prediction';
  public attritionTableFactbox = 'attrition-table-factbox';

  public riskLevels: AttritionRiskLevels[] = [
    AttritionRiskLevels.NOT_APPLICABLE,
    AttritionRiskLevels.VERY_LOW,
    AttritionRiskLevels.LOW,
    AttritionRiskLevels.MODERATE,
    AttritionRiskLevels.HIGH,
    AttritionRiskLevels.VERY_HIGH,
  ];

  @observable
  public riskKey = AttritionRiskFactboxKeys.RISK_AVG;

  @action
  public setRiskKey = (key: AttritionRiskFactboxKeys) => {
    return (this.riskKey = key);
  };

  @observable
  public predictionToggle = true;

  @observable
  public historyToggle = true;

  @action
  public togglePrediction = () => (this.predictionToggle = !this.predictionToggle);

  @action
  public toggleHistory = () => (this.historyToggle = !this.historyToggle);

  @observable
  public selectedRiskLevelsMap: Record<AttritionRiskLevels, boolean> = {
    [AttritionRiskLevels.NOT_APPLICABLE]: false,
    [AttritionRiskLevels.VERY_LOW]: false,
    [AttritionRiskLevels.LOW]: false,
    [AttritionRiskLevels.MODERATE]: false,
    [AttritionRiskLevels.HIGH]: true,
    [AttritionRiskLevels.VERY_HIGH]: true,
  };

  @observable
  public selectedEmployeeFields: DataFieldWithDataType[] = [
    rootStore.companySettingsStore.nameProperty(),
    { dataType: DataTypes.EMPLOYEE, dataField: ComputedEmployeeFields.MANAGER_NAME },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
  ];

  @action
  public setSelectedEmployeeFields = (newFields: DataFieldWithDataType[]) => {
    this.selectedEmployeeFields = newFields;
  };

  public isRiskLevelSelected = (riskLevel: AttritionRiskLevels) => this.selectedRiskLevelsMap[riskLevel];

  @computed
  public get selectedRiskLevels(): AttritionRiskLevels[] {
    return this.riskLevels.filter(this.isRiskLevelSelected);
  }

  @action
  public setSelectedRiskLevelsMap = (newSelectedRiskLevelsMap: Record<AttritionRiskLevels, boolean>) => {
    this.selectedRiskLevelsMap = newSelectedRiskLevelsMap;
  };

  @observable
  public dropDownVals: DataFieldWithDataType[] = [
    // Note: This list is modified upon first load. Non null hierarchical employee fields are added to it.
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
  ];

  @observable
  public selectedDropdownVal: DataFieldWithDataType = {
    dataType: DataTypes.EMPLOYEE,
    dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1,
  };
  // Assuming Org Level 1 will always be there

  @action
  public setSelectedDropdownVal = (val: DataFieldWithDataType) => {
    this.selectedDropdownVal = val;
  };

  @computed
  public get selectedRiskLevelsFilter(): ApiMasterDataQueryFilterItem {
    return {
      dataType: DataTypes.ATTRITIONPREDICTION,
      operation: Operations.EQUAL,
      property: AttritionPredictionDataFields.RISK_PROFILE,
      values: this.selectedRiskLevels,
      dontCombine: true,
    };
  }

  @observable
  public employeesTableHc: number = 0;

  @action
  public setEmployeesTableHc = (newHc: number) => {
    newHc ? (this.employeesTableHc = newHc) : 0;
  };

  @observable
  public managersTableHc: number = 0;

  @action
  public setManagersTableHc = (newHc: number) => {
    newHc ? (this.managersTableHc = newHc) : 0;
  };
}

export const attritionPredictionStore = new AttritionPredictionStore();
