import { ChartDataset } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { Color } from 'chartjs-plugin-datalabels/types/options';
import { ChartTypes, CONFIDENTIAL_VALUE } from '../constants/constants';
import { formatNumber, FormatTypes } from '../utilFunctions/formatters';

enum Align {
  CENTER = 'center',
  START = 'start',
  END = 'end',
}

interface DataLabelOptions {
  rotation: (context: Context) => number;
  backgroundColor: (context: Context) => Color | null;
  color: (context: Context) => string;
  formatter: (value: string | number, context: Context) => string;
  align: (context: Context) => Align;
  anchor: (context: Context) => Align;
  offset: (context: Context) => number;
  borderRadius: (context: Context) => number;
}

const getDatasetsFromContext = (context: Context) => {
  return context.chart.data.datasets ?? [];
};

export const isConfidentialDataset = (ds: ChartDataset) => ds.label === CONFIDENTIAL_VALUE;

const getNonConfidentialDatasetsFromContext = (context: Context) => {
  return getDatasetsFromContext(context).filter((ds) => !isConfidentialDataset(ds));
};

const getChartTypeFromContext = (context: Context) => {
  return context.chart.config.type;
};

const isChartStacked = (context: Context) => {
  const isBarChart = getChartTypeFromContext(context) === ChartTypes.BAR;
  return (isBarChart && context.chart.options.scales?.stacked) ?? false;
};

// Please note that threshold needs to be a number between 0-1;
const isAboveThresholdForDataset = (dataSet: any[], datum: number, threshold: number) => {
  const maxValue = Math.max(...dataSet);
  return datum >= threshold * maxValue;
};

export const DATALABEL_ALIGN_THRESHOLD = 0.3;
export const DATALABEL_STACK_BAR_SHOW_NUMBER_THRESHOLD = 0.1;

export const DataLabelDefaults: Record<ChartTypes, DataLabelOptions> = {
  [ChartTypes.BAR]: {
    rotation: (context: Context) => {
      const datasets = getNonConfidentialDatasetsFromContext(context);
      if (datasets && datasets.length > 1) {
        return 0;
      }
      return -90;
    },
    backgroundColor: (context: Context) => {
      return null;
    },
    color: (context: Context) => {
      const data = context.dataset.data ?? [];
      const datum: number = Number(data[context.dataIndex]);
      const datasets = getNonConfidentialDatasetsFromContext(context);

      // Stacked Bar chart logic
      if (datasets && datasets.length > 1) {
        if (isChartStacked(context)) {
          return isAboveThresholdForDataset(data, datum, DATALABEL_STACK_BAR_SHOW_NUMBER_THRESHOLD)
            ? 'white'
            : 'transparent';
        }
      }

      // Normal Logic
      return isAboveThresholdForDataset(data, datum, DATALABEL_ALIGN_THRESHOLD) ? 'white' : 'black';
    },
    formatter: (value: string | number, context: Context) => {
      return `${formatNumber(value, FormatTypes.COUNT)}`;
    },
    align: (context: Context) => {
      const data = context.dataset.data ?? [];
      const datum: number = Number(data[context.dataIndex]);
      return isChartStacked(context)
        ? Align.CENTER
        : isAboveThresholdForDataset(data, datum, DATALABEL_ALIGN_THRESHOLD)
        ? Align.START
        : Align.END;
    },
    anchor: (context: Context) => {
      return isChartStacked(context) ? Align.CENTER : Align.END;
    },
    offset: (context: Context) => {
      return 0;
    },
    borderRadius: (context: Context) => {
      return 4;
    },
  },
  [ChartTypes.HORIZONTALBAR]: {
    rotation: (context: Context) => {
      const datasets = getNonConfidentialDatasetsFromContext(context);
      if (datasets && datasets.length > 1) {
        return 0;
      }
      return -90;
    },
    backgroundColor: (context: Context) => {
      return null;
    },
    color: (context: Context) => {
      const data = context.dataset.data ?? [];
      const datum: number = Number(data[context.dataIndex]);
      const datasets = getNonConfidentialDatasetsFromContext(context);

      // Stacked Bar chart logic
      if (datasets && datasets.length > 1) {
        if (isChartStacked(context)) {
          return isAboveThresholdForDataset(data, datum, DATALABEL_STACK_BAR_SHOW_NUMBER_THRESHOLD)
            ? 'white'
            : 'transparent';
        }
      }

      // Normal Logic
      return isAboveThresholdForDataset(data, datum, DATALABEL_ALIGN_THRESHOLD) ? 'white' : 'black';
    },
    formatter: (value: string | number, context: Context) => {
      return `${formatNumber(value, FormatTypes.COUNT)}`;
    },
    align: (context: Context) => {
      const data = context.dataset.data ?? [];
      const datum: number = Number(data[context.dataIndex]);
      return isChartStacked(context)
        ? Align.CENTER
        : isAboveThresholdForDataset(data, datum, DATALABEL_ALIGN_THRESHOLD)
        ? Align.START
        : Align.END;
    },
    anchor: (context: Context) => {
      return isChartStacked(context) ? Align.CENTER : Align.END;
    },
    offset: (context: Context) => {
      return 0;
    },
    borderRadius: (context: Context) => {
      return 4;
    },
  },
  [ChartTypes.DOUGHNUT]: {
    rotation: (context: Context) => {
      return 0;
    },
    backgroundColor: (context: Context) => {
      return null;
    },
    color: (context: Context) => {
      return 'white';
    },
    formatter: (value: string | number, context: Context) => {
      return `${formatNumber(value, FormatTypes.COUNT)}`;
    },
    align: (context: Context) => {
      return Align.CENTER;
    },
    anchor: (context: Context) => {
      return Align.CENTER;
    },
    offset: (context: Context) => {
      return 0;
    },
    borderRadius: (context: Context) => {
      return 4;
    },
  },
  [ChartTypes.LINE]: {
    rotation: (context: Context) => {
      return 0;
    },
    backgroundColor: (context: Context) => {
      const datasets = getNonConfidentialDatasetsFromContext(context);
      if (datasets && datasets.length > 1) {
        return null;
      }
      return context.dataset.backgroundColor as Color;
    },
    color: (context: Context) => {
      const datasets = getNonConfidentialDatasetsFromContext(context);
      // Stacked Line chart
      if (datasets && datasets.length > 1) {
        return 'black';
      }

      // Normal text will be white
      // because they will have a background color of the color of the chart
      return 'white';
    },
    formatter: (value: string | number, context: Context) => {
      return `${formatNumber(value, FormatTypes.COUNT)}`;
    },
    align: (context: Context) => {
      const data = context.dataset.data ?? [];
      const datum: number = Number(data[context.dataIndex]);
      return isAboveThresholdForDataset(data, datum, DATALABEL_ALIGN_THRESHOLD) ? Align.START : Align.END;
    },
    anchor: (context: Context) => {
      return Align.END;
    },
    offset: (context: Context) => {
      return 0;
    },
    borderRadius: (context: Context) => {
      return 4;
    },
  },
};
