import {
  Autocomplete,
  Avatar,
  createFilterOptions,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  MenuList,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { ApiCompany } from '../../api/zod-schemas';
import { localStore } from '../../local-store';
import { rootStore } from '../../store/root-store';
import { sortAvailableDomains } from '../../utilFunctions/sorters';
import { wait } from '../../utilFunctions/utils';
import { WhiteTextField } from '../common-styled-components';
import { styled } from '../styled';
import Loading from '../utils/Loading';
import PopUpMenu from '../utils/PopUpMenu';

const StyledIconButton = styled(IconButton)`
  padding: 5px !important;
` as typeof IconButton;

export const StyledAvatar = styled(Avatar)`
  height: 20px;
  width: 20px;
  background: transparent;
  border-radius: 0;
` as typeof Avatar;

interface DomainWithDisplayName extends ApiCompany {
  domainDisplayNameText: string;
  domainDisplayNameComponent: React.ReactNode;
}

export const getDomainsWithDisplayName = (domains: ApiCompany[]): DomainWithDisplayName[] => {
  const formattedDomains = domains.map((d) => {
    const { alias, name } = d;
    const isPanalytSuperAdmin = rootStore.permissionsStore.canManageAllDomains;
    const domainDisplayNameNormalUser = alias ?? name;
    const domainDisplayNamePsa = alias ? (
      <>
        {name} <i>{alias}</i>
      </>
    ) : (
      name
    );
    const domainDisplayNameText = alias ? `${name} ( ${alias} )` : name;
    const domainDisplayNameComponent = isPanalytSuperAdmin ? domainDisplayNamePsa : domainDisplayNameNormalUser;
    return { ...d, domainDisplayNameComponent, domainDisplayNameText };
  });
  return formattedDomains;
};

export const Domain = observer(() => {
  const domainSearchRef = useRef<HTMLDivElement>(null);
  const logoRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    rootStore.companyStore.loadAvailableDomains().then(() => {
      rootStore.companyStore.getSelectedCompany();
    });
  }, []);
  const [open, setOpen] = useState(false);
  const handleDomainLogoClick = async (_event: React.MouseEvent<HTMLElement>) => {
    setOpen(true);
    await wait(100);
    if (domainSearchRef.current) {
      domainSearchRef.current.focus();
    }
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onDomainSelect = (domain: string) => {
    rootStore.companyStore.setDomain(domain);
    rootStore.permissionsStore.stopRoleSimulation();
    localStore.removeSavedStores();
  };
  const { domain, availableDomains, selectedCompany } = rootStore.companyStore;
  const logoURL = availableDomains.length ? selectedCompany?.logoURL ?? null : null;
  const domainsWithDisplayName = getDomainsWithDisplayName(availableDomains);
  const sortedDomains = domainsWithDisplayName.slice().sort(sortAvailableDomains);

  const getDomainMenuItem = (
    { domain: d, domainDisplayNameComponent, domainDisplayNameText, logoURL: url }: DomainWithDisplayName,
    menuItemProps: React.HTMLAttributes<HTMLLIElement> = {}
  ) => {
    return (
      <MenuItem key={d} selected={d === rootStore.companyStore.domain} {...menuItemProps}>
        <ListItemAvatar>
          {url ? (
            <StyledAvatar src={url} />
          ) : (
            <StyledAvatar>{domainDisplayNameText.charAt(0).toUpperCase()}</StyledAvatar>
          )}
        </ListItemAvatar>
        <ListItemText
          primaryTypographyProps={{
            variant: 'body2',
            noWrap: true,
          }}
        >
          {domainDisplayNameComponent}
        </ListItemText>
      </MenuItem>
    );
  };

  const singleDomainView = (
    <PopUpMenu open={open} anchorEl={logoRef.current} onClose={handleClose} placement="bottom-end">
      {sortedDomains.length > 0 ? (
        <MenuList>{sortedDomains.map((d) => getDomainMenuItem(d))}</MenuList>
      ) : (
        <Loading color="secondary" />
      )}
    </PopUpMenu>
  );

  return (
    <>
      <Grid container sx={{ width: '20vw', paddingRight: '8px' }}>
        <Grid item xs={11}>
          {/* only showing autocomplete for more than one domain  */}
          {sortedDomains.length > 1 ? (
            <Autocomplete
              options={sortedDomains.slice().sort(sortAvailableDomains)}
              getOptionLabel={(d) => {
                return d.domain;
              }}
              filterOptions={createFilterOptions({
                stringify: ({ domain, domainOfPartner, name, alias }) =>
                  [domain, domainOfPartner, name, alias].join('   '),
              })}
              renderOption={(props, option) => {
                return getDomainMenuItem(option, props);
              }}
              id="disable-close-on-select"
              disableCloseOnSelect
              renderInput={(params) => (
                <WhiteTextField
                  {...params}
                  variant="standard"
                  inputRef={domainSearchRef}
                  onClick={(e) => e.stopPropagation()}
                  sx={{
                    '& .MuiAutocomplete-popupIndicator': { color: '#fff' },
                  }}
                />
              )}
              componentsProps={{ popper: { sx: { '& .MuiPaper-root': { background: '#00a599', color: '#fff' } } } }}
              sx={{ display: open ? 'block' : 'none' }}
              onClose={handleClose}
              isOptionEqualToValue={(d1, d2) => d1.domain === d2.domain}
              onChange={(_e, value) => {
                if (value && value.domain) {
                  onDomainSelect(value.domain);
                }
              }}
              openOnFocus
            />
          ) : null}
        </Grid>
        <Grid item xs={1}>
          <StyledIconButton
            aria-haspopup="true"
            onClick={handleDomainLogoClick}
            color="inherit"
            className={'intercom_appbar_domain'}
            ref={logoRef}
          >
            {logoURL ? (
              <StyledAvatar src={logoURL} />
            ) : (
              <StyledAvatar>{domain && domain.charAt(0).toUpperCase()}</StyledAvatar>
            )}
          </StyledIconButton>
        </Grid>
      </Grid>
      {sortedDomains.length <= 1 && singleDomainView}
    </>
  );
});
