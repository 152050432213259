import { action, observable } from 'mobx';

export class EvaluationGridStore {
  @observable
  public xAxisGrades: number[] = [];

  @observable
  public yAxisGrades: number[] = [];

  @observable
  public evaluatinDataArray: number[] = [];

  @action
  public setLabels = (xAxisLabels: number[], yAxislabels: number[]): void => {
    this.xAxisGrades = xAxisLabels.reverse();
    this.yAxisGrades = yAxislabels.reverse();
  };

  @action
  public setEvaluatinDataArray = (evaluatinDataArray: number[]): void => {
    this.evaluatinDataArray = evaluatinDataArray;
  };
}
