import isEqual from 'lodash.isequal';
import { action } from 'mobx';
import { DataFieldWithDataType } from '../../../../common-types';
import { $HierarchicalFilterSeparator$, ApiMasterDataQueryFilterItem } from '../../api/api-interfaces';
import {
  GenderValues,
  LocalOrNonLocalValues,
  ManagerOrIcValues,
  MartialStatusValues,
  RegionalOrLocalValues,
  RegretNonRegretValues,
} from '../../constants/constant-backend-values';
import { DataFields, DataTypes, EmployeeDataFields, Operations } from '../../constants/constants';
import {
  genderLabelToDisplayLabelMap,
  localNonLocalLabelToDisplayLabelMap,
  managerOrICLabelToDisplayLabelMap,
  maritalStatusLabelToDisplayLabelMap,
  regionalLocalLabelToDisplayLabelMap,
  regretNonRegretLabelToDisplayLabelMap,
} from '../../constants/systemValuesTranslation';
import { areSameNonHierarchicalFilters, toggleFilterFromGivenFilters } from '../../filter/utils';
import { rootStore } from '../../store/root-store';
import { convertUndefinedFilterValuesToNull } from '../../utilFunctions/formatters';
import { getFilterKey, isHierarchical } from '../../utilFunctions/utils';
import { FilterOnChangeProps, FilterTrayItemProps } from './FilterTrayItem';
import { FilterTraySectionProps } from './FilterTraySection';

const filterQuery = (propertyBase: DataFieldWithDataType, values: any[]): ApiMasterDataQueryFilterItem => {
  return {
    property: propertyBase.dataField,
    operation: Operations.EQUAL,
    values,
    dataType: propertyBase.dataType,
  };
};

const fieldSpecificApplyFilters: Record<
  string,
  (data: FilterOnChangeProps, filterTraySectionProps: FilterTraySectionProps) => void
> = {
  [getFilterKey(DataTypes.EMPLOYEE, EmployeeDataFields.START_DATE)]: (data, filterTraySectionProps) => {
    rootStore.filterStore.filters = rootStore.filterStore.filters.filter((f) => {
      return !(
        // Removing all start date filters in filterStore that are not the applied filter
        (
          f.property === EmployeeDataFields.START_DATE &&
          !(data.filterValues as ApiMasterDataQueryFilterItem[]).some((hireYearFilter) => isEqual(hireYearFilter, f))
        )
      );
    });
    applyFilterDefault(data, filterTraySectionProps);
  },
};

export const applyFilter = action((data: FilterOnChangeProps, filterTraySectionProps: FilterTraySectionProps) => {
  const { dimension, applyFilter } = filterTraySectionProps;
  const { dataType } = dimension;
  const fieldSpecificApplyFn = fieldSpecificApplyFilters[getFilterKey(dimension.dataType, dimension.dataField)];
  if (fieldSpecificApplyFn) {
    fieldSpecificApplyFn(data, filterTraySectionProps);
  } else {
    switch (dataType) {
      case DataTypes.EMPLOYEE:
      case DataTypes.JOINERS_VIEW:
      case DataTypes.ATTRITIONPREDICTION:
        applyFilterDefault(data, filterTraySectionProps);
        break;
      case DataTypes.EVALUATION:
        applyFilterEvaluation(data, filterTraySectionProps);
        break;
      case DataTypes.APPLICATION:
      case DataTypes.JOB:
      case DataTypes.RECRUITERJOBS:
      case DataTypes.OFFER:
        applyFilterRecruitment(data, filterTraySectionProps);
        break;
      default:
        console.error(`DataType ${dataType} not supported in the FilterTray`);
    }
    if (applyFilter) {
      // After toggling filter if you want to add more functions then you are uplift that...
      applyFilter(data);
    }
  }
});

const applyFilterDefault = (data: FilterOnChangeProps, filterTraySectionProps: FilterTraySectionProps) => {
  const { dimension, filters, applyFilterNew, isBenchmark, afterApplyFilters, customFilters } = filterTraySectionProps;
  const { filterValues } = data;
  const filter: ApiMasterDataQueryFilterItem = getFilterFromFilterData(data, dimension);
  if (filters) {
    if (applyFilterNew) {
      const currentFilters = [...filters];
      toggleFilterFromGivenFilters(filter, currentFilters);
      applyFilterNew(currentFilters);
    } else {
      toggleFilterFromGivenFilters(filter, filters);
    }
  } else {
    if (filterValues) {
      toggleFiltersFromFilterValues(filterValues);
    } else {
      rootStore.filterStore.toggleFilter({
        filter,
        isBenchmark,
        afterApplyFilters,
        customFilters,
      });
    }
  }
};

export const applyFilterRecruitment = (data: FilterOnChangeProps, filterTraySectionProps: FilterTraySectionProps) => {
  const { dimension } = filterTraySectionProps;
  const filter: ApiMasterDataQueryFilterItem = getFilterFromFilterData(data, dimension);
  rootStore.recruitmentStore.toggleFilter(filter, dimension.dataType as DataTypes);
};

const applyFilterEvaluation = (data: FilterOnChangeProps, filterTraySectionProps: FilterTraySectionProps) => {
  const { filterValues } = data;
  if (!filterValues) {
    return;
  }
  // first checking if there is an eval filter of a different cycleType or cycleDate
  // if there is we can remove existing evaluationFilters
  const { filters } = rootStore.filterStore;
  const evaluationFilters = filters.filter((f) => f.dataType === 'EVALUATION');
  const cycleTypeFilter = evaluationFilters.find((f) => f.property === 'EVALUATION_CYCLE_TYPE');
  const cycleDateFilter = evaluationFilters.find((f) => f.property === 'EVALUATION_AS_OF');
  const newCycleTypeFilter = filterValues && filterValues.find((f) => f.property === 'EVALUATION_CYCLE_TYPE');
  const newCycleDateFilter = filterValues && filterValues.find((f) => f.property === 'EVALUATION_AS_OF');
  let isDifferentTypeOrDate = false;
  if (cycleTypeFilter && newCycleTypeFilter) {
    if (cycleTypeFilter.values[0] !== newCycleTypeFilter.values[0]) {
      isDifferentTypeOrDate = true;
    }
  }
  if (cycleDateFilter && newCycleDateFilter) {
    if (cycleDateFilter.values[0] !== newCycleDateFilter.values[0]) {
      isDifferentTypeOrDate = true;
    }
  }
  if (isDifferentTypeOrDate) {
    rootStore.filterStore.filters = filters.filter((f) => f.dataType !== 'EVALUATION');
  }

  toggleFiltersFromFilterValues(filterValues);
};

export const applyMultipleFilters = action(
  (data: FilterOnChangeProps[], filterTraySectionProps: FilterTraySectionProps) => {
    const { dimension, isLayerFilter, filters } = filterTraySectionProps;
    if (dimension.dataType === DataTypes.EMPLOYEE) {
      const multipleFilters: ApiMasterDataQueryFilterItem[] = data.flatMap((filter) => {
        if (filter.filterValues) {
          return filter.filterValues;
        } else {
          return [getFilterFromFilterData(filter, dimension)];
        }
      });
      if (isLayerFilter) {
        rootStore.filterStore.toggleMultipleFiltersInLayer(multipleFilters);
        // all layer filters are as of now of dataType employee.
      } else {
        rootStore.filterStore.toggleMultipleFilters(multipleFilters, filters);
      }
    } else {
      data.forEach((filter) => applyFilter(filter, filterTraySectionProps));
    }
  }
);

const getFilterFromFilterData = (
  data: FilterOnChangeProps,
  property: DataFieldWithDataType
): ApiMasterDataQueryFilterItem => {
  let filterValue;
  // Handle Survey scores since Jpholdings wants the Onboarding survey score and the pulse survey score together
  if (property.dataField === 'PULSE_SURVEY_SCORE_1') {
    filterValue = data.value.split($HierarchicalFilterSeparator$).map((val) => {
      return convertUndefinedFilterValuesToNull(val);
    });
    if (filterValue.length > 0) {
      // TODO: This logic is super unclear. Need to add comments or use meaningful variable names
      if (filterValue.length <= 2) {
        return filterQuery({ dataType: property.dataType, dataField: (filterValue[0] ?? '') as DataFields }, [
          ...filterValue.slice(1),
        ]);
      } else {
        return filterQuery({ dataType: property.dataType, dataField: (filterValue[2] ?? '') as DataFields }, [
          ...(filterValue[3] as any),
        ]);
      }
    }
  }
  if (isHierarchical(property)) {
    filterValue = data.value.split($HierarchicalFilterSeparator$).map((val) => {
      return convertUndefinedFilterValuesToNull(val);
    });
  } else {
    filterValue = convertUndefinedFilterValuesToNull(data.value);
  }
  return filterQuery(property, [filterValue]);
};

const isCheckedCallbackUsingFilterValues = (
  data: FilterOnChangeProps,
  filterTraySectionProps: Pick<FilterTraySectionProps, 'dimension' | 'isBenchmark' | 'onlyNested' | 'filters'>
) => {
  const { dimension, isBenchmark, filters } = filterTraySectionProps;
  const currentFilters = getCurrentFilters(dimension.dataType, !!isBenchmark, filters);
  const { filterValues } = data;
  const isChecked =
    !!filterValues &&
    filterValues.every((f) => {
      return (
        currentFilters.findIndex((item) => {
          return areSameNonHierarchicalFilters(f, item);
        }) !== -1
      );
    });
  return isChecked;
};

export const isCheckedCallback = (
  data: FilterOnChangeProps,
  filterTraySectionProps: Pick<FilterTraySectionProps, 'dimension' | 'isBenchmark' | 'onlyNested' | 'filters'>
) => {
  const { dimension, isBenchmark, onlyNested, filters } = filterTraySectionProps;
  if (data.filterValues) {
    return isCheckedCallbackUsingFilterValues(data, filterTraySectionProps);
  } else {
    const dataFieldWithDataType = dimension;
    let filter: ApiMasterDataQueryFilterItem;
    if (data.value) {
      if (onlyNested) {
        const values = data.value.split($HierarchicalFilterSeparator$);
        const filterValue = values.last();
        filter = filterQuery(dataFieldWithDataType, [filterValue]);
      } else {
        filter = getFilterFromFilterData(data, dataFieldWithDataType);
      }
    } else {
      filter = filterQuery(dataFieldWithDataType, isHierarchical(dataFieldWithDataType) ? [[]] : []);
    }
    const currentFilters = getCurrentFilters(dimension.dataType, !!isBenchmark, filters);
    const index = currentFilters.findIndex((f) => isEqual(f, filter));
    return index !== -1;
  }
};

const getCurrentFilters = (
  dataType: DataTypes,
  isBenchmark: boolean,
  filters?: ApiMasterDataQueryFilterItem[]
): ApiMasterDataQueryFilterItem[] => {
  if (filters) {
    return filters;
  } else if (rootStore.recruitmentStore.isRecruitmentDataType(dataType)) {
    return rootStore.recruitmentStore.masterFilters[dataType] ?? [];
  } else if (isBenchmark) {
    return rootStore.filterStore.benchmarkFilters;
  } else {
    return rootStore.filterStore.filters;
  }
};

const toggleFiltersFromFilterValues = (filterValues: ApiMasterDataQueryFilterItem[]) => {
  const isChecked = filterValues.every((filter) => {
    return (
      rootStore.filterStore.filters.findIndex((item) => {
        return areSameNonHierarchicalFilters(filter, item);
      }) !== -1
    );
  });
  if (isChecked) {
    filterValues.forEach((filter) => {
      const index = rootStore.filterStore.filters.findIndex((item) => areSameNonHierarchicalFilters(filter, item));
      rootStore.filterStore.filters.splice(index, 1);
    });
  } else {
    rootStore.filterStore.filters.push(...filterValues);
  }
};

const itemToDisplayNameMap: Partial<Record<DataTypes, Partial<Record<DataFields, Record<string, string>>>>> = {
  [DataTypes.EMPLOYEE]: {
    [EmployeeDataFields.GENDER]: {
      [GenderValues.Male]: genderLabelToDisplayLabelMap[GenderValues.Male],
      [GenderValues.Female]: genderLabelToDisplayLabelMap[GenderValues.Female],
    },
    [EmployeeDataFields.MANAGER_OR_IC]: {
      [ManagerOrIcValues.Manager]: managerOrICLabelToDisplayLabelMap[ManagerOrIcValues.Manager],
      [ManagerOrIcValues.Ic]: managerOrICLabelToDisplayLabelMap[ManagerOrIcValues.Ic],
    },
    [EmployeeDataFields.DEFINED_MANAGER]: {
      [ManagerOrIcValues.Manager]: managerOrICLabelToDisplayLabelMap[ManagerOrIcValues.Manager],
      [ManagerOrIcValues.Ic]: managerOrICLabelToDisplayLabelMap[ManagerOrIcValues.Ic],
    },
    [EmployeeDataFields.REGIONAL_OR_LOCAL]: {
      [RegionalOrLocalValues.Regional]: regionalLocalLabelToDisplayLabelMap[RegionalOrLocalValues.Regional],
      [RegionalOrLocalValues.Local]: regionalLocalLabelToDisplayLabelMap[RegionalOrLocalValues.Local],
    },
    [EmployeeDataFields.LOCAL_OR_NON_LOCAL]: {
      [LocalOrNonLocalValues.Local]: localNonLocalLabelToDisplayLabelMap[LocalOrNonLocalValues.Local],
      [LocalOrNonLocalValues.NonLocal]: localNonLocalLabelToDisplayLabelMap[LocalOrNonLocalValues.NonLocal],
    },
    [EmployeeDataFields.REGRET_ATTRITION]: {
      [RegretNonRegretValues.Regret]: regretNonRegretLabelToDisplayLabelMap[RegretNonRegretValues.Regret],
      [RegretNonRegretValues.NonRegret]: regretNonRegretLabelToDisplayLabelMap[RegretNonRegretValues.NonRegret],
    },
    [EmployeeDataFields.MARITAL_STATUS]: {
      [MartialStatusValues.Married]: maritalStatusLabelToDisplayLabelMap[MartialStatusValues.Married],
      [MartialStatusValues.Single]: maritalStatusLabelToDisplayLabelMap[MartialStatusValues.Single],
    },
  },
};

export const getItemDisplayName = (item: FilterTrayItemProps, dimension?: DataFieldWithDataType): string => {
  // This function simply places hardcoded displayNames for fields like gender, etc where we want
  // language sensitive values to show up. For all the other fields, simply return the exisitng item.displayName.
  // @ts-ignore (ts error occurse here because there are some duplicate fields in our DataFields union)
  return itemToDisplayNameMap[dimension.dataType]?.[dimension.dataField]?.[item.name] ?? item.displayName;
};
