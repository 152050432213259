import DataLoader from 'dataloader';
import isEqual from 'lodash.isequal';
import { match } from 'ts-pattern';
import { AuthService } from '../auth/auth-service';
import {
  ApplicationDataFields,
  DataFields,
  DataTypes,
  Domains,
  EmployeeDataFields,
  getDataFieldsForDataType,
  JobDataFields,
  Operations,
} from '../constants/constants';
import { environmentService } from '../environment/environment-service';
import {
  ApiMasterDataType,
  CohortMetricId,
  GroupingInput,
  MetricId,
  RegularMetricId,
  TimeSegment,
  TimeSelectionInput,
} from '../graphql/generated/graphql-sdk';
import { IPermissionsStore } from '../permissions/permissions-store';
import { trackError } from '../sentry/sentry';
import {
  ApiCustomSqlQueryResponse,
  ApiCustomSqlQueryResponseDataPoint,
  ApiCustomSqlQueryResponseDataPointDimensionData,
  ApiCustomSqlQueryResponseDataPointMeasureData,
  ApiHierarchyItemList,
  ApiMasterDataAdvancedQuery,
  ApiMasterDataAdvancedQueryDimension,
  ApiMasterDataBatchQueryResponse,
  ApiMasterDataBatchQueryResponseItem,
  ApiMasterDataField,
  ApiMasterDataMovementQuery,
  ApiMasterDataQuery,
  ApiMasterDataQueryMeasure,
  ApiMasterDataQueryMeasureOperation,
  ApiMasterDataQueryResponse,
  ApiMasterDataTypes,
  ApiMetricQueryResponse,
  ApiQueryTypes,
} from './api-interfaces';
import { CachedMasterDataService } from './cached-master-data-service';
import { employeeDataApiService, TimeSliderConfigStartAndEnd } from './employee-data-service/employee-data-service';
import { GraphQlRequestService } from './graphql-request-service';
import { MetricResultFailure, MetricResultSuccess } from './graphql-types';
import { parseCustomSqlValue } from './graphql-utils';
import { RequestService } from './request-service';
import { getGraphqlResult, handleGQLErrors } from './utils';

export interface BenchmarkConfig {
  prduId?: string | null;
  pdu?: boolean;
  limitedToPermittedPopulation?: boolean;
}
declare global {
  interface Window {
    missingVersionIdQueries: Array<ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery>;
  }
}

window.missingVersionIdQueries = [];

const missingVersionIdWarningMessage =
  "The following queries don't contain any version id filter. The backend will automatically add the latest version id filter. If that's what you need, add it explicitly to make the intention clear.";

const checkQueryContainsVersionId = (
  query: ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery
) => {
  if (
    !query.filterItems?.some((f) => {
      return f.property === 'VERSION_ID';
    })
  ) {
    window.missingVersionIdQueries.push(query);
  }
};

if (!environmentService.isProd) {
  console.warn(missingVersionIdWarningMessage, window.missingVersionIdQueries);
}
interface ApiQueryDataLoaderKey {
  queryType: ApiQueryTypes;
  query: ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery;
}

export type SQLFilters = string;

export interface MasterDataApiService {
  listUsedFieldsForDataType(domain: string, dataType: string, versionId?: string): Promise<string[]>;
  executeQuery(
    domain: string,
    query: ApiMasterDataQuery,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataQueryResponse>;
  executeCustomSqlQuery(
    domain: string,
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    query: string
  ): Promise<ApiCustomSqlQueryResponse>;
  executeAdvancedQuery(domain: string, query: ApiMasterDataAdvancedQuery): Promise<ApiMasterDataQueryResponse>;
  executeMovementQuery(domain: string, query: ApiMasterDataMovementQuery): Promise<ApiMasterDataQueryResponse>;
  executeQueriesInBatch(
    domain: string,
    queries: { queries: ApiMasterDataQuery[] },
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse>;
  executeAdvancedQueriesInBatch(
    domain: string,
    queries: { queries: ApiMasterDataAdvancedQuery[] },
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse>;
  listJobOrganizations(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listJobNames(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listJobJobGrades(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listJobOffices(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listJobFunctions(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listJobRecruitmentCategories(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listApplicationSources(domain: string, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList>;
  listCustomField(
    domain: string,
    fieldNumber: number,
    dataType: DataTypes,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList>;
  queryCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse>;
  queryMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse>;
}

// This service uses the new backend for old, advanced and movement queries and otherwise uses the old backend
// tslint:disable-next-line: max-classes-per-file
export class NewBackendMasterDataApiService implements MasterDataApiService {
  graphQlRequestService: GraphQlRequestService;
  permissionStore: IPermissionsStore;
  requestService: RequestService;
  authService: AuthService;

  constructor(
    graphqlRequestService: GraphQlRequestService,
    permissionStore: IPermissionsStore,
    requestService: RequestService,
    authService: AuthService
  ) {
    this.graphQlRequestService = graphqlRequestService;
    this.permissionStore = permissionStore;
    this.requestService = requestService;
    this.authService = authService;
  }

  public listUsedFieldsForDataType = async (
    domain: string,
    dataType: string,
    versionId?: string
  ): Promise<string[]> => {
    const result = await this.graphQlRequestService.graphQlSdk.listUsedFields({
      domain,
      datatype: dataType as ApiMasterDataType,
      versionId: versionId ?? null,
      simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
    });
    return getGraphqlResult(result.listUsedFields);
  };

  public async executeCustomSqlQuery(
    domain: string,
    dimensions: ApiMasterDataAdvancedQueryDimension[],
    measures: ApiMasterDataQueryMeasure[],
    queryString: string
  ): Promise<ApiCustomSqlQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .executeCustomSqlQuery({
          domain: domain as any,
          querySql: queryString,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const rows = result?.executeCustomSqlQuery?.data;
          return {
            dataPoints:
              rows?.map((row) =>
                row.reduce(
                  (acc, value, index) => {
                    const parsedValue = parseCustomSqlValue(value);
                    if (dimensions[index]) {
                      const dim = dimensions[index];
                      const dataType = dim.dataType;
                      const dataField = dim.property;
                      acc['dimensions'].push({
                        dataType: dataType as ApiMasterDataTypes,
                        property: dataField as ApiMasterDataField,
                        value: parsedValue,
                      });
                    } else if (measures[index - dimensions.length]) {
                      const measure = measures[index - dimensions.length];
                      acc['measures'].push({
                        dataType: measure.dataType as ApiMasterDataTypes,
                        property: measure.property as ApiMasterDataField,
                        value: parsedValue,
                        operation: measure.operation,
                      });
                    } else {
                      throw new Error('Missing dimension or measure');
                    }
                    return acc;
                  },
                  { dimensions: [], measures: [] } as ApiCustomSqlQueryResponseDataPoint
                )
              ) ?? [],
          };
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  // Comparison utils

  private compare(oldObj: any, newObj: any, query: any, sql: any, asAdvancedQuery: ApiMasterDataAdvancedQuery | null) {
    const resultOldDP = oldObj.dataPoints ? this.roundAllNumbers(oldObj.dataPoints) : null;
    const resultNewParsedDP = newObj.dataPoints ? this.roundAllNumbers(newObj.dataPoints) : null;
    const condition =
      (resultOldDP &&
        resultNewParsedDP &&
        resultOldDP.length === resultNewParsedDP.length &&
        isEqual(resultOldDP?.measures ?? [], resultNewParsedDP?.measures ?? []) &&
        isEqual(resultOldDP?.dimensions ?? [], resultNewParsedDP?.dimensions ?? [])) ||
      (resultOldDP === null && resultNewParsedDP === null);
    console.debug('eq check', condition);
    if (!condition) {
      console.debug('Query result not equal');
      console.debug(resultNewParsedDP);
      console.debug(resultOldDP);
      console.debug(query);
      console.debug(sql);
      if (asAdvancedQuery) {
        console.debug(asAdvancedQuery);
      }
    }
  }

  private sort(O: any) {
    if (typeof O !== 'object' || O instanceof Array) {
      return O.sort(function (a: any, b: any) {
        return JSON.stringify(a).localeCompare(JSON.stringify(b));
      });
    }
    let keys = Object.keys(O);
    keys.sort();
    let newObject: any = {};
    keys.forEach((_, i) => (newObject[keys[i]] = this.sort(O[keys[i]])));
    return newObject;
  }

  // New and old backend return slightly different numbers. I.e. 2.000000023 instead of 2.000000031
  // This is most likely due to 1.) minor changes in the queries and 2.) data conversions when converting the result into json
  // We treat numbers that are the same for the first 5 decimal places as equal
  private roundAllNumbers(any: any): any {
    if (typeof any === 'object') {
      for (const key in any) {
        if (Object.prototype.hasOwnProperty.call(any, key)) {
          const element = any[key];
          const rounded = this.roundAllNumbers(element);
          any[key] = rounded;
        }
      }
    } else if (Array.isArray(any)) {
      return any.map((elem) => this.roundAllNumbers(elem));
    } else if (typeof any === 'number') {
      return parseFloat(any.toFixed(5));
    }
    return any;
  }

  //private oldToAdvanced = async (oldQuery: ApiMasterDataQuery): Promise<ApiMasterDataAdvancedQuery> => {
  //  const oldQueryDatatype: DataTypes = oldQuery.dataType as DataTypes;
  //  const datatypeIsEmployee = oldQueryDatatype === DataTypes.EMPLOYEE;

  //  var extraDimensions: ApiMasterDataAdvancedQueryDimension[] = [];

  //  if (datatypeIsEmployee && !(oldQuery.dimensions ?? []).some((d) => d === 'VERSION_ID')) {
  //    extraDimensions.push({ dataType: DataTypes.EMPLOYEE, property: 'VERSION_ID' });
  //  }

  //  var extraLatestVersionIdFilters: ApiMasterDataQueryFilterItem[];

  //  const versioning = DataTypesWithAttributes[oldQueryDatatype as DataTypes].versioning;

  //  const latestVersion =
  //    (await rootStore.domainDependencyStore.getLatestVersion(oldQueryDatatype)) ??
  //    (() => {
  //      throw new Error(`No latest version id for ${oldQueryDatatype}`);
  //    })();

  //  var extraLatestVersionIdFilters: ApiMasterDataQueryFilterItem[] = [];
  //  if (
  //    versioning !== Versioning.MONTHLY ||
  //    (oldQuery.filterItems ?? []).some((fi) => fi.property === 'VERSION_ID') ||
  //    (oldQuery.dimensions ?? []).includes('VERSION_ID')
  //  ) {
  //    // Don't add it
  //  } else {
  //    extraLatestVersionIdFilters.push({
  //      operation: 'EQUAL',
  //      property: 'VERSION_ID',
  //      values: [latestVersion],
  //      dataType: oldQueryDatatype,
  //    });
  //  }

  //  const extraEmployeeTemporalityFilter: ApiMasterDataQueryFilterItem[] = [];
  //  if (datatypeIsEmployee && !(oldQuery.filterItems ?? []).some((fi) => fi.property === 'EMPLOYMENT_TEMPORALITY')) {
  //    extraEmployeeTemporalityFilter.push({
  //      operation: 'EQUAL',
  //      property: 'EMPLOYMENT_TEMPORALITY',
  //      values: ['PRESENT'],
  //      dataType: oldQueryDatatype,
  //    });
  //  }

  //  const extraEmployeeCounts: ApiMasterDataQueryMeasure[] = [];

  //  if (
  //    datatypeIsEmployee &&
  //    !(oldQuery.measures ?? []).some(
  //      (m) => m.property === 'EMPLOYEE_ID' && m.operation === 'COUNT' && m.dataType === DataTypes.EMPLOYEE
  //    )
  //  ) {
  //    extraEmployeeCounts.push({
  //      operation: 'COUNT',
  //      property: 'EMPLOYEE_ID',
  //      dataType: 'EMPLOYEE',
  //    });
  //  }

  //  const advancedQuery: ApiMasterDataAdvancedQuery = {
  //    dataType: oldQueryDatatype,
  //    measures: (oldQuery.measures ?? [])
  //      .map((m) => {
  //        return { ...m, dataType: oldQueryDatatype } as ApiMasterDataQueryMeasure;
  //      })
  //      .concat(extraEmployeeCounts),
  //    dimensions: (oldQuery.dimensions ?? [])
  //      .map((d) => {
  //        return { property: d, dataType: oldQueryDatatype } as ApiMasterDataAdvancedQueryDimension;
  //      })
  //      .concat(extraDimensions),
  //    filterItems: (oldQuery.filterItems ?? [])
  //      .concat(extraLatestVersionIdFilters)
  //      .concat(extraEmployeeTemporalityFilter),
  //    limitedToPermittedPopulation: oldQuery.limitedToPermittedPopulation,
  //    limit: oldQuery.limit,
  //    offset: oldQuery.offset,
  //  };

  //  return advancedQuery;
  //};

  public mapQueryResponse: (customSqlResponse: ApiCustomSqlQueryResponse) => ApiMasterDataQueryResponse = (
    customSqlResponse: ApiCustomSqlQueryResponse
  ) => {
    const response: ApiMasterDataQueryResponse = {
      dataPoints: customSqlResponse.dataPoints.map((dp) => {
        const { dimensions, measures } = dp;
        return {
          dimensions: dimensions.map((d) => ({
            dataType: d.dataType,
            property: d.property,
            value: d.value,
          })),
          measures: measures.map((m) => ({
            dataType: m.dataType,
            property: m.property,
            value: m.value,
            operation: m.operation,
          })),
        };
      }),
    };

    if (response.dataPoints.length === 0) {
      return { dataPoints: null } as unknown as ApiMasterDataQueryResponse; // Quirk for compatibility, please kill this
    } else {
      return response;
    }
  };

  private domainToDataLoaderMap: Record<
    string,
    DataLoader<ApiQueryDataLoaderKey, ApiMasterDataBatchQueryResponseItem>
  > = {};

  private getQueryDataLoader = (domain: string) => {
    if (this.domainToDataLoaderMap[domain]) {
      return this.domainToDataLoaderMap[domain];
    } else {
      const batchFn = async (keys: readonly ApiQueryDataLoaderKey[]) => {
        const queries = [...keys];
        return (await this.executeGraphqlQueriesInBatch(domain, { queries })).responseListItems;
      };
      const dataLoader = new DataLoader<ApiQueryDataLoaderKey, ApiMasterDataBatchQueryResponseItem>(
        (keys) => batchFn(keys),
        {
          cache: false,
          // We should enable cache eventually and maybe replace our in memory cache with this
          // One benefit of using data loader cache is that it comes with deduplication
          // However, when we enable cache, we might need to provide a custom cache as the default one
          // uses reference equality check for object keys
          maxBatchSize: 100,
          // We tried out different batch sizes and 100 seems to be fine. Less is causing
          // error due to too many requests. Although we can still continue experimenting
          // and refine the batch size for further optimization. This might also depend on
          // backend and db configurations
        }
      );
      this.domainToDataLoaderMap[domain] = dataLoader;
      return this.domainToDataLoaderMap[domain];
    }
  };

  public async executeQuery(
    domain: string,
    query: ApiMasterDataQuery,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);
    const queryDataLoader = this.getQueryDataLoader(domain);
    return (await queryDataLoader.load({ queryType: 'executeOldQuery', query })).response;
  }

  public async executeAdvancedQuery(
    domain: string,
    query: ApiMasterDataAdvancedQuery
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);
    const queryDataLoader = this.getQueryDataLoader(domain);
    return (await queryDataLoader.load({ queryType: 'executeAdvancedQuery', query })).response;
  }

  public async executeQueriesInBatch(
    domain: string,
    queries: { queries: ApiMasterDataQuery[] },
    _benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse> {
    queries.queries.forEach(checkQueryContainsVersionId);
    const queryDataLoader = this.getQueryDataLoader(domain);
    const responseListItems = (await queryDataLoader.loadMany(
      queries.queries.map((q) => {
        return { queryType: 'executeOldQuery', query: q };
      })
    )) as ApiMasterDataBatchQueryResponseItem[];
    // Without this assertion, the type is (ApiMasterDataBatchQueryResponseItem | Error)[]
    // I think this type assertion is fine as our underlying batchFn is executeGraphqlQueriesInBatch
    // which always returns ApiMasterDataBatchQueryResponseItem or at least it promises to
    // in the type. Although (ApiMasterDataBatchQueryResponseItem | Error)[] is more correct, we need to
    // first implement this in the underlying batch function and then handle that here
    return { responseListItems };
  }

  public async executeAdvancedQueriesInBatch(
    domain: string,
    queries: { queries: ApiMasterDataAdvancedQuery[] },
    _benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataBatchQueryResponse> {
    queries.queries.forEach(checkQueryContainsVersionId);
    const queryDataLoader = this.getQueryDataLoader(domain);
    const responseListItems = (await queryDataLoader.loadMany(
      queries.queries.map((q) => {
        return { queryType: 'executeAdvancedQuery', query: q };
      })
    )) as ApiMasterDataBatchQueryResponseItem[];
    // Read note on type assertion in executeQueriesInBatch function
    return { responseListItems };
  }

  // @deprecated
  private async executeQueryWithoutBatching(
    // keeping the nonBatched versions just in case we want to ever test/use these endpoints
    domain: string,
    query: ApiMasterDataQuery,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);
    const escapedQuery = JSON.stringify(query);
    const oldResult = await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .executeOldQuery({
          domain: domain as any,
          queryJson: escapedQuery,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
          query, // added to make debugging easier
        } as any)
        .then((result) => JSON.parse(getGraphqlResult(result.executeOldQuery)))
    );
    return oldResult;
  }

  // @deprecated
  private executeAdvancedQueryWithoutBatching = async (
    domain: string,
    query: ApiMasterDataAdvancedQuery
  ): Promise<ApiMasterDataQueryResponse> => {
    const escapedQuery = JSON.stringify(query);
    const oldResult = await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .executeAdvancedQuery({
          domain: domain as any,
          queryJson: escapedQuery,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
          disableNestLoop: query.disableNestLoop ?? false,
          query, // added to make debugging easier
        } as any)
        .then((result) =>
          JSON.parse(
            result.executeAdvancedQuery ??
              (() => {
                throw new Error('Did not find field executeAdvancedQuery');
              })()
          )
        )
    );
    return oldResult;
  };

  public async executeMovementQuery(
    domain: string,
    query: ApiMasterDataMovementQuery
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);
    const queryDataLoader = this.getQueryDataLoader(domain);
    return (await queryDataLoader.load({ queryType: 'executeMovementQuery', query })).response;
  }

  private async executeMovementQueryWithoutBatching(
    domain: string,
    query: ApiMasterDataMovementQuery
  ): Promise<ApiMasterDataQueryResponse> {
    checkQueryContainsVersionId(query);

    const escapedQuery = JSON.stringify(query);

    return await handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .executeMovementQuery({
          domain: domain as any,
          queryJson: escapedQuery,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
          query, // added to make debugging easier
        } as any)
        .then((result) =>
          JSON.parse(
            result.executeMovementQuery ??
              (() => {
                throw new Error('Did not find field executeMovementQuery');
              })()
          )
        )
    );
  }

  public listJobOrganizations(
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.ORGANIZATION },
      timeConfig ?? null
    );
  }

  public listJobNames(domain: Domains, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.JOB_NAME },
      timeConfig ?? null
    );
  }

  public listJobJobGrades(domain: Domains, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.JOB_GRADE },
      timeConfig ?? null
    );
  }

  public listJobOffices(domain: Domains, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.OFFICE },
      timeConfig ?? null
    );
  }

  public listJobFunctions(domain: Domains, timeConfig?: TimeSliderConfigStartAndEnd): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.FUNCTION },
      timeConfig ?? null
    );
  }

  public listJobRecruitmentCategories(
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.JOB, dataField: JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL },
      timeConfig ?? null
    );
  }

  public listApplicationSources(
    domain: Domains,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> {
    return employeeDataApiService.getHierarchy(
      domain,
      { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.SOURCE },
      timeConfig ?? null
    );
  }

  public listCustomField(
    domain: Domains,
    fieldNumber: number,
    dataType: DataTypes,
    timeConfig?: TimeSliderConfigStartAndEnd
  ): Promise<ApiHierarchyItemList> {
    const field: DataFields = `CUSTOM_FIELD_${fieldNumber}` as DataFields;
    const allFields = getDataFieldsForDataType(dataType);
    if (!allFields.deepCompareContains({ dataType, dataField: field })) {
      throw new Error(`Tried to use custom field with number ${fieldNumber} but this number does not exist`);
    }
    return employeeDataApiService.getHierarchy(domain, { dataType, dataField: field }, timeConfig ?? null);
  }

  private async executeGraphqlQueriesInBatch(
    domain: string,
    queries: {
      queries: {
        queryType: ApiQueryTypes;
        query: ApiMasterDataQuery | ApiMasterDataAdvancedQuery | ApiMasterDataMovementQuery;
      }[];
    }
  ): Promise<ApiMasterDataBatchQueryResponse> {
    const gqlUrl = environmentService.getGraphqlUrl();

    const aliasPrefix = 'query';

    const aliasedQueriesMap = new Map(queries.queries.map((query, index) => [aliasPrefix + index, query]));

    const aliasedQueryStrings: string[] = [];

    const getSimulateRoleId = this.permissionStore.currentlySimulatingRole()?.id ?? null;
    const simulateRoleQueryPart = getSimulateRoleId ? `"${getSimulateRoleId}"` : null;

    const selectedExecutorRole = this.permissionStore.getExecutorRole().id;

    aliasedQueriesMap.forEach(({ query, queryType }, alias) => {
      aliasedQueryStrings.push(
        `${alias}:${queryType}(domain:"${domain}", queryJson:"""${JSON.stringify(
          query
        )}""", selectedExecutorRole: "${selectedExecutorRole}", simulateRole: ${simulateRoleQueryPart}, disableNestLoop: ${
          query.disableNestLoop ?? false
        })`
      );
    });

    const combinedAliasedQueriesString = `query { ${aliasedQueryStrings.join(' ')} }`;

    const gqlQuery = { query: combinedAliasedQueriesString };
    const token = await this.authService.getAccessToken();
    if (!token) {
      throw Error('Access Token not found');
    }
    const result = await this.requestService.post(gqlUrl + `?batching=true`, domain as Domains, token, gqlQuery, {
      headers: { 'CONTENT-TYPE': 'application/json' },
    }); // The query parameter is only to make debugging easier
    if (result.errors) {
      const errorReferences = result.errors.map((e: any) => e.extensions?.errorReference);
      console.error({ errorReferences: errorReferences }, result.errors);
      trackError(`Error(s) when running batchedQueries`, {
        errors: result.errors,
        errorReferences: errorReferences,
      });
      // @ts-ignore
      return ''; // For compatibility. Optimally we should not return a string here but fail the promise
    } else {
      const resultMap: Map<string, string> = new Map(Object.entries(result.data));

      const responses: ApiMasterDataBatchQueryResponseItem[] = [];
      resultMap.forEach((queryResult, alias) => {
        const matchingEntry = aliasedQueriesMap.get(alias);
        if (matchingEntry !== undefined) {
          responses.push({ query: matchingEntry.query, response: JSON.parse(queryResult) }); // We should have a separate type for advanced batch query responses
        } else {
          console.error(
            `Did not expect alias ${alias} in graphql response. There is mismatch in the sent queries and the response. Skipping this result: ${queryResult}`
          );
        }
      });

      return { responseListItems: responses };
    }
  }

  public async queryCohortMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: CohortMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .queryCohortMetrics({
          domain,
          metrics,
          disableNestLoop: disableNestLoop ?? null,
          timeSelection,
          userFilters: userFilters ?? null,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const results = result.queryCohortMetrics?.results;

          const failures = results?.filter((r) => r.__typename === 'MetricResultFailure') as MetricResultFailure[];
          const successes = results?.filter((r) => r.__typename === 'MetricResultSuccess') as MetricResultSuccess[];

          return (
            successes?.reduce((acc, r) => {
              const dataPoints = r.segments.flatMap((s) => {
                const res = s.groupSegments.map((gs) => {
                  const dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[] = this.parseTimeSegment(
                    s.timeSegment
                  );
                  const measures: ApiCustomSqlQueryResponseDataPointMeasureData[] = [];
                  measures.push({
                    dataType: DataTypes.EMPLOYEE, //TODO: fix
                    operation: Operations.EQUAL as ApiMasterDataQueryMeasureOperation,
                    property: metricsAliases[r.metricId] as ApiMasterDataField, //TODO: fix
                    value: parseCustomSqlValue(gs.data),
                  });
                  dimensions.push(
                    ...gs.groupSegment.map(
                      (gss) =>
                        ({
                          property: gss.dataField as DataFields,
                          dataType: gss.dataType as DataTypes,
                          value: parseCustomSqlValue(gss.value),
                        } as ApiCustomSqlQueryResponseDataPointDimensionData)
                    )
                  );
                  return { dimensions, measures, meta: r.meta };
                });
                return res;
              });
              acc = { dataPoints: [...(acc?.dataPoints ?? []), ...(dataPoints ?? [])] };
              return acc;
            }, {} as ApiMetricQueryResponse) ?? ({} as ApiMetricQueryResponse)
          );
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  public async queryMetrics(
    domain: string,
    timeSelection: TimeSelectionInput,
    metrics: RegularMetricId[],
    metricsAliases: Partial<Record<MetricId, string>>,
    userFilters?: SQLFilters,
    grouping?: GroupingInput,
    disableNestLoop?: boolean
  ): Promise<ApiMetricQueryResponse> {
    return handleGQLErrors(
      this.graphQlRequestService.graphQlSdk
        .queryMetrics({
          domain,
          metrics,
          disableNestLoop: disableNestLoop ?? null,
          grouping: grouping ?? null,
          timeSelection,
          userFilters: userFilters ?? null,
          selectedExecutorRole: this.permissionStore.getExecutorRole().id,
          simulateRole: this.permissionStore.currentlySimulatingRole()?.id ?? null,
        })
        .then((result) => {
          const results = result.queryMetrics?.results;

          const failures = results?.filter((r) => r.__typename === 'MetricResultFailure') as MetricResultFailure[];
          const successes = results?.filter((r) => r.__typename === 'MetricResultSuccess') as MetricResultSuccess[];

          return (
            successes?.reduce((acc, r) => {
              const dataPoints = r.segments.flatMap((s) => {
                const res = s.groupSegments.map((gs) => {
                  const dimensions: ApiCustomSqlQueryResponseDataPointDimensionData[] = this.parseTimeSegment(
                    s.timeSegment
                  );
                  const measures: ApiCustomSqlQueryResponseDataPointMeasureData[] = [];
                  measures.push({
                    dataType: DataTypes.EMPLOYEE, //TODO: fix
                    operation: Operations.EQUAL as ApiMasterDataQueryMeasureOperation,
                    property: metricsAliases[r.metricId] as ApiMasterDataField, //TODO: fix
                    value: parseCustomSqlValue(gs.data),
                  });
                  dimensions.push(
                    ...gs.groupSegment.map(
                      (gss) =>
                        ({
                          property: gss.dataField as DataFields,
                          dataType: gss.dataType as DataTypes,
                          value: parseCustomSqlValue(gss.value),
                        } as ApiCustomSqlQueryResponseDataPointDimensionData)
                    )
                  );
                  return { dimensions, measures, meta: r.meta };
                });
                return res;
              });
              acc = { dataPoints: [...(acc?.dataPoints ?? []), ...(dataPoints ?? [])] };
              return acc;
            }, {} as ApiMetricQueryResponse) ?? ({} as ApiMetricQueryResponse)
          );
        })
    ).then((res) => {
      if (res === '') {
        throw new Error('error');
      } else {
        return res;
      }
    });
  }

  private parseTimeSegment(timeSegment: TimeSegment) {
    if (timeSegment.__typename === 'SingleValueTimeSegment') {
      return [];
    }
    const dimension: ApiCustomSqlQueryResponseDataPointDimensionData = {
      dataType: DataTypes.EMPLOYEE,
      property: EmployeeDataFields.VERSION_ID,
      value: match(timeSegment)
        .with({ __typename: 'CalendarYearYearlySegment' }, (value) => `${value.year}`)
        .with(
          { __typename: 'CalendarYearQuarterlySegment' },
          (value) => `${value.quarter.year}-${value.quarter.quarterOfYear}`
        )
        .with({ __typename: 'CalendarYearMonthlySegment' }, (value) => value.date)
        .with({ __typename: 'FinancialYearYearlySegment' }, (value) => `${value.year}`)
        .with(
          { __typename: 'FinancialYearQuarterlySegment' },
          (value) => `${value.quarter.year}-${value.quarter.quarterOfYear}`
        )
        .otherwise(() => {
          throw new Error('time segment is invalid');
        }) as string,
    };
    return [dimension];
  }
}

export const cachedMasterDataApiService = new CachedMasterDataService();
