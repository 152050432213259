import { action, computed, observable, reaction, runInAction } from 'mobx';
import i18n from '../../../i18n';
import { Languages } from '../constants/constants';

export interface LanguageObject {
  id: Languages;
  shortName: string;
  longName: string;
}
export class LanguageStore {
  public languages: LanguageObject[] = [
    { id: Languages.EN, shortName: 'EN', longName: 'English' },
    { id: Languages.JP, shortName: 'JA', longName: 'Japanese' },
    { id: Languages.ES, shortName: 'ES', longName: 'Spanish (Latin America)' },
  ];

  @observable
  public selectedLanguage: LanguageObject = this.languages[0];

  private languageIdToMomentLocaleMap: Partial<Record<Languages, string>> = {
    [Languages.EN]: 'en-GB',
    [Languages.JP]: 'ja-JP',
  };

  @computed
  public get momentDateLocale() {
    return this.languageIdToMomentLocaleMap[this.selectedLanguage.id] ?? this.languageIdToMomentLocaleMap[Languages.EN];
  }
  public constructor() {
    const currentLang = localStorage.getItem('i18nextLng');
    runInAction(
      () => (this.selectedLanguage = this.languages.find((lng) => lng.id === currentLang) || this.languages[0])
    );
  }

  @action
  public setLanguage(id: string) {
    const selectedLanguage = this.languages.find((lng) => lng.id === id) || this.languages[0];
    this.selectedLanguage = selectedLanguage;
    reaction(
      () => this.selectedLanguage,
      (language) => {
        i18n.changeLanguage(language.id);
      },
      { fireImmediately: true }
    );
    location.reload();
  }
}
