import memoize from 'fast-memoize';
import { action, computed, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../../common-types';
import i18n from '../../../../i18n';
import { ApiHierarchyItem, ApiMasterDataTypes } from '../../api/api-interfaces';
import { CompanySettingsStore } from '../../company-settings-store';
import {
  ApplicationDataFields,
  AttritionPredictionDataFields,
  DataTypes,
  EmployeeDataFields as EDF,
  EmployeeDataFields,
  EvaluationDataFields,
  JobDataFields,
  JoinersViewDataFields as JVDF,
  OfferDataFields,
  TimeAndAttendanceMonthlyDataFields,
} from '../../constants/constants';
import { FilterStore } from '../../filter/filter-store';
import { getDimension, getEmployeeDimension } from '../../filter/utils';
import { IPermissionsStore } from '../../permissions/permissions-store';
import { getBaseField, removeDuplicates } from '../../utilFunctions/utils';
import { OtherFilters } from './filter-tray-data/dataHooks';

export interface FilterTraySectionConfig extends ApiHierarchyItem {
  loadFn?: () => Promise<any>;
  searchFn?: (searchQuery: string) => Promise<any>;
  dimension: DataFieldWithDataType;
  onlyNested?: boolean;
  dependsOn?: DataFieldWithDataType;
  helpText?: string;
  noDefaultSubItems?: boolean;
  ignoreAlias?: boolean;
  allowMultiSelect?: boolean;
  isSegmentable?: boolean;
}

export class FilterTrayStore {
  private filterStore: FilterStore;
  private companySettingsStore: CompanySettingsStore;
  private permissionsStore: IPermissionsStore;

  constructor(
    filterStore: FilterStore,
    companySettingsStore: CompanySettingsStore,
    permissionsStore: IPermissionsStore
  ) {
    this.filterStore = filterStore;
    this.companySettingsStore = companySettingsStore;
    this.permissionsStore = permissionsStore;
  }

  private getEmployeeFilters = (): FilterTraySectionConfig[] => {
    const customFilters: FilterTraySectionConfig[] = Array(20)
      .fill(0)
      .map((_, i: number) => ({
        name: `Custom Filter ${i + 1}`,
        displayName: i18n.t(`common:api.masterDataField.customFilter${i + 1}`),
        dimension: getEmployeeDimension(`CUSTOM_FILTER_${i + 1}` as EDF),
      }));

    const customFields: FilterTraySectionConfig[] = Array(10)
      .fill(0)
      .map((_, i: number) => ({
        name: `Custom Field ${i + 1}`,
        dimension: getEmployeeDimension(`CUSTOM_FIELD_${i + 1}` as EDF),
      }));
    return [
      {
        name: 'Direct Manager',
        displayName: i18n.t('common:filterTray.directManager'),
        dimension: getEmployeeDimension(EDF.MANAGER_ID),
        onlyNested: true,
      },
      {
        name: 'Manager Or IC',
        displayName: i18n.t('common:filterTray.managerOrIc'),
        dimension: getEmployeeDimension(EDF.MANAGER_OR_IC),
      },
      {
        name: 'Defined Manager Or IC',
        displayName: i18n.t('common:filterTray.definedManager'),
        dimension: getEmployeeDimension(EDF.DEFINED_MANAGER),
      },
      {
        name: 'Organization',
        displayName: i18n.t('common:filterTray.organisation'),
        dimension: getEmployeeDimension(EDF.ORGANIZATION),
      },
      {
        name: 'Location',
        displayName: i18n.t('common:filterTray.locations'),
        dimension: getEmployeeDimension(EDF.LOCATION),
      },
      {
        name: 'Job Grade',
        displayName: i18n.t('common:filterTray.jobGrades'),
        dimension: getEmployeeDimension(EDF.JOB_GRADE),
      },
      {
        name: 'Job Grade as at Start Date',
        displayName: i18n.t('common:filterTray.jobGradesAtStartDate'),
        dimension: getDimension(DataTypes.JOINERS_VIEW, JVDF.JOB_GRADE),
      },
      {
        name: 'Employment Type',
        displayName: i18n.t('common:filterTray.employmentTypes'),
        dimension: getEmployeeDimension(EDF.EMPLOYMENT_TYPE),
      },
      {
        name: 'Function',
        displayName: i18n.t('common:filterTray.function'),
        dimension: getEmployeeDimension(EDF.FUNCTION),
      },
      {
        name: 'Position',
        displayName: i18n.t('common:filterTray.positions'),
        dimension: getEmployeeDimension(EDF.POSITION),
      },
      {
        name: 'Gender',
        displayName: i18n.t('common:filterTray.gender'),
        dimension: getEmployeeDimension(EDF.GENDER),
      },
      {
        name: 'Local/Non-Local',
        displayName: i18n.t('common:filterTray.localAndNonLocal'),
        dimension: getEmployeeDimension(EDF.LOCAL_OR_NON_LOCAL),
      },
      {
        name: 'Nationality',
        displayName: i18n.t('common:filterTray.nationality'),
        dimension: getEmployeeDimension(EDF.NATIONALITY_HIERARCHICAL),
      },
      {
        name: 'Age',
        displayName: i18n.t('common:filterTray.age'),
        dimension: getEmployeeDimension(EDF.AGE),
      },
      {
        name: 'Age Group',
        displayName: i18n.t('common:filterTray.ageGroup'),
        dimension: getEmployeeDimension(EDF.AGE_GROUP),
      },
      {
        name: 'Tenure',
        displayName: i18n.t('common:filterTray.tenure'),
        dimension: getEmployeeDimension(EDF.TENURE_GROUP),
      },
      {
        name: 'Marital Status',
        displayName: i18n.t('common:filterTray.maritalStatus'),
        dimension: getEmployeeDimension(EDF.MARITAL_STATUS),
      },
      {
        name: 'Performance Rating',
        dimension: getEmployeeDimension(EDF.PERFORMANCE_RATING),
      },
      {
        name: 'Job Title',
        displayName: i18n.t('common:filterTray.jobTitle'),
        dimension: getEmployeeDimension(EDF.JOB_TITLE),
      },
      {
        name: 'Regret/Non-Regret Exits',
        displayName: i18n.t('common:filterTray.regretNonRegret'),
        dimension: getEmployeeDimension(EDF.REGRET_ATTRITION),
      },
      {
        name: 'Recruitment Category',
        displayName: i18n.t('common:filterTray.recruitmentCategory'),
        dimension: getEmployeeDimension(EDF.RECRUITMENT_CATEGORY),
      },
      {
        name: 'Recruitment Category Hierarchical',
        displayName: i18n.t('common:filterTray.recruitmentCategoryHierarchical'),
        dimension: getEmployeeDimension(EDF.RECRUITMENT_CATEGORY_HIERARCHICAL),
      },
      {
        name: 'Work Consideration',
        displayName: i18n.t('common:filterTray.workConsideration'),
        dimension: getEmployeeDimension(EDF.WORK_CONSIDERATION),
      },
      {
        name: 'Social Type',
        displayName: i18n.t('common:filterTray.socialType'),
        dimension: getEmployeeDimension(EDF.SOCIAL_TYPE),
      },
      {
        name: 'Career Ladder',
        displayName: i18n.t('common:filterTray.careerLadder'),
        dimension: getEmployeeDimension(EDF.CAREER_LADDER),
      },
      {
        name: 'Onboarding Survey Score',
        displayName: i18n.t('common:filterTray.onboardingSurveyScore'),
        dimension: getEmployeeDimension(EDF.ONBOARDING_SURVEY_SCORE),
      },
      {
        name: 'Onboarding Survey Score',
        displayName: i18n.t('common:filterTray.onboardingSurveyScoreGroup'),
        dimension: getEmployeeDimension(EDF.ONBOARDING_SURVEY_SCORE_GROUP),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.ONBOARDING_SURVEY_SCORE },
      },
      {
        name: 'Performance Regular Cycle',
        displayName: i18n.t('common:filterTray.performanceRegularCycle'),
        dimension: getEmployeeDimension(EDF.PERFORMANCE_REGULAR_CYCLE),
      },
      {
        name: 'Performance Bonus Cycle',
        displayName: i18n.t('common:filterTray.performanceBonusCycle'),
        dimension: getEmployeeDimension(EDF.PERFORMANCE_BONUS_CYCLE),
      },
      {
        name: 'Manager Feedback',
        displayName: i18n.t('common:filterTray.managerFeedback'),
        dimension: getEmployeeDimension(EDF.MANAGER_FEEDBACK),
      },
      {
        name: 'Total Average',
        displayName: i18n.t('common:filterTray.totalAverage'),
        dimension: getEmployeeDimension(EDF.TOTAL_AVERAGE_SCORE_QUARTILE),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.TOTAL_AVERAGE_SCORE },
      },
      {
        name: 'Communication Skill',
        displayName: i18n.t('common:filterTray.communicationSkill'),
        dimension: getEmployeeDimension(EDF.COMMUNICATION_SKILL_SCORE_QUARTILE),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.COMMUNICATION_SKILL_SCORE },
      },
      {
        name: 'General Cognitive Ability',
        displayName: i18n.t('common:filterTray.generalCognitiveAbility'),
        dimension: getEmployeeDimension(EDF.GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.GENERAL_COGNITIVE_ABILITY_SCORE },
      },
      {
        name: 'Role Related Knowledge',
        displayName: i18n.t('common:filterTray.roleRelatedKnowledge'),
        dimension: getEmployeeDimension(EDF.ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.ROLE_RELATED_KNOWLEDGE_SCORE },
      },
      {
        name: 'Survey Score',
        displayName: i18n.t('common:filterTray.surveyScore'),
        dimension: getEmployeeDimension(OtherFilters.SURVEY_SCORES as any),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.PULSE_SURVEY_SCORE_1 },
      },
      {
        name: 'Pulse Survey Score',
        displayName: i18n.t('common:filterTray.pulseSurveyScore'),
        dimension: getEmployeeDimension(OtherFilters.PULSE_SCORES as any),
        dependsOn: { dataType: DataTypes.EMPLOYEE, dataField: EDF.PULSE_SURVEY_SCORE_1 },
      },
      {
        name: 'Career Change Intention',
        displayName: i18n.t('common:filterTray.careerChangeIntention'),
        dimension: getEmployeeDimension(EDF.CAREER_CHANGE_INTENTION),
      },
      {
        name: 'Internal Transfer Intention',
        displayName: i18n.t('common:filterTray.internalTransferIntention'),
        dimension: getEmployeeDimension(EDF.INTERNAL_TRANSFER_INTENTION),
      },
      {
        name: 'Evaluation',
        displayName: i18n.t('common:filterTray.evaluation'),
        // TODO: Ideally dimension should be id, but in data permissions, ID hasn't been set
        // I need to migrate it correctly later
        dimension: getDimension(DataTypes.EVALUATION, EvaluationDataFields.EVALUATION_CYCLE_TYPE),
        isSegmentable: false,
      },
      {
        name: 'Hire Year',
        displayName: i18n.t('common:filterTray.hireYear'),
        dimension: getEmployeeDimension(EDF.START_DATE),
        ignoreAlias: true,
        allowMultiSelect: false,
        isSegmentable: false,
      },
      {
        name: 'Individuals',
        displayName: i18n.t('common:filterTray.employees'),
        dimension: getEmployeeDimension(EmployeeDataFields.EMPLOYEE_ID),
        helpText: i18n.t('common:filterTray.employeeFilterHelpText'),
        noDefaultSubItems: true,
        searchFn: this.filterStore.loadEmployeeFilters,
      },
      ...customFilters,
      ...customFields,
    ];
  };

  private getRecruitmentFilters = (): FilterTraySectionConfig[] => {
    const recruitmentFilters: FilterTraySectionConfig[] = [
      {
        name: 'Requisitions Offices',
        displayName: i18n.t('common:filterTray.requisitionOffice'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.LOCATION),
      },
      {
        name: 'Organization',
        displayName: i18n.t('common:filterTray.recruitmentOrganization'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.ORGANIZATION),
      },
      {
        name: 'Application Status',
        displayName: i18n.t('common:filterTray.applicationStatus'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.STATUS),
      },
      {
        name: 'Start Year',
        displayName: i18n.t('common:filterTray.startYear'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.START_YEAR),
      },
      {
        name: 'Start Month',
        displayName: i18n.t('common:filterTray.startMonth'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.START_MONTH),
      },
      {
        name: 'Interview Stages',
        displayName: i18n.t('common:filterTray.interviewStages'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.STANDARDIZED_CURRENT_STAGE),
      },
      {
        name: 'Current Job Title',
        displayName: i18n.t('common:filterTray.currentJobTitle'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.CURRENT_JOB_TITLE),
      },
      {
        name: 'Rejection Reason',
        displayName: i18n.t('common:filterTray.rejectionReason'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.REJECTION_REASON),
      },
      {
        name: 'Job Name',
        displayName: i18n.t('common:filterTray.jobName'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.JOB_NAME),
      },
      {
        name: 'Req Status',
        displayName: i18n.t('common:filterTray.jobStatus'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.STATUS),
      },
      {
        name: 'Function',
        displayName: i18n.t('common:filterTray.function'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.FUNCTION),
      },
      {
        name: 'Recruitment Category Hierarchical',
        displayName: i18n.t('common:filterTray.recruitmentCategoryHierarchical'),
        dimension: getDimension(DataTypes.JOB, JobDataFields.RECRUITMENT_CATEGORY_HIERARCHICAL),
      },
      {
        name: 'Job Custom Field 1',
        dimension: getDimension(DataTypes.JOB, JobDataFields.CUSTOM_FIELD_1),
      },
      {
        name: 'Job Custom Field 2',
        dimension: getDimension(DataTypes.JOB, JobDataFields.CUSTOM_FIELD_2),
      },
      {
        name: 'Hire Status',
        displayName: i18n.t('common:filterTray.hireStatus'),
        dimension: getDimension(DataTypes.OFFER, OfferDataFields.STATUS),
      },
      {
        name: 'Sources',
        displayName: i18n.t('common:filterTray.sources'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.SOURCE),
      },
      {
        name: 'Degree Type',
        displayName: i18n.t('common:filterTray.degreeType'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.DEGREE_TYPE),
      },
      {
        name: 'Degree Major',
        displayName: i18n.t('common:filterTray.degreeMajor'),
        dimension: getDimension(DataTypes.APPLICATION, ApplicationDataFields.DEGREE_MAJOR),
      },
      ...Array(1)
        .fill(0)
        .map((_, i: number) => ({
          name: `Application Custom Field ${i + 1}`,
          dimension: getDimension(DataTypes.APPLICATION, `CUSTOM_FIELD_${i + 1}` as EDF),
        })),
      ...Array(5)
        .fill(0)
        .map((_, i: number) => ({
          name: `Application Custom Filter ${i + 1}`,
          dimension: getDimension(DataTypes.APPLICATION, `CUSTOM_FILTER_${i + 1}` as EDF),
        })),
    ];
    return recruitmentFilters;
  };

  private getAttritionPredictionFilters = (): FilterTraySectionConfig[] => {
    return [
      {
        name: 'Risk Profile',
        displayName: i18n.t('common:filterTray.riskProfile'),
        dimension: getDimension(DataTypes.ATTRITIONPREDICTION, AttritionPredictionDataFields.RISK_PROFILE),
      },
    ];
  };

  private getTimeAndAttendanceMonthlyFilters = (): FilterTraySectionConfig[] => {
    return [
      ...Array(5)
        .fill(0)
        .map((_, i: number) => ({
          name: `Time And Attendance Custom Field ${i + 1}`,
          displayName: i18n.t(`common:api.masterDataField.customFilter${i + 1}`),
          dimension: getDimension(
            DataTypes.TIMEANDATTENDANCEMONTHLY,
            `CUSTOM_FIELD_${i + 1}` as TimeAndAttendanceMonthlyDataFields
          ),
        })),
    ];
  };

  @computed
  private get employeeFilters(): FilterTraySectionConfig[] {
    return this.getEmployeeFilters();
  }

  @computed
  private get recruitmentFilters(): FilterTraySectionConfig[] {
    return this.getRecruitmentFilters();
  }

  @computed
  private get engagementFilters(): FilterTraySectionConfig[] {
    return [];
  }

  @computed
  private get attritionPredictionFilters(): FilterTraySectionConfig[] {
    return this.getAttritionPredictionFilters();
  }

  @computed
  private get timeAndAttendanceMonthlyFilters(): FilterTraySectionConfig[] {
    return this.getTimeAndAttendanceMonthlyFilters();
  }

  public get allFilters(): FilterTraySectionConfig[] {
    return [
      ...this.employeeFilters,
      ...this.recruitmentFilters,
      ...this.engagementFilters,
      ...this.attritionPredictionFilters,
      ...this.timeAndAttendanceMonthlyFilters,
    ];
  }

  @computed
  public get enabledFiltersForCompany() {
    return this.allFilters.filter((f) =>
      this.companySettingsStore.isFilterEnabledForDomain(f.dependsOn ?? f.dimension)
    );
  }

  public getFiltersWithData: (
    allFilters: DataFieldWithDataType[],
    nonNullFields: DataFieldWithDataType[]
  ) => DataFieldWithDataType[] = memoize(
    (allFilters: DataFieldWithDataType[], nonNullFields: DataFieldWithDataType[]): DataFieldWithDataType[] => {
      // since for hierarchical filters we only care about base dimension
      // and not level, we adjust non null fields accordingly here
      const modifiedNonNullFields = removeDuplicates(
        nonNullFields.map((f) => {
          return getBaseField(f);
        })
      );
      return allFilters.filter((f) => modifiedNonNullFields.deepCompareContains(f));
    }
  );

  public getNonEmptyFilters = (allowedDataTypes: ApiMasterDataTypes[] | DataTypes[]): FilterTraySectionConfig[] => {
    return this.permissionsStore.enabledFilters(
      this.enabledFiltersForCompany,
      allowedDataTypes as ApiMasterDataTypes[]
    );
  };

  // My Filter Groups
  @observable
  public filterGroupNameInput = '';

  @action
  public setFilterGroupNameInput = (input: string) => {
    this.filterGroupNameInput = input;
  };
}
