import { IMetrics, Metrics, PbsCategories, PbsItems, PbsMeasures } from '../types';

export const PbsBackendMetrics: IMetrics[] = [
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_1,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_1_NUM_MOVEMENTS,
    measure: PbsMeasures.NUM_MOVEMENTS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_1,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_1_NUM_EMPS_MOVED,
    measure: PbsMeasures.NUM_EMPS_MOVED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_1,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_1_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_2,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_2_NUM_MOVEMENTS,
    measure: PbsMeasures.NUM_MOVEMENTS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_2,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_2_NUM_EMPS_MOVED,
    measure: PbsMeasures.NUM_EMPS_MOVED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.INTERNAL_MOB_RATE_LEVEL_2,
    name: Metrics.INTERNAL_MOB_RATE_LEVEL_2_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ATTRITION,
    name: Metrics.ATTRITION_ANNUALIZED,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ATTRITION,
    name: Metrics.ATTRITION_MONTHLY,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ATTRITION,
    name: Metrics.ATTRITION_TTM,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ATTRITION,
    name: Metrics.ATTRITION_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.REGRET_ATTRITION,
    name: Metrics.REGRET_ATTRITION_ANNUALIZED,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.REGRET_ATTRITION,
    name: Metrics.REGRET_ATTRITION_MONTHLY,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.REGRET_ATTRITION,
    name: Metrics.REGRET_ATTRITION_TTM,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.REGRET_ATTRITION,
    name: Metrics.REGRET_ATTRITION_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.VOLUNTARY_ATTRITION,
    name: Metrics.VOLUNTARY_ATTRITION_ANNUALIZED,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.VOLUNTARY_ATTRITION,
    name: Metrics.VOLUNTARY_ATTRITION_MONTHLY,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.VOLUNTARY_ATTRITION,
    name: Metrics.VOLUNTARY_ATTRITION_TTM,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.VOLUNTARY_ATTRITION,
    name: Metrics.VOLUNTARY_ATTRITION_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_TENURE_ATTRITION,
    name: Metrics.THREE_MONTH_TENURE_ATTRITION_ANNUALIZED,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_TENURE_ATTRITION,
    name: Metrics.THREE_MONTH_TENURE_ATTRITION_MONTHLY,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_TENURE_ATTRITION,
    name: Metrics.THREE_MONTH_TENURE_ATTRITION_TTM,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_TENURE_ATTRITION,
    name: Metrics.THREE_MONTH_TENURE_ATTRITION_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_TENURE_ATTRITION,
    name: Metrics.ONE_YEAR_TENURE_ATTRITION_ANNUALIZED,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_TENURE_ATTRITION,
    name: Metrics.ONE_YEAR_TENURE_ATTRITION_MONTHLY,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_TENURE_ATTRITION,
    name: Metrics.ONE_YEAR_TENURE_ATTRITION_TTM,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_TENURE_ATTRITION,
    name: Metrics.ONE_YEAR_TENURE_ATTRITION_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },

  {
    pbsItem: PbsItems.THREE_MONTH_ATTRITION,
    name: Metrics.THREE_MONTH_ATTRITION_ATTRITION,
    measure: PbsMeasures.ATTRITION,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_ATTRITION,
    name: Metrics.THREE_MONTH_ATTRITION_COUNT_JOINERS,
    measure: PbsMeasures.JOINERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_MONTH_ATTRITION,
    name: Metrics.THREE_MONTH_ATTRITION_COUNT_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_ATTRITION,
    name: Metrics.ONE_YEAR_ATTRITION_ATTRITION,
    measure: PbsMeasures.ATTRITION,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_ATTRITION,
    name: Metrics.ONE_YEAR_ATTRITION_COUNT_JOINERS,
    measure: PbsMeasures.JOINERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ONE_YEAR_ATTRITION,
    name: Metrics.ONE_YEAR_ATTRITION_COUNT_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_YEAR_ATTRITION,
    name: Metrics.THREE_YEAR_ATTRITION_ATTRITION,
    measure: PbsMeasures.ATTRITION,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_YEAR_ATTRITION,
    name: Metrics.THREE_YEAR_ATTRITION_COUNT_JOINERS,
    measure: PbsMeasures.JOINERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THREE_YEAR_ATTRITION,
    name: Metrics.THREE_YEAR_ATTRITION_COUNT_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THIRD_YEAR_ATTRITION,
    name: Metrics.THIRD_YEAR_ATTRITION_ATTRITION,
    measure: PbsMeasures.ATTRITION,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THIRD_YEAR_ATTRITION,
    name: Metrics.THIRD_YEAR_ATTRITION_COUNT_JOINERS,
    measure: PbsMeasures.JOINERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.THIRD_YEAR_ATTRITION,
    name: Metrics.THIRD_YEAR_ATTRITION_COUNT_LEAVERS,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.RETENTION_RATE,
    name: Metrics.RETENTION_RATE_PERC,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.STARTING_POP_ATTRITION,
    name: Metrics.STARTING_POP_ATTRITION_PERC,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.REVENUE,
    name: Metrics.REVENUE_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.REVENUE,
    name: Metrics.REVENUE_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT,
    name: Metrics.GROSS_PROFIT_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT,
    name: Metrics.GROSS_PROFIT_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES,
    name: Metrics.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES,
    name: Metrics.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT,
    name: Metrics.OPERATING_PROFIT_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT,
    name: Metrics.OPERATING_PROFIT_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME,
    name: Metrics.NET_INCOME_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME,
    name: Metrics.NET_INCOME_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST,
    name: Metrics.TRAINING_COST_PERFTE_MONTHLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST,
    name: Metrics.TRAINING_COST_PERPERSON_MONTHLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },

  {
    pbsItem: PbsItems.REVENUE_QUARTERLY,
    name: Metrics.REVENUE_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.REVENUE_QUARTERLY,
    name: Metrics.REVENUE_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_QUARTERLY,
    name: Metrics.GROSS_PROFIT_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_QUARTERLY,
    name: Metrics.GROSS_PROFIT_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY,
    name: Metrics.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY,
    name: Metrics.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_QUARTERLY,
    name: Metrics.OPERATING_PROFIT_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_QUARTERLY,
    name: Metrics.OPERATING_PROFIT_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_QUARTERLY,
    name: Metrics.NET_INCOME_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_QUARTERLY,
    name: Metrics.NET_INCOME_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_QUARTERLY,
    name: Metrics.TRAINING_COST_PERFTE_QUARTERLY,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_QUARTERLY,
    name: Metrics.TRAINING_COST_PERPERSON_QUARTERLY,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },

  {
    pbsItem: PbsItems.REVENUE_WHOLECOMPANY,
    name: Metrics.Pm0302_1RevenueHcMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.REVENUE_WHOLECOMPANY,
    name: Metrics.Pm0302_2RevenueHcMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_WHOLECOMPANY,
    name: Metrics.Pm0303_1GrossProfitHcMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_WHOLECOMPANY,
    name: Metrics.Pm0303_2GrossProfitHcMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_WHOLECOMPANY,
    name: Metrics.Pm0304_1SgahcMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_WHOLECOMPANY,
    name: Metrics.Pm0304_2SgahcMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_WHOLECOMPANY,
    name: Metrics.Pm0305_1OperatingProfitHcMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_WHOLECOMPANY,
    name: Metrics.Pm0305_2OperatingProfitHcMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_WHOLECOMPANY,
    name: Metrics.Pm0307_1NetIncomeHcMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_WHOLECOMPANY,
    name: Metrics.Pm0307_2NetIncomeHcMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_WHOLECOMPANY,
    name: Metrics.Pm0306_1TrainingCostMonthlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_WHOLECOMPANY,
    name: Metrics.Pm0306_2TrainingCostMonthlyWholeCompanyPerIndiv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },

  {
    pbsItem: PbsItems.REVENUE_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0308_1RevenueHcQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.REVENUE_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0308_2RevenueHcQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0309_1GrossProfitHcQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.GROSS_PROFIT_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0309_2GrossProfitHcQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0310_1SgahcQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0310_2SgahcQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0311_1OperatingProfitHcQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.OPERATING_PROFIT_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0311_2OperatingProfitHcQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0313_1NetIncomeHcQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.NET_INCOME_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0313_2NetIncomeHcQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0312_1TrainingCostQuarterlyWholeCompanyPerFte,
    measure: PbsMeasures.PERFTE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TRAINING_COST_QUARTERLY_WHOLECOMPANY,
    name: Metrics.Pm0312_2TrainingCostQuarterlyWholeCompanyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },

  {
    pbsItem: PbsItems.DAYS_IN_OFFICE,
    name: Metrics.DAYS_IN_OFFICE_PERC_OF_WORKING_DAYS,
    measure: PbsMeasures.PERCENTAGEOFWORKINGDAYS,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_IN_OFFICE,
    name: Metrics.DAYS_IN_OFFICE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_IN_OFFICE,
    name: Metrics.DAYS_IN_OFFICE_AVG,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.TOTAL_COMPENSATION,
    name: Metrics.TOTAL_COMPENSATION_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.TOTAL_COMPENSATION,
    name: Metrics.TOTAL_COMPENSATION_AVERAGE,
    measure: PbsMeasures.PERPERSON,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.TOTAL_COMPENSATION,
    name: Metrics.PM0176_3TotalCashCompensationPerPersonWtcc,
    measure: PbsMeasures.PERPERSON_WITH_CC,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.SGA_PAYROLL,
    name: Metrics.SGA_PAYROLL_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SGA_PAYROLL,
    name: Metrics.SGA_PAYROLL_PERC_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SGA_PAYROLL,
    name: Metrics.SGA_PAYROLL_PERC_PAYROLL_COST,
    measure: PbsMeasures.PERCENTAGE_OF_PAYROLL_COST,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SGA_POP,
    name: Metrics.SGA_POPULATION_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.SGA_POP,
    name: Metrics.SGA_POPULATION_PERC_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.COGS_PAYROLL,
    name: Metrics.COGS_PAYROLL_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.COGS_PAYROLL,
    name: Metrics.COGS_PAYROLL_PERC_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.COGS_PAYROLL,
    name: Metrics.COGS_PAYROLL_PERC_PAYROLL_COST,
    measure: PbsMeasures.PERCENTAGE_OF_PAYROLL_COST,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.COGS_POP,
    name: Metrics.COGS_POPULATION_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.COGS_POP,
    name: Metrics.COGS_POPULATION_PERC_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.INDIRECT_INVESTMENT_RATIO,
    name: Metrics.INDIRECT_INVESTMENT_RATIO_PERC,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PAID_LEAVES_TAKEN,
    name: Metrics.PAID_LEAVES_TAKEN_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PAID_LEAVES_TAKEN,
    name: Metrics.PAID_LEAVES_TAKEN_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_OF_REMOTE_WORK,
    name: Metrics.DAYS_REMOTE_PERC_OF_WORKING_DAYS,
    measure: PbsMeasures.PERCENTAGEOFWORKINGDAYS,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_OF_REMOTE_WORK,
    name: Metrics.DAYS_REMOTE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_OF_REMOTE_WORK,
    name: Metrics.DAYS_REMOTE_AVG,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.EMPLOYER_STATUTORY_PAYMENT,
    name: Metrics.EMPLOYER_STATUTORY_PAYMENT_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.NUM_JOINERS,
    name: Metrics.JOINERS_COUNT,
    measure: PbsMeasures.JOINERS,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.NUM_JOINERS,
    name: Metrics.JOINERS_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.NUM_LEAVERS_TERM_DATE,
    name: Metrics.LEAVERS_TERM_DATE_COUNT,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.NUM_LEAVERS_TERM_DATE,
    name: Metrics.LEAVERS_TERM_DATE_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.SURVEY_COMPANY_MOOD_SCORE,
    name: Metrics.SURVEY_COMPANY_MOOD_SCORE_AVG_SCORE,
    measure: PbsMeasures.AVG_SCORE,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.SURVEY_COMPANY_MOOD_SCORE,
    name: Metrics.SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS,
    measure: PbsMeasures.NUM_RESPONDENTS,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.SURVEY_COMPANY_MOOD_SCORE,
    name: Metrics.SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.NUM_RESPONDENTS_PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.SURVEY_ENGAGEMENT_SCORE,
    name: Metrics.SURVEY_ENGAGEMENT_SCORE_AVG_SCORE,
    measure: PbsMeasures.AVG_SCORE,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.SURVEY_ENGAGEMENT_SCORE,
    name: Metrics.SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS,
    measure: PbsMeasures.NUM_RESPONDENTS,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.SURVEY_ENGAGEMENT_SCORE,
    name: Metrics.SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.NUM_RESPONDENTS_PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.ENPS,
    name: Metrics.ENPS_SCORE,
    measure: PbsMeasures.SCORE,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.ENPS,
    name: Metrics.ENPS_RESPONDENT_COUNT,
    measure: PbsMeasures.NUM_RESPONDENTS,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.ENPS,
    name: Metrics.ENPS_RESPONDENT_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.NUM_RESPONDENTS_PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.MSAD_PARENTAL_LEAVES_UTILIZATION,
    name: Metrics.MSAD_PARENTAL_LEAVES_UTILIZATION_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.MSAD_SURVEY_ENGAGEMENT_SCORE,
    name: Metrics.MSAD_SURVEY_ENGAGEMENT_SCORE_PERCENTAGEOFTOTALHC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.POTENTIAL_FEMALE_LEADER,
    name: Metrics.POTENTIAL_FEMALE_LEADER_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.POTENTIAL_FEMALE_LEADER,
    name: Metrics.POTENTIAL_FEMALE_LEADER_PERCENTAGE_OF_POTENTIAL_LEADER,
    measure: PbsMeasures.PERCENTAGE_OF_POTENTIAL_LEADER,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE,
    name: Metrics.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE,
    name: Metrics.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING,
    name: Metrics.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING,
    name: Metrics.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.DIGITAL_EXPERTS,
    name: Metrics.DIGITAL_EXPERTS_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.DIGITAL_EXPERTS,
    name: Metrics.DIGITAL_EXPERTS_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.HIGHLY_STRESSED_EMPLOYEE,
    name: Metrics.HIGHLY_STRESSED_EMPLOYEE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.HIGHLY_STRESSED_EMPLOYEE,
    name: Metrics.HIGHLY_STRESSED_EMPLOYEE_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.DISCIPLINARY_ACTION,
    name: Metrics.DISCIPLINARY_ACTION_COUNT,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.ANNUAL_PAID_LEAVES_TAKEN,
    name: Metrics.ANNUAL_PAID_LEAVES_TAKEN_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.ANNUAL_PAID_LEAVES_TAKEN,
    name: Metrics.ANNUAL_PAID_LEAVES_TAKEN_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.NUM_EMP,
    name: Metrics.EMPLOYEE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.FTEQUIVALENT,
    name: Metrics.FTEQUIVALENT_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.POP_PAY_GAP,
    name: Metrics.POP_PAY_GAP_MULTIPLE,
    measure: PbsMeasures.MULTIPLE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.TOTAL_WORKFORCE_COST,
    name: Metrics.TOTAL_WORKFORCE_COST_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.TOTAL_WORKFORCE_COST,
    name: Metrics.TOTAL_WORKFORCE_COST_AVERAGE,
    measure: PbsMeasures.PERPERSON,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.OVERTIME_PAY,
    name: Metrics.OVERTIME_PAY_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.OVERTIME_PAY,
    name: Metrics.OVERTIME_PAY_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.TRAVEL_AND_COMMUTING,
    name: Metrics.TRAVEL_AND_COMMUTING_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.TRAVEL_AND_COMMUTING,
    name: Metrics.TRAVEL_AND_COMMUTING_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.AGE,
    name: Metrics.AGE_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.AGE,
    name: Metrics.AGE_MIN,
    measure: PbsMeasures.MIN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.AGE,
    name: Metrics.AGE_MAX,
    measure: PbsMeasures.MAX,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.AGE,
    name: Metrics.AGE_MEDIAN,
    measure: PbsMeasures.MEDIAN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.SPAN_OF_CONTROL,
    name: Metrics.SPAN_OF_CONTROL_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.SPAN_OF_CONTROL,
    name: Metrics.SPAN_OF_CONTROL_MIN,
    measure: PbsMeasures.MIN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.SPAN_OF_CONTROL,
    name: Metrics.SPAN_OF_CONTROL_MAX,
    measure: PbsMeasures.MAX,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.SPAN_OF_CONTROL,
    name: Metrics.SPAN_OF_CONTROL_MEDIAN,
    measure: PbsMeasures.MEDIAN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.DEPTH_OF_CONTROL,
    name: Metrics.DEPTH_OF_CONTROL_MAX,
    measure: PbsMeasures.MAX,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ACTUAL_TENURE,
    name: Metrics.ACTUAL_TENURE_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ACTUAL_TENURE,
    name: Metrics.ACTUAL_TENURE_MIN,
    measure: PbsMeasures.MIN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ACTUAL_TENURE,
    name: Metrics.ACTUAL_TENURE_MAX,
    measure: PbsMeasures.MAX,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.ACTUAL_TENURE,
    name: Metrics.ACTUAL_TENURE_MEDIAN,
    measure: PbsMeasures.MEDIAN,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.MEDIAN_BASE,
    name: Metrics.MEDIAN_BASE,
    measure: PbsMeasures.MEDIAN,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEM_HC,
    name: Metrics.FEMALE_HEADCOUNT_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEM_HC,
    name: Metrics.FEMALE_HEADCOUNT_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.MANAGERS_CALCULATED,
    name: Metrics.MANAGERS_CALCULATED_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.MANAGERS_CALCULATED,
    name: Metrics.MANAGERS_CALCULATED_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.MANAGERS_DEFINED,
    name: Metrics.MANAGERS_DEFINED_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.MANAGERS_DEFINED,
    name: Metrics.MANAGERS_DEFINED_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_CALCULATED,
    name: Metrics.FEMALE_MANAGERS_CALCULATED_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_CALCULATED,
    name: Metrics.FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_CALCULATED,
    name: Metrics.FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_MANAGERS,
    measure: PbsMeasures.PERCENTAGE_OF_MANAGERS,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_DEFINED,
    name: Metrics.FEMALE_MANAGERS_DEFINED_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_DEFINED,
    name: Metrics.FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.FEMALE_MANAGERS_DEFINED,
    name: Metrics.FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_MANAGERS,
    measure: PbsMeasures.PERCENTAGE_OF_MANAGERS,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_GAP_FEMALE_OF_MALE,
    measure: PbsMeasures.FofM,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_GAP_MALE_OF_FEMALE,
    measure: PbsMeasures.MofF,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_PAY_GAP_BASE_SALARY_F_TO_M_CASH_GAP,
    measure: PbsMeasures.F_TO_M_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_PAY_GAP_BASE_SALARY_M_TO_F_CASH_GAP,
    measure: PbsMeasures.M_TO_F_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_PAY_GAP_BASE_SALARY_F_TO_M_PERCENT,
    measure: PbsMeasures.F_TO_M_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_GAP,
    name: Metrics.GENDER_PAY_GAP_BASE_SALARY_M_TO_F_PERCENT,
    measure: PbsMeasures.M_TO_F_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_PERCENT,
    measure: PbsMeasures.F_TO_M_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_PERCENT,
    measure: PbsMeasures.M_TO_F_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_FEMALE_OF_MALE,
    measure: PbsMeasures.FofM,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_MALE_OF_FEMALE,
    measure: PbsMeasures.MofF,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_CASH_GAP,
    measure: PbsMeasures.F_TO_M_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_CASH_GAP,
    measure: PbsMeasures.M_TO_F_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_PERCENT,
    measure: PbsMeasures.F_TO_M_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_PERCENT,
    measure: PbsMeasures.M_TO_F_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_FEMALE_OF_MALE,
    measure: PbsMeasures.FofM,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_MALE_OF_FEMALE,
    measure: PbsMeasures.MofF,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_CASH_GAP,
    measure: PbsMeasures.F_TO_M_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
    name: Metrics.GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_CASH_GAP,
    measure: PbsMeasures.M_TO_F_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.INTERNAL_COMPA_RATIO,
    name: Metrics.INTERNAL_COMPA_RATIO_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.REGULAR_TYPE_EMP,
    name: Metrics.REGULAR_TYPE_EMP_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.REGULAR_TYPE_EMP,
    name: Metrics.REGULAR_TYPE_EMP_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.NON_REGULAR_TYPE_EMP,
    name: Metrics.NON_REGULAR_TYPE_EMP_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.NON_REGULAR_TYPE_EMP,
    name: Metrics.NON_REGULAR_TYPE_EMP_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.FTE,
    name: Metrics.FTE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.FTE,
    name: Metrics.FTE_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.TRAINING_TAKEN,
    name: Metrics.TRAINING_TAKEN_COUNT,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.TRAINING_TAKEN,
    name: Metrics.TRAINING_TAKEN_AVERAGE_TRAINING_PER_TRAINEE,
    measure: PbsMeasures.AVERAGE_TRAINING_PER_TRAINEE,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.TRAINING_TAKEN,
    name: Metrics.TRAINING_TAKEN_AVERAGE_TRAINING_PER_EMPLOYEE,
    measure: PbsMeasures.AVERAGE_TRAINING_PER_EMPLOYEE,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.TRAINEE,
    name: Metrics.TRAINEE_COUNT,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.TRAINEE,
    name: Metrics.TRAINEE_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.CEO_PAY_GAP_BASE_AVG,
    name: Metrics.CEO_PAY_GAP_BASE_AVG_MULTIPLE,
    measure: PbsMeasures.MULTIPLE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.CEO_PAY_GAP_BASE_AVG,
    name: Metrics.CEO_PAY_GAP_BASE_AVG_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION,
    name: Metrics.TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION_RATIO_OF_SUMS,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TENURE_IN_JOB_GRADE_LEVEL_1,
    name: Metrics.TENURE_IN_JOB_GRADE_LEVEL_1_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.TENURE_IN_JOB_GRADE_LEVEL_2,
    name: Metrics.TENURE_IN_JOB_GRADE_LEVEL_2_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.TENURE_IN_ORG_LEVEL_1,
    name: Metrics.TENURE_IN_ORG_LEVEL_1_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.TENURE_IN_ORG_LEVEL_2,
    name: Metrics.TENURE_IN_ORG_LEVEL_2_AVERAGE,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.HUMAN_CAPITAL_ROI,
    name: Metrics.HUMAN_CAPITAL_ROI_PERCENT,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.MARUDAI_COMPLAINTS,
    name: Metrics.MARUDAI_COMPLAINTS_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.PEOPLE_PROFIT,
  },
  {
    pbsItem: PbsItems.JT_OCCUPATIONAL_ACCIDENTS,
    name: Metrics.JT_OCCUPATIONAL_ACCIDENTS_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.JT_OCCUPATIONAL_ACCIDENTS,
    name: Metrics.JT_OCCUPATIONAL_ACCIDENTS_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.JT_KILLED_AT_WORK,
    name: Metrics.JT_KILLED_AT_WORK_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.LABOR_COST,
    name: Metrics.LABOR_COST_PERCENTAGE,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.TOTAL_LABOR_COST_TO_SALES,
    name: Metrics.TOTAL_LABOR_COST_TO_SALES_RATIO,
    measure: PbsMeasures.RATIO,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PARENTAL_LEAVES_TAKEN_UTILIZATION,
    name: Metrics.PARENTAL_LEAVES_TAKEN_UTILIZATION_RATIO,
    measure: PbsMeasures.RATIO,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PARENTAL_LEAVES_TAKEN_UTILIZATION,
    name: Metrics.PARENTAL_LEAVES_TAKEN_UTILIZATION_PERCENT,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PARENTAL_LEAVES_TAKEN_UTILIZATION,
    name: Metrics.ELIGIBLE_FOR_PARENTAL_LEAVE_COUNT,
    measure: PbsMeasures.ELIGIBLE_FOR_PARENTAL_LEAVE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PARENTAL_LEAVES_TAKEN_UTILIZATION,
    name: Metrics.PARENTAL_LEAVES_TAKEN_COUNT,
    measure: PbsMeasures.PARENTAL_LEAVES_TAKEN_COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PARENTAL_LEAVES_TAKEN,
    name: Metrics.PARENTAL_LEAVES_TAKEN_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_OF_PARENTAL_LEAVES_TAKEN,
    name: Metrics.DAYS_OF_PARENTAL_LEAVES_TAKEN_AVG,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DAYS_OF_PARENTAL_LEAVES_TAKEN,
    name: Metrics.DAYS_OF_PARENTAL_LEAVES_TAKEN_SUM,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.MANAGEMENT_PROMOTION,
    name: Metrics.MANAGEMENT_PROMOTION_PERC_OF_POTENTIAL_MANAGEMENT_POOL,
    measure: PbsMeasures.PERCENTAGEOFPOTENTIALMANAGEMENTPOOL,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR,
    name: Metrics.TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_COUNT,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR,
    name: Metrics.TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_AVG_TENURE_SINCE_PTM_CASE,
    measure: PbsMeasures.AVGTENURESINCEPTMCASE,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR,
    name: Metrics.TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_PERC_OF_ALL_LEAVERS,
    measure: PbsMeasures.PERCENTAGEOFALLLEAVERS,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.RECURRING_PTM_CASE_WITHIN_1YEAR,
    name: Metrics.TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.RECURRING_PTM_CASE_WITHIN_1YEAR,
    name: Metrics.TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_PERC_OF_OPEN_PTM_CASES,
    measure: PbsMeasures.PERCENTAGEOFOPENPTMCASES,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_ALL,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_ALL_CUMULATIVE_COUNT,
    measure: PbsMeasures.CUMULATIVE_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_ALL,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_ALL_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_ALL,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_ALL_NEW_COUNT,
    measure: PbsMeasures.NEW_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_OPEN,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_OPEN_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_OPEN,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_OPEN_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_OPEN,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_OPEN_NEW_COUNT,
    measure: PbsMeasures.NEW_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_TOTAL,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_NEW_COUNT,
    measure: PbsMeasures.NEW_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_CLOSED,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_CLOSED_CUMULATIVE_COUNT,
    measure: PbsMeasures.CUMULATIVE_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_CLOSED,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_CLOSED_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.EMPLOYEE_WITH_PTM_CASES_CLOSED,
    name: Metrics.EMPLOYEE_WITH_PTM_CASES_CLOSED_NEW_COUNT,
    measure: PbsMeasures.NEW_COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.PTM_CASES_UNIQUE,
    name: Metrics.PTM_CASES_UNIQUE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.PTM_CASES_UNIQUE,
    name: Metrics.PTM_CASES_UNIQUE_PERC_OF_OPEN_CASES,
    measure: PbsMeasures.PERCENTAGEOFOPENPTMCASES,
    category: PbsCategories.SURVEY,
  },
  {
    pbsItem: PbsItems.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE,
    name: Metrics.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE,
    name: Metrics.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_PERC_OF_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.DISABILITY,
    name: Metrics.DISABILITY_COUNT,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.DISABILITY,
    name: Metrics.DISABILITY_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.DISABILITY,
    name: Metrics.DISABILITY_WEIGHTED_COUNT,
    measure: PbsMeasures.WEIGHTED_COUNT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.DISABILITY,
    name: Metrics.DISABILITY_WEIGHTED_PERCENTAGE_OF_TOTAL_HC,
    measure: PbsMeasures.WEIGHTED_PERCENTAGEOFPOP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },

  {
    pbsItem: PbsItems.KHNEOCHEM_CUSTOM_FEMALE,
    name: Metrics.KHNEOCHEM_CUSTOM_FEMALE_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.KHNEOCHEM_CUSTOM_FEMALE,
    name: Metrics.KHNEOCHEM_CUSTOM_FEMALE_PERCENTAGE_OF_FEMALE,
    measure: PbsMeasures.PERCENTAGE_OF_FEMALE,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS,
    name: Metrics.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_COUNT,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS,
    name: Metrics.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_PERCENTAGE_OF_FEMALE,
    measure: PbsMeasures.PERCENTAGE_OF_FEMALE,
    category: PbsCategories.HR_BASIC_METRICS,
  },

  {
    pbsItem: PbsItems.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS,
    name: Metrics.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_PERCENTAGE_OF_FEMALE,
    measure: PbsMeasures.PERCENTAGE_OF_FEMALE,
    category: PbsCategories.HR_BASIC_METRICS,
  },

  {
    pbsItem: PbsItems.PM0062_OT36720RuleEmployees,
    name: Metrics.PM0062_1_OT36720RuleEmployees_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0062_OT36720RuleEmployees,
    name: Metrics.PM0062_2_OT36720RuleEmployees_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0063_OT361Month100RuleEmp,
    name: Metrics.PM0063_1_OT361Month100RuleEmp_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0063_OT361Month100RuleEmp,
    name: Metrics.PM0063_2_OT361Month100RuleEmp_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0074_OT3645HrsRuleEmp,
    name: Metrics.PM0074_1_OT3645HrsRuleEmp_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0074_OT3645HrsRuleEmp,
    name: Metrics.PM0074_2_OT3645HrsRuleEmp_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0075_OT36EmpTrackingGroup,
    name: Metrics.PM0075_1_OT36EmpTrackingGroup_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0075_OT36EmpTrackingGroup,
    name: Metrics.PM0075_2_OT36EmpTrackingGroup_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0197_CustomPaidLeave1Ah,
    name: Metrics.PM0197_1CustomPaidLeave1AhCumSumPercUtilization,
    measure: PbsMeasures.CUM_SUM_PERCENTAGE_UTILIZATION,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0197_CustomPaidLeave1Ah,
    name: Metrics.PM0197_2CustomPaidLeave1AhCumSumPerHc,
    measure: PbsMeasures.CUM_SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0197_CustomPaidLeave1Ah,
    name: Metrics.PM0197_3CustomPaidLeave1AhSumPerHc,
    measure: PbsMeasures.SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0212_AbsentDays,
    name: Metrics.PM0212_1AbsentDaysPerHc,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0212_AbsentDays,
    name: Metrics.PM0212_2AbsentDaysPercOfWorkingDays,
    measure: PbsMeasures.PERCENTAGEOFWORKINGDAYS,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0213_AvgWorkHoursPerPerson,
    name: Metrics.PM0213_1AvgWorkHoursPerPersonAvg,
    measure: PbsMeasures.AVERAGE,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0213_AvgWorkHoursPerPerson,
    name: Metrics.PM0213_2AvgWorkHoursPerPersonAvgWithWh,
    measure: PbsMeasures.AVERAGE_POP_WITH_WH,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0215_MonthlyOT,
    name: Metrics.PM0215_1_MonthlyOT_SumPerHC,
    measure: PbsMeasures.AVERAGE_ALL_POP,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0215_MonthlyOT,
    name: Metrics.PM0215_2_MonthlyOT_SumPerHCWithOT,
    measure: PbsMeasures.AVERAGE_POP_WITH_OT,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0217_StatutoryAnnualPaidLeave,
    name: Metrics.PM0217_1StatutoryAnnualPaidLeaveCumSumPercUtilization,
    measure: PbsMeasures.CUM_SUM_PERCENTAGE_UTILIZATION,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0217_StatutoryAnnualPaidLeave,
    name: Metrics.PM0217_2StatutoryAnnualPaidLeaveCumSumPerHc,
    measure: PbsMeasures.CUM_SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0217_StatutoryAnnualPaidLeave,
    name: Metrics.PM0217_3StatutoryAnnualPaidLeaveSumPerHc,
    measure: PbsMeasures.SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0230_OT361Month75RuleEmp,
    name: Metrics.PM0230_1_OT361Month75RuleEmp_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0230_OT361Month75RuleEmp,
    name: Metrics.PM0230_2_OT361Month75RuleEmp_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0288_OT362to6MonthAvg80RuleEmp,
    name: Metrics.PM0288_1_OT362to6MonthAvg80RuleEmp_Count,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0288_OT362to6MonthAvg80RuleEmp,
    name: Metrics.PM0288_2_OT362to6MonthAvg80RuleEmp_PercHC,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.JAPAN_OVERTIME,
  },
  {
    pbsItem: PbsItems.PM0295_TotalCashCompFinanceYearly,
    name: Metrics.Pm0295_1TotalCashCompFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0295_TotalCashCompFinanceYearly,
    name: Metrics.Pm0295_2TotalCashCompFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0296_TotalBonusFinanceYearly,
    name: Metrics.Pm0296_1TotalBonusFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0296_TotalBonusFinanceYearly,
    name: Metrics.Pm0296_2TotalBonusFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0297_RecruitmentCostYearly,
    name: Metrics.Pm0297_1RecruitmentCostYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0297_RecruitmentCostYearly,
    name: Metrics.Pm0297_2RecruitmentCostYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0298_RetirementAllowanceFinanceYearly,
    name: Metrics.Pm0298_1RetirementAllowanceFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0298_RetirementAllowanceFinanceYearly,
    name: Metrics.Pm0298_2RetirementAllowanceFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0299_EmployerNonStatutoryPaymentFinanceYearly,
    name: Metrics.Pm0299_1EmployerNonStatutoryPaymentFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0299_EmployerNonStatutoryPaymentFinanceYearly,
    name: Metrics.Pm0299_2EmployerNonStatutoryPaymentFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0300_EmployerStatutoryPaymentFinanceYearly,
    name: Metrics.Pm0300_1EmployerStatutoryPaymentFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0300_EmployerStatutoryPaymentFinanceYearly,
    name: Metrics.Pm0300_2EmployerStatutoryPaymentFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0301_BoardCompensationFinanceYearly,
    name: Metrics.Pm0301_1BoardCompensationFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0301_BoardCompensationFinanceYearly,
    name: Metrics.Pm0301_2BoardCompensationFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0314_TravelCommutingFinanceYearly,
    name: Metrics.Pm0314_1TravelCommutingFinanceYearlyPerIndv,
    measure: PbsMeasures.PERINDIVIDUAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.PM0314_TravelCommutingFinanceYearly,
    name: Metrics.Pm0314_2TravelCommutingFinanceYearlyTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.FINANCIAL_METRICS,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE,
    name: Metrics.Pm0252_2ApplicationsCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_PASSED,
    name: Metrics.Pm0253_1PassthroughAtAppliedPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_PASSED,
    name: Metrics.Pm0253_2PassedApplicationsAtAppliedCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_REJECTED,
    name: Metrics.Pm0254_1RejectedApplicationsAtAppliedPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_REJECTED,
    name: Metrics.Pm0254_2RejectedApplicationsAtAppliedCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_WITHDRAWN,
    name: Metrics.Pm0255_1WithdrawnApplicationsAtAppliedPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_APPLIED_STAGE_WITHDRAWN,
    name: Metrics.Pm0255_2WithdrawnApplicationsAtAppliedCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  },

  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE,
    name: Metrics.Pm0256_1ApplicationsAtScreeningCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_PASSED,
    name: Metrics.Pm0257_1PassthroughAtScreeningPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_PASSED,
    name: Metrics.Pm0257_2PassedApplicationsAtScreeningCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_REJECTED,
    name: Metrics.Pm0258_1RejectedApplicationsAtScreeningPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_REJECTED,
    name: Metrics.Pm0258_2RejectedApplicationsAtScreeningCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_WITHDRAWN,
    name: Metrics.Pm0259_1WithdrawnApplicationsAtScreeningPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_SCREENING_STAGE_WITHDRAWN,
    name: Metrics.Pm0259_2WithdrawnApplicationsAtScreeningCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  },

  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE,
    name: Metrics.Pm0260_1ApplicationsAt_1stInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0261_1PassthroughAt_1stInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0261_2PassedApplicationsAt_1stInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0262_1RejectedApplicationsAt_1stInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0262_2RejectedApplicationsAt_1stInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0263_1WithdrawnApplicationsAt_1stInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0263_2WithdrawnApplicationsAt_1stInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },

  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE,
    name: Metrics.Pm0264_1ApplicationsAt_2ndInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0265_1PassthroughAt_2ndInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0265_2PassedApplicationsAt_2ndInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0266_1RejectedApplicationsAt_2ndInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0266_2RejectedApplicationsAt_2ndInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0267_1WithdrawnApplicationsAt_2ndInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0267_2WithdrawnApplicationsAt_2ndInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE,
    name: Metrics.Pm0268_1ApplicationsAtFinalInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0269_1PassthroughAtFinalInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_PASSED,
    name: Metrics.Pm0269_2PassedApplicationsAtFinalInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0270_1RejectedApplicationsAtFinalInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_REJECTED,
    name: Metrics.Pm0270_2RejectedApplicationsAtFinalInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0271_1WithdrawnApplicationsAtFinalInterviewPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_WITHDRAWN,
    name: Metrics.Pm0271_2WithdrawnApplicationsAtFinalInterviewCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE,
    name: Metrics.Pm0272_1ApplicationsAtOfferCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_PASSED,
    name: Metrics.Pm0273_1PassthroughAtOfferPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_PASSED,
    name: Metrics.Pm0273_2PassedApplicationsAtOfferCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_REJECTED,
    name: Metrics.Pm0274_1RejectedApplicationsAtOfferPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_REJECTED,
    name: Metrics.Pm0274_2RejectedApplicationsAtOfferCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_WITHDRAWN,
    name: Metrics.Pm0275_1WithdrawnApplicationsAtOfferPerc,
    measure: PbsMeasures.PERCENTAGE,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_OFFERED_STAGE_WITHDRAWN,
    name: Metrics.Pm0275_2WithdrawnApplicationsAtOfferCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  },
  {
    pbsItem: PbsItems.APPLICATION_AT_JOINED_STAGE,
    name: Metrics.Pm0276_1ApplicationsAtHiredCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.RECRUITMENT_FUNNEL_JOINED,
  },
  {
    pbsItem: PbsItems.PM0011_NewMngCalc,
    name: Metrics.PM0011_1NewMngCalcCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.PM0011_NewMngCalc,
    name: Metrics.PM0011_2NewMngCalcPercMng,
    measure: PbsMeasures.PERCENTAGE_OF_MANAGERS,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.PM0011_NewMngCalc,
    name: Metrics.PM0011_3NewMngCalcPercHc,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },

  {
    pbsItem: PbsItems.PM0015_NewMngDef,
    name: Metrics.PM0015_1NewMngDefCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.PM0015_NewMngDef,
    name: Metrics.PM0015_2NewMngDefPercMng,
    measure: PbsMeasures.PERCENTAGE_OF_MANAGERS,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.PM0015_NewMngDef,
    name: Metrics.PM0015_3NewMngDefPercHc,
    measure: PbsMeasures.PERCENTAGEOFTOTALHC,
    category: PbsCategories.GROWTH,
  },
  {
    pbsItem: PbsItems.PM0214_AvgEmp45HrsOTperMth,
    name: Metrics.PM0214_1AvgEmp45HrsOTperMthPercHc,
    measure: PbsMeasures.AVGPERCENTAGEOFHC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0214_AvgEmp45HrsOTperMth,
    name: Metrics.PM0214_2AvgEmp45HrsOTperMthHc,
    measure: PbsMeasures.AVGHC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0220_HoursWorkedHoliday,
    name: Metrics.PM0220_1HoursWorkedHolidayTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0221_HoursWorkedLegalHoliday,
    name: Metrics.PM0221_1HoursWorkedLegalHolidayTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0222_OtHours,
    name: Metrics.PM0222_1OtHoursTotal,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0224_TotalHolidayOt,
    name: Metrics.PM0224_1_TotalHolidayOT_Total,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0225_TotalOt,
    name: Metrics.PM0225_1_TotalOT_Total,
    measure: PbsMeasures.TOTAL,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0315_CustomStatutoryAnnualPaidLeaveNec,
    name: Metrics.PM0315_1CustomStatutoryAnnualPaidLeaveNecCumSumPercUtilization,
    measure: PbsMeasures.CUM_SUM_PERCENTAGE_UTILIZATION,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0315_CustomStatutoryAnnualPaidLeaveNec,
    name: Metrics.PM0315_2CustomStatutoryAnnualPaidLeaveNecCumSumPerHc,
    measure: PbsMeasures.CUM_SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0315_CustomStatutoryAnnualPaidLeaveNec,
    name: Metrics.PM0315_3CustomStatutoryAnnualPaidLeaveNecSumPerHc,
    measure: PbsMeasures.SUM_PER_HC,
    category: PbsCategories.ORG_HEALTH,
  },
  {
    pbsItem: PbsItems.PM0316_CustomIndividuals1ProperNec,
    name: Metrics.PM0316_1CustomIndividuals1ProperNecCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.PM0317_CustomIndividuals2FinancialReportingNec,
    name: Metrics.PM0317_1CustomIndividuals2FinancialReportingNecCount,
    measure: PbsMeasures.COUNT,
    category: PbsCategories.HR_BASIC_METRICS,
  },
  {
    pbsItem: PbsItems.PM0318_CustomAttritionNikki,
    name: Metrics.PM0318_1CustomAttritionNikkiAnnualized,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0318_CustomAttritionNikki,
    name: Metrics.PM0318_2CustomAttritionNikkiMonthly,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0318_CustomAttritionNikki,
    name: Metrics.PM0318_3CustomAttritionNikkiTtm,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0318_CustomAttritionNikki,
    name: Metrics.PM0318_4CustomAttritionNikkiLeavers,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0319_CustomAttritionAuFh,
    name: Metrics.PM0319_1CustomAttritionAuFhAnnualized,
    measure: PbsMeasures.ANNUALISED,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0319_CustomAttritionAuFh,
    name: Metrics.PM0319_2CustomAttritionAuFhMonthly,
    measure: PbsMeasures.MONTHLYPERCENTAGE,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0319_CustomAttritionAuFh,
    name: Metrics.PM0319_3CustomAttritionAuFhTtm,
    measure: PbsMeasures.TTM,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0319_CustomAttritionAuFh,
    name: Metrics.PM0319_4CustomAttritionAuFhLeavers,
    measure: PbsMeasures.LEAVERS,
    category: PbsCategories.ORG_AGILITY,
  },
  {
    pbsItem: PbsItems.PM0320_GenderPayGapMToFTotalCompAvgJpGov,
    name: Metrics.PM0320_1GenderPayGapFToMTotalCompAvgJpGovPerc,
    measure: PbsMeasures.F_TO_M_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.PM0320_GenderPayGapMToFTotalCompAvgJpGov,
    name: Metrics.PM0320_2GenderPayGapMToFTotalCompAvgJpGovPerc,
    measure: PbsMeasures.M_TO_F_PERCENT,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.PM0320_GenderPayGapMToFTotalCompAvgJpGov,
    name: Metrics.PM0320_3GenderPayGapFToMTotalCompAvgJpGovDiff,
    measure: PbsMeasures.F_TO_M_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
  {
    pbsItem: PbsItems.PM0320_GenderPayGapMToFTotalCompAvgJpGov,
    name: Metrics.PM0320_4GenderPayGapMToFTotalCompAvgJpGovDiff,
    measure: PbsMeasures.M_TO_F_CASH_GAP,
    category: PbsCategories.DIV_AND_FAIRNESS,
  },
];
