import { action, computed, observable, toJS } from 'mobx';
import { DataFieldWithDataType } from '../../../../common-types';
import i18n from '../../../../i18n';
import {
  ApiMasterDataAdvancedQuery,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQuerySortOrder,
} from '../../../common/api/api-interfaces';
import { Layer } from '../../../common/components/toolbars/layer-selector/LayerItem';
import { GenderValues } from '../../../common/constants/constant-backend-values';
import {
  ComputedEmployeeFields,
  DataFields,
  DataTypes,
  Domains,
  EmployeeDataFields,
  OnaChatOrEmailDataFields,
  Operations,
} from '../../../common/constants/constants';
import { getTranslation } from '../../../common/constants/systemValuesTranslation';
import { Granularity } from '../../../common/date-manager/date-manager-constants';
import { dateManagerService } from '../../../common/date-manager/date-manager-service';
import { getDatesInRange } from '../../../common/filter/utils';
import { GetResponseForAdvancedQueryService } from '../../../common/services/GetResponseForQueryService';
import { rootStore } from '../../../common/store/root-store';
import { toTitleCase } from '../../../common/utilFunctions/formatters';
import { getValue, getValueForMeasure, uuid } from '../../../common/utilFunctions/utils';
import { GraphLink } from './services/OnaMetricsDataDumpService';

type OnaMetricMapType = Partial<Record<DataTypes, boolean>>;

export const darkThemeOnaColors = ['rgb(18, 28, 37)', 'rgb(26,42,56)'];

export const lightThemeOnaColors = ['floralwhite', 'ghostwhite'];

export const layerColors = [
  '#ECF735',
  '#FB4E4E',
  '#48FFB7',
  '#FF48E6',
  '#6DBD5A',
  '#DDCBE5',
  '#3B753B',
  '#F4C400',
  '#00F3FF',
  '#007075',
];

export const minutesPerEmail = 2.8;
export const minutesPerMeeting = 58.6;
export const getInternalEmailDomains = () => {
  const { domain } = rootStore.companyStore;
  return domain === 'wilgroup' ? new Set(['willof.co.jp', 'willof.co.jp']) : new Set([domain]);
};
export const meetingsToEmailsRatio = 0.4;
export const avgBaseSalary = 3410;

export enum OnaMetric {
  SOURCE = 'source',
  TARGET = 'target',
}

export enum FilterMetrics {
  EmailRelationshipScore = 'collaborationScore',
  CountEmailOrMessageSource = 'countEmailsOrMessageSource',
  AverageResponseTime = 'avgResponseTime',
}

export enum PeriodAverageSelector {
  PeriodAvgCollabScore = 'periodAvgCollabScore',
  PeriodAvgNumberOfRelationships = 'periodAvgNumRelationships',
}
const periodAverageSelectorToLabelMap: Record<PeriodAverageSelector, string> = {
  [PeriodAverageSelector.PeriodAvgCollabScore]: i18n.t(
    'pages:dashboards.ona.onaMetricsOverTime.factbox.periodAvgCollabScore'
  ),
  [PeriodAverageSelector.PeriodAvgNumberOfRelationships]: i18n.t(
    'pages:dashboards.ona.onaMetricsOverTime.factbox.periodAvgNumRelationships'
  ),
};

export const initialRangeFilterValues: Record<string, [number, number]> = {
  [FilterMetrics.EmailRelationshipScore]: [0, 10],
  [FilterMetrics.CountEmailOrMessageSource]: [0, 300],
  [FilterMetrics.AverageResponseTime]: [0, 300],
};

export enum OnaMetricsTableKeys {
  PropertyName = 'propertyName',
  AvgCollabScoreCurrentPeriod = 'avgCollabScoreCurrPeriod',
  AvgCollabScorePrevPeriod = 'avgCollabScorePrevPeriod',
  Delta = 'delta',
  AvgNumRelationshipsCurrentPeriod = 'avgNumberOfRelationshipsCurrPeriod',
  AvgNumRelationshipsPrevPeriod = 'avgNumberOfRelationshipsPrevPeriod',
}

export const domainsWithBothEmailAndChatOnaMetric: string[] = [
  Domains.PANASONIC,
  Domains.PANASONIC_RCC,
  Domains.CARSALES,
];
const SENDER_RECEIVER_SEPARATOR = '---';
export const getKeyFromSenderAndReceiver = (sender: string, receiver: string) =>
  sender + SENDER_RECEIVER_SEPARATOR + receiver;

export enum StrengthThresholdKeys {
  WEAK = 'weak',
  MODERATE = 'moderate',
  STRONG = 'strong',
}
export interface StrengthThreshold {
  [StrengthThresholdKeys.WEAK]: number;
  [StrengthThresholdKeys.MODERATE]: number;
  [StrengthThresholdKeys.STRONG]: number;
}

export enum EdgeStrength {
  Strong = 'Strong',
  Moderate = 'Moderate',
  Weak = 'Weak',
}

export type OnaScoreField = 'collaborationScore' | 'countEmailsTotal';

const newLayerText = i18n.t('pages:dashboards.ona.sidebar.newLayer');

export enum EdgeType {
  RELATIONSHIP = 'RELATIONSHIP',
  CONNECTION = 'CONNECTION',
}

class OnaStore {
  @observable
  public emailsCountStrengthThreshold: StrengthThreshold = {
    weak: 0,
    moderate: 5,
    strong: 10,
  };

  @observable
  public scoreField: OnaScoreField = 'collaborationScore';

  @observable
  public darkTheme = true;

  @observable
  private legendColorMap: Record<string, string> = {};

  @observable
  public sourceFilters: ApiMasterDataQueryFilterItem[] = [];

  @observable
  public targetFilters: ApiMasterDataQueryFilterItem[] = [];

  @action
  public setTargetFilters = (filters: ApiMasterDataQueryFilterItem[]) => (this.targetFilters = [...filters]);

  @action
  public clearSourceFilters = () => {
    this.sourceFilters = [];
  };

  @action
  public clearTargetFilters = () => {
    this.targetFilters = [];
  };

  @computed
  public get colorMap(): Record<string, string> {
    return this.legendColorMap;
  }
  @computed
  public get isOnaLayersApplied(): boolean {
    return !!this.onaLayers.length;
  }
  @action
  public setColorMap = (colorMap: Record<string, string>) => {
    this.legendColorMap = colorMap;
  };
  @action
  public resetColorMap = () => {
    this.legendColorMap = {};
  };

  @observable
  public employeeDataForOna: Record<string, any> | null = null;

  @observable
  public clusterByField: DataFieldWithDataType = {
    dataType: DataTypes.EMPLOYEE,
    dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1,
  };

  @observable
  public groupByField: DataFieldWithDataType = {
    dataType: DataTypes.EMPLOYEE,
    dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1,
  };

  @observable
  public selectedTooltipFields: DataFieldWithDataType[] = [
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.COMPANY_EMAIL },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_2 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FULL_NAME },
  ];

  @observable
  public showTooltipFieldsSelector = false;

  @observable
  public edgeType: EdgeType = EdgeType.RELATIONSHIP;

  public getRelationshipScoreThresholdFromEdgeType = (edgeType: EdgeType) => {
    return edgeType === EdgeType.RELATIONSHIP ? this.emailsRelationshipStrengthThresholds?.weak ?? 0 : 0;
  };

  public getScaledCollabRelationshipScore = (score: number) => {
    const onaRelationshipScoreScalingFactor = rootStore.companySettingsStore.onaRelationshipScoreScalingFactor();
    return onaRelationshipScoreScalingFactor * score;
  };

  @observable
  public domainsToOnaMetricTypeMap: Partial<Record<Domains, OnaMetricMapType>> = {
    [Domains.PIXIV]: {
      [DataTypes.ONACHATMETRICS]: true,
      [DataTypes.ONAEMAILMETRICS]: false,
    },
    [Domains.PANASONIC]: {
      [DataTypes.ONACHATMETRICS]: false,
      [DataTypes.ONAEMAILMETRICS]: true,
    },
    [Domains.PANASONIC_RCC]: {
      [DataTypes.ONACHATMETRICS]: true,
      [DataTypes.ONAEMAILMETRICS]: false,
    },
    [Domains.LOOOP]: {
      [DataTypes.ONACHATMETRICS]: true,
      [DataTypes.ONAEMAILMETRICS]: false,
    },
    [Domains.CISNE]: {
      [DataTypes.ONACHATMETRICS]: false,
      [DataTypes.ONAEMAILMETRICS]: true,
    },
    [Domains.SWAN]: {
      [DataTypes.ONACHATMETRICS]: false,
      [DataTypes.ONAEMAILMETRICS]: true,
    },
    [Domains.SHIRATORI]: {
      [DataTypes.ONACHATMETRICS]: false,
      [DataTypes.ONAEMAILMETRICS]: true,
    },
    [Domains.CAPLAN]: {
      [DataTypes.ONACHATMETRICS]: true,
      [DataTypes.ONAEMAILMETRICS]: false,
    },
    [Domains.CARSALES]: {
      [DataTypes.ONACHATMETRICS]: false,
      [DataTypes.ONAEMAILMETRICS]: true,
    },
  };
  @computed
  public get relationshipScoreThreshold(): number {
    return this.getRelationshipScoreThresholdFromEdgeType(this.edgeType);
  }
  @computed
  public get shouldShowMetricToggleButton(): boolean {
    const currentDomain: Domains = rootStore.companyStore.domain as Domains;
    return domainsWithBothEmailAndChatOnaMetric.includes(currentDomain);
  }
  @computed
  public get shouldUseOnaChatMetrics(): boolean {
    const currentDomain: Domains = rootStore.companyStore.domain as Domains;
    return !!this.domainsToOnaMetricTypeMap?.[currentDomain]?.[DataTypes.ONACHATMETRICS];
  }
  @computed
  public get shouldUseOnaEmailMetrics(): boolean {
    const currentDomain: Domains = rootStore.companyStore.domain as Domains;
    return !!this.domainsToOnaMetricTypeMap?.[currentDomain]?.[DataTypes.ONAEMAILMETRICS];
  }

  @computed
  public get onaMetricsDataType(): DataTypes {
    return this.shouldUseOnaChatMetrics ? DataTypes.ONACHATMETRICS : DataTypes.ONAEMAILMETRICS;
  }

  public ONA_METRICS_INTERNAL_FILTER_BY_DOMAIN(dataType: DataTypes): ApiMasterDataQueryFilterItem[] {
    return [
      {
        dataType,
        operation: Operations.EQUAL,
        property: 'DOMAIN_PERSON_1',
        values: rootStore.companySettingsStore.internalDomains(),
      },
      {
        dataType,
        operation: Operations.EQUAL,
        property: 'DOMAIN_PERSON_2',
        values: rootStore.companySettingsStore.internalDomains(),
      },
    ];
  }
  @computed
  public get ONA_METRICS_INTERNAL_FILTER(): ApiMasterDataQueryFilterItem {
    return {
      dataType: this.onaMetricsDataType,
      operation: Operations.EQUAL,
      property: 'INTERNAL_OR_EXTERNAL',
      values: ['Internal'],
    };
  }

  public internalOrExternalFilter(dataType: DataTypes, onlyInternal: boolean): ApiMasterDataQueryFilterItem[] {
    return onlyInternal ? this.ONA_METRICS_INTERNAL_FILTER_BY_DOMAIN(dataType) : [];
  }

  public internalOnlyFilter(dataType: DataTypes): ApiMasterDataQueryFilterItem[] {
    return this.ONA_METRICS_INTERNAL_FILTER_BY_DOMAIN(dataType);
  }
  private getFieldValueFromNode = (node: any, field: DataFieldWithDataType) => {
    return node.empData?.[field.dataField] || node[field.dataField] || '';
  };

  private defaultTooltipRowObjectGetter = (field: DataFieldWithDataType) => {
    return {
      fieldName: field.dataField,
      dataType: field.dataType,
      label: getTranslation(field) || field.dataField,
      getValue: (node: any) => this.getFieldValueFromNode(node, field),
    };
  };

  public fieldTotooltipRowObjMap: Partial<
    Record<
      keyof typeof EmployeeDataFields,
      { fieldName: DataFields; dataType: DataTypes; label: string; getValue: (node: GraphLink) => string }
    >
  > = {
    [EmployeeDataFields.COMPANY_EMAIL as DataFields]: {
      ...this.defaultTooltipRowObjectGetter({
        dataType: DataTypes.EMPLOYEE,
        dataField: EmployeeDataFields.COMPANY_EMAIL,
      }),
      getValue: (node: any) => node.id,
    },
    [EmployeeDataFields.FULL_NAME as DataFields]: {
      ...this.defaultTooltipRowObjectGetter({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME }),
      getValue: (node: any) =>
        toTitleCase(
          this.getFieldValueFromNode(node, { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME })
        ),
    },
    [EmployeeDataFields.AGE as DataFields]: {
      ...this.defaultTooltipRowObjectGetter({ dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE }),
      getValue: (node: any) => {
        const age = this.getFieldValueFromNode(node, {
          dataType: DataTypes.EMPLOYEE,
          dataField: EmployeeDataFields.AGE,
        });
        return age ? `${age} years` : '';
      },
    },
  };

  public getTooltipRowObjFromField = (field: DataFieldWithDataType) => {
    if (this.fieldTotooltipRowObjMap[field.dataField as EmployeeDataFields]) {
      return this.fieldTotooltipRowObjMap[field.dataField as EmployeeDataFields];
    } else {
      return this.defaultTooltipRowObjectGetter(field);
    }
  };

  public mainEmployeeFields: DataFieldWithDataType[] = [
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.COMPANY_EMAIL },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME },
  ];

  @observable
  public groupEmployeeFields: Set<DataFieldWithDataType> = new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCAL_FULL_NAME },
  ]);

  public getEmployeeDataForEmail = (empData: Record<string, any> | null, email: string) => {
    return empData?.[email.toLowerCase()];
  };

  @computed
  public get onaColors() {
    return this.darkTheme ? darkThemeOnaColors : lightThemeOnaColors;
  }

  @action
  public toggleTheme = () => (this.darkTheme = !this.darkTheme);

  @observable
  public onaLayers: Layer[] = [];

  @observable
  public tempOnaLayers: Layer[] = [];

  public dummyOnaLayers = [
    {
      layerName: 'SE Asia',
      filters: [
        {
          property: 'LOCATION',
          operation: 'EQUAL',
          values: [['Asia', 'Thailand'], ['Singapore']],
          dataType: 'EMPLOYEE',
        },
        {
          property: 'GENDER',
          operation: 'EQUAL',
          values: [GenderValues.Male],
          dataType: 'EMPLOYEE',
        },
      ],
      color: 'aliceblue',
    },
    {
      layerName: 'Fav People',
      filters: [
        {
          property: 'ORGANIZATION',
          operation: 'EQUAL',
          values: [['Finance', 'IT'], ['Tech']],
          dataType: 'EMPLOYEE',
        },
        {
          property: 'GENDER',
          operation: 'EQUAL',
          values: [GenderValues.Female],
          dataType: 'EMPLOYEE',
        },
      ],
      color: 'yellow',
    },
    {
      layerName: 'Another Layer',
      filters: [],
      color: 'green',
    },
    {
      layerName: 'Another Layer',
      filters: [],
      color: 'pink',
    },
  ];

  @observable
  public onaMetricsDataDump: any = null;

  @observable
  public layerErrorMessage: any = null;

  @observable
  public layerHistory: any = [[]];

  @observable
  public layerHistoryActiveIndex: number = 0;

  @observable
  private collabScoreThreshold: StrengthThreshold = {
    [StrengthThresholdKeys.WEAK]: 0,
    [StrengthThresholdKeys.MODERATE]: 0,
    [StrengthThresholdKeys.STRONG]: 0,
  };

  @observable
  collabScoreThresholdInitialized: boolean = false;

  private onaCollabScorePercentile: StrengthThreshold = {
    [StrengthThresholdKeys.WEAK]: 0.75,
    [StrengthThresholdKeys.MODERATE]: 0.85,
    [StrengthThresholdKeys.STRONG]: 0.95,
  };

  @observable
  public dropDownVals: DataFieldWithDataType[] = [
    // Note: This list is modified upon first load. Non null hierarchical employee fields are added to it.
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.GENDER },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.AGE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
  ];

  @observable
  public periodAverageSelectorDropdownVals: PeriodAverageSelector[] = [
    PeriodAverageSelector.PeriodAvgCollabScore,
    PeriodAverageSelector.PeriodAvgNumberOfRelationships,
  ];

  @observable
  public selectedPeriodAverageSelectorDropdownVal: PeriodAverageSelector = this.periodAverageSelectorDropdownVals[0];

  @observable
  public selectedDropdownVal: DataFieldWithDataType = this.dropDownVals[0];

  @action
  public setSelectedDropdownVal = (val: DataFieldWithDataType) => {
    this.selectedDropdownVal = val;
  };

  @action
  public setSelectedPeriodAverageSelectorDropdownVal = (val: PeriodAverageSelector) => {
    this.selectedPeriodAverageSelectorDropdownVal = val;
  };

  @action
  public getPeriodAverageSelectorLabel = (val: PeriodAverageSelector) => {
    return periodAverageSelectorToLabelMap[val];
  };

  public setOnaMetricsDataDump = (newOnaMetricsDataDump: any) => {
    this.onaMetricsDataDump = newOnaMetricsDataDump;
  };

  // hack to force the clearLabels method call in the OnaChart component, by changing a prop that will trigger an effect
  @observable
  public clearLabelsCounter: number = 0;

  @action
  public incClearLabelsCounter = () => this.clearLabelsCounter++;

  @observable
  public onlyInternal: boolean = true;

  @observable
  public onaSliderFilters: Record<string, [number, number]> = {
    [FilterMetrics.EmailRelationshipScore]: initialRangeFilterValues[FilterMetrics.EmailRelationshipScore],
    [FilterMetrics.CountEmailOrMessageSource]: initialRangeFilterValues[FilterMetrics.CountEmailOrMessageSource],
    [FilterMetrics.AverageResponseTime]: initialRangeFilterValues[FilterMetrics.AverageResponseTime],
  };
  @action
  public onaSliderFilterChange = (key: string, val: [number, number]) => {
    this.onaSliderFilters[key] = val;
  };
  @action
  public setScoreField = (newField: OnaScoreField) => {
    this.scoreField = newField;
  };
  @action
  public toggleOnlyInternal = () => {
    this.onlyInternal = !this.onlyInternal;
  };
  @action
  public toggleOnaMetricType = () => {
    const currentDomain: Domains = rootStore.companyStore.domain as Domains;
    this.domainsToOnaMetricTypeMap = {
      ...toJS(this.domainsToOnaMetricTypeMap),
      [currentDomain]: {
        [DataTypes.ONACHATMETRICS]: !this.shouldUseOnaChatMetrics,
        [DataTypes.ONAEMAILMETRICS]: !this.shouldUseOnaEmailMetrics,
      },
    };
    rootStore.timeSliderStore.resetTimeslider();
  };

  @action
  public deleteLayer = (index: number) => {
    this.layerErrorMessage = null;
    this.tempOnaLayers = this.tempOnaLayers.filter((_eachLayer, indexOfLayer) => {
      return index != indexOfLayer;
    });
    this.onaLayers = this.onaLayers.filter((_eachLayer, indexOfLayer) => {
      return index != indexOfLayer;
    });
    if (this.tempOnaLayers.length) {
      this.layerHistory.push(toJS(this.tempOnaLayers));
      this.layerHistoryActiveIndex++;
    }
    if (this.onaLayers.length === 0) {
      this.setClusterByField({
        dataType: DataTypes.EMPLOYEE,
        dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1,
      });
    }
  };
  private updateLayerNameInHistory = (newLayerName: string, layerId: string) => {
    this.layerHistory = toJS(this.layerHistory).map((layers: Layer[]) => {
      const editLayerIndex: number = layers.findIndex((eachLayer) => eachLayer.layerId == layerId);
      if (editLayerIndex != -1) {
        layers[editLayerIndex].layerName = newLayerName;
      }
      return layers;
    });
  };
  @action
  public editLayerName = (e: React.FocusEvent<HTMLSpanElement>, index: number) => {
    const newLayerName = e.target.innerHTML;
    const { layerId } = this.tempOnaLayers[index];
    this.tempOnaLayers[index].layerName = newLayerName.replace(/&nbsp;/g, '');
    this.onaLayers[index].layerName = newLayerName.replace(/&nbsp;/g, '');
    this.updateLayerNameInHistory(newLayerName, layerId);
  };

  @action
  public deleteAllLayers = () => {
    if (!this.onaLayers.length) return;
    this.tempOnaLayers = [];
    this.onaLayers = [];
    this.layerErrorMessage = null;
    this.layerHistory.push([]);
    this.layerHistoryActiveIndex++;
    this.setClusterByField({
      dataType: DataTypes.EMPLOYEE,
      dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1,
    });
  };

  private shouldAddNewLayer = () => {
    const onaLayers = this.tempOnaLayers;
    if (onaLayers.length >= 10) {
      alert(i18n.t('pages:dashboards.ona.sidebar.maxNumberOfLayersReached'));
      return false;
    }
    return onaLayers.length == 0 ? true : !!onaLayers?.last()?.filters.length;
  };

  @action
  public addNewLayer = () => {
    if (this.shouldAddNewLayer()) {
      this.layerErrorMessage = null;
      const newLayer = {
        layerName: newLayerText,
        filters: [],
        layerId: uuid(),
        color: layerColors[this.tempOnaLayers.length],
      };
      this.tempOnaLayers.push(newLayer);
      this.onaLayers.push(newLayer);
    } else {
      this.layerErrorMessage = i18n.t('pages:dashboards.ona.sidebar.layerErrorMessage');
    }
  };

  @action
  public editLayer = (_index: number, newLayer: Layer) => {
    this.tempOnaLayers.push(newLayer);
    this.onaLayers.push(newLayer);
  };
  @action
  public saveOnaLayers = () => {
    this.onaLayers = toJS(this.tempOnaLayers);
    this.layerErrorMessage = null;
    if (this.onaLayers.length) {
      this.clusterByField = { dataType: DataTypes.EMPLOYEE, dataField: ComputedEmployeeFields.none };
    }
    if (this.onaLayers?.last()?.filters.length) {
      this.layerHistory.push(toJS(this.onaLayers));
      this.layerHistoryActiveIndex++;
    }
  };

  @action
  public setClusterByField = (field: DataFieldWithDataType) => {
    if (!this.onaLayers.length) {
      this.clusterByField = field;
    }
  };
  @action
  public undoLayer = () => {
    if (this.layerHistoryActiveIndex > 0) {
      this.layerHistoryActiveIndex--;
      const layerDetails = toJS(this.layerHistory[this.layerHistoryActiveIndex]);
      this.onaLayers = layerDetails;
      this.tempOnaLayers = layerDetails;
    }
  };

  @action
  public redoLayer = () => {
    if (this.layerHistoryActiveIndex + 1 < this.layerHistory.length) {
      this.layerHistoryActiveIndex++;
      const layerDetails = toJS(this.layerHistory[this.layerHistoryActiveIndex]);
      this.onaLayers = layerDetails;
      this.tempOnaLayers = layerDetails;
    }
  };

  @action
  public setGroupByField = (field: DataFieldWithDataType) => {
    this.groupByField = field;
  };

  @action
  public setTooltipFields = (newFields: DataFieldWithDataType[]) => {
    this.selectedTooltipFields = newFields;
  };

  @action
  public toggleTooltipFieldsSelector = () => (this.showTooltipFieldsSelector = !this.showTooltipFieldsSelector);

  @action
  public setEdgeType = (edgeType: EdgeType) => {
    this.edgeType = edgeType;
  };

  @action
  public toggleEdgeType = () => {
    this.edgeType = this.edgeType === EdgeType.CONNECTION ? EdgeType.RELATIONSHIP : EdgeType.CONNECTION;
  };

  public filterInternalMetrics = (onaMetricsDataDump: any) => {
    const internalDomains = rootStore.companySettingsStore.internalDomains();

    const filteredOnaMetricsDataDump = onaMetricsDataDump.filter((d: any) => {
      return internalDomains.includes(d.sourceDomain) && internalDomains.includes(d.targetDomain);
    });
    return filteredOnaMetricsDataDump;
  };

  public getAllFilteredOnaMetricsDataDump = (
    onaMetricsDataDump: GraphLink[],
    edgeType = this.edgeType,
    onlyInternal = this.onlyInternal
  ): GraphLink[] => {
    if (!onaMetricsDataDump) {
      return onaMetricsDataDump;
    }
    let filteredOnaMetricsDataDump = onaMetricsDataDump.filter((d: GraphLink) =>
      this.isEmailsRelationshipScoreInRangeForEdgeType(d.collaborationScore, edgeType)
    );

    if (onlyInternal) {
      filteredOnaMetricsDataDump = this.filterInternalMetrics(filteredOnaMetricsDataDump);
    }

    // filteredOnaMetricsDataDump = this.filterdOnaMetricsDataDumpBySliderFilters(filteredOnaMetricsDataDump);
    // will uncomment it when we start using filterdOnaMetricsDataDumpBySliderFilters

    return filteredOnaMetricsDataDump;
  };

  public isEmailsRelationshipScoreInRangeForEdgeType = (collaborationScore: any, edgeType = this.edgeType) => {
    const relationshipScoreThreshold: number = this.getRelationshipScoreThresholdFromEdgeType(edgeType);
    return collaborationScore > relationshipScoreThreshold;
  };

  public filterdOnaMetricsDataDumpBySliderFilters = (onaMetricsDataDump: GraphLink[]) => {
    const filteredOnaMetricsDataDump = onaMetricsDataDump.filter((d: GraphLink) => {
      for (const key in this.onaSliderFilters) {
        const [minSliderValue, maxSliderValue] = this.onaSliderFilters[key];

        const dataPointValue = d[key as keyof GraphLink] as number;
        if (!(dataPointValue >= minSliderValue && dataPointValue <= maxSliderValue)) return false;
      }
      return true;
    });
    return filteredOnaMetricsDataDump;
  };

  @computed
  public get emailsRelationshipStrengthThresholds(): StrengthThreshold {
    return this.collabScoreThreshold;
  }
  public setCollabScoreThreshold = (collabScoreThreshold: StrengthThreshold) => {
    this.collabScoreThreshold = collabScoreThreshold;
  };
  public thresholdCollaborationScoreFilter(dataType: DataTypes): ApiMasterDataQueryFilterItem {
    return {
      operation: Operations.GREATER_THAN,
      property: OnaChatOrEmailDataFields.COLLABORATION_SCORE,
      values: [rootStore.companySettingsStore.minimumCollabScoreThreshold()],
      dataType,
    };
  }

  public fetchAndSetCollabScoreThreshold = async (dataType: DataTypes) => {
    const { thresholdCollaborationScoreFilter } = onaStore;
    const { timeSliderConfig } = rootStore.timeSliderStore;
    const latestVersion = rootStore.domainDependencyStore.getLatestVersion(dataType);

    if (!latestVersion) {
      throw new Error('latestVersion undefined. fetchAndSetColalbScoreThreshold called before latestVersion is set.');
    }
    const { formatDateApi, parseApiDate } = dateManagerService;

    let selectedStartDateOfTimeSlider = formatDateApi(
      parseApiDate(latestVersion).subtract(11, Granularity.MONTH).startOf(Granularity.MONTH)
    );

    const person1Field = OnaChatOrEmailDataFields.PERSON1;
    const person2Field = OnaChatOrEmailDataFields.PERSON2;

    const relationshipScoreField = { dataType, dataField: OnaChatOrEmailDataFields.COLLABORATION_SCORE };
    const dates = getDatesInRange({
      ...timeSliderConfig,
      startDate: selectedStartDateOfTimeSlider,
      endDate: latestVersion,
    });

    const selectedRowsFilters = [
      {
        operation: Operations.EQUAL,
        property: OnaChatOrEmailDataFields.GRANULARITY,
        values: [Granularity.MONTH],
        dataType,
      },
      {
        operation: Operations.EQUAL,
        property: OnaChatOrEmailDataFields.VERSION_ID,
        values: dates,
        dataType,
      },
      thresholdCollaborationScoreFilter(dataType),
    ];

    const numRowsInDataQuery: ApiMasterDataAdvancedQuery = {
      dataType,
      measures: [
        {
          dataType,
          operation: Operations.COUNT,
          property: OnaChatOrEmailDataFields.PERSON1,
        },
      ],
      filterItems: selectedRowsFilters,
    };

    const numRowsResult = await GetResponseForAdvancedQueryService(numRowsInDataQuery);
    const numRows = getValueForMeasure(
      numRowsResult?.dataPoints?.[0]?.measures ?? [],
      { dataType, dataField: OnaChatOrEmailDataFields.PERSON1 },
      Operations.COUNT
    );

    const weakPercentile = Math.floor(this.onaCollabScorePercentile[StrengthThresholdKeys.WEAK] * numRows);
    const moderatePercentile = Math.floor(this.onaCollabScorePercentile[StrengthThresholdKeys.MODERATE] * numRows);
    const strongPercentile = Math.floor(this.onaCollabScorePercentile[StrengthThresholdKeys.STRONG] * numRows);

    const baseThresholdQuery: ApiMasterDataAdvancedQuery = {
      dataType,
      dimensions: [
        {
          dataType,
          property: person1Field,
        },
        {
          dataType,
          property: person2Field,
        },
        {
          dataType,
          property: OnaChatOrEmailDataFields.VERSION_ID,
        },
        {
          dataType: relationshipScoreField.dataType,
          property: relationshipScoreField.dataField,
        },
      ],
      filterItems: selectedRowsFilters,
      limit: 1,
      orderBy: [
        {
          dimension: {
            dataType: relationshipScoreField.dataType,
            property: relationshipScoreField.dataField,
          },
          sortOrder: ApiMasterDataQuerySortOrder.ASC,
        },
      ],
    };

    const getThresholdQuery = (rowNum: number): ApiMasterDataAdvancedQuery => {
      return {
        ...baseThresholdQuery,
        offset: rowNum,
      };
    };

    let [weakThreshold, moderateThreshold, strongThreshold] = await Promise.all(
      [weakPercentile, moderatePercentile, strongPercentile].map(async (p) => {
        const query = getThresholdQuery(p);
        const result = await GetResponseForAdvancedQueryService(query);
        const threshold = getValue(result?.dataPoints?.[0]?.dimensions, relationshipScoreField) ?? 0;
        return threshold;
      })
    );
    if (moderateThreshold === weakThreshold) {
      moderateThreshold += 0.1;
    }
    if (strongThreshold <= moderateThreshold) {
      strongThreshold = moderateThreshold + 0.1;
    }
    const thresholdPercentile = {
      [StrengthThresholdKeys.WEAK]: weakThreshold,
      [StrengthThresholdKeys.MODERATE]: moderateThreshold,
      [StrengthThresholdKeys.STRONG]: strongThreshold,
    };
    this.setCollabScoreThreshold(thresholdPercentile);
    this.collabScoreThresholdInitialized = true;
  };
}

export const onaStore = new OnaStore();
