import { DataFieldWithDataType } from '../../../../common-types';
import { ApiMasterDataQueryFilterItem } from '../../../common/api/api-interfaces';
import { Benchmark } from '../../../common/benchmark/types';
import { DataTypes } from '../../../common/constants/constants';
import { Granularity, Months } from '../../../common/date-manager/date-manager-constants';
import { TimeSliderConfig } from '../../../common/filter/filter-store';
import { MetricResultMetaData } from '../../../common/graphql/generated/graphql-sdk';
import { DataMapAndLabels, Service } from '../../../common/services/utils';
import { Monoid } from '../../../common/utilFunctions/utils';
import { pbsItemsToMeasuresMap } from './pbsItemsToMeasuresMap';

export type PbsItemsToServiceMap = {
  [key in PbsItems]: PbsService<key>;
};

type CalendarGranularity = Granularity.DAY | Granularity.WEEK | Granularity.MONTH | Granularity.YEAR;
type FinancialGranularity = Granularity.FINQUARTER | Granularity.FINYEAR;

export type CalendarGranularityConfig = {
  granularity: CalendarGranularity;
};

export type FinancialGranularityConfig = {
  granularity: FinancialGranularity;
  financialYearStartMonth: Months;
};

export type GranularityConfig = CalendarGranularityConfig | FinancialGranularityConfig;

export interface IMetrics {
  pbsItem: PbsItems;
  name: Metrics;
  measure: PbsMeasures;
  category: PbsCategories;
}

export type PbsItemToIMetricsMap = Record<PbsItems, (IMetrics & { isPermitted: boolean })[]>;

export enum PbsCategories {
  DIV_AND_FAIRNESS = 'divAndFairness',
  GROWTH = 'growth',
  ORG_AGILITY = 'orgAgility',
  ORG_HEALTH = 'orgHealth',
  PEOPLE_PROFIT = 'peopleProfit',
  FINANCIAL_METRICS = 'financialMetrics',
  JAPAN_OVERTIME = 'japanOvertime',
  RECRUITMENT_FUNNEL = 'recruitmentFunnel',
  RECRUITMENT_FUNNEL_APPLICATION = 'recruitmentFunnelApplication',
  RECRUITMENT_FUNNEL_SCREENING = 'recruitmentFunnelScreening',
  RECRUITMENT_FUNNEL_1ST_INTERVIEW = 'recruitmentFunnel1stInterview',
  RECRUITMENT_FUNNEL_2ND_INTERVIEW = 'recruitmentFunnel2ndInterview',
  RECRUITMENT_FUNNEL_3RD_INTERVIEW = 'recruitmentFunnel3rdInterview',
  RECRUITMENT_FUNNEL_OFFERED = 'recruitmentFunnelOffered',
  RECRUITMENT_FUNNEL_JOINED = 'recruitmentFunnelJoined',
  SURVEY = 'survey',
  HR_BASIC_METRICS = 'hrBasicMetrics',
  CUSTOM_1 = 'custom1',
  CUSTOM_2 = 'custom2',
  CUSTOM_3 = 'custom3',
  CUSTOM_4 = 'custom4',
  CUSTOM_5 = 'custom5',
}

export enum PbsItems {
  KHNEOCHEM_CUSTOM_FEMALE = 'khneochemCustomFemale',
  KHNEOCHEM_CUSTOM_FEMALE_MANAGERS = 'khneochemCustomFemaleManagers',
  TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION = 'totalBonusPercOfTotalCashCompensation',
  LABOR_COST = 'laborCost',
  TOTAL_LABOR_COST_TO_SALES = 'totalLaborCostToSales',
  PARENTAL_LEAVES_TAKEN = 'parentalLeavesTaken',
  PARENTAL_LEAVES_TAKEN_UTILIZATION = 'parentalLeavesTakenUtilization',
  DAYS_OF_PARENTAL_LEAVES_TAKEN = 'daysOfParentalLeavesTaken',
  MANAGEMENT_PROMOTION = 'managementPromotion',
  JT_OCCUPATIONAL_ACCIDENTS = 'jtOccupationalAccidents',
  JT_KILLED_AT_WORK = 'jtKilledAtWork',
  MARUDAI_COMPLAINTS = 'marudaiComplaints',
  TENURE_IN_JOB_GRADE_LEVEL_1 = 'tenureInJobGradeLevel1',
  TENURE_IN_JOB_GRADE_LEVEL_2 = 'tenureInJobGradeLevel2',
  TENURE_IN_ORG_LEVEL_1 = 'tenureInOrgLevel1',
  TENURE_IN_ORG_LEVEL_2 = 'tenureInOrgLevel2',
  HUMAN_CAPITAL_ROI = 'humanCapitalROI',
  MSAD_PARENTAL_LEAVES_UTILIZATION = 'msadParentalLeavesUtilization', //MS&AD specific metric
  MSAD_SURVEY_ENGAGEMENT_SCORE = 'msadSurveyEngagementScore', //MS&AD specific metric
  POTENTIAL_FEMALE_LEADER = 'potentialFemaleLeader', //MS&AD specific metric
  EMPLOYEE_WITH_EXTERNAL_EXPERIENCE = 'employeeWithExternalExperience', //MS&AD specific metric
  EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING = 'employeeCompletedBasicDigitalTraining', //MS&AD specific metric
  DIGITAL_EXPERTS = 'digitalExperts', //MS&AD specific metric
  HIGHLY_STRESSED_EMPLOYEE = 'highlyStressedEmployee', //MS&AD specific metric
  SURVEY_ENGAGEMENT_SCORE = 'surveyEngagementScore', //Tiket specific survey metric
  SURVEY_COMPANY_MOOD_SCORE = 'surveyCompanyMoodScore', //Tiket specific survey metric
  ENPS = 'enps', //Tiket specific survey metric
  EMPLOYEE_WITH_PTM_CASES_ALL = 'employeeWithPtmCasesAll',
  EMPLOYEE_WITH_PTM_CASES_OPEN = 'employeeWithPtmCasesOpen',
  EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS = 'employeeWithPtmCasesInProgress',
  EMPLOYEE_WITH_PTM_CASES_CLOSED = 'employeeWithPtmCasesClosed',
  LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR = 'leaversWithPtmCaseWithin1year', //Tiket specific survey metric
  RECURRING_PTM_CASE_WITHIN_1YEAR = 'recurringPtmCaseWithin1year', //Tiket specific survey metric
  DISABILITY = 'disability',
  DISCIPLINARY_ACTION = 'disciplinaryAction',
  TRAINING_TAKEN = 'trainingTaken',
  TRAINEE = 'trainee',
  FEM_HC = 'femHc',
  POP_PAY_GAP = 'popPayGap',
  GENDER_GAP = 'genderGap',
  // calc is according to Japanese govt reqs
  GENDER_PAY_GAP_TOTAL_COMP_AVG = 'genderPayGapTotalCompAvg',
  GENDER_PAY_GAP_TOTAL_COMP = 'genderPayGapTotalComp',
  NUM_EMP = 'numEmp',
  NUM_JOINERS = 'numJoiners',
  NUM_LEAVERS_TERM_DATE = 'numLeavers',
  AGE = 'age',
  SPAN_OF_CONTROL = 'spanOfControl',
  INTERNAL_MOB_RATE_LEVEL_1 = 'internalMobRate',
  INTERNAL_MOB_RATE_LEVEL_2 = 'internalMobRateLevel2',
  ATTRITION = 'attrition',
  REGRET_ATTRITION = 'regretAttrition',
  VOLUNTARY_ATTRITION = 'voluntaryAttrition',
  DEPTH_OF_CONTROL = 'depthOfControl',
  FTE = 'fte',
  FTEQUIVALENT = 'ftEquivalent',
  REVENUE = 'revenue',
  GROSS_PROFIT = 'grossProfit',
  OPERATING_PROFIT = 'operatingProfit',
  NET_INCOME = 'netIncome',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES = 'sellingGeneralAndAdminExpenses',
  TRAINING_COST = 'trainingCost',
  INDIRECT_INVESTMENT_RATIO = 'indirectInvestmentRatio',
  REVENUE_QUARTERLY = 'revenueQuarterly',
  GROSS_PROFIT_QUARTERLY = 'grossProfitQuarterly',
  OPERATING_PROFIT_QUARTERLY = 'operatingProfitQuarterly',
  NET_INCOME_QUARTERLY = 'netIncomeQuarterly',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY = 'sellingGeneralAndAdminExpensesQuarterly',
  TRAINING_COST_QUARTERLY = 'trainingCostQuarterly',
  FEMALE_MANAGERS_CALCULATED = 'femaleManagersCalculated',
  MANAGERS_CALCULATED = 'managersCalculated',
  MEDIAN_BASE = 'medianBase',
  NON_REGULAR_TYPE_EMP = 'nonRegularTypeEmp',
  REGULAR_TYPE_EMP = 'regularTypeEmp',
  INTERNAL_COMPA_RATIO = 'internalCompaRatio',
  RETENTION_RATE = 'retentionRate',
  THREE_MONTH_TENURE_ATTRITION = 'threeMonthAttrition',
  ONE_YEAR_TENURE_ATTRITION = 'oneYearTenureAttrition',
  THREE_MONTH_ATTRITION = 'threeMonthAttritionAvgHC',
  ONE_YEAR_ATTRITION = 'oneYearAttritionAvgHC',
  THREE_YEAR_ATTRITION = 'threeYearAttritionAvgHC',
  THIRD_YEAR_ATTRITION = 'thirdYearAttritionAvgHC',
  STARTING_POP_ATTRITION = 'startingPopAttrition',
  ACTUAL_TENURE = 'actualTenure',
  TOTAL_WORKFORCE_COST = 'totalWorkforceCost',
  SGA_PAYROLL = 'sgaPayroll', //TODO: migrated to SQL
  COGS_PAYROLL = 'cogsPayroll', //TODO: migrated to SQL
  SGA_POP = 'sgaPop', //TODO: migrated to SQL
  COGS_POP = 'cogsPop', //TODO: migrated to SQL
  CEO_PAY_GAP_BASE_AVG = 'ceoPayGapBaseAvg',
  TRAVEL_AND_COMMUTING = 'travelAndCommuting',
  OVERTIME_PAY = 'overtimePay',
  TOTAL_COMPENSATION = 'totalCompensation',
  DAYS_IN_OFFICE = 'daysInOffice',
  DAYS_OF_REMOTE_WORK = 'daysOfRemoteWork',
  PAID_LEAVES_TAKEN = 'paidLeaveTaken',
  ANNUAL_PAID_LEAVES_TAKEN = 'annualPaidLeaveTaken',
  JAPAN_5_ANNUAL_LEAVE_DAYS_RULE = 'japan5AnnualLeaveDaysRule',
  MANAGERS_DEFINED = 'managersDefined',
  FEMALE_MANAGERS_DEFINED = 'femaleManagersDefined',
  EMPLOYER_STATUTORY_PAYMENT = 'employerStatutoryPayment', // metric is specifically for hoosiers, short term

  //Recruitment Metrics
  APPLICATION_AT_APPLIED_STAGE = 'application_at_applied_stage',
  APPLICATION_AT_APPLIED_STAGE_PASSED = 'application_at_applied_stage_passed',
  // APPLICATION_AT_APPLIED_STAGE_FAILED = 'application_at_applied_stage_failed',
  APPLICATION_AT_APPLIED_STAGE_REJECTED = 'application_at_applied_stage_rejected',
  APPLICATION_AT_APPLIED_STAGE_WITHDRAWN = 'application_at_applied_stage_withdrawn',

  APPLICATION_AT_SCREENING_STAGE = 'application_at_screening_stage',
  APPLICATION_AT_SCREENING_STAGE_PASSED = 'application_at_screening_stage_passed',
  // APPLICATION_AT_SCREENING_STAGE_FAILED= 'application_at_screening_stage_failed',
  APPLICATION_AT_SCREENING_STAGE_REJECTED = 'application_at_screening_stage_rejected',
  APPLICATION_AT_SCREENING_STAGE_WITHDRAWN = 'application_at_screening_stage_withdrawn',

  APPLICATION_AT_1ST_INTERVIEW_STAGE = 'application_at_1st_interview_stage',
  APPLICATION_AT_1ST_INTERVIEW_STAGE_PASSED = 'application_at_1st_interview_stage_passed',
  // APPLICATION_AT_1ST_INTERVIEW_STAGE_FAILED = 'application_at_1st_interview_stage_failed',
  APPLICATION_AT_1ST_INTERVIEW_STAGE_REJECTED = 'application_at_1st_interview_stage_rejected',
  APPLICATION_AT_1ST_INTERVIEW_STAGE_WITHDRAWN = 'application_at_1st_interview_stage_withdrawn',

  APPLICATION_AT_2ND_INTERVIEW_STAGE = 'application_at_2nd_interview_stage',
  APPLICATION_AT_2ND_INTERVIEW_STAGE_PASSED = 'application_at_2nd_interview_stage_passed',
  // APPLICATION_AT_2ND_INTERVIEW_STAGE_FAILED = 'application_at_2nd_interview_stage_failed',
  APPLICATION_AT_2ND_INTERVIEW_STAGE_REJECTED = 'application_at_2nd_interview_stage_rejected',
  APPLICATION_AT_2ND_INTERVIEW_STAGE_WITHDRAWN = 'application_at_2nd_interview_stage_withdrawn',

  APPLICATION_AT_3RD_INTERVIEW_STAGE = 'application_at_3rd_interview_stage',
  APPLICATION_AT_3RD_INTERVIEW_STAGE_PASSED = 'application_at_3rd_interview_stage_passed',
  // APPLICATION_AT_3RD_INTERVIEW_STAGE_FAILED = 'application_at_3rd_interview_stage_failed',
  APPLICATION_AT_3RD_INTERVIEW_STAGE_REJECTED = 'application_at_3rd_interview_stage_rejected',
  APPLICATION_AT_3RD_INTERVIEW_STAGE_WITHDRAWN = 'application_at_3rd_interview_stage_withdrawn',

  APPLICATION_AT_OFFERED_STAGE = 'application_at_offered_stage',
  APPLICATION_AT_OFFERED_STAGE_PASSED = 'application_at_offered_stage_passed',
  // APPLICATION_AT_OFFERED_STAGE_FAILED = 'application_at_offered_stage_failed',
  APPLICATION_AT_OFFERED_STAGE_REJECTED = 'application_at_offered_stage_rejected',
  APPLICATION_AT_OFFERED_STAGE_WITHDRAWN = 'application_at_offered_stage_withdrawn',

  APPLICATION_AT_JOINED_STAGE = 'application_at_joined_stage',
  PTM_CASES_UNIQUE = 'ptmCasesUnique',

  REVENUE_WHOLECOMPANY = 'revenueWholeCompany',
  GROSS_PROFIT_WHOLECOMPANY = 'grossProfitWholeCompany',
  OPERATING_PROFIT_WHOLECOMPANY = 'operatingProfitWholeCompany',
  NET_INCOME_WHOLECOMPANY = 'netIncomeWholeCompany',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_WHOLECOMPANY = 'sellingGeneralAndAdminExpensesWholeCompany',
  TRAINING_COST_WHOLECOMPANY = 'trainingCostWholeCompany',
  REVENUE_QUARTERLY_WHOLECOMPANY = 'revenueQuarterlyWholeCompany',
  GROSS_PROFIT_QUARTERLY_WHOLECOMPANY = 'grossProfitQuarterlyWholeCompany',
  OPERATING_PROFIT_QUARTERLY_WHOLECOMPANY = 'operatingProfitQuarterlyWholeCompany',
  NET_INCOME_QUARTERLY_WHOLECOMPANY = 'netIncomeQuarterlyWholeCompany',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY_WHOLECOMPANY = 'sellingGeneralAndAdminExpensesQuarterlyWholeCompany',
  TRAINING_COST_QUARTERLY_WHOLECOMPANY = 'trainingCostQuarterlyWholeCompany',

  PM0011_NewMngCalc = 'PM0011_NewMngCalc',
  PM0015_NewMngDef = 'PM0015_NewMngDef',
  PM0062_OT36720RuleEmployees = 'PM0062_OT36720RuleEmployees',
  PM0063_OT361Month100RuleEmp = 'PM0063_OT361Month100RuleEmp',
  PM0074_OT3645HrsRuleEmp = 'PM0074_OT3645HrsRuleEmp',
  PM0075_OT36EmpTrackingGroup = 'PM0075_OT36EmpTrackingGroup',
  PM0197_CustomPaidLeave1Ah = 'PM0197_CustomPaidLeave1AH',
  PM0212_AbsentDays = 'PM0212_AbsentDays',
  PM0213_AvgWorkHoursPerPerson = 'PM0213_AvgWorkHoursPerPerson',
  PM0214_AvgEmp45HrsOTperMth = 'PM0214_AvgEmp45HrsOTperMth',
  PM0215_MonthlyOT = 'PM0215_MonthlyOT',
  PM0217_StatutoryAnnualPaidLeave = 'PM0217_StatutoryAnnualPaidLeave',
  PM0220_HoursWorkedHoliday = 'PM0220_HoursWorkedHoliday',
  PM0221_HoursWorkedLegalHoliday = 'PM0221_HoursWorkedLegalHoliday',
  PM0222_OtHours = 'PM0222_OtHours',
  PM0224_TotalHolidayOt = 'PM0224_TotalHolidayOt',
  PM0225_TotalOt = 'PM0225_TotalOt',
  PM0228_BonusCoef = 'PM0228_BonusCoef',
  PM0230_OT361Month75RuleEmp = 'PM0230_OT361Month75RuleEmp',
  PM0288_OT362to6MonthAvg80RuleEmp = 'PM0288_OT362to6MonthAvg80RuleEmp',
  PM0295_TotalCashCompFinanceYearly = 'PM0295_TotalCashCompFinanceYearly',
  PM0296_TotalBonusFinanceYearly = 'PM0296_TotalBonusFinanceYearly',
  PM0297_RecruitmentCostYearly = 'PM0297_RecruitmentCostYearly',
  PM0298_RetirementAllowanceFinanceYearly = 'PM0298_RetirementAllowanceFinanceYearly',
  PM0299_EmployerNonStatutoryPaymentFinanceYearly = 'PM0299_EmployerNonStatutoryPaymentFinanceYearly',
  PM0300_EmployerStatutoryPaymentFinanceYearly = 'PM0300_EmployerStatutoryPaymentFinanceYearly',
  PM0301_BoardCompensationFinanceYearly = 'PM0301_BoardCompensationFinanceYearly',
  PM0314_TravelCommutingFinanceYearly = 'PM0314_TravelCommutingFinanceYearly',
  PM0315_CustomStatutoryAnnualPaidLeaveNec = 'PM0315_CustomStatutoryAnnualPaidLeaveNec',
  PM0316_CustomIndividuals1ProperNec = 'PM0316_CustomIndividuals1ProperNec',
  PM0317_CustomIndividuals2FinancialReportingNec = 'PM0317_CustomIndividuals2FinancialReportingNec',
  PM0318_CustomAttritionNikki = 'PM0318_CustomAttritionNikki',
  PM0319_CustomAttritionAuFh = 'PM0319_CustomAttritionAuFh',
  PM0320_GenderPayGapMToFTotalCompAvgJpGov = 'PM0320_GenderPayGapMToFTotalCompAvgJpGov',
}

export enum Metrics {
  INTERNAL_MOB_RATE_LEVEL_1_NUM_MOVEMENTS = 'INTERNAL_MOB_RATE_LEVEL_1_NUM_MOVEMENTS',
  INTERNAL_MOB_RATE_LEVEL_1_NUM_EMPS_MOVED = 'INTERNAL_MOB_RATE_LEVEL_1_NUM_EMPS_MOVED',
  INTERNAL_MOB_RATE_LEVEL_1_PERCENTAGEOFTOTALHC = 'INTERNAL_MOB_RATE_LEVEL_1_PERCENTAGEOFTOTALHC',
  INTERNAL_MOB_RATE_LEVEL_2_NUM_MOVEMENTS = 'INTERNAL_MOB_RATE_LEVEL_2_NUM_MOVEMENTS',
  INTERNAL_MOB_RATE_LEVEL_2_NUM_EMPS_MOVED = 'INTERNAL_MOB_RATE_LEVEL_2_NUM_EMPS_MOVED',
  INTERNAL_MOB_RATE_LEVEL_2_PERCENTAGEOFTOTALHC = 'INTERNAL_MOB_RATE_LEVEL_2_PERCENTAGEOFTOTALHC',
  KHNEOCHEM_CUSTOM_FEMALE_COUNT = 'KHNEOCHEM_CUSTOM_FEMALE_COUNT',
  KHNEOCHEM_CUSTOM_FEMALE_PERCENTAGE_OF_FEMALE = 'KHNEOCHEM_CUSTOM_FEMALE_PERCENTAGE_OF_FEMALE',
  KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_COUNT = 'KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_COUNT',
  KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_PERCENTAGE_OF_FEMALE = 'KHNEOCHEM_CUSTOM_FEMALE_MANAGERS_PERCENTAGE_OF_FEMALE',
  ATTRITION_ANNUALIZED = 'ATTRITION_ANNUALIZED',
  ATTRITION_MONTHLY = 'ATTRITION_MONTHLY',
  ATTRITION_TTM = 'ATTRITION_TTM',
  ATTRITION_LEAVERS = 'ATTRITION_LEAVERS',
  RETENTION_RATE_PERC = 'RETENTION_RATE_PERC',
  REGRET_ATTRITION_ANNUALIZED = 'REGRET_ATTRITION_ANNUALIZED',
  REGRET_ATTRITION_MONTHLY = 'REGRET_ATTRITION_MONTHLY',
  REGRET_ATTRITION_TTM = 'REGRET_ATTRITION_TTM',
  REGRET_ATTRITION_LEAVERS = 'REGRET_ATTRITION_LEAVERS',
  STARTING_POP_ATTRITION_PERC = 'STARTING_POP_ATTRITION_PERC',
  VOLUNTARY_ATTRITION_ANNUALIZED = 'VOLUNTARY_ATTRITION_ANNUALIZED',
  VOLUNTARY_ATTRITION_MONTHLY = 'VOLUNTARY_ATTRITION_MONTHLY',
  VOLUNTARY_ATTRITION_TTM = 'VOLUNTARY_ATTRITION_TTM',
  VOLUNTARY_ATTRITION_LEAVERS = 'VOLUNTARY_ATTRITION_LEAVERS',
  THREE_MONTH_TENURE_ATTRITION_ANNUALIZED = 'THREE_MONTH_TENURE_ATTRITION_ANNUALIZED',
  THREE_MONTH_TENURE_ATTRITION_MONTHLY = 'THREE_MONTH_TENURE_ATTRITION_MONTHLY',
  THREE_MONTH_TENURE_ATTRITION_TTM = 'THREE_MONTH_TENURE_ATTRITION_TTM',
  THREE_MONTH_TENURE_ATTRITION_LEAVERS = 'THREE_MONTH_TENURE_ATTRITION_LEAVERS',
  ONE_YEAR_TENURE_ATTRITION_ANNUALIZED = 'ONE_YEAR_TENURE_ATTRITION_ANNUALIZED',
  ONE_YEAR_TENURE_ATTRITION_MONTHLY = 'ONE_YEAR_TENURE_ATTRITION_MONTHLY',
  ONE_YEAR_TENURE_ATTRITION_TTM = 'ONE_YEAR_TENURE_ATTRITION_TTM',
  ONE_YEAR_TENURE_ATTRITION_LEAVERS = 'ONE_YEAR_TENURE_ATTRITION_LEAVERS',
  THREE_MONTH_ATTRITION_ATTRITION = 'THREE_MONTH_ATTRITION_ATTRITION',
  THREE_MONTH_ATTRITION_COUNT_JOINERS = 'THREE_MONTH_ATTRITION_COUNT_JOINERS',
  THREE_MONTH_ATTRITION_COUNT_LEAVERS = 'THREE_MONTH_ATTRITION_COUNT_LEAVERS',
  ONE_YEAR_ATTRITION_ATTRITION = 'ONE_YEAR_ATTRITION_ATTRITION',
  ONE_YEAR_ATTRITION_COUNT_JOINERS = 'ONE_YEAR_ATTRITION_COUNT_JOINERS',
  ONE_YEAR_ATTRITION_COUNT_LEAVERS = 'ONE_YEAR_ATTRITION_COUNT_LEAVERS',
  THREE_YEAR_ATTRITION_ATTRITION = 'THREE_YEAR_ATTRITION_ATTRITION',
  THREE_YEAR_ATTRITION_COUNT_JOINERS = 'THREE_YEAR_ATTRITION_COUNT_JOINERS',
  THREE_YEAR_ATTRITION_COUNT_LEAVERS = 'THREE_YEAR_ATTRITION_COUNT_LEAVERS',
  THIRD_YEAR_ATTRITION_ATTRITION = 'THIRD_YEAR_ATTRITION_ATTRITION',
  THIRD_YEAR_ATTRITION_COUNT_JOINERS = 'THIRD_YEAR_ATTRITION_COUNT_JOINERS',
  THIRD_YEAR_ATTRITION_COUNT_LEAVERS = 'THIRD_YEAR_ATTRITION_COUNT_LEAVERS',
  TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION_RATIO_OF_SUMS = 'TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION_RATIO_OF_SUMS',
  LABOR_COST_RATIO = 'LABOR_COST_RATIO',
  LABOR_COST_PERCENTAGE = 'LABOR_COST_PERCENTAGE',
  TOTAL_LABOR_COST_TO_SALES_RATIO = 'TOTAL_LABOR_COST_TO_SALES_RATIO',
  PARENTAL_LEAVES_TAKEN_UTILIZATION_RATIO = 'PARENTAL_LEAVES_TAKEN_UTILIZATION_RATIO',
  PARENTAL_LEAVES_TAKEN_UTILIZATION_PERCENT = 'PARENTAL_LEAVES_TAKEN_UTILIZATION_PERCENT',
  DAYS_OF_PARENTAL_LEAVES_TAKEN_AVG = 'DAYS_OF_PARENTAL_LEAVES_TAKEN_AVG',
  DAYS_OF_PARENTAL_LEAVES_TAKEN_SUM = 'DAYS_OF_PARENTAL_LEAVES_TAKEN_SUM',
  HUMAN_CAPITAL_ROI_PERCENT = 'HUMAN_CAPITAL_ROI_PERCENT',
  MANAGEMENT_PROMOTION_PERC_OF_POTENTIAL_MANAGEMENT_POOL = 'MANAGEMENT_PROMOTION_PERC_OF_POTENTIAL_MANAGEMENT_POOL',
  JT_OCCUPATIONAL_ACCIDENTS_COUNT = 'JT_OCCUPATIONAL_ACCIDENTS_COUNT',
  JT_OCCUPATIONAL_ACCIDENTS_PERC_OF_HC = 'JT_OCCUPATIONAL_ACCIDENTS_PERC_OF_HC',
  JT_KILLED_AT_WORK_COUNT = 'JT_KILLED_AT_WORK_COUNT',
  MARUDAI_COMPLAINTS_COUNT = 'MARUDAI_COMPLAINTS_COUNT',
  TENURE_IN_JOB_GRADE_LEVEL_1_AVERAGE = 'TENURE_IN_JOB_GRADE_LEVEL_1_AVERAGE',
  TENURE_IN_JOB_GRADE_LEVEL_2_AVERAGE = 'TENURE_IN_JOB_GRADE_LEVEL_2_AVERAGE',
  TENURE_IN_ORG_LEVEL_1_AVERAGE = 'TENURE_IN_ORG_LEVEL_1_AVERAGE',
  TENURE_IN_ORG_LEVEL_2_AVERAGE = 'TENURE_IN_ORG_LEVEL_2_AVERAGE',
  ELIGIBLE_FOR_PARENTAL_LEAVE_COUNT = 'ELIGIBLE_FOR_PARENTAL_LEAVE_COUNT',
  PARENTAL_LEAVES_TAKEN_TOTAL = 'PARENTAL_LEAVES_TAKEN_TOTAL',
  PARENTAL_LEAVES_TAKEN_COUNT = 'PARENTAL_LEAVES_TAKEN_COUNT',
  MSAD_PARENTAL_LEAVES_UTILIZATION_PERCENTAGEOFTOTALHC = 'MSAD_PARENTAL_LEAVES_UTILIZATION_PERCENTAGEOFTOTALHC',
  MSAD_SURVEY_ENGAGEMENT_SCORE_PERCENTAGEOFTOTALHC = 'MSAD_SURVEY_ENGAGEMENT_SCORE_PERCENTAGEOFTOTALHC',
  POTENTIAL_FEMALE_LEADER_COUNT = 'POTENTIAL_FEMALE_LEADER_COUNT',
  POTENTIAL_FEMALE_LEADER_PERCENTAGE_OF_POTENTIAL_LEADER = 'POTENTIAL_FEMALE_LEADER_PERCENTAGE_OF_POTENTIAL_LEADER',
  EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_COUNT = 'EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_COUNT',
  EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_PERCENTAGE_OF_TOTAL_HC = 'EMPLOYEE_WITH_EXTERNAL_EXPERIENCE_PERCENTAGE_OF_TOTAL_HC',
  EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_COUNT = 'EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_COUNT',
  EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_PERCENTAGE_OF_TOTAL_HC = 'EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING_PERCENTAGE_OF_TOTAL_HC',
  DIGITAL_EXPERTS_COUNT = 'DIGITAL_EXPERTS_COUNT',
  DIGITAL_EXPERTS_PERCENTAGE_OF_TOTAL_HC = 'DIGITAL_EXPERTS_PERCENTAGE_OF_TOTAL_HC',
  HIGHLY_STRESSED_EMPLOYEE_COUNT = 'HIGHLY_STRESSED_EMPLOYEE_COUNT',
  HIGHLY_STRESSED_EMPLOYEE_PERCENTAGE_OF_TOTAL_HC = 'HIGHLY_STRESSED_EMPLOYEE_PERCENTAGE_OF_TOTAL_HC',
  LESS_THAN_5_LEAVE_TAKEN_COUNT = 'LESS_THAN_5_LEAVE_TAKEN_COUNT',
  PERCENT_OF_LESS_THAN_5_LEAVE_TAKEN_OF_TOTAL_HC = 'PERCENT_OF_LESS_THAN_5_LEAVE_TAKEN_OF_TOTAL_HC',
  SURVEY_ENGAGEMENT_SCORE_AVG_SCORE = 'SURVEY_ENGAGEMENT_SCORE_AVG_SCORE',
  SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS = 'SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS',
  SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC = 'SURVEY_ENGAGEMENT_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC',
  SURVEY_COMPANY_MOOD_SCORE_AVG_SCORE = 'SURVEY_COMPANY_MOOD_SCORE_AVG_SCORE',
  SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS = 'SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS',
  SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC = 'SURVEY_COMPANY_MOOD_SCORE_NUM_RESPONDENTS_PERCENTAGEOFTOTALHC',
  ENPS_SCORE = 'ENPS_SCORE',
  ENPS_RESPONDENT_COUNT = 'ENPS_RESPONDENT_COUNT',
  ENPS_RESPONDENT_PERCENTAGE_OF_TOTAL_HC = 'ENPS_RESPONDENT_PERCENTAGE_OF_TOTAL_HC',
  TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_AVG_TENURE_SINCE_PTM_CASE = 'TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_AVG_TENURE_SINCE_PTM_CASE',
  TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_COUNT = 'TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_COUNT',
  TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_PERC_OF_ALL_LEAVERS = 'TIKET_LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR_PERC_OF_ALL_LEAVERS',
  TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_COUNT = 'TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_COUNT',
  TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_PERC_OF_OPEN_PTM_CASES = 'TIKET_RECURRING_PTM_CASE_WITHIN_1YEAR_PERC_OF_OPEN_PTM_CASES',
  FTEQUIVALENT_TOTAL = 'FTEQUIVALENT_TOTAL',
  REVENUE_PERFTE_QUARTERLY = 'REVENUE_PERFTE_QUARTERLY',
  REVENUE_PERPERSON_QUARTERLY = 'REVENUE_PERPERSON_QUARTERLY',
  REVENUE_PERFTE_MONTHLY = 'REVENUE_PERFTE_MONTHLY',
  REVENUE_PERPERSON_MONTHLY = 'REVENUE_PERPERSON_MONTHLY',
  GROSS_PROFIT_PERFTE_QUARTERLY = 'GROSS_PROFIT_PERFTE_QUARTERLY',
  GROSS_PROFIT_PERPERSON_QUARTERLY = 'GROSS_PROFIT_PERPERSON_QUARTERLY',
  GROSS_PROFIT_PERFTE_MONTHLY = 'GROSS_PROFIT_PERFTE_MONTHLY',
  GROSS_PROFIT_PERPERSON_MONTHLY = 'GROSS_PROFIT_PERPERSON_MONTHLY',
  OPERATING_PROFIT_PERFTE_QUARTERLY = 'OPERATING_PROFIT_PERFTE_QUARTERLY',
  OPERATING_PROFIT_PERPERSON_QUARTERLY = 'OPERATING_PROFIT_PERPERSON_QUARTERLY',
  OPERATING_PROFIT_PERFTE_MONTHLY = 'OPERATING_PROFIT_PERFTE_MONTHLY',
  OPERATING_PROFIT_PERPERSON_MONTHLY = 'OPERATING_PROFIT_PERPERSON_MONTHLY',
  NET_INCOME_PERFTE_QUARTERLY = 'NET_INCOME_PERFTE_QUARTERLY',
  NET_INCOME_PERPERSON_QUARTERLY = 'NET_INCOME_PERPERSON_QUARTERLY',
  NET_INCOME_PERFTE_MONTHLY = 'NET_INCOME_PERFTE_MONTHLY',
  NET_INCOME_PERPERSON_MONTHLY = 'NET_INCOME_PERPERSON_MONTHLY',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_QUARTERLY = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_QUARTERLY',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_QUARTERLY = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_QUARTERLY',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_MONTHLY = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERFTE_MONTHLY',
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_MONTHLY = 'SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_PERPERSON_MONTHLY',
  TRAINING_COST_PERFTE_QUARTERLY = 'TRAINING_COST_PERFTE_QUARTERLY',
  TRAINING_COST_PERPERSON_QUARTERLY = 'TRAINING_COST_PERPERSON_QUARTERLY',
  TRAINING_COST_PERFTE_MONTHLY = 'TRAINING_COST_PERFTE_MONTHLY',
  TRAINING_COST_PERPERSON_MONTHLY = 'TRAINING_COST_PERPERSON_MONTHLY',
  ANNUAL_PAID_LEAVES_TAKEN_TOTAL = 'ANNUAL_PAID_LEAVES_TAKEN_TOTAL',
  ANNUAL_PAID_LEAVES_TAKEN_AVERAGE = 'ANNUAL_PAID_LEAVES_TAKEN_AVERAGE',
  JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_COUNT = 'JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_COUNT',
  JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_PERC_OF_HC = 'JAPAN_5_ANNUAL_LEAVE_DAYS_RULE_PERC_OF_HC',
  DISABILITY_COUNT = 'DISABILITY_COUNT',
  DISABILITY_RATIO_OF_TOTAL_HC = 'DISABILITY_RATIO_OF_TOTAL_HC',
  DISABILITY_PERCENTAGE_OF_TOTAL_HC = 'DISABILITY_PERCENTAGE_OF_TOTAL_HC',
  DISABILITY_WEIGHTED_COUNT = 'DISABILITY_WEIGHTED_COUNT',
  DISABILITY_WEIGHTED_RATIO_OF_POP = 'DISABILITY_WEIGHTED_RATIO_OF_POP',
  DISABILITY_WEIGHTED_PERCENTAGE_OF_TOTAL_HC = 'DISABILITY_WEIGHTED_PERCENTAGE_OF_TOTAL_HC',
  TRAINING_TAKEN_AVERAGE_TRAINING_PER_EMPLOYEE = 'TRAINING_TAKEN_AVERAGE_TRAINING_PER_EMPLOYEE',
  TRAINING_TAKEN_AVERAGE_TRAINING_PER_TRAINEE = 'TRAINING_TAKEN_AVERAGE_TRAINING_PER_TRAINEE',
  TRAINING_TAKEN_COUNT = 'TRAINING_TAKEN_COUNT',
  TRAINEE_COUNT = 'TRAINEE_COUNT',
  TRAINEE_PERCENTAGE_OF_TOTAL_HC = 'TRAINEE_PERCENTAGE_OF_TOTAL_HC',
  DISCIPLINARY_ACTION_COUNT = 'DISCIPLINARY_ACTION_COUNT',
  CEO_PAY_GAP_BASE_AVG_MULTIPLE = 'CEO_PAY_GAP_BASE_AVG_MULTIPLE',
  CEO_PAY_GAP_BASE_AVG_PERCENTAGE = 'CEO_PAY_GAP_BASE_AVG_PERCENTAGE',
  POP_PAY_GAP_MULTIPLE = 'POP_PAY_GAP_MULTIPLE',
  INTERNAL_COMPA_RATIO_AVERAGE = 'INTERNAL_COMPA_RATIO_AVERAGE',
  FEMALE_MANAGERS_CALCULATED_COUNT = 'FEMALE_MANAGERS_CALCULATED_COUNT',
  FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_TOTAL_HC = 'FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_TOTAL_HC',
  FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_MANAGERS = 'FEMALE_MANAGERS_CALCULATED_PERCENTAGE_OF_MANAGERS',
  FEMALE_MANAGERS_DEFINED_COUNT = 'FEMALE_MANAGERS_DEFINED_COUNT',
  FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_TOTAL_HC = 'FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_TOTAL_HC',
  FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_MANAGERS = 'FEMALE_MANAGERS_DEFINED_PERCENTAGE_OF_MANAGERS',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_FEMALE_OF_MALE = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_FEMALE_OF_MALE',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_MALE_OF_FEMALE = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_MALE_OF_FEMALE',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_CASH_GAP = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_CASH_GAP',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_CASH_GAP = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_CASH_GAP',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_PERCENT = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_F_TO_M_PERCENT',
  GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_PERCENT = 'GENDER_PAY_GAP_TOTAL_COMP_AVG_M_TO_F_PERCENT',
  GENDER_GAP_FEMALE_OF_MALE = 'GENDER_GAP_FEMALE_OF_MALE',
  GENDER_GAP_MALE_OF_FEMALE = 'GENDER_GAP_MALE_OF_FEMALE',
  GENDER_PAY_GAP_BASE_SALARY_F_TO_M_CASH_GAP = 'GENDER_PAY_GAP_BASE_SALARY_F_TO_M_CASH_GAP',
  GENDER_PAY_GAP_BASE_SALARY_M_TO_F_CASH_GAP = 'GENDER_PAY_GAP_BASE_SALARY_M_TO_F_CASH_GAP',
  GENDER_PAY_GAP_BASE_SALARY_F_TO_M_PERCENT = 'GENDER_PAY_GAP_BASE_SALARY_F_TO_M_PERCENT',
  GENDER_PAY_GAP_BASE_SALARY_M_TO_F_PERCENT = 'GENDER_PAY_GAP_BASE_SALARY_M_TO_F_PERCENT',
  GENDER_PAY_GAP_TOTAL_COMP_FEMALE_OF_MALE = 'GENDER_PAY_GAP_TOTAL_COMP_FEMALE_OF_MALE',
  GENDER_PAY_GAP_TOTAL_COMP_MALE_OF_FEMALE = 'GENDER_PAY_GAP_TOTAL_COMP_MALE_OF_FEMALE',
  GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_CASH_GAP = 'GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_CASH_GAP',
  GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_CASH_GAP = 'GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_CASH_GAP',
  GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_PERCENT = 'GENDER_PAY_GAP_TOTAL_COMP_F_TO_M_PERCENT',
  GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_PERCENT = 'GENDER_PAY_GAP_TOTAL_COMP_M_TO_F_PERCENT',
  FEMALE_HEADCOUNT_COUNT = 'FEMALE_HEADCOUNT_COUNT',
  FEMALE_HEADCOUNT_PERCENTAGE = 'FEMALE_HEADCOUNT_PERCENTAGE',
  MANAGERS_DEFINED_COUNT = 'MANAGERS_DEFINED_COUNT',
  MANAGERS_DEFINED_PERCENTAGE = 'MANAGERS_DEFINED_PERCENTAGE',
  MANAGERS_CALCULATED_COUNT = 'MANAGERS_CALCULATED_COUNT',
  MANAGERS_CALCULATED_PERCENTAGE = 'MANAGERS_CALCULATED_PERCENTAGE',
  FTE_COUNT = 'FTE_COUNT',
  FTE_PERCENTAGE = 'FTE_PERCENTAGE',
  NON_REGULAR_TYPE_EMP_COUNT = 'NON_REGULAR_TYPE_EMP_COUNT',
  NON_REGULAR_TYPE_EMP_PERCENTAGE = 'NON_REGULAR_TYPE_EMP_PERCENTAGE',
  REGULAR_TYPE_EMP_COUNT = 'REGULAR_TYPE_EMP_COUNT',
  REGULAR_TYPE_EMP_PERCENTAGE = 'REGULAR_TYPE_EMP_PERCENTAGE',
  ACTUAL_TENURE_AVERAGE = 'ACTUAL_TENURE_AVERAGE',
  ACTUAL_TENURE_MIN = 'ACTUAL_TENURE_MIN',
  ACTUAL_TENURE_MAX = 'ACTUAL_TENURE_MAX',
  ACTUAL_TENURE_MEDIAN = 'ACTUAL_TENURE_MEDIAN',
  MEDIAN_BASE = 'MEDIAN_BASE',
  AGE_AVERAGE = 'AGE_AVERAGE',
  AGE_MIN = 'AGE_MIN',
  AGE_MAX = 'AGE_MAX',
  AGE_MEDIAN = 'AGE_MEDIAN',
  SPAN_OF_CONTROL_AVERAGE = 'SPAN_OF_CONTROL_AVERAGE',
  SPAN_OF_CONTROL_MIN = 'SPAN_OF_CONTROL_MIN',
  SPAN_OF_CONTROL_MAX = 'SPAN_OF_CONTROL_MAX',
  SPAN_OF_CONTROL_MEDIAN = 'SPAN_OF_CONTROL_MEDIAN',
  DEPTH_OF_CONTROL_MAX = 'DEPTH_OF_CONTROL_MAX',
  EMPLOYEE_COUNT = 'EMPLOYEE_COUNT',
  JOINERS_COUNT = 'JOINERS_COUNT',
  JOINERS_PERCENTAGE = 'JOINERS_PERCENTAGE',
  LEAVERS_TERM_DATE_COUNT = 'LEAVERS_TERM_DATE_COUNT',
  LEAVERS_TERM_DATE_PERCENTAGE = 'LEAVERS_TERM_DATE_PERCENTAGE',
  LEAVERS_EFFECTIVE_LEAVER_DATE_COUNT = 'LEAVERS_EFFECTIVE_LEAVER_DATE_COUNT',
  LEAVERS_EFFECTIVE_LEAVER_DATE_PERCENTAGE = 'LEAVERS_EFFECTIVE_LEAVER_DATE_PERCENTAGE',
  TRAVEL_AND_COMMUTING_TOTAL = 'TRAVEL_AND_COMMUTING_TOTAL',
  TRAVEL_AND_COMMUTING_AVERAGE = 'TRAVEL_AND_COMMUTING_AVERAGE',
  OVERTIME_PAY_TOTAL = 'OVERTIME_PAY_TOTAL',
  OVERTIME_PAY_AVERAGE = 'OVERTIME_PAY_AVERAGE',
  TOTAL_WORKFORCE_COST_TOTAL = 'TOTAL_WORKFORCE_COST_TOTAL',
  TOTAL_WORKFORCE_COST_AVERAGE = 'TOTAL_WORKFORCE_COST_AVERAGE',
  TOTAL_COMPENSATION_TOTAL = 'TOTAL_COMPENSATION_TOTAL',
  TOTAL_COMPENSATION_AVERAGE = 'TOTAL_COMPENSATION_AVERAGE',
  SGA_PAYROLL_TOTAL = 'SGA_PAYROLL_TOTAL',
  SGA_PAYROLL_PERC_PAYROLL_COST = 'SGA_PAYROLL_PERC_PAYROLL_COST',
  SGA_PAYROLL_PERC_OF_TOTAL_HC = 'SGA_PAYROLL_PERC_OF_TOTAL_HC',
  SGA_POPULATION_COUNT = 'SGA_POPULATION_COUNT',
  SGA_POPULATION_PERC_OF_TOTAL_HC = 'SGA_POPULATION_PERC_OF_TOTAL_HC',
  COGS_PAYROLL_TOTAL = 'COGS_PAYROLL_TOTAL',
  COGS_PAYROLL_PERC_PAYROLL_COST = 'COGS_PAYROLL_PERC_PAYROLL_COST',
  COGS_PAYROLL_PERC_OF_TOTAL_HC = 'COGS_PAYROLL_PERC_OF_TOTAL_HC',
  COGS_POPULATION_COUNT = 'COGS_POPULATION_COUNT',
  COGS_POPULATION_PERC_OF_TOTAL_HC = 'COGS_POPULATION_PERC_OF_TOTAL_HC',
  INDIRECT_INVESTMENT_RATIO_PERC = 'INDIRECT_INVESTMENT_RATIO_PERC',
  PAID_LEAVES_TAKEN_TOTAL = 'PAID_LEAVES_TAKEN_TOTAL',
  PAID_LEAVES_TAKEN_AVERAGE = 'PAID_LEAVES_TAKEN_AVERAGE',
  DAYS_IN_OFFICE_PERC_OF_WORKING_DAYS = 'DAYS_IN_OFFICE_PERC_OF_WORKING_DAYS',
  DAYS_IN_OFFICE_COUNT = 'DAYS_IN_OFFICE_COUNT',
  DAYS_IN_OFFICE_AVG = 'DAYS_IN_OFFICE_AVG',
  DAYS_REMOTE_PERC_OF_WORKING_DAYS = 'DAYS_REMOTE_PERC_OF_WORKING_DAYS',
  DAYS_REMOTE_COUNT = 'DAYS_REMOTE_COUNT',
  DAYS_REMOTE_AVG = 'DAYS_REMOTE_AVG',
  EMPLOYER_STATUTORY_PAYMENT_TOTAL = 'EMPLOYER_STATUTORY_PAYMENT_TOTAL',
  EMPLOYEE_WITH_PTM_CASES_ALL_CUMULATIVE_COUNT = 'EMPLOYEE_WITH_PTM_CASES_ALL_CUMULATIVE_COUNT',
  EMPLOYEE_WITH_PTM_CASES_ALL_PERC_OF_HC = 'EMPLOYEE_WITH_PTM_CASES_ALL_PERC_OF_HC',
  EMPLOYEE_WITH_PTM_CASES_ALL_NEW_COUNT = 'EMPLOYEE_WITH_PTM_CASES_ALL_NEW_COUNT',
  EMPLOYEE_WITH_PTM_CASES_OPEN_TOTAL = 'EMPLOYEE_WITH_PTM_CASES_OPEN_TOTAL',
  EMPLOYEE_WITH_PTM_CASES_OPEN_PERC_OF_HC = 'EMPLOYEE_WITH_PTM_CASES_OPEN_PERC_OF_HC',
  EMPLOYEE_WITH_PTM_CASES_OPEN_NEW_COUNT = 'EMPLOYEE_WITH_PTM_CASES_OPEN_NEW_COUNT',
  EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_TOTAL = 'EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_TOTAL',
  EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_PERC_OF_HC = 'EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_PERC_OF_HC',
  EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_NEW_COUNT = 'EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS_NEW_COUNT',
  EMPLOYEE_WITH_PTM_CASES_CLOSED_CUMULATIVE_COUNT = 'EMPLOYEE_WITH_PTM_CASES_CLOSED_CUMULATIVE_COUNT',
  EMPLOYEE_WITH_PTM_CASES_CLOSED_PERC_OF_HC = 'EMPLOYEE_WITH_PTM_CASES_CLOSED_PERC_OF_HC',
  EMPLOYEE_WITH_PTM_CASES_CLOSED_NEW_COUNT = 'EMPLOYEE_WITH_PTM_CASES_CLOSED_NEW_COUNT',
  PTM_CASES_UNIQUE_COUNT = 'PTM_CASES_UNIQUE_COUNT',
  PTM_CASES_UNIQUE_PERC_OF_OPEN_CASES = 'PTM_CASES_UNIQUE_PERC_OF_OPEN_CASES',

  PM0011_1NewMngCalcCount = 'PM0011_1NewMngCalcCount',
  PM0011_2NewMngCalcPercMng = 'PM0011_2NewMngCalcPercMng',
  PM0011_3NewMngCalcPercHc = 'PM0011_3NewMngCalcPercHc',
  PM0015_1NewMngDefCount = 'PM0015_1NewMngDefCount',
  PM0015_2NewMngDefPercMng = 'PM0015_2NewMngDefPercMng',
  PM0015_3NewMngDefPercHc = 'PM0015_3NewMngDefPercHc',
  PM0062_1_OT36720RuleEmployees_Count = 'PM0062_1_OT36720RuleEmployees_Count',
  PM0062_2_OT36720RuleEmployees_PercHC = 'PM0062_2_OT36720RuleEmployees_PercHC',
  PM0063_1_OT361Month100RuleEmp_Count = 'PM0063_1_OT361Month100RuleEmp_Count',
  PM0063_2_OT361Month100RuleEmp_PercHC = 'PM0063_2_OT361Month100RuleEmp_PercHC',
  PM0074_1_OT3645HrsRuleEmp_Count = 'PM0074_1_OT3645HrsRuleEmp_Count',
  PM0074_2_OT3645HrsRuleEmp_PercHC = 'PM0074_2_OT3645HrsRuleEmp_PercHC',
  PM0075_1_OT36EmpTrackingGroup_Count = 'PM0075_1_OT36EmpTrackingGroup_Count',
  PM0075_2_OT36EmpTrackingGroup_PercHC = 'PM0075_2_OT36EmpTrackingGroup_PercHC',
  PM0176_3TotalCashCompensationPerPersonWtcc = 'PM0176_3_TotalCashCompensation_PerPersonWTCC',
  PM0197_1CustomPaidLeave1AhCumSumPercUtilization = 'PM0197_1_CustomPaidLeave1AH_CumSumPercUtilization',
  PM0197_2CustomPaidLeave1AhCumSumPerHc = 'PM0197_2_CustomPaidLeave1AH_CumSumPerHC',
  PM0197_3CustomPaidLeave1AhSumPerHc = 'PM0197_3_CustomPaidLeave1AH_SumPerHC',
  PM0212_1AbsentDaysPerHc = 'PM0212_1_AbsentDays_PerHC',
  PM0212_2AbsentDaysPercOfWorkingDays = 'PM0212_2_AbsentDays_PercOfWorkingDays',
  PM0213_1AvgWorkHoursPerPersonAvg = 'PM0213_1_AvgWorkHoursPerPerson_Avg',
  PM0213_2AvgWorkHoursPerPersonAvgWithWh = 'PM0213_2_AvgWorkHoursPerPerson_AvgWithWH',
  PM0214_1AvgEmp45HrsOTperMthPercHc = 'PM0214_1AvgEmp45HrsOTperMthPercHc',
  PM0214_2AvgEmp45HrsOTperMthHc = 'PM0214_2AvgEmp45HrsOTperMthHc',
  PM0215_1_MonthlyOT_SumPerHC = 'PM0215_1_MonthlyOT_SumPerHC',
  PM0215_2_MonthlyOT_SumPerHCWithOT = 'PM0215_2_MonthlyOT_SumPerHCWithOT',
  PM0217_1StatutoryAnnualPaidLeaveCumSumPercUtilization = 'PM0217_1_StatutoryAnnualPaidLeave_CumSumPercUtilization',
  PM0217_2StatutoryAnnualPaidLeaveCumSumPerHc = 'PM0217_2_StatutoryAnnualPaidLeave_CumSumPerHC',
  PM0217_3StatutoryAnnualPaidLeaveSumPerHc = 'PM0217_3_StatutoryAnnualPaidLeave_SumPerHC',
  PM0220_1HoursWorkedHolidayTotal = 'PM0220_1HoursWorkedHolidayTotal',
  PM0221_1HoursWorkedLegalHolidayTotal = 'PM0221_1HoursWorkedLegalHolidayTotal',
  PM0222_1OtHoursTotal = 'PM0222_1OtHoursTotal',
  PM0224_1_TotalHolidayOT_Total = 'PM0224_1_TotalHolidayOT_Total',
  PM0225_1_TotalOT_Total = 'PM0225_1_TotalOT_Total',
  PM0228_1BonusCoefAvg = 'PM0228_1_BonusCoef_Avg',
  PM0228_2BonusCoefMedian = 'PM0228_2_BonusCoef_Median',
  PM0230_1_OT361Month75RuleEmp_Count = 'PM0230_1_OT361Month75RuleEmp_Count',
  PM0230_2_OT361Month75RuleEmp_PercHC = 'PM0230_2_OT361Month75RuleEmp_PercHC',
  PM0288_1_OT362to6MonthAvg80RuleEmp_Count = 'PM0288_1_OT362to6MonthAvg80RuleEmp_Count',
  PM0288_2_OT362to6MonthAvg80RuleEmp_PercHC = 'PM0288_2_OT362to6MonthAvg80RuleEmp_PercHC',
  Pm0252_2ApplicationsCount = 'Pm0252_2ApplicationsCount',
  Pm0253_1PassthroughAtAppliedPerc = 'Pm0253_1PassthroughAtAppliedPerc',
  Pm0253_2PassedApplicationsAtAppliedCount = 'Pm0253_2PassedApplicationsAtAppliedCount',
  Pm0254_1RejectedApplicationsAtAppliedPerc = 'Pm0254_1RejectedApplicationsAtAppliedPerc',
  Pm0254_2RejectedApplicationsAtAppliedCount = 'Pm0254_2RejectedApplicationsAtAppliedCount',
  Pm0255_1WithdrawnApplicationsAtAppliedPerc = 'Pm0255_1WithdrawnApplicationsAtAppliedPerc',
  Pm0255_2WithdrawnApplicationsAtAppliedCount = 'Pm0255_2WithdrawnApplicationsAtAppliedCount',
  Pm0256_1ApplicationsAtScreeningCount = 'Pm0256_1ApplicationsAtScreeningCount',
  Pm0257_1PassthroughAtScreeningPerc = 'Pm0257_1PassthroughAtScreeningPerc',
  Pm0257_2PassedApplicationsAtScreeningCount = 'Pm0257_2PassedApplicationsAtScreeningCount',
  Pm0258_1RejectedApplicationsAtScreeningPerc = 'Pm0258_1RejectedApplicationsAtScreeningPerc',
  Pm0258_2RejectedApplicationsAtScreeningCount = 'Pm0258_2RejectedApplicationsAtScreeningCount',
  Pm0259_1WithdrawnApplicationsAtScreeningPerc = 'Pm0259_1WithdrawnApplicationsAtScreeningPerc',
  Pm0259_2WithdrawnApplicationsAtScreeningCount = 'Pm0259_2WithdrawnApplicationsAtScreeningCount',
  Pm0260_1ApplicationsAt_1stInterviewCount = 'Pm0260_1ApplicationsAt_1stInterviewCount',
  Pm0261_1PassthroughAt_1stInterviewPerc = 'Pm0261_1PassthroughAt_1stInterviewPerc',
  Pm0261_2PassedApplicationsAt_1stInterviewCount = 'Pm0261_2PassedApplicationsAt_1stInterviewCount',
  Pm0262_1RejectedApplicationsAt_1stInterviewPerc = 'Pm0262_1RejectedApplicationsAt_1stInterviewPerc',
  Pm0262_2RejectedApplicationsAt_1stInterviewCount = 'Pm0262_2RejectedApplicationsAt_1stInterviewCount',
  Pm0263_1WithdrawnApplicationsAt_1stInterviewPerc = 'Pm0263_1WithdrawnApplicationsAt_1stInterviewPerc',
  Pm0263_2WithdrawnApplicationsAt_1stInterviewCount = 'Pm0263_2WithdrawnApplicationsAt_1stInterviewCount',
  Pm0264_1ApplicationsAt_2ndInterviewCount = 'Pm0264_1ApplicationsAt_2ndInterviewCount',
  Pm0265_1PassthroughAt_2ndInterviewPerc = 'Pm0265_1PassthroughAt_2ndInterviewPerc',
  Pm0265_2PassedApplicationsAt_2ndInterviewCount = 'Pm0265_2PassedApplicationsAt_2ndInterviewCount',
  Pm0266_1RejectedApplicationsAt_2ndInterviewPerc = 'Pm0266_1RejectedApplicationsAt_2ndInterviewPerc',
  Pm0266_2RejectedApplicationsAt_2ndInterviewCount = 'Pm0266_2RejectedApplicationsAt_2ndInterviewCount',
  Pm0267_1WithdrawnApplicationsAt_2ndInterviewPerc = 'Pm0267_1WithdrawnApplicationsAt_2ndInterviewPerc',
  Pm0267_2WithdrawnApplicationsAt_2ndInterviewCount = 'Pm0267_2WithdrawnApplicationsAt_2ndInterviewCount',
  Pm0268_1ApplicationsAtFinalInterviewCount = 'Pm0268_1ApplicationsAtFinalInterviewCount',
  Pm0269_1PassthroughAtFinalInterviewPerc = 'Pm0269_1PassthroughAtFinalInterviewPerc',
  Pm0269_2PassedApplicationsAtFinalInterviewCount = 'Pm0269_2PassedApplicationsAtFinalInterviewCount',
  Pm0270_1RejectedApplicationsAtFinalInterviewPerc = 'Pm0270_1RejectedApplicationsAtFinalInterviewPerc',
  Pm0270_2RejectedApplicationsAtFinalInterviewCount = 'Pm0270_2RejectedApplicationsAtFinalInterviewCount',
  Pm0271_1WithdrawnApplicationsAtFinalInterviewPerc = 'Pm0271_1WithdrawnApplicationsAtFinalInterviewPerc',
  Pm0271_2WithdrawnApplicationsAtFinalInterviewCount = 'Pm0271_2WithdrawnApplicationsAtFinalInterviewCount',
  Pm0272_1ApplicationsAtOfferCount = 'Pm0272_1ApplicationsAtOfferCount',
  Pm0273_1PassthroughAtOfferPerc = 'Pm0273_1PassthroughAtOfferPerc',
  Pm0273_2PassedApplicationsAtOfferCount = 'Pm0273_2PassedApplicationsAtOfferCount',
  Pm0274_1RejectedApplicationsAtOfferPerc = 'Pm0274_1RejectedApplicationsAtOfferPerc',
  Pm0274_2RejectedApplicationsAtOfferCount = 'Pm0274_2RejectedApplicationsAtOfferCount',
  Pm0275_1WithdrawnApplicationsAtOfferPerc = 'Pm0275_1WithdrawnApplicationsAtOfferPerc',
  Pm0275_2WithdrawnApplicationsAtOfferCount = 'Pm0275_2WithdrawnApplicationsAtOfferCount',
  Pm0276_1ApplicationsAtHiredCount = 'Pm0276_1ApplicationsAtHiredCount',
  Pm0285_1ParentalLeaveUtilizationMsadPercHc = 'Pm0285_1ParentalLeaveUtilizationMsadPercHc',
  Pm0289_1_3mAttritionAttrition = 'Pm0289_1_3mAttritionAttrition',
  Pm0289_2_3mAttritionCountJoiners = 'Pm0289_2_3mAttritionCountJoiners',
  Pm0289_3_3mAttritionCountLeavers = 'Pm0289_3_3mAttritionCountLeavers',
  Pm0290_1_1yAttritionAttrition = 'Pm0290_1_1yAttritionAttrition',
  Pm0290_2_1yAttritionCountJoiners = 'Pm0290_2_1yAttritionCountJoiners',
  Pm0290_3_1yAttritionCountLeavers = 'Pm0290_3_1yAttritionCountLeavers',
  Pm0291_1_3yAttritionAttrition = 'Pm0291_1_3yAttritionAttrition',
  Pm0291_2_3yAttritionCountJoiners = 'Pm0291_2_3yAttritionCountJoiners',
  Pm0291_3_3yAttritionCountLeavers = 'Pm0291_3_3yAttritionCountLeavers',
  Pm0292_1_3rdYearAttritionAttrition = 'Pm0292_1_3rdYearAttritionAttrition',
  Pm0292_2_3rdYearAttritionCountJoiners = 'Pm0292_2_3rdYearAttritionCountJoiners',
  Pm0292_3_3rdYearAttritionCountLeavers = 'Pm0292_3_3rdYearAttritionCountLeavers',
  Pm0295_1TotalCashCompFinanceYearlyPerIndv = 'Pm0295_1_TotalCashCompFinanceYearly_PerIndv',
  Pm0295_2TotalCashCompFinanceYearlyTotal = 'Pm0295_2_TotalCashCompFinanceYearly_Total',
  Pm0296_1TotalBonusFinanceYearlyPerIndv = 'Pm0296_1_TotalBonusFinanceYearly_PerIndv',
  Pm0296_2TotalBonusFinanceYearlyTotal = 'Pm0296_2_TotalBonusFinanceYearly_Total',
  Pm0297_1RecruitmentCostYearlyPerIndv = 'Pm0297_1_RecruitmentCostYearly_PerIndv',
  Pm0297_2RecruitmentCostYearlyTotal = 'Pm0297_2_RecruitmentCostYearly_Total',
  Pm0298_1RetirementAllowanceFinanceYearlyPerIndv = 'Pm0298_1_RetirementAllowanceFinanceYearly_PerIndv',
  Pm0298_2RetirementAllowanceFinanceYearlyTotal = 'Pm0298_2_RetirementAllowanceFinanceYearly_Total',
  Pm0299_1EmployerNonStatutoryPaymentFinanceYearlyPerIndv = 'Pm0299_1_EmployerNonStatutoryPaymentFinanceYearly_PerIndv',
  Pm0299_2EmployerNonStatutoryPaymentFinanceYearlyTotal = 'Pm0299_2_EmployerNonStatutoryPaymentFinanceYearly_Total',
  Pm0300_1EmployerStatutoryPaymentFinanceYearlyPerIndv = 'Pm0300_1_EmployerStatutoryPaymentFinanceYearly_PerIndv',
  Pm0300_2EmployerStatutoryPaymentFinanceYearlyTotal = 'Pm0300_2_EmployerStatutoryPaymentFinanceYearly_Total',
  Pm0301_1BoardCompensationFinanceYearlyPerIndv = 'Pm0301_1_BoardCompensationFinanceYearly_PerIndv',
  Pm0301_2BoardCompensationFinanceYearlyTotal = 'Pm0301_2_BoardCompensationFinanceYearly_Total',
  Pm0302_1RevenueHcMonthlyWholeCompanyPerFte = 'Pm0302_1RevenueHcMonthlyWholeCompanyPerFte',
  Pm0302_2RevenueHcMonthlyWholeCompanyPerIndiv = 'Pm0302_2RevenueHcMonthlyWholeCompanyPerIndiv',
  Pm0303_1GrossProfitHcMonthlyWholeCompanyPerFte = 'Pm0303_1GrossProfitHcMonthlyWholeCompanyPerFte',
  Pm0303_2GrossProfitHcMonthlyWholeCompanyPerIndiv = 'Pm0303_2GrossProfitHcMonthlyWholeCompanyPerIndiv',
  Pm0304_1SgahcMonthlyWholeCompanyPerFte = 'Pm0304_1SgahcMonthlyWholeCompanyPerFte',
  Pm0304_2SgahcMonthlyWholeCompanyPerIndiv = 'Pm0304_2SgahcMonthlyWholeCompanyPerIndiv',
  Pm0305_1OperatingProfitHcMonthlyWholeCompanyPerFte = 'Pm0305_1OperatingProfitHcMonthlyWholeCompanyPerFte',
  Pm0305_2OperatingProfitHcMonthlyWholeCompanyPerIndiv = 'Pm0305_2OperatingProfitHcMonthlyWholeCompanyPerIndiv',
  Pm0306_1TrainingCostMonthlyWholeCompanyPerFte = 'Pm0306_1TrainingCostMonthlyWholeCompanyPerFte',
  Pm0306_2TrainingCostMonthlyWholeCompanyPerIndiv = 'Pm0306_2TrainingCostMonthlyWholeCompanyPerIndiv',
  Pm0307_1NetIncomeHcMonthlyWholeCompanyPerFte = 'Pm0307_1NetIncomeHcMonthlyWholeCompanyPerFte',
  Pm0307_2NetIncomeHcMonthlyWholeCompanyPerIndiv = 'Pm0307_2NetIncomeHcMonthlyWholeCompanyPerIndiv',
  Pm0308_1RevenueHcQuarterlyWholeCompanyPerFte = 'Pm0308_1RevenueHcQuarterlyWholeCompanyPerFte',
  Pm0308_2RevenueHcQuarterlyWholeCompanyPerIndv = 'Pm0308_2RevenueHcQuarterlyWholeCompanyPerIndv',
  Pm0309_1GrossProfitHcQuarterlyWholeCompanyPerFte = 'Pm0309_1GrossProfitHcQuarterlyWholeCompanyPerFte',
  Pm0309_2GrossProfitHcQuarterlyWholeCompanyPerIndv = 'Pm0309_2GrossProfitHcQuarterlyWholeCompanyPerIndv',
  Pm0310_1SgahcQuarterlyWholeCompanyPerFte = 'Pm0310_1SgahcQuarterlyWholeCompanyPerFte',
  Pm0310_2SgahcQuarterlyWholeCompanyPerIndv = 'Pm0310_2SgahcQuarterlyWholeCompanyPerIndv',
  Pm0311_1OperatingProfitHcQuarterlyWholeCompanyPerFte = 'Pm0311_1OperatingProfitHcQuarterlyWholeCompanyPerFte',
  Pm0311_2OperatingProfitHcQuarterlyWholeCompanyPerIndv = 'Pm0311_2OperatingProfitHcQuarterlyWholeCompanyPerIndv',
  Pm0312_1TrainingCostQuarterlyWholeCompanyPerFte = 'Pm0312_1TrainingCostQuarterlyWholeCompanyPerFte',
  Pm0312_2TrainingCostQuarterlyWholeCompanyPerIndv = 'Pm0312_2TrainingCostQuarterlyWholeCompanyPerIndv',
  Pm0313_1NetIncomeHcQuarterlyWholeCompanyPerFte = 'Pm0313_1NetIncomeHcQuarterlyWholeCompanyPerFte',
  Pm0313_2NetIncomeHcQuarterlyWholeCompanyPerIndv = 'Pm0313_2NetIncomeHcQuarterlyWholeCompanyPerIndv',
  Pm0314_1TravelCommutingFinanceYearlyPerIndv = 'Pm0314_1_TravelCommutingFinanceYearly_PerIndv',
  Pm0314_2TravelCommutingFinanceYearlyTotal = 'Pm0314_2_TravelCommutingFinanceYearly_Total',
  PM0315_1CustomStatutoryAnnualPaidLeaveNecCumSumPercUtilization = 'PM0315_1_CustomStatutoryAnnualPaidLeaveNEC_CumSumPercUtilization',
  PM0315_2CustomStatutoryAnnualPaidLeaveNecCumSumPerHc = 'PM0315_2_CustomStatutoryAnnualPaidLeaveNEC_CumSumPerHC',
  PM0315_3CustomStatutoryAnnualPaidLeaveNecSumPerHc = 'PM0315_3_CustomStatutoryAnnualPaidLeaveNEC_SumPerHC',
  PM0316_1CustomIndividuals1ProperNecCount = 'PM0316_1_CustomIndividuals1ProperNEC_Count',
  PM0317_1CustomIndividuals2FinancialReportingNecCount = 'PM0317_1_CustomIndividuals2FinancialReportingNEC_Count',
  PM0318_1CustomAttritionNikkiAnnualized = 'PM0318_1_CustomAttritionNikki_Annualized',
  PM0318_2CustomAttritionNikkiMonthly = 'PM0318_2_CustomAttritionNikki_Monthly',
  PM0318_3CustomAttritionNikkiTtm = 'PM0318_3_CustomAttritionNikki_TTM',
  PM0318_4CustomAttritionNikkiLeavers = 'PM0318_4_CustomAttritionNikki_Leavers',
  PM0319_1CustomAttritionAuFhAnnualized = 'PM0319_1_CustomAttritionAuFH_Annualized',
  PM0319_2CustomAttritionAuFhMonthly = 'PM0319_2_CustomAttritionAuFH_Monthly',
  PM0319_3CustomAttritionAuFhTtm = 'PM0319_3_CustomAttritionAuFH_TTM',
  PM0319_4CustomAttritionAuFhLeavers = 'PM0319_4_CustomAttritionAuFH_Leavers',
  PM0320_1GenderPayGapFToMTotalCompAvgJpGovPerc = 'PM0320_1_GenderPayGapFToMTotalCompAvgJpGov_perc',
  PM0320_2GenderPayGapMToFTotalCompAvgJpGovPerc = 'PM0320_2_GenderPayGapMToFTotalCompAvgJpGov_perc',
  PM0320_3GenderPayGapFToMTotalCompAvgJpGovDiff = 'PM0320_3_GenderPayGapFToMTotalCompAvgJpGov_diff',
  PM0320_4GenderPayGapMToFTotalCompAvgJpGovDiff = 'PM0320_4_GenderPayGapMToFTotalCompAvgJpGov_diff',
}

export interface PbsHierarchicalValue {
  value: PbsCategories;
  subItems: PbsItems[];
}

export interface PbsServiceInputs {
  pbsItemToIMetricsMap: PbsItemToIMetricsMap;
  timeSliderConfig: TimeSliderConfig;
  selectedBenchmark: Benchmark | null;
  targetFilters?: ApiMasterDataQueryFilterItem[];
}
export interface PbsByDimensionServiceInputs extends PbsServiceInputs {
  dimension: DataFieldWithDataType;
}

export interface JoinedPbsByDimensionServiceInputs extends PbsByDimensionServiceInputs {
  metricChart: PbsMetricChart | null;
  secondaryMetricChart: PbsMetricChart | null;
}

export interface PbsSegmentationServiceInputs extends PbsServiceInputs {
  segmentLevel1Dimension?: DataFieldWithDataType;
  segmentsLevel1: ApiMasterDataQueryFilterItem[];
  segmentLevel2Dimension?: DataFieldWithDataType;
  segmentsLevel2: ApiMasterDataQueryFilterItem[];
  recruitmentFilters?: Partial<Record<DataTypes, ApiMasterDataQueryFilterItem[]>>;
}

export enum PbsBenchmarkTypes {
  TARGET = 'target',
  YOY = 'yoy',
  COMPANY = 'company',
}

export const PERIOD_TOTAL = 'periodTotal';
export const DELTA = 'delta';
export const DELTA_PERCENTAGE = 'deltaPercentage';

export type FixedPbsTableHeaders = typeof PERIOD_TOTAL | typeof DELTA | PbsBenchmarkTypes | typeof DELTA_PERCENTAGE;

export interface PbsTableHeaderObj {
  label: string;
  value: string;
  subVal?: string; // TODO: Not sure if I like this subVal thing but keeping it for now
}

export enum SegmentValTypes {
  COUNT = 'count',
  PERCENTAGE_OF_TOTAL = 'percentageOfTotal',
}

export enum PbsMeasures {
  PARENTAL_LEAVES_TAKEN_COUNT = 'parentalLeavesTakenCount',
  ELIGIBLE_FOR_PARENTAL_LEAVE = 'eligibleForParentalLeave',
  RATIO = 'ratio',
  SCORE = 'score',
  AVG_SCORE = 'avgScore',
  TOTAL = 'total',
  PERCENTAGE = 'percentage',
  AVERAGE = 'average',
  CUMULATIVE_AVERAGE = 'cumulativeAverage',
  CUMULATIVE_COUNT = 'cumulativeCount',
  CUM_SUM_PERCENTAGE_UTILIZATION = 'cumSumPercentageUtilization',
  CUM_SUM_PER_HC = 'cumSumPerHC',
  SUM_PER_HC = 'sumPerHC',
  TRAILING_AVERAGE = 'trailingAverage',
  MULTIPLE = 'multiple',
  FofM = 'fOfM',
  MofF = 'mOfF',
  COUNT = 'count',
  MEDIAN = 'median',
  MAX = 'max',
  MIN = 'min',
  ATTRITION = 'attrition',
  ANNUALISED = 'annualised',
  TTM = 'ttm',
  STARTING_POP = 'startingPop',
  MONTHLYPERCENTAGE = 'monthlyPercentage',
  PERCENTAGEOFTOTALHC = 'percentageOfTotalHc',
  AVGPERCENTAGEOFHC = 'avgPercentOfHc',
  AVGTENURESINCEPTMCASE = 'avgTenureSincePtmCase',
  PERCENTAGEOFWORKINGDAYS = 'percentageOfWorkingDays',
  PERCENTAGEOFPOTENTIALMANAGEMENTPOOL = 'percentageOfPotentialManagementPool',
  PERCENTAGEOFOPENPTMCASES = 'percentageOfOpenPtmCases',
  PERCENTAGEOFALLLEAVERS = 'percentageOfAllLeavers',
  AVGHC = 'avgHc',
  PERPERSON = 'perPerson',
  PERPERSON_WITH_CC = 'perPersonWithCC',
  PERINDIVIDUAL = 'perIndividual',
  PERFTE = 'perFTE',
  JOINERS = 'joiners',
  LEAVERS = 'leavers',
  PERCENTAGE_OF_PAYROLL_COST = 'percentageOfPayrollCost',
  PERCENTAGE_OF_MANAGERS = 'percentageOfManagers',
  PERCENTAGE_OF_POTENTIAL_LEADER = 'percentageOfPotentialLeader',
  PERCENTAGE_OF_FEMALE = 'percentageOfFemale',
  NUM_EMPS_MOVED = 'numEmpsMoved',
  NUM_MOVEMENTS = 'numMovements',
  NUM_RESPONDENTS = 'numRespondents',
  NUM_RESPONDENTS_PERCENTAGEOFTOTALHC = 'numRespondentsPercentageOfTotalHc',
  AVERAGE_ALL_POP = 'averageAllPop',
  AVERAGE_POP_WITH_OT = 'averagePopWithOt',
  AVERAGE_POP_WITH_WH = 'averagePopWithWh',
  AVERAGE_TRAINING_PER_EMPLOYEE = 'averageTrainingPerEmployee',
  AVERAGE_TRAINING_PER_TRAINEE = 'averageTrainingPerTrainee',
  // Gender Pay Gap (Total Comp) for JGovt calc
  F_TO_M_PERCENT = 'fToMPercent',
  M_TO_F_PERCENT = 'mToFPercent',
  F_TO_M_CASH_GAP = 'fToMCashGap',
  M_TO_F_CASH_GAP = 'mToFCashGap',
  NEW_COUNT = 'newCount',
  WEIGHTED_COUNT = 'weightedCount',
  WEIGHTED_RATIOOFPOP = 'weightedRatioOfPop',
  WEIGHTED_PERCENTAGEOFPOP = 'weightedPercentageOfPop',
}

export const recruitmentFunnelPbsCategories = [
  PbsCategories.RECRUITMENT_FUNNEL,
  PbsCategories.RECRUITMENT_FUNNEL_APPLICATION,
  PbsCategories.RECRUITMENT_FUNNEL_SCREENING,
  PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  PbsCategories.RECRUITMENT_FUNNEL_OFFERED,
  PbsCategories.RECRUITMENT_FUNNEL_JOINED,
];

export const recruitmentFunnelPbsItems = [
  // applied
  PbsItems.APPLICATION_AT_APPLIED_STAGE,
  PbsItems.APPLICATION_AT_APPLIED_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_APPLIED_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_APPLIED_STAGE_PASSED,
  // screening
  PbsItems.APPLICATION_AT_SCREENING_STAGE,
  PbsItems.APPLICATION_AT_SCREENING_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_SCREENING_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_SCREENING_STAGE_PASSED,
  // 1st interview
  PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE,
  PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_PASSED,
  // 2nd interview
  PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE,
  PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_PASSED,
  // 3rd interview
  PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE,
  PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_PASSED,
  // offered
  PbsItems.APPLICATION_AT_OFFERED_STAGE,
  PbsItems.APPLICATION_AT_OFFERED_STAGE_WITHDRAWN,
  PbsItems.APPLICATION_AT_OFFERED_STAGE_REJECTED,
  PbsItems.APPLICATION_AT_OFFERED_STAGE_PASSED,
  // joined
  PbsItems.APPLICATION_AT_JOINED_STAGE,
] as const;

export type RecruitmentPbsItems = typeof recruitmentFunnelPbsItems[number];

export type PbsCellData<PbsItem extends PbsItems> = Record<MeasuresForPbsItem<PbsItem>, number | null>;
// we return null when data isn't availble instead of returning 0, which could be misleading
// in the UI, null can be rendered as NA, or -, etc as per the business decision

export type MeasuresForPbsItem<PbsItem extends PbsItems> = typeof pbsItemsToMeasuresMap[PbsItem][number];
// TODO: PbsItem and PbsItems is very confusing. Have better naming

export type TimeHeaderData<PbsItem extends PbsItems> = {
  label: string;
  dateRange: [string, string];
  data: PbsCellData<PbsItem>;
};

export type MeasuresValueMap = Partial<Record<PbsMeasures, number | null>>;

//TODO: Not sure if there is a way to build the same Monoid for Partial Record and Record
export class PartialRecordMonoid<K extends keyof any, V> implements Monoid<Partial<Record<K, V>>> {
  private monoid: Monoid<V>;
  constructor(monoid: Monoid<V>) {
    this.monoid = monoid;
  }
  combine = (r1: Partial<Record<K, V>>, r2: Partial<Record<K, V>>) => {
    if (Object.keys(r1).length === 0) {
      return r2;
    } else if (Object.keys(r2).length === 0) {
      return r1;
    } else {
      return Array.from(new Set([...Object.keys(r1), ...Object.keys(r2)])).reduce(
        (acc, key) => {
          acc[key as K] = this.monoid.combine(r1[key as K] ?? this.monoid.empty, r2[key as K] ?? this.monoid.empty);
          return acc;
        },
        { ...this.empty }
      );
    }
  };

  empty = {} as Partial<Record<K, V>>;
}

export class RecordMonoid<K extends keyof any, V> implements Monoid<Record<K, V>> {
  private monoid: Monoid<V>;
  constructor(monoid: Monoid<V>) {
    this.monoid = monoid;
  }
  combine = (r1: Record<K, V>, r2: Record<K, V>) => {
    if (Object.keys(r1).length === 0) {
      return r2;
    } else if (Object.keys(r2).length === 0) {
      return r1;
    } else {
      return Array.from(new Set([...Object.keys(r1), ...Object.keys(r2)])).reduce(
        (acc, key) => {
          acc[key as K] = this.monoid.combine(r1[key as K] ?? this.monoid.empty, r2[key as K] ?? this.monoid.empty);
          return acc;
        },
        { ...this.empty }
      );
    }
  };

  empty = {} as Record<K, V>;
}

export class NumberOrNullMonoid implements Monoid<number | null> {
  combine = (r1: number | null, r2: number | null) => (r1 ?? 0) + (r2 ?? 0);
  empty = 0;
}

class ListRbsTimeHeaderDataMonoid implements Monoid<RbsTimeHeaderData[]> {
  private partialRecordMonoid = new PartialRecordMonoid<PbsMeasures, number | null>(new NumberOrNullMonoid());

  combine = (l1: RbsTimeHeaderData[], l2: RbsTimeHeaderData[]) => {
    if (l1.length === 0) return l2;
    if (l2.length === 0) return l1;
    return l1.flatMap((rbsTimeHeaderData1) => {
      const rbsTimeHeaderData2 = l2.find((item) => rbsTimeHeaderData1.label === item.label);
      if (rbsTimeHeaderData2) {
        let data = {};
        if (!rbsTimeHeaderData1?.data) {
          data = rbsTimeHeaderData2?.data;
        } else if (!rbsTimeHeaderData2?.data) {
          data = rbsTimeHeaderData1?.data;
        } else {
          data = this.partialRecordMonoid.combine(rbsTimeHeaderData1.data, rbsTimeHeaderData2.data);
        }
        return [
          {
            label: rbsTimeHeaderData1.label,
            dateRange: rbsTimeHeaderData1.dateRange,
            data,
          },
        ];
      } else {
        return [];
      }
    });
  };
  empty = [] as RbsTimeHeaderData[];
}

export class RbsServiceResponseDataMonoid implements Monoid<RbsServiceResponseData> {
  private partialRecordMonoid = new RecordMonoid(
    new PartialRecordMonoid<PbsMeasures, number | null>(new NumberOrNullMonoid())
  );

  combine = (r1: RbsServiceResponseData, r2: RbsServiceResponseData) => {
    return {
      timeHeadersData: new ListRbsTimeHeaderDataMonoid().combine(r1.timeHeadersData, r2.timeHeadersData),
      fixedHeadersData: this.partialRecordMonoid.combine(r1.fixedHeadersData, r2.fixedHeadersData),
      benchmarkHeadersData: this.partialRecordMonoid.combine(
        r1.benchmarkHeadersData ?? {},
        r2.benchmarkHeadersData ?? {}
      ),
    };
  };
  empty = {
    timeHeadersData: new ListRbsTimeHeaderDataMonoid().empty,
    fixedHeadersData: this.partialRecordMonoid.empty,
    benchmarkHeadersData: this.partialRecordMonoid.empty,
  };
}

export type SegmentLabels = string;
export type SegmentedResponseData = Record<SegmentLabels, Record<string, ValueByDate>>;

export type MetaDataObject = MetricResultMetaData & {
  metric: Metrics;
  pbsItem: PbsItems;
  measure: PbsMeasures;
};

export type MetaDataForRow = {
  [measure: string]: {
    overtime?: MetaDataObject;
    periodTotal?: MetaDataObject;
    benchmark?: MetaDataObject;
  };
};
export type SegmentedResponseDataMetaData = {
  [pbsItem: string]: Record<
    SegmentLabels,
    { meta: MetaDataForRow; segments?: Record<SegmentLabels, { meta: MetaDataForRow }> }
  >;
};

export type ResponseDataMetaData = {
  [pbsItem: string]: MetaDataForRow;
};

export type ResponseData = {
  values: Record<RecruitmentPbsItems | PbsItemAndMeasure, ValueByDate>;
  meta?: ResponseDataMetaData;
};
export type ValueByDate = Record<FixedPbsTableHeaders | string, MeasuresValueMap>;

//TODO: rename to SQLServiceResponseData
export type RbsServiceResponseData = {
  timeHeadersData: RbsTimeHeaderData[];
  fixedHeadersData: ValueByDate;
  benchmarkHeadersData?: ValueByDate;
  segments?: {
    segments: Record<string, RbsServiceResponseData>[];
    totalData: RbsServiceResponseData;
  };
};

export type RbsTimeHeaderData = {
  label: string;
  dateRange: [string, string];
  data: MeasuresValueMap;
};

export type PbsItemAndMeasure = string;

export type SQLTableRowData = {
  value: PbsItems;
  measures: PbsMeasures[];
  data: Partial<Record<PbsItems, RbsServiceResponseData>>;
};

export type PbsCellDataGetter<PbsItem extends PbsItems> = (
  timeSliderConfig: TimeSliderConfig,
  filters: ApiMasterDataQueryFilterItem[]
) => Promise<PbsCellData<PbsItem>>;

export interface TimeSlice {
  dateRange: [string, string];
  label: string;
}

export type PbsServiceResponseData<PbsItem extends PbsItems> = {
  timeHeadersData: TimeHeaderData<PbsItem>[];
  fixedHeadersData: Record<string, PbsCellData<PbsItem>>;
  benchmarkHeadersData?: Record<string, PbsCellData<PbsItem>>;
};

// TODO: Ideally I want to restrict PbsItem to be only a stirng literal
// and not allow string unions, but not sure how to enforce that constraint right now

export type PbsServiceResponse<PbsItem extends PbsItems> = Record<PbsItem, PbsServiceResponseData<PbsItem>>;
// I don't like the optional here as it allows empty object but just gonna keep this for now
// as I can't find a better way

export type PbsByDimensionServiceResponse<PbsItem extends PbsItems> = DataMapAndLabels<PbsCellData<PbsItem>>;

export type PbsSegmentationServiceResponse<PbsItem extends PbsItems> = {
  fixedHeaderObjs: PbsTableHeaderObj[];
  timeHeaderObjs: PbsTableHeaderObj[];
  benchmarkHeaderObjs: PbsTableHeaderObj[];
  responseData: Record<PbsItem, Record<string, RbsServiceResponseData>[]>;
  totalData: RbsServiceResponseData;
  meta: SegmentedResponseDataMetaData;
};

export type PbsSegmentationService<PbsItem extends PbsItems> = Service<
  PbsSegmentationServiceInputs,
  PbsSegmentationServiceResponse<PbsItem> | null // TODO: Do I need this null?
>;

export type PbsTableRowData<RowItem extends PbsItems> = {
  value: RowItem;
  measures: PbsMeasures[];
  data: PbsServiceResponse<RowItem>;
};

export interface PbsTableRowGroup {
  value: PbsCategories;
  rows: PbsTableRowData<PbsItems>[];
  // any any might prevent more type info. Might need to fix
}

export interface PbsTableServiceResponseData {
  rowGroups: PbsTableRowGroup[];
}

export type PbsService<PbsItem extends PbsItems> = Service<PbsServiceInputs, PbsServiceResponse<PbsItem> | null>;

export interface PbsItemDropdownItem {
  label: string;
  value: PbsItems;
  selected: boolean;
}

export interface PbsCategoryDropdownItem {
  label: string;
  value: PbsCategories;
  subItems: PbsItemDropdownItem[];
  isExpanded: boolean;
}

export interface PbsMetricChartMeasures {
  label: string;
  value: PbsMeasures;
  key: string;
}

export interface PbsMetricChart {
  label: string;
  value: PbsItems;
  measures: PbsMetricChartMeasures[];
}

export interface BalanceSheetTableServiceInputs {
  timeSliderConfig: TimeSliderConfig;
  selectedPbsValues: PbsHierarchicalValue[];
  selectedBenchmark: Benchmark | null;
  recruitmentFilters?: Partial<Record<DataTypes, ApiMasterDataQueryFilterItem[]>>;
  pbsItemToIMetricsMap: PbsItemToIMetricsMap;
}

export interface BalanceSheetTableServiceResponse {
  fixedHeaderObjs: PbsTableHeaderObj[];
  timeHeaderObjs: PbsTableHeaderObj[];
  benchmarkHeaderObjs: PbsTableHeaderObj[];
  tableData: PbsTableServiceResponseData;
  meta: ResponseDataMetaData;
}

export type BalanceSheetTableService = Service<BalanceSheetTableServiceInputs, BalanceSheetTableServiceResponse>;
