import { CircularProgress } from '@mui/material';
import React, { Component } from 'react';
import { styled } from '../styled';

const CenteredLoading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
` as typeof CircularProgress;

const LoadingWrapper = styled('div')<{ shadeBackground: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${(props) => (props.shadeBackground ? 'rgba(0, 0, 0, 0.1)' : 'transparent')};
  /* z-index: 1000; */
`;

interface LoadingProps {
  color?: 'primary' | 'secondary';
  shadeBackground?: boolean;
}

export default class LoadingOverlay extends Component<LoadingProps> {
  public render() {
    const { color, shadeBackground = true } = this.props;
    return (
      <LoadingWrapper shadeBackground={shadeBackground}>
        <CenteredLoading data-cy="panalyt-loader" color={color ? color : 'primary'} />
      </LoadingWrapper>
    );
  }
}
