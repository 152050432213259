import { action, observable, when } from 'mobx';
import { GraphQlRequestService } from '../api/graphql-request-service';
import { CompanyStore } from '../company/company-store';
import { DataTypes, Domains } from '../constants/constants';
import { DomainDependenciesQuery } from '../graphql/generated/graphql-sdk';

// Not using an interface because of direct usage of graphql types, making it almost impossible to define the interface types
/**
 * This dependency store is used to get all the dependencies/configuration etc. that we need to retrieve after a specific domain has been selected by the user.
 */
export class DomainDependencyStore {
  private graphQlRequestService: GraphQlRequestService;

  @observable
  public domainDependencies: DomainDependenciesQuery | null = null;

  @observable
  public isDomainDependenciesFetched: boolean = false;

  @observable
  public couldNotFetchDomainDependencies: 'generalDomainDependenciesError' | 'networkError' | null = null;

  public domainDependenciesReady = () => {
    return this.isDomainDependenciesFetched;
  };

  constructor(graphqlRequestService: GraphQlRequestService, companyStore: CompanyStore) {
    this.graphQlRequestService = graphqlRequestService;

    when(
      () => this.graphQlRequestService.graphQlClientIgnoringErrors != null && companyStore.domain !== '',
      () => {
        this.loadDomainDependencies(companyStore.domain as Domains).catch((error) => {
          const errorMessage: string = error.message ?? '';
          const networkRequestFailedRegex = /Network request failed/i;
          if (networkRequestFailedRegex.test(errorMessage)) {
            this.couldNotFetchDomainDependencies = 'networkError';
          } else {
            // Optimally we should check the errorCode of the backend to see for what reason it failed
            this.couldNotFetchDomainDependencies = 'generalDomainDependenciesError';
          }
        });
      }
    );
  }

  @action
  public async loadDomainDependencies(domain: Domains) {
    const result = await this.graphQlRequestService.graphQlSdkIgnoringErrors.domainDependencies({
      domain: domain,
      simulateRole: null,
    });
    this.domainDependencies = result;
    this.isDomainDependenciesFetched = true;
  }

  // TODO for all the callsites below, improve errorhandling by showing an inline-error instead of using fallbacks like empty ists

  public getUserRoles() {
    return this.domainDependencies?.domainInfo?.userRoles;
  }

  public getAllUsedFields() {
    return this.domainDependencies?.domainInfo?.allUsedFields;
  }

  public getAliases() {
    return this.domainDependencies?.domainInfo?.aliases;
  }

  public getAllowedReports() {
    return this.domainDependencies?.domainInfo?.allowedReports;
  }

  public getAllLatestVersions() {
    return this.domainDependencies?.domainInfo?.allLatestVersions;
  }

  public getLatestVersion(dataType: DataTypes): string {
    return (this.getAllLatestVersions() || []).find((entry) => entry.dataType.valueOf() === dataType.valueOf())
      ?.version;
  }
}
