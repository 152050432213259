import React, { lazy } from 'react';
import { ValueOf } from 'ts-pattern/dist/types/helpers';

export const lazyWithRetry = (componentImport: any) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      localStorage.getItem('page-has-been-force-refreshed') || 'false'
    );

    try {
      const component = await componentImport();

      localStorage.setItem('page-has-been-force-refreshed', 'false');

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        localStorage.setItem('page-has-been-force-refreshed', 'true');
        return window.location.reload();
      }

      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      // Let's let the application crash and raise the error.
      throw error;
    }
  });

export const forwardRefSimply = <T extends {}>(Component: React.ComponentType<React.PropsWithChildren<T>>) => {
  return React.forwardRef<React.RefObject<typeof Component>, React.ComponentProps<typeof Component>>((props, ref) => (
    <Component {...props} ref={ref} />
  ));
};

export function enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
  return Object.keys(obj).filter((k) => Number.isNaN(+k)) as K[];
}

// Iterate through enumeration keys without loosing the type of the enumeration
// Without this, the type of each element would be string
export function enumValues<O extends object, V extends ValueOf<O> = ValueOf<O>>(obj: O): V[] {
  return Object.values(obj) as V[];
}
