import * as Sentry from '@sentry/react';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { rootStore } from './common/store/root-store';
import FallbackComponent from './ErrorFallback';

class ErrorBoundary extends React.Component<WithTranslation & { children?: React.ReactNode }> {
  public render() {
    const { t } = this.props;
    const { authStore, userStore, companyStore } = rootStore || {};
    const { currentUser: authCurrentUser } = authStore || {};
    const { currentUser } = userStore || {};

    const basicOptions = {
      title: t('pages:errorBoundary.dialog.title'),
      subtitle: t('pages:errorBoundary.dialog.subtitle'),
      subtitle2: t('pages:errorBoundary.dialog.subtitle2'),
      labelName: t('pages:errorBoundary.dialog.labelName'),
      labelEmail: t('pages:errorBoundary.dialog.labelEmail'),
      labelComments: t('pages:errorBoundary.dialog.labelComments'),
      labelClose: t('pages:errorBoundary.dialog.labelClose'),
      labelSubmit: t('pages:errorBoundary.dialog.labelSubmit'),
      errorGeneric: t('pages:errorBoundary.dialog.errorGeneric'),
      errorFormEntry: t('pages:errorBoundary.dialog.errorFormEntry'),
      successMessage: t('pages:errorBoundary.dialog.successMessage'),
    };
    const dialogOptions = {
      ...basicOptions,
      user:
        currentUser && authCurrentUser
          ? {
              email: currentUser.email ?? 'UNKNOWN',
              name: authCurrentUser.displayName ?? 'UNKNOWN',
            }
          : {},
    };

    return (
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setUser({
            ...scope.getUser(),
            email: currentUser?.email,
            username: authCurrentUser?.displayName ?? undefined,
          });
          scope.setTag('panalyt.domain', companyStore.domain ?? 'UNKNOWN');
          scope.setTag('panalyt.errorType', 'errorBoundary');
        }}
        fallback={<FallbackComponent />}
        showDialog
        dialogOptions={dialogOptions}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export default withTranslation()(ErrorBoundary);
