export interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  messagingSenderId: string;
}

export interface Environment {
  name: Environments;
  hosts: string[];
  newApiBase: string;
  legacyPanalytFirebaseConfig?: FirebaseConfig;
  mixpanelToken: string;
  newPanacloudFirebaseConfig?: FirebaseConfig; // Should be mandatory, but we have to remove prod and eu before we can do so.
}

export enum Environments {
  EU = 'eu',
  JAPAN = 'japan',
  PROD = 'prod',
  QA = 'qa',
  DEMO = 'demo',
  PANACLOUD_QA = 'panacloud-qa',
  PANACLOUD_DEMO = 'panacloud-demo',
  PANACLOUD_JAPAN = 'panacloud-japan',
}

export const localGraphQlUrl = 'http://localhost:8080/graphql';
export const environmentConstants: Environment[] = [
  {
    name: Environments.DEMO,
    hosts: ['demo.panalyt.com', 'panalyt-demo.panalyt.com'],
    newApiBase: 'api-demo.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7',
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyBqBKyMDaR9xB0RkVaIuaqX2AhoIQwR1EQ',
      authDomain: 'panalyt-demo.firebaseapp.com',
      databaseURL: 'https://panalyt-demo.firebaseio.com',
      projectId: 'panalyt-demo',
      messagingSenderId: '72749267226',
    },
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyClY-rVyPohj80xNZB3rEABlC_M85c5UTo',
      authDomain: 'panacloud-demo.firebaseapp.com',
      databaseURL: 'https://panacloud-demo.firebaseio.com',
      projectId: 'panacloud-demo',
      messagingSenderId: '72749267226',
    },
  },
  {
    name: Environments.QA,
    hosts: [
      'qa.panalyt.com',
      'localhost',
      '127.0.0.1',
      'swan.panalyt.com',
      'valentinsaml.panalyt.com',
      'quickfix-qa.panalyt.com',
      'panalyt-qa.panalyt.com',
      'panalyt-swan.panalyt.com',
      'panalyt-valentinsaml.panalyt.com',
      'panalyt-quickfix-qa.panalyt.com',
    ],
    newApiBase: 'api-qa.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7', //NOTE: we only send event for production environments
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyDEBle6ckQQ3gFPGwgvhPmJZpdkWvXcyhA',
      authDomain: 'panalyt-qa.firebaseapp.com',
      databaseURL: 'https://panalyt-qa.firebaseio.com',
      projectId: 'panalyt-qa',
      messagingSenderId: '788718841297',
    },
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyDP3HoNRx7AcHd56kM5RiGrJey1WdhqiLM',
      authDomain: 'panacloud-qa.firebaseapp.com',
      databaseURL: 'https://panacloud-qa.firebaseio.com',
      projectId: 'panacloud-qa',

      messagingSenderId: '788718841297',
    },
  },
  {
    name: Environments.JAPAN,
    hosts: [
      'japan.panalyt.com',
      'jt.panalyt.com',
      'crexta.panalyt.com',
      'panalyt-japan.panalyt.com',
      'panalyt-jt.panalyt.com',
      'panalyt-crexta.panalyt.com',
    ],
    newApiBase: 'api-japan.panalyt.com',
    mixpanelToken: '93741733e5ab3cc78559072173805905',
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyDTfA_5zIX9biVAlQvkKxfACRtiI3JeE1g',
      authDomain: 'panalyt-japan.firebaseapp.com',
      databaseURL: 'https://panalyt-japan.firebaseio.com',
      projectId: 'panalyt-japan',
      messagingSenderId: '580290219341',
    },
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyDtkdLt77sKge95wYZW58og3P0TJFoM8SM',
      authDomain: 'panacloud-japan.firebaseapp.com',
      databaseURL: 'https://panacloud-japan.firebaseio.com',
      projectId: 'panacloud-japan',
      messagingSenderId: '580290219341',
    },
  },
  {
    name: Environments.PANACLOUD_DEMO,
    hosts: ['panacloud-demo.panalyt.com'],
    newApiBase: 'api-demo-panacloud.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7',
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyClY-rVyPohj80xNZB3rEABlC_M85c5UTo',
      authDomain: 'panacloud-demo.firebaseapp.com',
      databaseURL: 'https://panacloud-demo.firebaseio.com',
      projectId: 'panacloud-demo',
      messagingSenderId: '72749267226',
    },
  },
  {
    name: Environments.PANACLOUD_QA,
    hosts: [
      'panacloud-qa.panalyt.com',
      'localhost',
      '127.0.0.1',
      'panacloud-swan.panalyt.com',
      'panacloud-valentinsaml.panalyt.com',
      'panacloud-quickfix-qa.panalyt.com',
    ],
    newApiBase: 'api-qa-panacloud.panalyt.com',
    mixpanelToken: '083e820927ae1f73caac8d4d508af4e7', //NOTE: we only send event for production environments
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyDP3HoNRx7AcHd56kM5RiGrJey1WdhqiLM',
      authDomain: 'panacloud-qa.firebaseapp.com',
      databaseURL: 'https://panacloud-qa.firebaseio.com',
      projectId: 'panacloud-qa',

      messagingSenderId: '788718841297',
    },
  },
  {
    name: Environments.PANACLOUD_JAPAN,
    hosts: [
      'panacloud-japan.panalyt.com',
      'panacloud-jt.panalyt.com',
      'jt2.panalyt.com',
      'panacloud-crexta.panalyt.com',
    ],
    newApiBase: 'api-japan-panacloud.panalyt.com',
    mixpanelToken: '93741733e5ab3cc78559072173805905',
    newPanacloudFirebaseConfig: {
      apiKey: 'AIzaSyDtkdLt77sKge95wYZW58og3P0TJFoM8SM',
      authDomain: 'panacloud-japan.firebaseapp.com',
      databaseURL: 'https://panacloud-japan.firebaseio.com',
      projectId: 'panacloud-japan',
      messagingSenderId: '580290219341',
    },
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyDTfA_5zIX9biVAlQvkKxfACRtiI3JeE1g',
      authDomain: 'panalyt-japan.firebaseapp.com',
      databaseURL: 'https://panalyt-japan.firebaseio.com',
      projectId: 'panalyt-japan',
      messagingSenderId: '580290219341',
    },
  },
  {
    name: Environments.PROD,
    hosts: ['app.panalyt.com'],
    newApiBase: 'panalyt-backend-dot-panalyt-prod.appspot.com',
    mixpanelToken: '93741733e5ab3cc78559072173805905', //TODO: use a new Mixpanel environment if we reactivate prod
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyBBeS7G5dQ00rzNwQs8g5Ris50J3hB868E',
      authDomain: 'panalyt-prod.firebaseapp.com',
      databaseURL: 'https://panalyt-prod.firebaseio.com',
      projectId: 'panalyt-prod',
      messagingSenderId: '598928658632',
    },
  },
  {
    name: Environments.EU,
    hosts: ['eu.panalyt.com'],
    newApiBase: 'panalyt-backend-dot-panalyt-eu.appspot.com',
    mixpanelToken: '93741733e5ab3cc78559072173805905', //TODO: use a new Mixpanel environment if we reactivate eu
    legacyPanalytFirebaseConfig: {
      apiKey: 'AIzaSyCCAeeRjQ_yQKWQnVSPl8y560sfQeDqAuM',
      authDomain: 'panalyt-eu.firebaseapp.com',
      databaseURL: '',
      projectId: 'panalyt-eu',
      messagingSenderId: '1059151716591',
    },
  },
];
