import IntercomLoader from 'react-live-chat-loader/dist/providers/intercom';
import { PbsItems, PERIOD_TOTAL } from '../../pages/dashboard/balance-sheet/types';
import { publicRoutes } from '../routes';

export enum IntercomArticleKeys {
  FILTER_TRAY = 'FILTER_TRAY',
  DATA_VIEW = 'DATA_VIEW',
  BENCHMARK = 'BENCHMARK',
  FORECAST = 'FORECAST',
  SEGMENT = 'SEGMENT',
  EXPORT = 'EXPORT',
}
export class IntercomStore {
  // private intercomTourIds: Record<string, TourIdsLng> = {
  //   intercomTourIdsEn: {
  //     '/my-panalyt': 51788,
  //     '/dashboard/attrition': 51802,
  //     '/dashboard/people-snapshot': 51704,
  //     '/dashboard/median-base': 51760,
  //     '/dashboard/diversity-gender': 51841,
  //   },
  //   intercomTourIdsJa: {
  //     '/my-panalyt': 66695,
  //     '/dashboard/attrition': 66696,
  //     '/dashboard/people-snapshot': 66682,
  //     '/dashboard/median-base': 66685,
  //     '/dashboard/diversity-gender': 66687,
  //   },
  // };

  private defaultIntercomArticleid = 5971932;

  private intercomKeyToArticleIdMap = {
    [PbsItems.FEMALE_MANAGERS_CALCULATED]: 6548399,
    [PbsItems.FEMALE_MANAGERS_DEFINED]: 6548464,
    [PbsItems.DEPTH_OF_CONTROL]: 6549054,
    [PbsItems.NUM_EMP]: 6547137,
    [PbsItems.NUM_JOINERS]: 6547158,
    [PbsItems.NUM_LEAVERS_TERM_DATE]: 6548391,
    [PbsItems.MANAGERS_DEFINED]: 6548404,
    [PbsItems.MANAGERS_CALCULATED]: 6548404,
    [PbsItems.PM0011_NewMngCalc]: 6548386,
    [PbsItems.PM0015_NewMngDef]: 6548386,
    [PbsItems.AGE]: 6549029,
    [PbsItems.SPAN_OF_CONTROL]: 6549037,
    [PbsItems.INTERNAL_MOB_RATE_LEVEL_1]: 7043566,
    [PbsItems.INTERNAL_MOB_RATE_LEVEL_2]: 7043566,
    [PbsItems.ATTRITION]: 7043628,
    [PbsItems.MEDIAN_BASE]: 7063179,
    [PbsItems.INTERNAL_COMPA_RATIO]: 7043938,
    [PbsItems.PM0213_AvgWorkHoursPerPerson]: 7062311,
    [PbsItems.FTEQUIVALENT]: 6547137,
    [PbsItems.FEM_HC]: 6547137,
    [PbsItems.ACTUAL_TENURE]: 8072715,
    [PbsItems.POP_PAY_GAP]: 8100072,
    [PbsItems.CEO_PAY_GAP_BASE_AVG]: 7063152,
    [PbsItems.REVENUE]: 8064350,
    [PbsItems.GROSS_PROFIT]: 8064350,
    [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES]: 8064350,
    [PbsItems.OPERATING_PROFIT]: 8064350,
    [PbsItems.TRAINING_COST]: 8064350,
    [PbsItems.NET_INCOME]: 8064350,
    [PbsItems.REVENUE_QUARTERLY]: 8064350,
    [PbsItems.GROSS_PROFIT_QUARTERLY]: 8064350,
    [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY]: 8064350,
    [PbsItems.OPERATING_PROFIT_QUARTERLY]: 8064350,
    [PbsItems.TRAINING_COST_QUARTERLY]: 8064350,
    [PbsItems.NET_INCOME_QUARTERLY]: 8064350,
    [PbsItems.INDIRECT_INVESTMENT_RATIO]: 8064350,
    [PbsItems.GENDER_GAP]: 7043878,
    [PbsItems.RETENTION_RATE]: 7127417,
    [PbsItems.THREE_MONTH_TENURE_ATTRITION]: 7043828,
    [PbsItems.ONE_YEAR_TENURE_ATTRITION]: 7043828,
    [PbsItems.REGRET_ATTRITION]: 7127452,
    [PbsItems.DAYS_IN_OFFICE]: 8109902,
    [PbsItems.TRAINING_TAKEN]: 8111444,
    [PbsItems.TRAINEE]: 8111444,
    [PbsItems.TOTAL_WORKFORCE_COST]: 8064350,
    [PbsItems.TOTAL_COMPENSATION]: 8064350,
    [PbsItems.SGA_PAYROLL]: 8064350,
    [PbsItems.SGA_POP]: 8064350,
    [PbsItems.COGS_PAYROLL]: 8064350,
    [PbsItems.COGS_POP]: 8064350,
    [PbsItems.GENDER_PAY_GAP_TOTAL_COMP]: 7043878,
    [PbsItems.NON_REGULAR_TYPE_EMP]: 6547137,
    [PbsItems.REGULAR_TYPE_EMP]: 6547137,
    [PbsItems.FTE]: 6547137,
    [PbsItems.PM0214_AvgEmp45HrsOTperMth]: 7063154,
    [PbsItems.PM0215_MonthlyOT]: 7062210,
    [PbsItems.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE]: 8105176,
    [PbsItems.PM0217_StatutoryAnnualPaidLeave]: 8105176,
    [PbsItems.ANNUAL_PAID_LEAVES_TAKEN]: 8105176,
    [PbsItems.PAID_LEAVES_TAKEN]: 8105176,
    [PbsItems.DAYS_OF_REMOTE_WORK]: 8109902,
    [PbsItems.OVERTIME_PAY]: 8077818,
    [PbsItems.TRAVEL_AND_COMMUTING]: 8077818,
    [PbsItems.EMPLOYER_STATUTORY_PAYMENT]: 8077818,
    [PERIOD_TOTAL]: 6826931,
    [IntercomArticleKeys.FILTER_TRAY]: 5685680,
    [IntercomArticleKeys.DATA_VIEW]: 5685717,
    [IntercomArticleKeys.BENCHMARK]: 6169046,
    [IntercomArticleKeys.FORECAST]: 6961743,
    [IntercomArticleKeys.SEGMENT]: 6147673,
    [IntercomArticleKeys.EXPORT]: 8026031,
  };

  private panalytIntercomKey = 'qcxorkyv';

  private initIntercomIfNeeded() {
    IntercomLoader.load({ providerKey: this.panalytIntercomKey, setState: () => {} });
  }

  public getCorrespondingArticleIdFromIntercomKey = (intercomKey: string): number => {
    return (
      this.intercomKeyToArticleIdMap[intercomKey as keyof typeof this.intercomKeyToArticleIdMap] ??
      this.defaultIntercomArticleid
    );
  };

  public startTour(tourId: number) {
    this.initIntercomIfNeeded();
    window.Intercom('startTour', tourId);
  }

  public showArticleInIntercom = (intercomKey: string) => {
    this.initIntercomIfNeeded();
    window.Intercom('showArticle', this.getCorrespondingArticleIdFromIntercomKey(intercomKey));
  };

  private isPublicRoute = (): boolean => {
    return publicRoutes.some((route) => route.path?.includes(window.location.pathname));
  };

  public showOrHideDefaultIntercomIcon = () => {
    if (window.Intercom) {
      if (this.isPublicRoute()) {
        window.Intercom('update', {
          hide_default_launcher: false,
        });
      } else {
        window.Intercom('update', {
          hide_default_launcher: true,
        });
      }
    }
  };
}
