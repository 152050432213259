import * as Sentry from '@sentry/react';

export const trackError = (errorMsg: string, contexts: Record<string, any> = {}, tags: Record<string, any> = {}) => {
  if (Sentry) {
    const domain = localStorage.getItem('DOMAIN');

    Sentry.withScope((scope) => {
      scope.setTag('panalyt.domain', domain ?? 'UNKNOWN');
      scope.setTag('panalyt.errorType', 'general');
      scope.setTags(tags);

      Sentry.captureException(new Error(errorMsg), {
        contexts,
      });
    });
  }
};
