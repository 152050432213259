import { PbsItems } from '../../../pages/dashboard/balance-sheet/types';

export const ivs2023EnglishMetricAliases: Partial<Record<PbsItems, string>> = {
  [PbsItems.ACTUAL_TENURE]: 'Tenure until promotion',
  [PbsItems.ATTRITION]: '1yr attrition',
};

export const ivs2023JapaneseMetricAliases: Partial<Record<PbsItems, string>> = {
  [PbsItems.ACTUAL_TENURE]: '管理職への昇級期間',
  [PbsItems.ATTRITION]: '1年目離職率',
};
