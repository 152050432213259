import { observer } from 'mobx-react';
import React from 'react';
import { Redirect } from 'react-router';
import LoadingOverlay from '../../common/components/utils/LoadingOverlay';
import { rootStore } from '../../common/store/root-store';

export const InitialDependenciesGuard: React.FC<React.PropsWithChildren<unknown>> = observer(({ children }) => {
  const { initialDependenciesReady: InitialDependenciesReady, couldNotFetchFrontendStartupData } =
    rootStore.initialDependencyStore;
  if (couldNotFetchFrontendStartupData) {
    return <Redirect to="/setup-error" />;
  }

  if (!InitialDependenciesReady()) {
    return <LoadingOverlay />;
  } else {
    return <>{children}</>;
  }
});
