import isEqual from 'lodash.isequal';
import { action, autorun, computed, observable } from 'mobx';
import { FilterValue } from 'react-table';
import { DataFieldWithDataType } from '../../../../common-types';
import { $HierarchicalFilterSeparator$, ApiMasterDataQueryFilterItem } from '../../api/api-interfaces';
import { DataFields, DataTypes, Operations } from '../../constants/constants';
import { dashboardConfigurations } from '../../dashboard/dashboard-configurations';
import { toggleFilterFromGivenFilters } from '../../filter/utils';
import { localStore } from '../../local-store';
import { convertUndefinedFilterValuesToNull } from '../../utilFunctions/formatters';
import { isHierarchical } from '../../utilFunctions/utils';
import { isCheckedCallback } from '../filter-tray/filter-tray-utils';
import { FilterOnChangeProps } from '../filter-tray/FilterTrayItem';

export interface SegmentationConfig {
  segmentDimension: DataFieldWithDataType | undefined;
  segments: ApiMasterDataQueryFilterItem[];
}

export class SegmentationStore {
  public constructor() {
    let firstRun = true;
    autorun(() => {
      const storeData = JSON.stringify({ segmentsLevel1: this.segmentsLevel1, segmentsLevel2: this.segmentsLevel2 });
      if (!firstRun) {
        localStore.set('segmentationStore', storeData);
      }
      firstRun = false;
    });
  }

  @observable
  public segmentsLevel1: ApiMasterDataQueryFilterItem[] = [];

  @observable
  public segmentsLevel2: ApiMasterDataQueryFilterItem[] = [];

  public MAX_ALLOWED_SEGMENTS: number = 40;

  @computed
  public get segmentLevel1Dimension(): DataFieldWithDataType | undefined {
    return this.segmentsLevel1.length
      ? {
          dataType: this.segmentsLevel1[0].dataType as DataTypes,
          dataField: this.segmentsLevel1[0].property as DataFields,
        }
      : undefined;
  }

  @computed
  public get selectedSegmentLevel1Values(): FilterValue[] {
    return this.segmentsLevel1.map((s) => s.values);
  }

  @action
  public resetSegments = (segments: ApiMasterDataQueryFilterItem[]) => {
    segments.splice(0, segments.length);
  };

  @computed
  public get segmentLevel2Dimension(): DataFieldWithDataType | undefined {
    return this.segmentsLevel2.length
      ? {
          dataType: this.segmentsLevel2[0].dataType as DataTypes,
          dataField: this.segmentsLevel2[0].property as DataFields,
        }
      : undefined;
  }

  @computed
  public get selectedSegmentLevel2Values(): FilterValue[] {
    return this.segmentsLevel2.map((s) => s.values);
  }

  private replaceArrayContentsInPlace = (oldArray: any[], newArray: any[]) => {
    oldArray.splice(0, oldArray.length);
    oldArray.push(...newArray);
  };

  @action
  public toggleSegment = (segment: ApiMasterDataQueryFilterItem, segments: ApiMasterDataQueryFilterItem[]) => {
    const isParent = (parentValues: string[], descendantValues: string[]) => {
      /**  TODO: This function assumes the typical hierarchical filter structure
        where the values array contains the entire string separated hierarchy
        This therefore doesn't work for Hierarchical age filter (where the
        hierarchy is generated on the frontend) because in that case the
        filter doesn't contain the full hierarchy
        So this will need to be fixed to support our typical behaviour of allowing
        different level segments as long as they aren't in the same hierarchy
        Currently, the case where you apply a new age segment from a different level
        doesn't reach here as the segment property is different(AGE vs AGE_GROUP)
      */
      if (descendantValues.length <= parentValues.length) {
        // Cannot be parent if its at same level.
        // This also covers case where parentValues = descendantValues
        return false;
      } else {
        // We get the descendant subArray with the same # levels as parentValues.
        // If the subArray and parentValues are the same, it means
        // there is a parent-descendant relationship
        const numLevelsOfParent = parentValues.length;
        const parentOfDescendant = descendantValues.slice(0, numLevelsOfParent);
        return isEqual(parentValues, parentOfDescendant);
      }
    };

    const newSegments = [...segments].filter((s) => {
      // some values are strings and numbers (gender), other objects/array (location)
      if (
        isHierarchical({ dataType: s.dataType as DataTypes, dataField: s.property as DataFields }) &&
        isHierarchical({ dataType: segment.dataType as DataTypes, dataField: segment.property as DataFields })
      ) {
        const isParentOrChild = isParent(segment.values[0], s.values[0]) || isParent(s.values[0], segment.values[0]);
        return !isParentOrChild;
      }
      return true;
    });

    this.replaceArrayContentsInPlace(segments, newSegments);
    if (segments.length) {
      const currentProperty = segments[0].property;

      if (segment.property !== currentProperty) {
        this.replaceArrayContentsInPlace(segments, []);
      }
    }
    toggleFilterFromGivenFilters(segment, segments, segments.length);
  };

  @action
  public applySegmentation = (
    data: FilterOnChangeProps,
    propertyBase: DataFieldWithDataType,
    segments: ApiMasterDataQueryFilterItem[]
  ) => {
    const applySegmentationDefault = () => {
      if (data.filterValues) {
        data.filterValues.forEach((f) => this.toggleSegment(f, segments));
      } else {
        const segment: ApiMasterDataQueryFilterItem = this.getFilterFromFilterData(data, propertyBase);
        this.toggleSegment(segment, segments);
      }
    };

    switch (propertyBase.dataType) {
      case DataTypes.EMPLOYEE:
      case DataTypes.APPLICATION:
      case DataTypes.JOB:
      case DataTypes.RECRUITERJOBS:
      case DataTypes.OFFER:
        applySegmentationDefault();
        break;
      default:
        console.error(`DataType ${propertyBase.dataType} not supported in the Segmentation Tray`);
    }
  };

  @action
  public applyMultipleSegmentation = (
    data: FilterOnChangeProps[],
    propertyBase: DataFieldWithDataType,
    segments: ApiMasterDataQueryFilterItem[]
  ) => {
    const multipleSegments: ApiMasterDataQueryFilterItem[] = data.flatMap((filter) => {
      return filter.filterValues ?? [this.getFilterFromFilterData(filter, propertyBase)];
    });
    multipleSegments.forEach((s) => this.toggleSegment(s, segments));
  };

  private filterQuery = (propertyBase: DataFieldWithDataType, values: any[]): ApiMasterDataQueryFilterItem => {
    return {
      property: propertyBase.dataField,
      operation: Operations.EQUAL,
      values,
      dataType: propertyBase.dataType,
    };
  };
  private getFilterFromFilterData = (
    data: FilterOnChangeProps,
    propertyBase: DataFieldWithDataType
  ): ApiMasterDataQueryFilterItem => {
    let filterValue;
    if (isHierarchical(propertyBase)) {
      filterValue = data.value.split($HierarchicalFilterSeparator$).map((val) => {
        return convertUndefinedFilterValuesToNull(val);
      });
    } else {
      filterValue = convertUndefinedFilterValuesToNull(data.value);
    }
    return this.filterQuery(propertyBase, [filterValue]);
  };

  public isCheckedCallback = (
    data: FilterOnChangeProps,
    dimension: DataFieldWithDataType,
    segments: ApiMasterDataQueryFilterItem[]
  ) => {
    return isCheckedCallback(data, { dimension, filters: segments });
  };

  public isSegmentationEnabled = () => true;

  public showSegmentation = (dashboardPage: string | undefined) => {
    return (
      dashboardPage &&
      dashboardConfigurations.isSegmentationAllowedforDbPage(dashboardPage) &&
      this.isSegmentationEnabled()
    );
  };
}
