import { action, computed, observable } from 'mobx';
import moment, { Moment } from 'moment';
import {
  endOfDate,
  getDatesInRange,
  getRawBenchmarkGranularity,
  getSamePrecedingPeriodTimeSliderConfig,
  startOfDate,
} from '../../common/filter/utils';
import { CompanySettingsStore } from '../company-settings-store';
import { DataTypes } from '../constants/constants';
import { Granularity, Months } from '../date-manager/date-manager-constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { EmployeeDataStore } from '../employee-data/employee-data-store';
import { TimeSliderConfig } from '../filter/filter-store';
import { getApplicableVersionForDate, getPastDatesFromDate } from '../filter/utils';
import { trackTimeSliderValueApply } from '../helpers/trackers/timeSliderTracker';
import { localStore } from '../local-store';
import { DomainDependencyStore } from '../startup/domain-dependency-store';
import { deepClone } from '../utilFunctions/utils';

export interface TimeSliderPreset {
  id: string;
  value: string;
  labelKey: string;
  startDate: string;
  endDate: string;
}

export class TimeSliderStore {
  @computed
  public get selectedTimeSiderPreset(): TimeSliderPreset {
    return this.timeSliderPresets.find((p) => p.id === this.selectedTimeSliderPresetId) as TimeSliderPreset;
  }

  @observable
  public timeSliderConfig: TimeSliderConfig = {
    startDate: '',
    endDate: '',
    granularity: Granularity.MONTH,
    rangeStartDate: null,
    rangeEndDate: null,
    specificPeriodBenchmarkStartDate: null,
    specificPeriodBenchmarkEndDate: null,
    firstMonthOfYear: Months.January,
  };

  @observable
  public initialTimeSliderConfig: TimeSliderConfig | null = null;

  @observable
  public localTimeSliderValues = ['', ''];

  @observable
  public timeSliderPresets: TimeSliderPreset[] = [];

  @observable
  public selectedTimeSliderPresetId: string = 'allTime';

  private companySettingsStore: CompanySettingsStore;
  private domainDependencyStore: DomainDependencyStore;
  private employeeDataStore: EmployeeDataStore;

  constructor(
    companySettingsStore: CompanySettingsStore,
    domainDependencyStore: DomainDependencyStore,
    employeeDataStore: EmployeeDataStore
  ) {
    this.companySettingsStore = companySettingsStore;
    this.domainDependencyStore = domainDependencyStore;
    this.employeeDataStore = employeeDataStore;
  }

  @action
  public setSelectedTimeSliderPresetId = (newPreset: string) => (this.selectedTimeSliderPresetId = newPreset);

  @action
  public setTimeSliderConfig = (newTimeSliderConfig: Partial<TimeSliderConfig>) => {
    this.timeSliderConfig = { ...this.timeSliderConfig, ...newTimeSliderConfig };
  };

  @action
  public setInitialTimeSliderConfig = (newTimeSliderConfig: TimeSliderConfig) => {
    this.initialTimeSliderConfig = newTimeSliderConfig;
  };

  public getOldestStartDate = (): string => {
    let { oldestStartDate: rawOldestStartDate } = this.employeeDataStore;
    const configuredTimeSliderRangeStart = this.companySettingsStore.configuredTimeSliderRangeStart();
    if (!rawOldestStartDate) {
      throw new Error('Oldest start date not found');
    }
    let oldestStartDate = rawOldestStartDate;
    if (configuredTimeSliderRangeStart) {
      oldestStartDate = configuredTimeSliderRangeStart;
    } else if (moment().diff(dateManagerService.parseApiDate(oldestStartDate), Granularity.MONTH) > 60) {
      oldestStartDate = dateManagerService.formatDateApi(
        moment().subtract(5, Granularity.YEAR).endOf(Granularity.MONTH)
      );
    }
    return oldestStartDate;
  };

  public getFirstDateOfYear = (date: Moment) => {
    const firstMonthOfYear = this.timeSliderConfig.firstMonthOfYear;
    if (date.clone().month(firstMonthOfYear).isSameOrBefore(date, Granularity.MONTH)) {
      return date.clone().month(firstMonthOfYear).startOf(Granularity.MONTH);
    } else {
      return date.clone().subtract(1, Granularity.YEAR).month(firstMonthOfYear).startOf(Granularity.MONTH);
    }
  };

  public getApplicableGlobalStartDateFromDate = (date: string) => {
    const { granularity: currentGranularity, rangeStartDate: rangeStart, firstMonthOfYear } = this.timeSliderConfig;
    if (rangeStart) {
      const rangeStartDate = dateManagerService.parseApiDate(rangeStart);
      const unitStartDate = startOfDate(dateManagerService.parseApiDate(date), currentGranularity, firstMonthOfYear);
      const adjustedStartDate = unitStartDate.isBefore(rangeStartDate, 'day') ? rangeStartDate : unitStartDate;
      const startDate = dateManagerService.formatDateApi(adjustedStartDate);
      return startDate;
    }
    return date;
  };

  public getApplicableGlobalEndDateFromDate = (date: string) => {
    const { granularity: currentGranularity, rangeEndDate: rangeEnd, firstMonthOfYear } = this.timeSliderConfig;
    if (rangeEnd) {
      const rangeEndDate = dateManagerService.parseApiDate(rangeEnd);
      const unitEndDate = endOfDate(dateManagerService.parseApiDate(date), currentGranularity, firstMonthOfYear);
      const adjustedEndDate = unitEndDate.isAfter(rangeEndDate, 'day') ? rangeEndDate : unitEndDate;
      const endDate = dateManagerService.formatDateApi(adjustedEndDate);
      return endDate;
    }
    return date;
  };

  @action
  public resetTimeslider = () => {
    this.timeSliderConfig = {
      ...this.initialTimeSliderConfig,
    } as TimeSliderConfig;
    this.setInitialLocalTimeSliderConfig();
  };

  @action
  public updateTimeSliderLocally = (startDate: string, endDate: string) => {
    this.localTimeSliderValues = [startDate, endDate];
  };

  private getTimeSliderConfigFromLocalStore = () => {
    const configFromLocalStorage = localStore.get('filterStore');
    const savedTimeSliderConfig: TimeSliderConfig | undefined = JSON.parse(
      configFromLocalStorage ?? '{}'
    ).timeSliderConfig;
    const localStoreHasValidTimeSliderConfig =
      !!savedTimeSliderConfig && this.isValidTimeSliderConfig(savedTimeSliderConfig);
    if (localStoreHasValidTimeSliderConfig) {
      return savedTimeSliderConfig;
    } else {
      return null;
    }
  };

  private getInitialTimeSliderConfig = (latestVersion: string) => {
    this.setTimeSliderPresetsFromLastVersion(latestVersion);
    const oldestStartDate = this.getOldestStartDate();
    const { formatDateApi, parseApiDate } = dateManagerService;
    const rangeStartDate = parseApiDate(oldestStartDate);
    const unitStartDate = getPastDatesFromDate(parseApiDate(latestVersion), 11, Granularity.MONTH).startOf(
      Granularity.MONTH
    );
    const adjustedStartDate = unitStartDate.isBefore(rangeStartDate, 'day') ? rangeStartDate : unitStartDate;
    const startDate = formatDateApi(adjustedStartDate);
    const initialTimeSliderConfig = {
      startDate,
      endDate: latestVersion,
      granularity: Granularity.MONTH,
      rangeStartDate: oldestStartDate,
      rangeEndDate: latestVersion,
      specificPeriodBenchmarkStartDate: null,
      specificPeriodBenchmarkEndDate: null,
      firstMonthOfYear: this.companySettingsStore.firstMonthOfYear(),
    };
    const { startDate: specificPeriodBenchmarkStartDate, endDate: specificPeriodBenchmarkEndDate } =
      getSamePrecedingPeriodTimeSliderConfig(initialTimeSliderConfig);
    const initialTimeSliderConfigWithSpecificPeriodBenchmarks = {
      ...initialTimeSliderConfig,
      specificPeriodBenchmarkEndDate,
      specificPeriodBenchmarkStartDate,
    };
    return initialTimeSliderConfigWithSpecificPeriodBenchmarks;
  };

  @action
  public setUpTimeSlider = async (dataType: DataTypes = DataTypes.EMPLOYEE) => {
    const latestVersion = (this.domainDependencyStore.getAllLatestVersions() || []).find(
      (entry) => entry.dataType.valueOf() === dataType.valueOf()
    )?.version;

    if (!latestVersion) {
      console.error(`getLatestVersion failed for dataType ${dataType}`);
      return;
    }
    this.setTimeSliderPresetsFromLastVersion(latestVersion);
    const initialTimeSliderConfig = this.getInitialTimeSliderConfig(latestVersion);
    this.setInitialTimeSliderConfig(deepClone(initialTimeSliderConfig));
    const localStoreTimeSliderConfig = this.getTimeSliderConfigFromLocalStore();
    const newTimeSliderConfig: TimeSliderConfig = {
      ...(localStoreTimeSliderConfig ?? deepClone(initialTimeSliderConfig)),
      rangeStartDate: initialTimeSliderConfig.rangeStartDate,
      rangeEndDate: initialTimeSliderConfig.rangeEndDate,
      firstMonthOfYear: this.companySettingsStore.firstMonthOfYear(),
    };
    this.setTimeSliderConfig(newTimeSliderConfig);
    this.setInitialLocalTimeSliderConfig();
  };

  @action
  public updateTimeSlider = (start: string, end: string, granularity?: Granularity) => {
    this.updateTimeSliderLocally(start, end);
    this.updateTimeSliderGlobally(start, end, granularity);
  };

  // Since we need to do both together, is it okay to group different actions together ??
  @action
  public updateTimeSliderGlobally = (start: string, end: string, granularity?: Granularity) => {
    const {
      granularity: currentGranularity,
      rangeStartDate: rangeStart,
      rangeEndDate: rangeEnd,
    } = this.timeSliderConfig;
    if (rangeStart && rangeEnd) {
      const startDate = this.getApplicableGlobalStartDateFromDate(start);
      const endDate = this.getApplicableGlobalEndDateFromDate(end);
      this.setTimeSliderConfig({
        startDate,
        endDate,
      });
      trackTimeSliderValueApply({
        startDate,
        endDate,
      });
    }
  };

  @action
  public onChangeGranularity = (granularity: Granularity, maxTimeSliderIntervals?: number) => {
    const { startDate, endDate, rangeEndDate, firstMonthOfYear } = this.timeSliderConfig;
    // some custom logic for start date here, might need to think it through when we add more granularities
    // right now we want new start date to be the end of the first full week in the current month

    const latestVersion = rangeEndDate as string;
    let newStartDate: string;
    const startDateObj = dateManagerService.parseApiDate(startDate);
    const endDateObj = dateManagerService.parseApiDate(endDate);
    if (granularity === 'week') {
      if (startDateObj.isSame(startOfDate(startDateObj.clone(), granularity, firstMonthOfYear), 'day')) {
        newStartDate = getApplicableVersionForDate(startDateObj.clone(), firstMonthOfYear, granularity, latestVersion);
      } else {
        newStartDate = getApplicableVersionForDate(
          startDateObj.clone().add(1, granularity),
          firstMonthOfYear,
          granularity,
          latestVersion
        );
      }
    } else {
      newStartDate = getApplicableVersionForDate(startDateObj, firstMonthOfYear, granularity, latestVersion);
    }

    let newEndDate = getApplicableVersionForDate(endDateObj.clone(), firstMonthOfYear, granularity, latestVersion);
    this.setTimeSliderConfig({ granularity });

    // adjust if range exceeds maxTimeSliderIntervals
    const numIntervals = getDatesInRange({
      ...this.timeSliderConfig,
      startDate: newStartDate,
      endDate: newEndDate,
      granularity,
    }).length;
    if (maxTimeSliderIntervals && numIntervals > maxTimeSliderIntervals) {
      newStartDate = getApplicableVersionForDate(
        dateManagerService
          .parseApiDate(newEndDate)
          .subtract(maxTimeSliderIntervals - 1, getRawBenchmarkGranularity(granularity)),
        firstMonthOfYear
      );
    }
    this.updateTimeSliderGlobally(newStartDate, newEndDate);
    this.updateTimeSliderLocally(newStartDate, newEndDate);
  };

  @action
  public setTimeSliderPresetsFromLastVersion = (lastVersion: string) => {
    const { formatDateApi, parseApiDate } = dateManagerService;
    const firstDateOfFinancialYear = this.getFirstDateOfYear(parseApiDate(lastVersion));
    const firstDateOfLastFinancialYear = firstDateOfFinancialYear.clone().subtract(1, 'year').startOf('month');
    const endOfLastFinancialYear = firstDateOfLastFinancialYear.clone().add(11, 'month').endOf('month');
    const timeSliderPresets: TimeSliderPreset[] = [
      {
        id: 'today',
        value: 'today',
        labelKey: 'timeSlider.presets.today',
        startDate: lastVersion,
        endDate: lastVersion,
      },
      {
        id: 'thisMonth',
        value: 'thisMonth',
        labelKey: 'timeSlider.presets.thisMonth',
        startDate: formatDateApi(parseApiDate(lastVersion).startOf(Granularity.MONTH)),
        endDate: lastVersion,
      },
      {
        id: 'lastMonth',
        value: 'lastMonth',
        labelKey: 'timeSlider.presets.lastMonth',
        startDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 1, Granularity.MONTH).startOf(Granularity.MONTH)
        ),
        endDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 1, Granularity.MONTH).endOf(Granularity.MONTH)
        ),
      },
      {
        id: 'last3Months',
        value: 'last3Months',
        labelKey: 'timeSlider.presets.last3Months',
        startDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 2, Granularity.MONTH).startOf(Granularity.MONTH)
        ),
        endDate: lastVersion,
      },
      {
        id: 'last6Months',
        value: 'last6Months',
        labelKey: 'timeSlider.presets.last6Months',
        startDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 5, Granularity.MONTH).startOf(Granularity.MONTH)
        ),
        endDate: lastVersion,
      },
      {
        id: 'last12Months',
        value: 'last12Months',
        labelKey: 'timeSlider.presets.last12Months',
        startDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 11, Granularity.MONTH).startOf('month')
        ),
        endDate: lastVersion,
      },
      {
        id: 'ytd',
        value: 'ytd',
        labelKey: 'timeSlider.presets.ytd',
        startDate: formatDateApi(parseApiDate(lastVersion).startOf(Granularity.YEAR)),
        endDate: lastVersion,
      },
      {
        id: 'lastYear',
        value: 'lastYear',
        labelKey: 'timeSlider.presets.lastYear',
        startDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 1, Granularity.YEAR).startOf(Granularity.YEAR)
        ),
        endDate: formatDateApi(
          getPastDatesFromDate(parseApiDate(lastVersion), 1, Granularity.YEAR).endOf(Granularity.YEAR)
        ),
      },
      {
        id: 'financialYear',
        value: 'financialYear',
        labelKey: 'timeSlider.presets.financialYear',
        startDate: formatDateApi(firstDateOfFinancialYear),
        endDate: lastVersion,
      },
      {
        id: 'lastFinancialYear',
        value: 'lastFinancialYear',
        labelKey: 'timeSlider.presets.lastFinancialYear',
        startDate: formatDateApi(firstDateOfLastFinancialYear),
        endDate: formatDateApi(endOfLastFinancialYear),
      },
      {
        id: 'allTime',
        value: 'allTime',
        labelKey: 'timeSlider.presets.allTime',
        startDate: this.getOldestStartDate(),
        endDate: lastVersion,
      },
    ];
    this.timeSliderPresets = timeSliderPresets;
  };

  private setInitialLocalTimeSliderConfig = () => {
    this.updateTimeSliderLocally(
      this.getApplicableGlobalEndDateFromDate(this.timeSliderConfig.startDate),
      this.getApplicableGlobalEndDateFromDate(this.timeSliderConfig.endDate)
    );
  };

  private isValidTimeSliderConfig = (tsc: TimeSliderConfig) => {
    const { startDate, endDate, granularity, rangeStartDate, rangeEndDate } = tsc;
    return (
      Object.values(Granularity).includes(granularity) &&
      [startDate, endDate, rangeStartDate, rangeEndDate].every((d) => d && dateManagerService.parseApiDate(d).isValid())
    );
  };
}
