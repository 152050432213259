import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  RadialLinearScale,
  ScatterController,
  Title,
  Tooltip,
} from 'chart.js';
import datalabels, { Context } from 'chartjs-plugin-datalabels';
import { ChartTypes } from '../constants/constants';
import { DataLabelDefaults } from './DatalabelDefaults';

const getChartTypeFromContext: (context: Context) => ChartTypes = (context: Context) => {
  const { type } = context.chart.config;
  return type as ChartTypes;
};

const setChartjsDefaults = () => {
  Chart.defaults.maintainAspectRatio = false;
  Chart.defaults.responsive = true;

  Chart.register(
    ArcElement,
    LineElement,
    BarElement,
    PointElement,
    BarController,
    DoughnutController,
    LineController,
    PieController,
    ScatterController,
    CategoryScale,
    LinearScale,
    LogarithmicScale,
    RadialLinearScale,
    Decimation,
    Filler,
    Legend,
    Title,
    Tooltip
  );

  Chart.defaults.datasets.line.tension = 0.4;
  Chart.defaults.responsive = true;
  Chart.defaults.maintainAspectRatio = false;
  Chart.defaults.resizeDelay = 10;
  Chart.defaults.plugins.legend.display = false;
  Chart.register(datalabels);
  if (Chart.defaults.plugins.datalabels) {
    // Display the datalabels
    Chart.defaults.plugins.datalabels.display = false;

    // Orientation of the Data Labels only for normal bar charts must be in the sideways orientation
    Chart.defaults.plugins.datalabels.rotation = (context: Context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].rotation(context);
    };

    // Background color should be transparent unless it's a line chart with 1 line.
    // In that case we need to have a box with the same color as the line chart.
    Chart.defaults.plugins.datalabels.backgroundColor = (context: Context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].backgroundColor(context);
    };

    // Color of the text Logic here.
    // Doughnut charts : All should be white
    // Bar Charts: White and Inside when 30 % of max value , else it should be Black and outside
    // Line Charts: All the labels are white with a backgroundColor similar to that of the color of the line
    // Stacked Bar Charts: All labels should be inside and aligned in the middle (don't show any 0s)
    // Stacked Line Charts: All labels are in black (0s are removed for stacked charts)
    // Horizontal Bar Charts: Remove all labels that are less than 10% of the max Value. Otherwise follow bar chart rules

    Chart.defaults.plugins.datalabels.color = (context: Context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].color(context);
    };

    Chart.defaults.plugins.datalabels.formatter = (value, context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].formatter(value, context);
    };

    Chart.defaults.plugins.datalabels.align = (context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].align(context);
    };
    Chart.defaults.plugins.datalabels.anchor = (context: Context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].anchor(context);
    };
    Chart.defaults.plugins.datalabels.offset = (context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].offset(context);
    };
    Chart.defaults.plugins.datalabels.borderRadius = (context) => {
      const chartType = getChartTypeFromContext(context);
      return DataLabelDefaults[chartType].borderRadius(context);
    };
  }
};

export default setChartjsDefaults;
