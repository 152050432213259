import memoize from 'fast-memoize';
import isEqual from 'lodash.isequal';
import { action, observable } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import { MetricGroup, NewApiAliasForDataField, NewApiAliasForMetricGroup } from '../api/api-interfaces';
import { CachedAliasService } from '../api/cached-alias-service';
import { Domains, Languages } from '../constants/constants';

export class AliasStore {
  @observable
  public aliasesForDataField: NewApiAliasForDataField[] = [];

  @observable
  public aliasesForMetricGroup: NewApiAliasForMetricGroup[] = [];

  @observable
  public aliasesLoaded = false;

  public getAliasTextForField = memoize(
    (
      dataFieldWithDataType: DataFieldWithDataType,
      language: Languages = Languages.JP,
      aliases: NewApiAliasForDataField[] = this.aliasesForDataField
    ) => {
      const alias = aliases.find((a) => isEqual(a.dataFieldWithDataType, dataFieldWithDataType));
      return alias ? this.getAliasForFieldText(alias, language) : null;
    }
  );

  public getAliasTextForMetricGroup = memoize(
    (
      metricGroup: MetricGroup,
      language: Languages = Languages.JP,
      aliases: NewApiAliasForMetricGroup[] = this.aliasesForMetricGroup
    ) => {
      const alias = aliases.find((a) => isEqual(a.metricGroup, metricGroup));
      return alias ? this.getAliasForMetricText(alias, language) : null;
    }
  );

  public getAliasForFieldText = memoize((alias: NewApiAliasForDataField, language: Languages = Languages.JP) => {
    return alias.translations.find((t) => t.language === language)?.text;
  });

  public getAliasForMetricText = memoize((alias: NewApiAliasForMetricGroup, language: Languages = Languages.JP) => {
    return alias.translations.find((t) => t.language === language)?.text;
  });

  private cachedAliasService: CachedAliasService;

  constructor(cachedAliasService: CachedAliasService) {
    this.cachedAliasService = cachedAliasService;
  }

  @action
  public async loadAliases(domain: Domains) {
    this.cachedAliasService.clearCache();
    this.aliasesForDataField = await this.cachedAliasService.getAliasesForDataFields(domain);
    this.aliasesForMetricGroup = await this.cachedAliasService.getAliasesForMetricGroup(domain);
    this.aliasesLoaded = true;
  }
}
