import { DataFieldWithDataType } from '../../../common-types';
import i18n from '../../../i18n';
import {
  $UndefinedValueIndicator$,
  ApiQuestionAnswerDataField,
  SEGMENT_OTHERS_INDICATOR,
  UndefinedDisplayKey,
} from '../api/api-interfaces';
import { Granularity } from '../date-manager/date-manager-constants';
import {
  ApplicationStatusValues,
  GenderValues,
  LocalOrNonLocalValues,
  ManagerOrIcValues,
  MartialStatusValues,
  RegionalOrLocalValues,
  RegretNonRegretValues,
} from './constant-backend-values';
import {
  ApplicationDataFields,
  AttritionPredictionDataFields,
  ComputedEmployeeFields,
  DataFields,
  DataTypes,
  EmployeeDataFields,
  EvaluationDataFields,
  FinancialDataFields,
  InterviewDataFields,
  JobDataFields,
  ManagerEnpsDataFields,
  OfferDataFields,
  OnaChatOrEmailDataFields,
  Operations,
  PayrollDataFields,
  RecruiterJobsDataFields,
  TimeAndAttendanceMonthlyDataFields,
} from './constants';

export const UndefinedValue = `${$UndefinedValueIndicator$}${i18n.t(UndefinedDisplayKey)}`;

export const SegmentOthersString = `${SEGMENT_OTHERS_INDICATOR}${i18n.t(
  'common:commonValues.misc.segmentationOthers'
)}`;

export const apiEvaluationDataFieldTranslationMap: Record<EvaluationDataFields, string> = {
  EVALUATION_ID: i18n.t('common:api.masterDataField.evaluationId'),
  EMPLOYEE_ID: i18n.t('common:api.masterDataField.employeeId'),
  EVALUATION_AS_OF: i18n.t('common:api.masterDataField.evaluationAsOf'),
  EVALUATION_CYCLE_NAME: i18n.t('common:api.masterDataField.evaluationCycleName'),
  EVALUATION_CYCLE_TYPE: i18n.t('common:api.masterDataField.evaluationCycleType'),
  EVALUATION_CYCLE_VIZ_TYPE: i18n.t('common:api.masterDataField.evaluationCycleVizType'),
  EVALUATION_SCORE: i18n.t('common:api.masterDataField.evaluationScore'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
  // EVALUATION: i18n.t('common:api.masterDataField.evaluation'),
};

export const apiJobDataFieldTranslationMap: Record<JobDataFields, string> = {
  RECRUITMENT_CATEGORY_HIERARCHICAL: i18n.t('common:api.masterDataField.recruitmentCategory'),
  CUSTOM_FIELD_1: i18n.t('common:api.masterDataField.customField1'),
  CUSTOM_FIELD_2: i18n.t('common:api.masterDataField.customField2'),
  CLOSED_AT: i18n.t('common:api.masterDataField.closedAt'),
  JOB_AGE: i18n.t('common:api.masterDataField.jobAge'),
  JOB_ID: i18n.t('common:api.masterDataField.jobId'),
  JOB_NAME: i18n.t('common:api.masterDataField.jobName'),
  JOB_NAME_LEVEL_1: i18n.t('common:api.masterDataField.jobNameLevel1'),
  JOB_NAME_LEVEL_10: i18n.t('common:api.masterDataField.jobNameLevel10'),
  JOB_NAME_LEVEL_2: i18n.t('common:api.masterDataField.jobNameLevel2'),
  JOB_NAME_LEVEL_3: i18n.t('common:api.masterDataField.jobNameLevel3'),
  JOB_NAME_LEVEL_4: i18n.t('common:api.masterDataField.jobNameLevel4'),
  JOB_NAME_LEVEL_5: i18n.t('common:api.masterDataField.jobNameLevel5'),
  JOB_NAME_LEVEL_6: i18n.t('common:api.masterDataField.jobNameLevel6'),
  JOB_NAME_LEVEL_7: i18n.t('common:api.masterDataField.jobNameLevel7'),
  JOB_NAME_LEVEL_8: i18n.t('common:api.masterDataField.jobNameLevel8'),
  JOB_NAME_LEVEL_9: i18n.t('common:api.masterDataField.jobNameLevel9'),
  JOB_GRADE: i18n.t('common:api.masterDataField.jobGrade'),
  JOB_GRADE_LEVEL_1: i18n.t('common:api.masterDataField.jobGradeLevel1'),
  JOB_GRADE_LEVEL_10: i18n.t('common:api.masterDataField.jobGradeLevel10'),
  JOB_GRADE_LEVEL_2: i18n.t('common:api.masterDataField.jobGradeLevel2'),
  JOB_GRADE_LEVEL_3: i18n.t('common:api.masterDataField.jobGradeLevel3'),
  JOB_GRADE_LEVEL_4: i18n.t('common:api.masterDataField.jobGradeLevel4'),
  JOB_GRADE_LEVEL_5: i18n.t('common:api.masterDataField.jobGradeLevel5'),
  JOB_GRADE_LEVEL_6: i18n.t('common:api.masterDataField.jobGradeLevel6'),
  JOB_GRADE_LEVEL_7: i18n.t('common:api.masterDataField.jobGradeLevel7'),
  JOB_GRADE_LEVEL_8: i18n.t('common:api.masterDataField.jobGradeLevel8'),
  JOB_GRADE_LEVEL_9: i18n.t('common:api.masterDataField.jobGradeLevel9'),
  ORGANIZATION: i18n.t('common:api.masterDataField.organization'),
  ORGANIZATION_LEVEL_1: i18n.t('common:api.masterDataField.organizationLevel1'),
  ORGANIZATION_LEVEL_10: i18n.t('common:api.masterDataField.organizationLevel10'),
  ORGANIZATION_LEVEL_2: i18n.t('common:api.masterDataField.organizationLevel2'),
  ORGANIZATION_LEVEL_3: i18n.t('common:api.masterDataField.organizationLevel3'),
  ORGANIZATION_LEVEL_4: i18n.t('common:api.masterDataField.organizationLevel4'),
  ORGANIZATION_LEVEL_5: i18n.t('common:api.masterDataField.organizationLevel5'),
  ORGANIZATION_LEVEL_6: i18n.t('common:api.masterDataField.organizationLevel6'),
  ORGANIZATION_LEVEL_7: i18n.t('common:api.masterDataField.organizationLevel7'),
  ORGANIZATION_LEVEL_8: i18n.t('common:api.masterDataField.organizationLevel8'),
  ORGANIZATION_LEVEL_9: i18n.t('common:api.masterDataField.organizationLevel9'),
  FUNCTION: i18n.t('common:api.masterDataField.function'),
  FUNCTION_LEVEL_1: i18n.t('common:api.masterDataField.functionLevel1'),
  FUNCTION_LEVEL_10: i18n.t('common:api.masterDataField.functionLevel10'),
  FUNCTION_LEVEL_2: i18n.t('common:api.masterDataField.functionLevel2'),
  FUNCTION_LEVEL_3: i18n.t('common:api.masterDataField.functionLevel3'),
  FUNCTION_LEVEL_4: i18n.t('common:api.masterDataField.functionLevel4'),
  FUNCTION_LEVEL_5: i18n.t('common:api.masterDataField.functionLevel5'),
  FUNCTION_LEVEL_6: i18n.t('common:api.masterDataField.functionLevel6'),
  FUNCTION_LEVEL_7: i18n.t('common:api.masterDataField.functionLevel7'),
  FUNCTION_LEVEL_8: i18n.t('common:api.masterDataField.functionLevel8'),
  FUNCTION_LEVEL_9: i18n.t('common:api.masterDataField.functionLevel9'),
  LOCATION: i18n.t('common:api.masterDataField.location'),
  LOCATION_LEVEL_1: i18n.t('common:api.masterDataField.locationLevel1'),
  LOCATION_LEVEL_10: i18n.t('common:api.masterDataField.locationLevel10'),
  LOCATION_LEVEL_2: i18n.t('common:api.masterDataField.locationLevel2'),
  LOCATION_LEVEL_3: i18n.t('common:api.masterDataField.locationLevel3'),
  LOCATION_LEVEL_4: i18n.t('common:api.masterDataField.locationLevel4'),
  LOCATION_LEVEL_5: i18n.t('common:api.masterDataField.locationLevel5'),
  LOCATION_LEVEL_6: i18n.t('common:api.masterDataField.locationLevel6'),
  LOCATION_LEVEL_7: i18n.t('common:api.masterDataField.locationLevel7'),
  LOCATION_LEVEL_8: i18n.t('common:api.masterDataField.locationLevel8'),
  LOCATION_LEVEL_9: i18n.t('common:api.masterDataField.locationLevel9'),
  OFFICE: i18n.t('common:api.masterDataField.office'),
  OFFICE_LEVEL_1: i18n.t('common:api.masterDataField.office1'),
  OFFICE_LEVEL_10: i18n.t('common:api.masterDataField.office10'),
  OFFICE_LEVEL_2: i18n.t('common:api.masterDataField.office2'),
  OFFICE_LEVEL_3: i18n.t('common:api.masterDataField.office3'),
  OFFICE_LEVEL_4: i18n.t('common:api.masterDataField.office4'),
  OFFICE_LEVEL_5: i18n.t('common:api.masterDataField.office5'),
  OFFICE_LEVEL_6: i18n.t('common:api.masterDataField.office6'),
  OFFICE_LEVEL_7: i18n.t('common:api.masterDataField.office7'),
  OFFICE_LEVEL_8: i18n.t('common:api.masterDataField.office8'),
  OFFICE_LEVEL_9: i18n.t('common:api.masterDataField.office9'),
  OPENED_AT: i18n.t('common:api.masterDataField.openedAt'),
  RECRUITMENT_CATEGORY: i18n.t('common:api.masterDataField.recruitmentCategory'),
  STATUS: i18n.t('common:api.masterDataField.status'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
};

export const apiApplicationDataFieldTranslationMap: Record<ApplicationDataFields, string> = {
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
  JOB_ID: i18n.t('common:api.masterDataField.jobId'),
  STATUS: i18n.t('common:api.masterDataField.status'),
  OFFICE: i18n.t('common:api.masterDataField.office'),
  LOCATION: i18n.t('common:api.masterDataField.location'),
  ORGANIZATION: i18n.t('common:api.masterDataField.organization'),
  JOB_NAME: i18n.t('common:api.masterDataField.jobName'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  APPLICATION_AGE: i18n.t('common:api.masterDataField.applicationAge'),
  APPLICATION_CURRENT_STAGE: i18n.t('common:api.masterDataField.applicationCurrentStage'),
  APPLICATION_CURRENT_STAGE_LEVEL_1: i18n.t('common:api.masterDataField.applicationCurrentStageLevel1'),
  APPLICATION_CURRENT_STAGE_LEVEL_2: i18n.t('common:api.masterDataField.applicationCurrentStageLevel2'),
  APPLICATION_CURRENT_STAGE_LEVEL_3: i18n.t('common:api.masterDataField.applicationCurrentStageLevel3'),
  APPLICATION_CURRENT_STAGE_OUTCOME: i18n.t('common:api.masterDataField.applicationCurrentStageOutcome'),
  APPLICATION_CURRENT_STAGE_LEVEL1_OUTCOME: i18n.t('common:api.masterDataField.applicationCurrentStageLevel1Outcome'),
  APPLICATION_CURRENT_STAGE_LEVEL2_OUTCOME: i18n.t('common:api.masterDataField.applicationCurrentStageLevel2Outcome'),
  APPLICATION_CURRENT_STAGE_LEVEL3_OUTCOME: i18n.t('common:api.masterDataField.applicationCurrentStageLevel3Outcome'),
  APPLICATION_ID: i18n.t('common:api.masterDataField.applicationId'),
  APPLIED_AT: i18n.t('common:api.masterDataField.appliedAt'),
  CANDIDATE_EMAIL_ADDRESS: i18n.t('common:api.masterDataField.candidateEmailAddress'),
  CANDIDATE_FIRST_NAME: i18n.t('common:api.masterDataField.candidateFirstName'),
  CANDIDATE_ID: i18n.t('common:api.masterDataField.candidateId'),
  CANDIDATE_LAST_NAME: i18n.t('common:api.masterDataField.candidateLastName'),
  CURRENT_JOB_TITLE: i18n.t('common:api.masterDataField.currentJobTitle'),
  CURRENT_STAGE: i18n.t('common:api.masterDataField.currentStage'),
  REJECTED_AT: i18n.t('common:api.masterDataField.rejectedAt'),
  REJECTION_REASON: i18n.t('common:api.masterDataField.rejectionReason'),
  REJECTION_REASON_LEVEL_1: i18n.t('common:api.masterDataField.rejectionReasonLevel1'),
  REJECTION_REASON_LEVEL_2: i18n.t('common:api.masterDataField.rejectionReasonLevel2'),
  RESOLVED_AT: i18n.t('common:api.masterDataField.resolvedAt'),
  SOURCE: i18n.t('common:api.masterDataField.source'),
  SOURCE_ID: i18n.t('common:api.masterDataField.sourceId'),
  SOURCE_LEVEL_1: i18n.t('common:api.masterDataField.sourceLevel1'),
  SOURCE_LEVEL_10: i18n.t('common:api.masterDataField.sourceLevel10'),
  SOURCE_LEVEL_2: i18n.t('common:api.masterDataField.sourceLevel2'),
  SOURCE_LEVEL_3: i18n.t('common:api.masterDataField.sourceLevel3'),
  SOURCE_LEVEL_4: i18n.t('common:api.masterDataField.sourceLevel4'),
  SOURCE_LEVEL_5: i18n.t('common:api.masterDataField.sourceLevel5'),
  SOURCE_LEVEL_6: i18n.t('common:api.masterDataField.sourceLevel6'),
  SOURCE_LEVEL_7: i18n.t('common:api.masterDataField.sourceLevel7'),
  SOURCE_LEVEL_8: i18n.t('common:api.masterDataField.sourceLevel8'),
  SOURCE_LEVEL_9: i18n.t('common:api.masterDataField.sourceLevel9'),
  SOURCE_NAME: i18n.t('common:api.masterDataField.sourceName'),
  SOURCE_TYPE: i18n.t('common:api.masterDataField.sourceType'),
  STANDARDIZED_CURRENT_STAGE: i18n.t('common:api.masterDataField.standardizedCurrentStage'),
  STANDARDIZED_CURRENT_STAGE_OUTCOME: i18n.t('common:api.masterDataField.standardizedCurrentStageOutcome'),
  START_MONTH: i18n.t('common:api.masterDataField.startMonth'),
  START_YEAR: i18n.t('common:api.masterDataField.startYear'),
  DEGREE_TYPE: i18n.t('common:api.masterDataField.degreeType'),
  DEGREE_MAJOR: i18n.t('common:api.masterDataField.degreeMajor'),
  CUSTOM_FIELD_1: i18n.t('common:api.masterDataField.customField1'),
  CUSTOM_FILTER_1: i18n.t('common:api.masterDataField.customFilter1'),
  CUSTOM_FILTER_2: i18n.t('common:api.masterDataField.customFilter2'),
  CUSTOM_FILTER_3: i18n.t('common:api.masterDataField.customFilter3'),
  CUSTOM_FILTER_4: i18n.t('common:api.masterDataField.customFilter4'),
  CUSTOM_FILTER_5: i18n.t('common:api.masterDataField.customFilter5'),
};

const apiRecruiterDataFieldTranslationMap: Record<RecruiterJobsDataFields, string> = {
  RECRUITER_NAME: i18n.t('common:api.masterDataField.recruiterName'),
  RECRUITER_REAL_ID: i18n.t('common:api.masterDataField.recruiterRealId'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  RECRUITER_TEMPORALITY: i18n.t('common:api.masterDataField.versionId'),
  JOB_ID: i18n.t('common:api.masterDataField.jobId'),
  RECRUITER_ID: i18n.t('common:api.masterDataField.recruiterId'),
  RESPONSIBLE: i18n.t('common:api.masterDataField.responsible'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
};

const apiManagerEnpsDataFieldTranslationMap: Record<ManagerEnpsDataFields, string> = {
  ENPS_SCORE: i18n.t('common:api.masterDataField.enpsscore'),
  PND_VALUE: i18n.t('common:api.masterDataField.pndvalue'),
};

const apiEmployeeDataFieldTranslationMap: Record<EmployeeDataFields, string> = {
  BASE_BOARD_COMPENSATION: i18n.t('common:api.masterDataField.baseBoardCompensation'),
  VARIABLE_BOARD_COMPENSATION: i18n.t('common:api.masterDataField.variableBoardCompensation'),
  PND_VALUE: i18n.t('common:api.masterDataField.pndValue'),
  LOCAL_REVENUE_SALARY: i18n.t('common:api.masterDataField.localRevenueSalary'),
  INTERNAL_JOB_GRADE: i18n.t('common:api.masterDataField.internalJobGrade'),
  INTERNAL_JOB_TITLE: i18n.t('common:api.masterDataField.internalJobTitle'),
  DATE_OF_BIRTH_YEAR_MONTH: i18n.t('common:api.masterDataField.dateOfBirthYearMonth'),
  START_DATE_NORMALIZED: i18n.t('common:api.masterDataField.startDateNormalized'),
  START_DATE_NORMALIZED_YEAR_MONTH: i18n.t('common:api.masterDataField.startDateNormalizedYearMonth'),
  TERM_DATE_NORMALIZED: i18n.t('common:api.masterDataField.termDateNormalized'),
  TERM_DATE_NORMALIZED_YEAR_MONTH: i18n.t('common:api.masterDataField.termDateNormalizedYearMonth'),
  CALENDAR_YEAR: i18n.t('common:api.masterDataField.calendarYear'),
  CALENDAR_QUARTER: i18n.t('common:api.masterDataField.calendarQuarter'),
  FINANCIAL_YEAR: i18n.t('common:api.masterDataField.financialYear'),
  FINANCIAL_QUARTER: i18n.t('common:api.masterDataField.financialQuarter'),
  AGE: i18n.t('common:api.masterDataField.age'),
  AGE_GROUP: i18n.t('common:api.masterDataField.ageGroup'),
  ANNUAL_CASH_BONUS_1: i18n.t('common:api.masterDataField.annualCashBonus1'),
  ANNUAL_CASH_BONUS_1_CURRENCY: i18n.t('common:api.masterDataField.annualCashBonus1Currency'),
  ATTRITION_SCORE: i18n.t('common:api.masterDataField.attritionScore'),
  BASE_SALARY: i18n.t('common:api.masterDataField.baseSalary'),
  BASE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.baseSalaryCurrency'),
  BASE_SALARY_FREQUENCY: i18n.t('common:api.masterDataField.baseSalaryFrequency'),
  BASE_SALARY_PER_YEAR: i18n.t('common:api.masterDataField.baseSalaryPerYear'),
  CAREER_CHANGE_INTENTION: i18n.t('common:api.masterDataField.careerChangeIntention'),
  CAREER_COURSE: i18n.t('common:api.masterDataField.careerCourse'),
  CAREER_LADDER: i18n.t('common:api.masterDataField.careerLadder'),
  CLIENT_EFFECTIVE_DATE: i18n.t('common:api.masterDataField.clientEffectiveDate'),
  COMMUNICATION_SKILL_SCORE: i18n.t('common:api.masterDataField.communicationSkillScore'),
  COMMUNICATION_SKILL_SCORE_QUARTILE: i18n.t('common:api.masterDataField.communicationSkillScoreQuartile'),
  COMPANY_COUNTRY: i18n.t('common:api.masterDataField.companyCountry'),
  COMPANY_EMAIL: i18n.t('common:api.masterDataField.companyEmail'),
  COST_CENTER: i18n.t('common:api.masterDataField.costCenter'),
  CUSTOM_FIELD_1: i18n.t('common:api.masterDataField.customField1'),
  CUSTOM_FIELD_10: i18n.t('common:api.masterDataField.customField10'),
  CUSTOM_FIELD_10_LEVEL_1: i18n.t('common:api.masterDataField.customField10Level1'),
  CUSTOM_FIELD_10_LEVEL_10: i18n.t('common:api.masterDataField.customField10Level10'),
  CUSTOM_FIELD_10_LEVEL_2: i18n.t('common:api.masterDataField.customField10Level2'),
  CUSTOM_FIELD_10_LEVEL_3: i18n.t('common:api.masterDataField.customField10Level3'),
  CUSTOM_FIELD_10_LEVEL_4: i18n.t('common:api.masterDataField.customField10Level4'),
  CUSTOM_FIELD_10_LEVEL_5: i18n.t('common:api.masterDataField.customField10Level5'),
  CUSTOM_FIELD_10_LEVEL_6: i18n.t('common:api.masterDataField.customField10Level6'),
  CUSTOM_FIELD_10_LEVEL_7: i18n.t('common:api.masterDataField.customField10Level7'),
  CUSTOM_FIELD_10_LEVEL_8: i18n.t('common:api.masterDataField.customField10Level8'),
  CUSTOM_FIELD_10_LEVEL_9: i18n.t('common:api.masterDataField.customField10Level9'),
  CUSTOM_FIELD_1_LEVEL_1: i18n.t('common:api.masterDataField.customField1Level1'),
  CUSTOM_FIELD_1_LEVEL_10: i18n.t('common:api.masterDataField.customField1Level10'),
  CUSTOM_FIELD_1_LEVEL_2: i18n.t('common:api.masterDataField.customField1Level2'),
  CUSTOM_FIELD_1_LEVEL_3: i18n.t('common:api.masterDataField.customField1Level3'),
  CUSTOM_FIELD_1_LEVEL_4: i18n.t('common:api.masterDataField.customField1Level4'),
  CUSTOM_FIELD_1_LEVEL_5: i18n.t('common:api.masterDataField.customField1Level5'),
  CUSTOM_FIELD_1_LEVEL_6: i18n.t('common:api.masterDataField.customField1Level6'),
  CUSTOM_FIELD_1_LEVEL_7: i18n.t('common:api.masterDataField.customField1Level7'),
  CUSTOM_FIELD_1_LEVEL_8: i18n.t('common:api.masterDataField.customField1Level8'),
  CUSTOM_FIELD_1_LEVEL_9: i18n.t('common:api.masterDataField.customField1Level9'),
  CUSTOM_FIELD_2: i18n.t('common:api.masterDataField.customField2'),
  CUSTOM_FIELD_2_LEVEL_1: i18n.t('common:api.masterDataField.customField2Level1'),
  CUSTOM_FIELD_2_LEVEL_10: i18n.t('common:api.masterDataField.customField2Level10'),
  CUSTOM_FIELD_2_LEVEL_2: i18n.t('common:api.masterDataField.customField2Level2'),
  CUSTOM_FIELD_2_LEVEL_3: i18n.t('common:api.masterDataField.customField2Level3'),
  CUSTOM_FIELD_2_LEVEL_4: i18n.t('common:api.masterDataField.customField2Level4'),
  CUSTOM_FIELD_2_LEVEL_5: i18n.t('common:api.masterDataField.customField2Level5'),
  CUSTOM_FIELD_2_LEVEL_6: i18n.t('common:api.masterDataField.customField2Level6'),
  CUSTOM_FIELD_2_LEVEL_7: i18n.t('common:api.masterDataField.customField2Level7'),
  CUSTOM_FIELD_2_LEVEL_8: i18n.t('common:api.masterDataField.customField2Level8'),
  CUSTOM_FIELD_2_LEVEL_9: i18n.t('common:api.masterDataField.customField2Level9'),
  CUSTOM_FIELD_3: i18n.t('common:api.masterDataField.customField3'),
  CUSTOM_FIELD_3_LEVEL_1: i18n.t('common:api.masterDataField.customField3Level1'),
  CUSTOM_FIELD_3_LEVEL_10: i18n.t('common:api.masterDataField.customField3Level10'),
  CUSTOM_FIELD_3_LEVEL_2: i18n.t('common:api.masterDataField.customField3Level2'),
  CUSTOM_FIELD_3_LEVEL_3: i18n.t('common:api.masterDataField.customField3Level3'),
  CUSTOM_FIELD_3_LEVEL_4: i18n.t('common:api.masterDataField.customField3Level4'),
  CUSTOM_FIELD_3_LEVEL_5: i18n.t('common:api.masterDataField.customField3Level5'),
  CUSTOM_FIELD_3_LEVEL_6: i18n.t('common:api.masterDataField.customField3Level6'),
  CUSTOM_FIELD_3_LEVEL_7: i18n.t('common:api.masterDataField.customField3Level7'),
  CUSTOM_FIELD_3_LEVEL_8: i18n.t('common:api.masterDataField.customField3Level8'),
  CUSTOM_FIELD_3_LEVEL_9: i18n.t('common:api.masterDataField.customField3Level9'),
  CUSTOM_FIELD_4: i18n.t('common:api.masterDataField.customField4'),
  CUSTOM_FIELD_4_LEVEL_1: i18n.t('common:api.masterDataField.customField4Level1'),
  CUSTOM_FIELD_4_LEVEL_10: i18n.t('common:api.masterDataField.customField4Level10'),
  CUSTOM_FIELD_4_LEVEL_2: i18n.t('common:api.masterDataField.customField4Level2'),
  CUSTOM_FIELD_4_LEVEL_3: i18n.t('common:api.masterDataField.customField4Level3'),
  CUSTOM_FIELD_4_LEVEL_4: i18n.t('common:api.masterDataField.customField4Level4'),
  CUSTOM_FIELD_4_LEVEL_5: i18n.t('common:api.masterDataField.customField4Level5'),
  CUSTOM_FIELD_4_LEVEL_6: i18n.t('common:api.masterDataField.customField4Level6'),
  CUSTOM_FIELD_4_LEVEL_7: i18n.t('common:api.masterDataField.customField4Level7'),
  CUSTOM_FIELD_4_LEVEL_8: i18n.t('common:api.masterDataField.customField4Level8'),
  CUSTOM_FIELD_4_LEVEL_9: i18n.t('common:api.masterDataField.customField4Level9'),
  CUSTOM_FIELD_5: i18n.t('common:api.masterDataField.customField5'),
  CUSTOM_FIELD_5_LEVEL_1: i18n.t('common:api.masterDataField.customField5Level1'),
  CUSTOM_FIELD_5_LEVEL_10: i18n.t('common:api.masterDataField.customField5Level10'),
  CUSTOM_FIELD_5_LEVEL_2: i18n.t('common:api.masterDataField.customField5Level2'),
  CUSTOM_FIELD_5_LEVEL_3: i18n.t('common:api.masterDataField.customField5Level3'),
  CUSTOM_FIELD_5_LEVEL_4: i18n.t('common:api.masterDataField.customField5Level4'),
  CUSTOM_FIELD_5_LEVEL_5: i18n.t('common:api.masterDataField.customField5Level5'),
  CUSTOM_FIELD_5_LEVEL_6: i18n.t('common:api.masterDataField.customField5Level6'),
  CUSTOM_FIELD_5_LEVEL_7: i18n.t('common:api.masterDataField.customField5Level7'),
  CUSTOM_FIELD_5_LEVEL_8: i18n.t('common:api.masterDataField.customField5Level8'),
  CUSTOM_FIELD_5_LEVEL_9: i18n.t('common:api.masterDataField.customField5Level9'),
  CUSTOM_FIELD_6: i18n.t('common:api.masterDataField.customField6'),
  CUSTOM_FIELD_6_LEVEL_1: i18n.t('common:api.masterDataField.customField6Level1'),
  CUSTOM_FIELD_6_LEVEL_10: i18n.t('common:api.masterDataField.customField6Level10'),
  CUSTOM_FIELD_6_LEVEL_2: i18n.t('common:api.masterDataField.customField6Level2'),
  CUSTOM_FIELD_6_LEVEL_3: i18n.t('common:api.masterDataField.customField6Level3'),
  CUSTOM_FIELD_6_LEVEL_4: i18n.t('common:api.masterDataField.customField6Level4'),
  CUSTOM_FIELD_6_LEVEL_5: i18n.t('common:api.masterDataField.customField6Level5'),
  CUSTOM_FIELD_6_LEVEL_6: i18n.t('common:api.masterDataField.customField6Level6'),
  CUSTOM_FIELD_6_LEVEL_7: i18n.t('common:api.masterDataField.customField6Level7'),
  CUSTOM_FIELD_6_LEVEL_8: i18n.t('common:api.masterDataField.customField6Level8'),
  CUSTOM_FIELD_6_LEVEL_9: i18n.t('common:api.masterDataField.customField6Level9'),
  CUSTOM_FIELD_7: i18n.t('common:api.masterDataField.customField7'),
  CUSTOM_FIELD_7_LEVEL_1: i18n.t('common:api.masterDataField.customField7Level1'),
  CUSTOM_FIELD_7_LEVEL_10: i18n.t('common:api.masterDataField.customField7Level10'),
  CUSTOM_FIELD_7_LEVEL_2: i18n.t('common:api.masterDataField.customField7Level2'),
  CUSTOM_FIELD_7_LEVEL_3: i18n.t('common:api.masterDataField.customField7Level3'),
  CUSTOM_FIELD_7_LEVEL_4: i18n.t('common:api.masterDataField.customField7Level4'),
  CUSTOM_FIELD_7_LEVEL_5: i18n.t('common:api.masterDataField.customField7Level5'),
  CUSTOM_FIELD_7_LEVEL_6: i18n.t('common:api.masterDataField.customField7Level6'),
  CUSTOM_FIELD_7_LEVEL_7: i18n.t('common:api.masterDataField.customField7Level7'),
  CUSTOM_FIELD_7_LEVEL_8: i18n.t('common:api.masterDataField.customField7Level8'),
  CUSTOM_FIELD_7_LEVEL_9: i18n.t('common:api.masterDataField.customField7Level9'),
  CUSTOM_FIELD_8: i18n.t('common:api.masterDataField.customField8'),
  CUSTOM_FIELD_8_LEVEL_1: i18n.t('common:api.masterDataField.customField8Level1'),
  CUSTOM_FIELD_8_LEVEL_10: i18n.t('common:api.masterDataField.customField8Level10'),
  CUSTOM_FIELD_8_LEVEL_2: i18n.t('common:api.masterDataField.customField8Level2'),
  CUSTOM_FIELD_8_LEVEL_3: i18n.t('common:api.masterDataField.customField8Level3'),
  CUSTOM_FIELD_8_LEVEL_4: i18n.t('common:api.masterDataField.customField8Level4'),
  CUSTOM_FIELD_8_LEVEL_5: i18n.t('common:api.masterDataField.customField8Level5'),
  CUSTOM_FIELD_8_LEVEL_6: i18n.t('common:api.masterDataField.customField8Level6'),
  CUSTOM_FIELD_8_LEVEL_7: i18n.t('common:api.masterDataField.customField8Level7'),
  CUSTOM_FIELD_8_LEVEL_8: i18n.t('common:api.masterDataField.customField8Level8'),
  CUSTOM_FIELD_8_LEVEL_9: i18n.t('common:api.masterDataField.customField8Level9'),
  CUSTOM_FIELD_9: i18n.t('common:api.masterDataField.customField9'),
  CUSTOM_FIELD_9_LEVEL_1: i18n.t('common:api.masterDataField.customField9Level1'),
  CUSTOM_FIELD_9_LEVEL_10: i18n.t('common:api.masterDataField.customField9Level10'),
  CUSTOM_FIELD_9_LEVEL_2: i18n.t('common:api.masterDataField.customField9Level2'),
  CUSTOM_FIELD_9_LEVEL_3: i18n.t('common:api.masterDataField.customField9Level3'),
  CUSTOM_FIELD_9_LEVEL_4: i18n.t('common:api.masterDataField.customField9Level4'),
  CUSTOM_FIELD_9_LEVEL_5: i18n.t('common:api.masterDataField.customField9Level5'),
  CUSTOM_FIELD_9_LEVEL_6: i18n.t('common:api.masterDataField.customField9Level6'),
  CUSTOM_FIELD_9_LEVEL_7: i18n.t('common:api.masterDataField.customField9Level7'),
  CUSTOM_FIELD_9_LEVEL_8: i18n.t('common:api.masterDataField.customField9Level8'),
  CUSTOM_FIELD_9_LEVEL_9: i18n.t('common:api.masterDataField.customField9Level9'),
  CUSTOM_FILTER_1: i18n.t('common:api.masterDataField.customFilter1'),
  CUSTOM_FILTER_10: i18n.t('common:api.masterDataField.customFilter10'),
  CUSTOM_FILTER_11: i18n.t('common:api.masterDataField.customFilter11'),
  CUSTOM_FILTER_12: i18n.t('common:api.masterDataField.customFilter12'),
  CUSTOM_FILTER_13: i18n.t('common:api.masterDataField.customFilter13'),
  CUSTOM_FILTER_14: i18n.t('common:api.masterDataField.customFilter14'),
  CUSTOM_FILTER_15: i18n.t('common:api.masterDataField.customFilter15'),
  CUSTOM_FILTER_16: i18n.t('common:api.masterDataField.customFilter16'),
  CUSTOM_FILTER_17: i18n.t('common:api.masterDataField.customFilter17'),
  CUSTOM_FILTER_18: i18n.t('common:api.masterDataField.customFilter18'),
  CUSTOM_FILTER_19: i18n.t('common:api.masterDataField.customFilter19'),
  CUSTOM_FILTER_2: i18n.t('common:api.masterDataField.customFilter2'),
  CUSTOM_FILTER_20: i18n.t('common:api.masterDataField.customFilter20'),
  CUSTOM_FILTER_3: i18n.t('common:api.masterDataField.customFilter3'),
  CUSTOM_FILTER_4: i18n.t('common:api.masterDataField.customFilter4'),
  CUSTOM_FILTER_5: i18n.t('common:api.masterDataField.customFilter5'),
  CUSTOM_FILTER_6: i18n.t('common:api.masterDataField.customFilter6'),
  CUSTOM_FILTER_7: i18n.t('common:api.masterDataField.customFilter7'),
  CUSTOM_FILTER_8: i18n.t('common:api.masterDataField.customFilter8'),
  CUSTOM_FILTER_9: i18n.t('common:api.masterDataField.customFilter9'),
  DATE_OF_BIRTH: i18n.t('common:api.masterDataField.dateOfBirth'),
  DAYS_IN_COMPANY: i18n.t('common:api.masterDataField.daysInCompany'),
  DEPARTMENT: i18n.t('common:api.masterDataField.department'),
  DEPTH_OF_CONTROL: i18n.t('common:api.masterDataField.depthOfControl'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
  EFFECTIVE_MONTH: i18n.t('common:api.masterDataField.effectiveMonth'),
  EFFECTIVE_LEAVER_DATE: i18n.t('common:api.masterDataField.effectiveLeaverDate'),
  EFFECTIVE_LEAVER_DATE_NORMALIZED: i18n.t('common:api.masterDataField.effectiveLeaverDateNormalized'),
  EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH: i18n.t(
    'common:api.masterDataField.effectiveLeaverDateNormalizedYearMonth'
  ),
  EMPLOYEE_ID: i18n.t('common:api.masterDataField.employeeId'),
  EMPLOYING_ENTITY: i18n.t('common:api.masterDataField.employingEntity'),
  EMPLOYMENT_TEMPORALITY: i18n.t('common:api.masterDataField.employmentTemporality'),
  EMPLOYMENT_TYPE: i18n.t('common:api.masterDataField.employmentType'),
  EMPLOYMENT_TYPE_LEVEL_1: i18n.t('common:api.masterDataField.employmentTypeLevel1'),
  EMPLOYMENT_TYPE_LEVEL_10: i18n.t('common:api.masterDataField.employmentTypeLevel10'),
  EMPLOYMENT_TYPE_LEVEL_2: i18n.t('common:api.masterDataField.employmentTypeLevel2'),
  EMPLOYMENT_TYPE_LEVEL_3: i18n.t('common:api.masterDataField.employmentTypeLevel3'),
  EMPLOYMENT_TYPE_LEVEL_4: i18n.t('common:api.masterDataField.employmentTypeLevel4'),
  EMPLOYMENT_TYPE_LEVEL_5: i18n.t('common:api.masterDataField.employmentTypeLevel5'),
  EMPLOYMENT_TYPE_LEVEL_6: i18n.t('common:api.masterDataField.employmentTypeLevel6'),
  EMPLOYMENT_TYPE_LEVEL_7: i18n.t('common:api.masterDataField.employmentTypeLevel7'),
  EMPLOYMENT_TYPE_LEVEL_8: i18n.t('common:api.masterDataField.employmentTypeLevel8'),
  EMPLOYMENT_TYPE_LEVEL_9: i18n.t('common:api.masterDataField.employmentTypeLevel9'),
  FIRST_NAME: i18n.t('common:api.masterDataField.firstName'),
  FULL_NAME: i18n.t('common:api.masterDataField.fullName'),
  FTE_VALUE: i18n.t('common:api.masterDataField.fteValue'),
  FUNCTION: i18n.t('common:api.masterDataField.function'),
  FUNCTION_LEVEL_10: i18n.t('common:api.masterDataField.functionLevel10'),
  FUNCTION_LEVEL_1: i18n.t('common:api.masterDataField.functionLevel1'),
  FUNCTION_LEVEL_2: i18n.t('common:api.masterDataField.functionLevel2'),
  FUNCTION_LEVEL_3: i18n.t('common:api.masterDataField.functionLevel3'),
  FUNCTION_LEVEL_4: i18n.t('common:api.masterDataField.functionLevel4'),
  FUNCTION_LEVEL_5: i18n.t('common:api.masterDataField.functionLevel5'),
  FUNCTION_LEVEL_6: i18n.t('common:api.masterDataField.functionLevel6'),
  FUNCTION_LEVEL_7: i18n.t('common:api.masterDataField.functionLevel7'),
  FUNCTION_LEVEL_8: i18n.t('common:api.masterDataField.functionLevel8'),
  FUNCTION_LEVEL_9: i18n.t('common:api.masterDataField.functionLevel9'),
  GENDER: i18n.t('common:api.masterDataField.gender'),
  GENERAL_COGNITIVE_ABILITY_SCORE: i18n.t('common:api.masterDataField.generalCognitiveAbilityScore'),
  GENERAL_COGNITIVE_ABILITY_SCORE_QUARTILE: i18n.t('common:api.masterDataField.generalCognitiveAbilityScoreQuartile'),
  ICR: i18n.t('common:api.masterDataField.icr'),
  INTERNAL_TRANSFER_INTENTION: i18n.t('common:api.masterDataField.internalTransferIntention'),
  JOB_GRADE: i18n.t('common:api.masterDataField.jobGrade'),
  JOB_GRADE_LEVEL_10: i18n.t('common:api.masterDataField.jobGradeLevel10'),
  JOB_GRADE_LEVEL_1: i18n.t('common:api.masterDataField.jobGradeLevel1'),
  JOB_GRADE_LEVEL_2: i18n.t('common:api.masterDataField.jobGradeLevel2'),
  JOB_GRADE_LEVEL_3: i18n.t('common:api.masterDataField.jobGradeLevel3'),
  JOB_GRADE_LEVEL_4: i18n.t('common:api.masterDataField.jobGradeLevel4'),
  JOB_GRADE_LEVEL_5: i18n.t('common:api.masterDataField.jobGradeLevel5'),
  JOB_GRADE_LEVEL_6: i18n.t('common:api.masterDataField.jobGradeLevel6'),
  JOB_GRADE_LEVEL_7: i18n.t('common:api.masterDataField.jobGradeLevel7'),
  JOB_GRADE_LEVEL_8: i18n.t('common:api.masterDataField.jobGradeLevel8'),
  JOB_GRADE_LEVEL_9: i18n.t('common:api.masterDataField.jobGradeLevel9'),
  JOB_TITLE: i18n.t('common:api.masterDataField.jobTitle'),
  JOINING_AGE: i18n.t('common:api.masterDataField.joiningAge'),
  JOINING_AGE_GROUP: i18n.t('common:api.masterDataField.joiningAgeGroup'),
  LAST_NAME: i18n.t('common:api.masterDataField.lastName'),
  LOCAL_FIRST_NAME: i18n.t('common:api.masterDataField.localFirstName'),
  LOCAL_FIRST_NAME_PRONUNCIATION: i18n.t('common:api.masterDataField.localFirstNamePronunciation'),
  LOCAL_FIRST_NAME_PRONUNCIATION_2: i18n.t('common:api.masterDataField.localFirstNamePronunciation2'),
  LOCAL_FULL_NAME: i18n.t('common:api.masterDataField.localFullName'),
  LOCAL_FULL_NAME_PRONUNCIATION: i18n.t('common:api.masterDataField.localFullNamePronunciation'),
  LOCAL_FULL_NAME_PRONUNCIATION_2: i18n.t('common:api.masterDataField.localFullNamePronunciation2'),
  LOCAL_LAST_NAME: i18n.t('common:api.masterDataField.localLastName'),
  LOCAL_LAST_NAME_PRONUNCIATION: i18n.t('common:api.masterDataField.localLastNamePronunciation'),
  LOCAL_LAST_NAME_PRONUNCIATION_2: i18n.t('common:api.masterDataField.localLastNamePronunciation2'),
  LOCAL_OR_NON_LOCAL: i18n.t('common:api.masterDataField.localOrNonLocal'),
  LOCAL_SALARY: i18n.t('common:api.masterDataField.localSalary'),
  LOCAL_SALARY_CURRENCY: i18n.t('common:api.masterDataField.localSalaryCurrency'),
  LOCATION: i18n.t('common:api.masterDataField.location'),
  LOCATION_LEVEL_10: i18n.t('common:api.masterDataField.locationLevel10'),
  LOCATION_LEVEL_1: i18n.t('common:api.masterDataField.locationLevel1'),
  LOCATION_LEVEL_2: i18n.t('common:api.masterDataField.locationLevel2'),
  LOCATION_LEVEL_3: i18n.t('common:api.masterDataField.locationLevel3'),
  LOCATION_LEVEL_4: i18n.t('common:api.masterDataField.locationLevel4'),
  LOCATION_LEVEL_5: i18n.t('common:api.masterDataField.locationLevel5'),
  LOCATION_LEVEL_6: i18n.t('common:api.masterDataField.locationLevel6'),
  LOCATION_LEVEL_7: i18n.t('common:api.masterDataField.locationLevel7'),
  LOCATION_LEVEL_8: i18n.t('common:api.masterDataField.locationLevel8'),
  LOCATION_LEVEL_9: i18n.t('common:api.masterDataField.locationLevel9'),
  MANAGER_EMAIL: i18n.t('common:api.masterDataField.managerEmail'),
  MANAGER_FEEDBACK: i18n.t('common:api.masterDataField.managerFeedback'),
  MANAGER_ID: i18n.t('common:api.masterDataField.managerId'),
  MANAGER_OR_IC: i18n.t('common:api.masterDataField.managerOrIc'),
  MARITAL_STATUS: i18n.t('common:api.masterDataField.maritalStatus'),
  MEMBER_ID: i18n.t('common:api.masterDataField.memberId'),
  NATIONALITY_HIERARCHICAL: i18n.t('common:api.masterDataField.nationality'),
  NATIONALITY_HIERARCHICAL_LEVEL_10: i18n.t('common:api.masterDataField.nationalityLevel10'),
  NATIONALITY_HIERARCHICAL_LEVEL_1: i18n.t('common:api.masterDataField.nationalityLevel1'),
  NATIONALITY_HIERARCHICAL_LEVEL_2: i18n.t('common:api.masterDataField.nationalityLevel2'),
  NATIONALITY_HIERARCHICAL_LEVEL_3: i18n.t('common:api.masterDataField.nationalityLevel3'),
  NATIONALITY_HIERARCHICAL_LEVEL_4: i18n.t('common:api.masterDataField.nationalityLevel4'),
  NATIONALITY_HIERARCHICAL_LEVEL_5: i18n.t('common:api.masterDataField.nationalityLevel5'),
  NATIONALITY_HIERARCHICAL_LEVEL_6: i18n.t('common:api.masterDataField.nationalityLevel6'),
  NATIONALITY_HIERARCHICAL_LEVEL_7: i18n.t('common:api.masterDataField.nationalityLevel7'),
  NATIONALITY_HIERARCHICAL_LEVEL_8: i18n.t('common:api.masterDataField.nationalityLevel8'),
  NATIONALITY_HIERARCHICAL_LEVEL_9: i18n.t('common:api.masterDataField.nationalityLevel9'),
  ONBOARDING_SURVEY_SCORE: i18n.t('common:api.masterDataField.onboardingSurveyScore'),
  ONBOARDING_SURVEY_SCORE_GROUP: i18n.t('common:api.masterDataField.onboardingSurveyScoreGroup'),
  ORGANIZATION: i18n.t('common:api.masterDataField.organization'),
  ORGANIZATION_LEVEL_10: i18n.t('common:api.masterDataField.organizationLevel10'),
  ORGANIZATION_LEVEL_1: i18n.t('common:api.masterDataField.organizationLevel1'),
  ORGANIZATION_LEVEL_2: i18n.t('common:api.masterDataField.organizationLevel2'),
  ORGANIZATION_LEVEL_3: i18n.t('common:api.masterDataField.organizationLevel3'),
  ORGANIZATION_LEVEL_4: i18n.t('common:api.masterDataField.organizationLevel4'),
  ORGANIZATION_LEVEL_5: i18n.t('common:api.masterDataField.organizationLevel5'),
  ORGANIZATION_LEVEL_6: i18n.t('common:api.masterDataField.organizationLevel6'),
  ORGANIZATION_LEVEL_7: i18n.t('common:api.masterDataField.organizationLevel7'),
  ORGANIZATION_LEVEL_8: i18n.t('common:api.masterDataField.organizationLevel8'),
  ORGANIZATION_LEVEL_9: i18n.t('common:api.masterDataField.organizationLevel9'),
  PERFORMANCE_BONUS_CYCLE: i18n.t('common:api.masterDataField.performanceBonusCycle'),
  PERFORMANCE_CYCLE: i18n.t('common:api.masterDataField.performanceCycle'),
  PERFORMANCE_RATING: i18n.t('common:api.masterDataField.performanceRating'),
  PERFORMANCE_REGULAR_CYCLE: i18n.t('common:api.masterDataField.performanceRegularCycle'),
  POSITION: i18n.t('common:api.masterDataField.position'),
  POSITION_LEVEL_10: i18n.t('common:api.masterDataField.positionLevel10'),
  POSITION_LEVEL_1: i18n.t('common:api.masterDataField.positionLevel1'),
  POSITION_LEVEL_2: i18n.t('common:api.masterDataField.positionLevel2'),
  POSITION_LEVEL_3: i18n.t('common:api.masterDataField.positionLevel3'),
  POSITION_LEVEL_4: i18n.t('common:api.masterDataField.positionLevel4'),
  POSITION_LEVEL_5: i18n.t('common:api.masterDataField.positionLevel5'),
  POSITION_LEVEL_6: i18n.t('common:api.masterDataField.positionLevel6'),
  POSITION_LEVEL_7: i18n.t('common:api.masterDataField.positionLevel7'),
  POSITION_LEVEL_8: i18n.t('common:api.masterDataField.positionLevel8'),
  POSITION_LEVEL_9: i18n.t('common:api.masterDataField.positionLevel9'),
  PREFERRED_NAME: i18n.t('common:api.masterDataField.preferredName'),
  PRE_MERGER_ORGANIZATION: i18n.t('common:api.masterDataField.preMergerOrganization'),
  PRE_MERGER_ORGANIZATION_LEVEL_10: i18n.t('common:api.masterDataField.preMergerOrganizationLevel10'),
  PRE_MERGER_ORGANIZATION_LEVEL_1: i18n.t('common:api.masterDataField.preMergerOrganizationLevel1'),
  PRE_MERGER_ORGANIZATION_LEVEL_2: i18n.t('common:api.masterDataField.preMergerOrganizationLevel2'),
  PRE_MERGER_ORGANIZATION_LEVEL_3: i18n.t('common:api.masterDataField.preMergerOrganizationLevel3'),
  PRE_MERGER_ORGANIZATION_LEVEL_4: i18n.t('common:api.masterDataField.preMergerOrganizationLevel4'),
  PRE_MERGER_ORGANIZATION_LEVEL_5: i18n.t('common:api.masterDataField.preMergerOrganizationLevel5'),
  PRE_MERGER_ORGANIZATION_LEVEL_6: i18n.t('common:api.masterDataField.preMergerOrganizationLevel6'),
  PRE_MERGER_ORGANIZATION_LEVEL_7: i18n.t('common:api.masterDataField.preMergerOrganizationLevel7'),
  PRE_MERGER_ORGANIZATION_LEVEL_8: i18n.t('common:api.masterDataField.preMergerOrganizationLevel8'),
  PRE_MERGER_ORGANIZATION_LEVEL_9: i18n.t('common:api.masterDataField.preMergerOrganizationLevel9'),
  PULSE_SURVEY_CYCLE_1: i18n.t('common:api.masterDataField.pulseSurveyCycle1'),
  PULSE_SURVEY_CYCLE_2: i18n.t('common:api.masterDataField.pulseSurveyCycle2'),
  PULSE_SURVEY_CYCLE_3: i18n.t('common:api.masterDataField.pulseSurveyCycle3'),
  PULSE_SURVEY_SCORE_1: i18n.t('common:api.masterDataField.pulseSurveyScore1'),
  PULSE_SURVEY_SCORE_2: i18n.t('common:api.masterDataField.pulseSurveyScore2'),
  RECRUITMENT_CATEGORY: i18n.t('common:api.masterDataField.recruitmentCategory'),
  RECRUITMENT_CATEGORY_HIERARCHICAL: i18n.t('common:api.masterDataField.recruitmentCategory'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_10: i18n.t('common:api.masterDataField.recruitmentCategoryLevel10'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_1: i18n.t('common:api.masterDataField.recruitmentCategoryLevel1'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_2: i18n.t('common:api.masterDataField.recruitmentCategoryLevel2'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_3: i18n.t('common:api.masterDataField.recruitmentCategoryLevel3'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_4: i18n.t('common:api.masterDataField.recruitmentCategoryLevel4'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_5: i18n.t('common:api.masterDataField.recruitmentCategoryLevel5'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_6: i18n.t('common:api.masterDataField.recruitmentCategoryLevel6'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_7: i18n.t('common:api.masterDataField.recruitmentCategoryLevel7'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_8: i18n.t('common:api.masterDataField.recruitmentCategoryLevel8'),
  RECRUITMENT_CATEGORY_HIERARCHICAL_LEVEL_9: i18n.t('common:api.masterDataField.recruitmentCategoryLevel9'),
  REGIONAL_OR_LOCAL: i18n.t('common:api.masterDataField.regionalOrLocal'),
  REGRET_ATTRITION: i18n.t('common:api.masterDataField.regretAttrition'),
  REVENUE_SALARY: i18n.t('common:api.masterDataField.revenueSalary'),
  REVENUE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.revenueSalaryCurrency'),
  ROLE_RELATED_KNOWLEDGE_SCORE: i18n.t('common:api.masterDataField.roleRelatedKnowledgeScore'),
  ROLE_RELATED_KNOWLEDGE_SCORE_QUARTILE: i18n.t('common:api.masterDataField.roleRelatedKnowledgeScoreQuartile'),
  SOCIAL_TYPE: i18n.t('common:api.masterDataField.socialType'),
  SPAN_OF_CONTROL: i18n.t('common:api.masterDataField.spanOfControl'),
  STANDARDIZED_BASE_SALARY: i18n.t('common:api.masterDataField.standardizedBaseSalary'),
  STANDARDIZED_BASE_SALARY_PER_YEAR: i18n.t('common:api.masterDataField.standardizedBaseSalaryPerYear'),
  START_DATE: i18n.t('common:api.masterDataField.startDate'),
  SUPERIOR_ID: i18n.t('common:api.masterDataField.superiorId'),
  TENURE_GROUP: i18n.t('common:api.masterDataField.tenureGroup'),
  TERM_DATE: i18n.t('common:api.masterDataField.termDate'),
  TOTAL_AVERAGE_SCORE: i18n.t('common:api.masterDataField.totalAverageScore'),
  TOTAL_AVERAGE_SCORE_QUARTILE: i18n.t('common:api.masterDataField.totalAverageScoreQuartile'),
  TOTAL_COMPENSATION: i18n.t('common:api.masterDataField.totalCompensation'),
  TOTAL_COMPENSATION_CURRENCY: i18n.t('common:api.masterDataField.totalCompensationCurrency'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  VOLUNTARY_OR_INVOLUNTARY_ATTRITION: i18n.t('common:api.masterDataField.voluntaryOrInvoluntaryAttrition'),
  WORK_CONSIDERATION: i18n.t('common:api.masterDataField.workConsideration'),
  DEFINED_MANAGER: i18n.t('common:api.masterDataField.definedManager'),
  move_from: i18n.t('common:api.masterDataField.moveFrom'),
  move_to: i18n.t('common:api.masterDataField.moveTo'),
  PERMISSIONS: i18n.t('common:api.masterDataField.permissions'), //TODO: delete the whole field so no need for actual translation
  SUPERIOR_IDS: i18n.t('common:api.masterDataField.superiorIds'), //TODO: delete the whole field so no need for actual translation
  TENURE_IN_JOBGRADE_LEVEL_1: i18n.t('common:api.masterDataField.tenureInJobGradeLevel1'),
  TENURE_IN_JOBGRADE_LEVEL_2: i18n.t('common:api.masterDataField.tenureInJobGradeLevel2'),
  TENURE_IN_ORG_LEVEL_1: i18n.t('common:api.masterDataField.tenureInOrgLevel1'),
  TENURE_IN_ORG_LEVEL_2: i18n.t('common:api.masterDataField.tenureInOrgLevel2'),
  STATUTORY_ELIGIBILITY_FOR_PARENTAL_LEAVE: i18n.t('common:api.masterDataField.statutoryEligibilityForParentalLeave'),
  DISABILITY_SEVERITY: i18n.t('common:api.masterDataField.disabilitySeverity'),
  MANAGEMENT_POOL: i18n.t('common:api.masterDataField.managementPool'),
  YOUNGEST_CHILD_BIRTHDATE: i18n.t('common:api.masterDataField.youngestChildBirthDate'),
  LATEST_PARENTAL_LEAVE_STARTDATE: i18n.t('common:api.masterDataField.latestParentalLeaveStartDate'),
  TENURE_IN_PREVIOUS_JOBGRADE_LEVEL_1: i18n.t('common:api.masterDataField.tenureInPreviousJobGradeLevel1'),
  TENURE_AS_MANAGER: i18n.t('common:api.masterDataField.tenureAsManager'),
};

const apiAttritionPredictionDataFieldTranslationMap: Record<AttritionPredictionDataFields, string> = {
  EMPLOYEE_ID: i18n.t('common:api.masterDataField.employeeId'),
  EMPLOYEE_SCORE: i18n.t('common:api.masterDataField.employeeScore'),
  FEATURE_NAME: i18n.t('common:api.masterDataField.featureName'),
  FEATURE_SCORE: i18n.t('common:api.masterDataField.featureScore'),
  FEATURE_VALUE: i18n.t('common:api.masterDataField.featureValue'),
  FEATURE_UNIT: i18n.t('common:api.masterDataField.featureUnit'),
  RISK_PROFILE: i18n.t('common:api.masterDataField.riskProfile'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  HAS_MISSING_FEATURE: i18n.t('common:api.masterDataField.hasMissingFeature'),
};

const apiTimeAndAttendanceMonthlyDataFieldTranslationMap: Record<TimeAndAttendanceMonthlyDataFields, string> = {
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  EMPLOYEE_ID: i18n.t('common:api.masterDataField.employeeId'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
  NUM_DAYS_WORKED: i18n.t('common:api.masterDataField.numDaysWorked'),
  NUM_DAYS_REMOTE: i18n.t('common:api.masterDataField.numDaysRemote'),
  NUM_WORKING_DAYS: i18n.t('common:api.masterDataField.numWorkingDays'),
  NUM_LEAVES: i18n.t('common:api.masterDataField.numLeaves'),
  NUM_HOURS_WORKED: i18n.t('common:api.masterDataField.numHoursWorked'),
  NUM_OVERTIME_HOURS: i18n.t('common:api.masterDataField.numOvertimeHours'),
  NUM_DAYS_IN_OFFICE: i18n.t('common:api.masterDataField.numDaysInOffice'),
  NUM_HOURS_WORKING_DAY_OVERTIME: i18n.t('common:api.masterDataField.numHoursWorkingDayOvertime'),
  NUM_HOURS_WORKED_ON_LEGAL_HOLIDAY: i18n.t('common:api.masterDataField.numHoursWorkedOnLegalHoliday'),
  NUM_HOURS_WORKED_ON_HOLIDAY: i18n.t('common:api.masterDataField.numHoursWorkedOnHoliday'),
  PAID_LEAVES_BALANCE: i18n.t('common:api.masterDataField.paidLeavesBalance'),
  NUM_PAID_LEAVES_GRANTED: i18n.t('common:api.masterDataField.numPaidLeavesGranted'),
  NUM_PAID_LEAVES_TAKEN: i18n.t('common:api.masterDataField.numPaidLeavesTaken'),
  NUM_ANNUAL_PAID_LEAVES_TAKEN: i18n.t('common:api.masterDataField.numAnnualPaidLeavesTaken'),
  NUM_DAYS_ABSENTED: i18n.t('common:api.masterDataField.numDaysAbsented'),
  CUSTOM_FIELD_1: i18n.t('common:api.masterDataField.customField1'),
  CUSTOM_FIELD_2: i18n.t('common:api.masterDataField.customField2'),
  CUSTOM_FIELD_3: i18n.t('common:api.masterDataField.customField3'),
  CUSTOM_FIELD_4: i18n.t('common:api.masterDataField.customField4'),
  CUSTOM_FIELD_5: i18n.t('common:api.masterDataField.customField5'),
  NUM_WORKING_HOURS: i18n.t('common:api.masterDataField.numWorkingHours'),
  NUM_DAILY_WORKING_HOURS: i18n.t('common:api.masterDataField.numDailyWorkingHours'),
  NUM_PARENTAL_LEAVES_TAKEN: i18n.t('common:api.masterDataField.numParentalLeavesTaken'),
  LATEST_PARENTAL_LEAVE_STARTDATE: i18n.t('common:api.masterDataField.latestParentalLeaveStartDate'),
};

const apiQuestionAnswerDataFieldTranslationMap: Record<ApiQuestionAnswerDataField, string> = {
  IS_ANONYMOUS: i18n.t('common:api.masterDataField.isAnonymous'),
  QUESTION: i18n.t('common:api.masterDataField.question'),
  QUESTION_TYPE: i18n.t('common:api.masterDataField.questionType'),
  ANSWER: i18n.t('common:api.masterDataField.answer'),
  ANSWER_TYPE: i18n.t('common:api.masterDataField.answerType'),
  SENTIMENT: i18n.t('common:api.masterDataField.sentiment'),
  SCORE: i18n.t('common:api.masterDataField.score'),
  THEME_NAME: i18n.t('common:api.masterDataField.themeName'),
  START_DATE: i18n.t('common:api.masterDataField.startDate'),
  CYCLE: i18n.t('common:api.masterDataField.cycle'),
  CYCLE_END_DATE: i18n.t('common:api.masterDataField.cycleEndDate'),
  CYCLE_START_DATE: i18n.t('common:api.masterDataField.cycleStartDate'),
};

const apiOfferDataFieldTranslationMap: Record<OfferDataFields, string> = {
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  STATUS: i18n.t('common:api.masterDataField.status'),
  STARTS_AT: i18n.t('common:api.masterDataField.startsAt'),
  APPLICATION_ID: i18n.t('common:api.masterDataField.applicationId'),
  OFFER_ID: i18n.t('common:api.masterDataField.offerId'),
  JOB_ID: i18n.t('common:api.masterDataField.jobId'),
  CREATED_AT: i18n.t('common:api.masterDataField.createdAt'),
  RESOLVED_AT: i18n.t('common:api.masterDataField.resolvedAt'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
};

const apiInterviewDataFieldTranslationMap: Record<InterviewDataFields, string> = {
  INTERVIEW_ID: i18n.t('common:api.masterDataField.interviewId'),
  APPLICATION_ID: i18n.t('common:api.masterDataField.applicationId'),
  INTERVIEWER_ID: i18n.t('common:api.masterDataField.interviewerId'),
  INTERVIEWER_NAME: i18n.t('common:api.masterDataField.interviewerName'),
  SCORE: i18n.t('common:api.masterDataField.score'),
  DATE: i18n.t('common:api.masterDataField.date'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
};

const apiOnaEmailMetricsDataFieldTranslationMap: Record<OnaChatOrEmailDataFields, string> = {
  METRICID: i18n.t('common:api.masterDataField.metricId'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  AVERAGE_DAILY_VOLUME_MESSAGES: i18n.t('common:api.masterDataField.averageDailyVolumeMessages'),
  AVERAGE_RESPONSE_TIME: i18n.t('common:api.masterDataField.averageResponseTime'),
  AVERAGE_RESPONSE_TIME_PERSON1: i18n.t('common:api.masterDataField.averageResponseTimePerson1'),
  AVERAGE_RESPONSE_TIME_PERSON2: i18n.t('common:api.masterDataField.averageResponseTimePerson2'),
  COUNT_MESSAGES_PERSON_1: i18n.t('common:api.masterDataField.countMessagesPerson1'),
  COUNT_MESSAGES_PERSON_2: i18n.t('common:api.masterDataField.countMessagesPerson2'),
  COUNT_MESSAGES_TOTAL: i18n.t('common:api.masterDataField.countMessagesTotal'),
  DOMAIN_PERSON_1: i18n.t('common:api.masterDataField.domainPerson1'),
  DOMAIN_PERSON_2: i18n.t('common:api.masterDataField.domainPerson2'),
  GRANULARITY: i18n.t('common:api.masterDataField.granularity'),
  METRIC_ID: i18n.t('common:api.masterDataField.metricId'),
  PERCENTAGE_RESPONSE: i18n.t('common:api.masterDataField.percentageResponse'),
  PERCENTAGE_RESPONSE_PERSON1: i18n.t('common:api.masterDataField.percentageResponsePerson1'),
  PERCENTAGE_RESPONSE_PERSON2: i18n.t('common:api.masterDataField.percentageResponsePerson2'),
  PERSON1: i18n.t('common:api.masterDataField.person1'),
  PERSON2: i18n.t('common:api.masterDataField.person2'),
  RECIPROCITY_RATIO: i18n.t('common:api.masterDataField.reciprocityRatio'),
  COLLABORATION_SCORE: i18n.t('common:api.masterDataField.collaborationScore'),
  LAST_SENT_AT_BY_PERSON_1: i18n.t('common:api.masterDataField.lastSentAtByPerson1'),
  LAST_SENT_AT_BY_PERSON_2: i18n.t('common:api.masterDataField.lastSentAtByPerson2'),
};

const apiPayrollDataFieldTranslationMap: Record<PayrollDataFields, string> = {
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  EFFECTIVE_DATE: i18n.t('common:api.masterDataField.effectiveDate'),
  STANDARDIZED_REIMBURSEMENT_1: i18n.t('common:api.masterDataField.standardizedReimbursement1'),
  STANDARDIZED_REIMBURSEMENT_2: i18n.t('common:api.masterDataField.standardizedReimbursement2'),
  STANDARDIZED_REIMBURSEMENT_3: i18n.t('common:api.masterDataField.standardizedReimbursement3'),
  STANDARDIZED_REIMBURSEMENT_4: i18n.t('common:api.masterDataField.standardizedReimbursement4'),
  STANDARDIZED_REIMBURSEMENT_5: i18n.t('common:api.masterDataField.standardizedReimbursement5'),
  STANDARDIZED_REIMBURSEMENT_6: i18n.t('common:api.masterDataField.standardizedReimbursement6'),
  STANDARDIZED_REIMBURSEMENT_7: i18n.t('common:api.masterDataField.standardizedReimbursement7'),
  STANDARDIZED_REIMBURSEMENT_8: i18n.t('common:api.masterDataField.standardizedReimbursement8'),
  STANDARDIZED_REIMBURSEMENT_9: i18n.t('common:api.masterDataField.standardizedReimbursement9'),
  STANDARDIZED_REIMBURSEMENT_10: i18n.t('common:api.masterDataField.standardizedReimbursement10'),
  REIMBURSEMENT_1: i18n.t('common:api.masterDataField.reimbursement1'),
  REIMBURSEMENT_2: i18n.t('common:api.masterDataField.reimbursement2'),
  REIMBURSEMENT_3: i18n.t('common:api.masterDataField.reimbursement3'),
  REIMBURSEMENT_4: i18n.t('common:api.masterDataField.reimbursement4'),
  REIMBURSEMENT_5: i18n.t('common:api.masterDataField.reimbursement5'),
  REIMBURSEMENT_6: i18n.t('common:api.masterDataField.reimbursement6'),
  REIMBURSEMENT_7: i18n.t('common:api.masterDataField.reimbursement7'),
  REIMBURSEMENT_8: i18n.t('common:api.masterDataField.reimbursement8'),
  REIMBURSEMENT_9: i18n.t('common:api.masterDataField.reimbursement9'),
  REIMBURSEMENT_10: i18n.t('common:api.masterDataField.reimbursement10'),
  REIMBURSEMENT_11: i18n.t('common:api.masterDataField.reimbursement11'),
  REIMBURSEMENT_12: i18n.t('common:api.masterDataField.reimbursement12'),
  REIMBURSEMENT_13: i18n.t('common:api.masterDataField.reimbursement13'),
  REIMBURSEMENT_14: i18n.t('common:api.masterDataField.reimbursement14'),
  REIMBURSEMENT_15: i18n.t('common:api.masterDataField.reimbursement15'),
  REIMBURSEMENT_16: i18n.t('common:api.masterDataField.reimbursement16'),
  REIMBURSEMENT_17: i18n.t('common:api.masterDataField.reimbursement17'),
  REIMBURSEMENT_18: i18n.t('common:api.masterDataField.reimbursement18'),
  REIMBURSEMENT_19: i18n.t('common:api.masterDataField.reimbursement19'),
  REIMBURSEMENT_20: i18n.t('common:api.masterDataField.reimbursement20'),
  STANDARDIZED_EMPLOYEE_AWARD_1: i18n.t('common:api.masterDataField.standardizedEmployeeAward1'),
  STANDARDIZED_EMPLOYEE_AWARD_2: i18n.t('common:api.masterDataField.standardizedEmployeeAward2'),
  STANDARDIZED_EMPLOYEE_AWARD_3: i18n.t('common:api.masterDataField.standardizedEmployeeAward3'),
  STANDARDIZED_EMPLOYEE_AWARD_4: i18n.t('common:api.masterDataField.standardizedEmployeeAward4'),
  STANDARDIZED_EMPLOYEE_AWARD_5: i18n.t('common:api.masterDataField.standardizedEmployeeAward5'),
  STANDARDIZED_EMPLOYEE_AWARD_6: i18n.t('common:api.masterDataField.standardizedEmployeeAward6'),
  STANDARDIZED_EMPLOYEE_AWARD_7: i18n.t('common:api.masterDataField.standardizedEmployeeAward7'),
  STANDARDIZED_EMPLOYEE_AWARD_8: i18n.t('common:api.masterDataField.standardizedEmployeeAward8'),
  STANDARDIZED_EMPLOYEE_AWARD_9: i18n.t('common:api.masterDataField.standardizedEmployeeAward9'),
  STANDARDIZED_EMPLOYEE_AWARD_10: i18n.t('common:api.masterDataField.standardizedEmployeeAward10'),
  EMPLOYEE_AWARD_1: i18n.t('common:api.masterDataField.employeeAward1'),
  EMPLOYEE_AWARD_2: i18n.t('common:api.masterDataField.employeeAward2'),
  EMPLOYEE_AWARD_3: i18n.t('common:api.masterDataField.employeeAward3'),
  EMPLOYEE_AWARD_4: i18n.t('common:api.masterDataField.employeeAward4'),
  EMPLOYEE_AWARD_5: i18n.t('common:api.masterDataField.employeeAward5'),
  EMPLOYEE_AWARD_6: i18n.t('common:api.masterDataField.employeeAward6'),
  EMPLOYEE_AWARD_7: i18n.t('common:api.masterDataField.employeeAward7'),
  EMPLOYEE_AWARD_8: i18n.t('common:api.masterDataField.employeeAward8'),
  EMPLOYEE_AWARD_9: i18n.t('common:api.masterDataField.employeeAward9'),
  EMPLOYEE_AWARD_10: i18n.t('common:api.masterDataField.employeeAward10'),
  EMPLOYEE_AWARD_11: i18n.t('common:api.masterDataField.employeeAward11'),
  EMPLOYEE_AWARD_12: i18n.t('common:api.masterDataField.employeeAward12'),
  EMPLOYEE_AWARD_13: i18n.t('common:api.masterDataField.employeeAward13'),
  EMPLOYEE_AWARD_14: i18n.t('common:api.masterDataField.employeeAward14'),
  EMPLOYEE_AWARD_15: i18n.t('common:api.masterDataField.employeeAward15'),
  EMPLOYEE_AWARD_16: i18n.t('common:api.masterDataField.employeeAward16'),
  EMPLOYEE_AWARD_17: i18n.t('common:api.masterDataField.employeeAward17'),
  EMPLOYEE_AWARD_18: i18n.t('common:api.masterDataField.employeeAward18'),
  EMPLOYEE_AWARD_19: i18n.t('common:api.masterDataField.employeeAward19'),
  EMPLOYEE_AWARD_20: i18n.t('common:api.masterDataField.employeeAward20'),
  BOARD_COMPENSATION_1: i18n.t('common:api.masterDataField.boardCompensation1'),
  BOARD_COMPENSATION_2: i18n.t('common:api.masterDataField.boardCompensation2'),
  BOARD_COMPENSATION_3: i18n.t('common:api.masterDataField.boardCompensation3'),
  BOARD_COMPENSATION_4: i18n.t('common:api.masterDataField.boardCompensation4'),
  BOARD_COMPENSATION_5: i18n.t('common:api.masterDataField.boardCompensation5'),
  BOARD_COMPENSATION_6: i18n.t('common:api.masterDataField.boardCompensation6'),
  BOARD_COMPENSATION_7: i18n.t('common:api.masterDataField.boardCompensation7'),
  BOARD_COMPENSATION_8: i18n.t('common:api.masterDataField.boardCompensation8'),
  BOARD_COMPENSATION_9: i18n.t('common:api.masterDataField.boardCompensation9'),
  BOARD_COMPENSATION_10: i18n.t('common:api.masterDataField.boardCompensation10'),
  BOARD_COMPENSATION_11: i18n.t('common:api.masterDataField.boardCompensation11'),
  BOARD_COMPENSATION_12: i18n.t('common:api.masterDataField.boardCompensation12'),
  BOARD_COMPENSATION_13: i18n.t('common:api.masterDataField.boardCompensation13'),
  BOARD_COMPENSATION_14: i18n.t('common:api.masterDataField.boardCompensation14'),
  BOARD_COMPENSATION_15: i18n.t('common:api.masterDataField.boardCompensation15'),
  BOARD_COMPENSATION_16: i18n.t('common:api.masterDataField.boardCompensation16'),
  BOARD_COMPENSATION_17: i18n.t('common:api.masterDataField.boardCompensation17'),
  BOARD_COMPENSATION_18: i18n.t('common:api.masterDataField.boardCompensation18'),
  BOARD_COMPENSATION_19: i18n.t('common:api.masterDataField.boardCompensation19'),
  BOARD_COMPENSATION_20: i18n.t('common:api.masterDataField.boardCompensation20'),
  STANDARDIZED_BOARD_COMPENSATION_1: i18n.t('common:api.masterDataField.standardizedBoardCompensation1'),
  STANDARDIZED_BOARD_COMPENSATION_2: i18n.t('common:api.masterDataField.standardizedBoardCompensation2'),
  STANDARDIZED_BOARD_COMPENSATION_3: i18n.t('common:api.masterDataField.standardizedBoardCompensation3'),
  STANDARDIZED_BOARD_COMPENSATION_4: i18n.t('common:api.masterDataField.standardizedBoardCompensation4'),
  STANDARDIZED_BOARD_COMPENSATION_5: i18n.t('common:api.masterDataField.standardizedBoardCompensation5'),
  STANDARDIZED_BOARD_COMPENSATION_6: i18n.t('common:api.masterDataField.standardizedBoardCompensation6'),
  STANDARDIZED_BOARD_COMPENSATION_7: i18n.t('common:api.masterDataField.standardizedBoardCompensation7'),
  STANDARDIZED_BOARD_COMPENSATION_8: i18n.t('common:api.masterDataField.standardizedBoardCompensation8'),
  STANDARDIZED_BOARD_COMPENSATION_9: i18n.t('common:api.masterDataField.standardizedBoardCompensation9'),
  STANDARDIZED_BOARD_COMPENSATION_10: i18n.t('common:api.masterDataField.standardizedBoardCompensation10'),
  CUSTOM_EMPLOYER_CONTRIBUTION_1: i18n.t('common:api.masterDataField.customEmployerContribution1'),
  CUSTOM_EMPLOYER_CONTRIBUTION_2: i18n.t('common:api.masterDataField.customEmployerContribution2'),
  CUSTOM_EMPLOYER_CONTRIBUTION_3: i18n.t('common:api.masterDataField.customEmployerContribution3'),
  CUSTOM_EMPLOYER_CONTRIBUTION_4: i18n.t('common:api.masterDataField.customEmployerContribution4'),
  CUSTOM_EMPLOYER_CONTRIBUTION_5: i18n.t('common:api.masterDataField.customEmployerContribution5'),
  CUSTOM_EMPLOYER_CONTRIBUTION_6: i18n.t('common:api.masterDataField.customEmployerContribution6'),
  CUSTOM_EMPLOYER_CONTRIBUTION_7: i18n.t('common:api.masterDataField.customEmployerContribution7'),
  CUSTOM_EMPLOYER_CONTRIBUTION_8: i18n.t('common:api.masterDataField.customEmployerContribution8'),
  CUSTOM_EMPLOYER_CONTRIBUTION_9: i18n.t('common:api.masterDataField.customEmployerContribution9'),
  CUSTOM_EMPLOYER_CONTRIBUTION_10: i18n.t('common:api.masterDataField.customEmployerContribution10'),
  CUSTOM_EMPLOYER_CONTRIBUTION_11: i18n.t('common:api.masterDataField.customEmployerContribution11'),
  CUSTOM_EMPLOYER_CONTRIBUTION_12: i18n.t('common:api.masterDataField.customEmployerContribution12'),
  CUSTOM_EMPLOYER_CONTRIBUTION_13: i18n.t('common:api.masterDataField.customEmployerContribution13'),
  CUSTOM_EMPLOYER_CONTRIBUTION_14: i18n.t('common:api.masterDataField.customEmployerContribution14'),
  CUSTOM_EMPLOYER_CONTRIBUTION_15: i18n.t('common:api.masterDataField.customEmployerContribution15'),
  CUSTOM_EMPLOYER_CONTRIBUTION_16: i18n.t('common:api.masterDataField.customEmployerContribution16'),
  CUSTOM_EMPLOYER_CONTRIBUTION_17: i18n.t('common:api.masterDataField.customEmployerContribution17'),
  CUSTOM_EMPLOYER_CONTRIBUTION_18: i18n.t('common:api.masterDataField.customEmployerContribution18'),
  CUSTOM_EMPLOYER_CONTRIBUTION_19: i18n.t('common:api.masterDataField.customEmployerContribution19'),
  CUSTOM_EMPLOYER_CONTRIBUTION_20: i18n.t('common:api.masterDataField.customEmployerContribution20'),
  CUSTOM_EMPLOYER_CONTRIBUTION_1_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution1Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_2_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution2Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_3_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution3Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_4_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution4Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_5_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution5Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_6_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution6Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_7_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution7Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_8_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution8Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_9_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution9Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_10_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution10Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_11_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution11Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_12_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution12Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_13_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution13Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_14_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution14Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_15_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution15Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_16_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution16Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_17_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution17Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_18_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution18Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_19_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution19Currency'),
  CUSTOM_EMPLOYER_CONTRIBUTION_20_CURRENCY: i18n.t('common:api.masterDataField.customEmployerContribution20Currency'),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_1: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution1'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_2: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution2'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_3: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution3'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_4: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution4'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_5: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution5'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_6: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution6'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_7: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution7'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_8: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution8'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_9: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution9'
  ),
  STANDARDIZED_CUSTOM_EMPLOYER_CONTRIBUTION_10: i18n.t(
    'common:api.masterDataField.standardizedCustomEmployerContribution10'
  ),
  STANDARDIZED_CUSTOM_PAYROLL_COST_1: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost1'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_2: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost2'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_3: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost3'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_4: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost4'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_5: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost5'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_6: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost6'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_7: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost7'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_8: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost8'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_9: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost9'),
  STANDARDIZED_CUSTOM_PAYROLL_COST_10: i18n.t('common:api.masterDataField.standardizedCustomPayrollCost10'),
  CUSTOM_PAYROLL_COST_1: i18n.t('common:api.masterDataField.customPayrollCost1'),
  CUSTOM_PAYROLL_COST_2: i18n.t('common:api.masterDataField.customPayrollCost2'),
  CUSTOM_PAYROLL_COST_3: i18n.t('common:api.masterDataField.customPayrollCost3'),
  CUSTOM_PAYROLL_COST_4: i18n.t('common:api.masterDataField.customPayrollCost4'),
  CUSTOM_PAYROLL_COST_5: i18n.t('common:api.masterDataField.customPayrollCost5'),
  CUSTOM_PAYROLL_COST_6: i18n.t('common:api.masterDataField.customPayrollCost6'),
  CUSTOM_PAYROLL_COST_7: i18n.t('common:api.masterDataField.customPayrollCost7'),
  CUSTOM_PAYROLL_COST_8: i18n.t('common:api.masterDataField.customPayrollCost8'),
  CUSTOM_PAYROLL_COST_9: i18n.t('common:api.masterDataField.customPayrollCost9'),
  CUSTOM_PAYROLL_COST_10: i18n.t('common:api.masterDataField.customPayrollCost10'),
  CUSTOM_PAYROLL_COST_11: i18n.t('common:api.masterDataField.customPayrollCost11'),
  CUSTOM_PAYROLL_COST_12: i18n.t('common:api.masterDataField.customPayrollCost12'),
  CUSTOM_PAYROLL_COST_13: i18n.t('common:api.masterDataField.customPayrollCost13'),
  CUSTOM_PAYROLL_COST_14: i18n.t('common:api.masterDataField.customPayrollCost14'),
  CUSTOM_PAYROLL_COST_15: i18n.t('common:api.masterDataField.customPayrollCost15'),
  CUSTOM_PAYROLL_COST_16: i18n.t('common:api.masterDataField.customPayrollCost16'),
  CUSTOM_PAYROLL_COST_17: i18n.t('common:api.masterDataField.customPayrollCost17'),
  CUSTOM_PAYROLL_COST_18: i18n.t('common:api.masterDataField.customPayrollCost18'),
  CUSTOM_PAYROLL_COST_19: i18n.t('common:api.masterDataField.customPayrollCost19'),
  CUSTOM_PAYROLL_COST_20: i18n.t('common:api.masterDataField.customPayrollCost20'),
  CUSTOM_PAYROLL_COST_1_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost1Currency'),
  CUSTOM_PAYROLL_COST_2_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost2Currency'),
  CUSTOM_PAYROLL_COST_3_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost3Currency'),
  CUSTOM_PAYROLL_COST_4_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost4Currency'),
  CUSTOM_PAYROLL_COST_5_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost5Currency'),
  CUSTOM_PAYROLL_COST_6_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost6Currency'),
  CUSTOM_PAYROLL_COST_7_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost7Currency'),
  CUSTOM_PAYROLL_COST_8_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost8Currency'),
  CUSTOM_PAYROLL_COST_9_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost9Currency'),
  CUSTOM_PAYROLL_COST_10_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost10Currency'),
  CUSTOM_PAYROLL_COST_11_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost11Currency'),
  CUSTOM_PAYROLL_COST_12_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost12Currency'),
  CUSTOM_PAYROLL_COST_13_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost13Currency'),
  CUSTOM_PAYROLL_COST_14_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost14Currency'),
  CUSTOM_PAYROLL_COST_15_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost15Currency'),
  CUSTOM_PAYROLL_COST_16_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost16Currency'),
  CUSTOM_PAYROLL_COST_17_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost17Currency'),
  CUSTOM_PAYROLL_COST_18_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost18Currency'),
  CUSTOM_PAYROLL_COST_19_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost19Currency'),
  CUSTOM_PAYROLL_COST_20_CURRENCY: i18n.t('common:api.masterDataField.customPayrollCost20Currency'),
  STANDARDIZED_RECEIVED_BASE_SALARY: i18n.t('common:api.masterDataField.standardizedReceivedBaseSalary'),
  RECEIVED_BASE_SALARY: i18n.t('common:api.masterDataField.receivedBaseSalary'),
  STANDARDIZED_COMPUTED_ACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.standardizedComputedActualBaseSalary'),
  COMPUTED_ACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.computedActualBaseSalary'),
  CONTRACTUAL_SALARY_FREQUENCY: i18n.t('common:api.masterDataField.contractualSalaryFrequency'),
  ACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.actualBaseSalary'),
  ACTUAL_BASE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.actualBaseSalaryCurrency'),
  COMPUTED_ACTUAL_BASE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.computedActualBaseSalaryCurrency'),
  CONTRACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.contractualBaseSalary'),
  CONTRACTUAL_BASE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.contractualBaseSalaryCurrency'),
  CUSTOM_BONUS_1: i18n.t('common:api.masterDataField.customBonus1'),
  CUSTOM_BONUS_2: i18n.t('common:api.masterDataField.customBonus2'),
  CUSTOM_BONUS_3: i18n.t('common:api.masterDataField.customBonus3'),
  CUSTOM_BONUS_4: i18n.t('common:api.masterDataField.customBonus4'),
  CUSTOM_BONUS_5: i18n.t('common:api.masterDataField.customBonus5'),
  CUSTOM_BONUS_6: i18n.t('common:api.masterDataField.customBonus6'),
  CUSTOM_BONUS_7: i18n.t('common:api.masterDataField.customBonus7'),
  CUSTOM_BONUS_8: i18n.t('common:api.masterDataField.customBonus8'),
  CUSTOM_BONUS_9: i18n.t('common:api.masterDataField.customBonus9'),
  CUSTOM_BONUS_10: i18n.t('common:api.masterDataField.customBonus10'),
  CUSTOM_BONUS_11: i18n.t('common:api.masterDataField.customBonus11'),
  CUSTOM_BONUS_12: i18n.t('common:api.masterDataField.customBonus12'),
  CUSTOM_BONUS_13: i18n.t('common:api.masterDataField.customBonus13'),
  CUSTOM_BONUS_14: i18n.t('common:api.masterDataField.customBonus14'),
  CUSTOM_BONUS_15: i18n.t('common:api.masterDataField.customBonus15'),
  CUSTOM_BONUS_16: i18n.t('common:api.masterDataField.customBonus16'),
  CUSTOM_BONUS_17: i18n.t('common:api.masterDataField.customBonus17'),
  CUSTOM_BONUS_18: i18n.t('common:api.masterDataField.customBonus18'),
  CUSTOM_BONUS_19: i18n.t('common:api.masterDataField.customBonus19'),
  CUSTOM_BONUS_20: i18n.t('common:api.masterDataField.customBonus20'),
  CUSTOM_BONUS_1_CURRENCY: i18n.t('common:api.masterDataField.customBonus1Currency'),
  CUSTOM_BONUS_2_CURRENCY: i18n.t('common:api.masterDataField.customBonus2Currency'),
  CUSTOM_BONUS_3_CURRENCY: i18n.t('common:api.masterDataField.customBonus3Currency'),
  CUSTOM_BONUS_4_CURRENCY: i18n.t('common:api.masterDataField.customBonus4Currency'),
  CUSTOM_BONUS_5_CURRENCY: i18n.t('common:api.masterDataField.customBonus5Currency'),
  CUSTOM_BONUS_6_CURRENCY: i18n.t('common:api.masterDataField.customBonus6Currency'),
  CUSTOM_BONUS_7_CURRENCY: i18n.t('common:api.masterDataField.customBonus7Currency'),
  CUSTOM_BONUS_8_CURRENCY: i18n.t('common:api.masterDataField.customBonus8Currency'),
  CUSTOM_BONUS_9_CURRENCY: i18n.t('common:api.masterDataField.customBonus9Currency'),
  CUSTOM_BONUS_10_CURRENCY: i18n.t('common:api.masterDataField.customBonus10Currency'),
  CUSTOM_BONUS_11_CURRENCY: i18n.t('common:api.masterDataField.customBonus11Currency'),
  CUSTOM_BONUS_12_CURRENCY: i18n.t('common:api.masterDataField.customBonus12Currency'),
  CUSTOM_BONUS_13_CURRENCY: i18n.t('common:api.masterDataField.customBonus13Currency'),
  CUSTOM_BONUS_14_CURRENCY: i18n.t('common:api.masterDataField.customBonus14Currency'),
  CUSTOM_BONUS_15_CURRENCY: i18n.t('common:api.masterDataField.customBonus15Currency'),
  CUSTOM_BONUS_16_CURRENCY: i18n.t('common:api.masterDataField.customBonus16Currency'),
  CUSTOM_BONUS_17_CURRENCY: i18n.t('common:api.masterDataField.customBonus17Currency'),
  CUSTOM_BONUS_18_CURRENCY: i18n.t('common:api.masterDataField.customBonus18Currency'),
  CUSTOM_BONUS_19_CURRENCY: i18n.t('common:api.masterDataField.customBonus19Currency'),
  CUSTOM_BONUS_20_CURRENCY: i18n.t('common:api.masterDataField.customBonus20Currency'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment1'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment2'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment3'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment4'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment5'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment6'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment7'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment8'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment9'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment10'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment11'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment12'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment13'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment14'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment15'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment16'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment17'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment18'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment19'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20: i18n.t('common:api.masterDataField.employeeCustomNonStatutoryPayment20'),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment1Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment2Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment3Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment4Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment5Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment6Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment7Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment8Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment9Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment10Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment11Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment12Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment13Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment14Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment15Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment16Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment17Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment18Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment19Currency'
  ),
  EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomNonStatutoryPayment20Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment10'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment10Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment11'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment11Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment12'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment12Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment13'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment13Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment14'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment14Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment15'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment15Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment16'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment16Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment17'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment17Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment18'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment18Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment19'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment19Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment1'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment1Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment20'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment20Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment2'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment2Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment3'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment3Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment4'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment4Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment5'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment5Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment6'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment6Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment7'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment7Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment8'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment8Currency'
  ),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9: i18n.t('common:api.masterDataField.employeeCustomStatutoryPayment9'),
  EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeCustomStatutoryPayment9Currency'
  ),
  EMPLOYEE_ID: i18n.t('common:api.masterDataField.employeeId'),
  EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.employeeNonStatutoryPaymentTotal'),
  EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t(
    'common:api.masterDataField.employeeNonStatutoryPaymentTotalCurrency'
  ),
  EMPLOYEE_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.employeeStatutoryPaymentTotal'),
  EMPLOYEE_STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t('common:api.masterDataField.employeeStatutoryPaymentTotalCurrency'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment10'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment10Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment11'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment11Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment12'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment12Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment13'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment13Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment14'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment14Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment15'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment15Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment16'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment16Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment17'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment17Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment18'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment18Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment19'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment19Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment1'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment1Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment20'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment20Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment2'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment2Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment3'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment3Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment4'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment4Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment5'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment5Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment6'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment6Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment7'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment7Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment8'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment8Currency'
  ),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9: i18n.t('common:api.masterDataField.employerCustomNonStatutoryPayment9'),
  EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomNonStatutoryPayment9Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment10'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment10Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment11'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment11Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment12'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment12Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment13'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment13Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment14'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment14Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment15'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment15Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment16'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment16Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment17'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment17Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment18'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment18Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment19'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment19Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment1'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment1Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment20'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment20Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment2'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment2Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment3'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment3Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment4'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment4Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment5'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment5Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment6'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment6Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment7'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment7Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment8'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment8Currency'
  ),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9: i18n.t('common:api.masterDataField.employerCustomStatutoryPayment9'),
  EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9_CURRENCY: i18n.t(
    'common:api.masterDataField.employerCustomStatutoryPayment9Currency'
  ),
  EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.employerNonStatutoryPaymentTotal'),
  EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t(
    'common:api.masterDataField.employerNonStatutoryPaymentTotalCurrency'
  ),
  EMPLOYER_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.employerStatutoryPaymentTotal'),
  EMPLOYER_STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t('common:api.masterDataField.employerStatutoryPaymentTotalCurrency'),
  NON_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.nonStatutoryPaymentTotal'),
  NON_STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t('common:api.masterDataField.nonStatutoryPaymentTotalCurrency'),
  OVERTIME: i18n.t('common:api.masterDataField.overtime'),
  OVERTIME_CURRENCY: i18n.t('common:api.masterDataField.overtimeCurrency'),
  RECEIVED_BASE_SALARY_CURRENCY: i18n.t('common:api.masterDataField.receivedBaseSalaryCurrency'),
  STANDARDIZED_ACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.standardizedActualBaseSalary'),
  STANDARDIZED_CONTRACTUAL_BASE_SALARY: i18n.t('common:api.masterDataField.standardizedContractualBaseSalary'),
  STANDARDIZED_CUSTOM_BONUS_1: i18n.t('common:api.masterDataField.standardizedCustomBonus1'),
  STANDARDIZED_CUSTOM_BONUS_2: i18n.t('common:api.masterDataField.standardizedCustomBonus2'),
  STANDARDIZED_CUSTOM_BONUS_3: i18n.t('common:api.masterDataField.standardizedCustomBonus3'),
  STANDARDIZED_CUSTOM_BONUS_4: i18n.t('common:api.masterDataField.standardizedCustomBonus4'),
  STANDARDIZED_CUSTOM_BONUS_5: i18n.t('common:api.masterDataField.standardizedCustomBonus5'),
  STANDARDIZED_CUSTOM_BONUS_6: i18n.t('common:api.masterDataField.standardizedCustomBonus6'),
  STANDARDIZED_CUSTOM_BONUS_7: i18n.t('common:api.masterDataField.standardizedCustomBonus7'),
  STANDARDIZED_CUSTOM_BONUS_8: i18n.t('common:api.masterDataField.standardizedCustomBonus8'),
  STANDARDIZED_CUSTOM_BONUS_9: i18n.t('common:api.masterDataField.standardizedCustomBonus9'),
  STANDARDIZED_CUSTOM_BONUS_10: i18n.t('common:api.masterDataField.standardizedCustomBonus10'),
  STANDARDIZED_CUSTOM_BONUS_11: i18n.t('common:api.masterDataField.standardizedCustomBonus11'),
  STANDARDIZED_CUSTOM_BONUS_12: i18n.t('common:api.masterDataField.standardizedCustomBonus12'),
  STANDARDIZED_CUSTOM_BONUS_13: i18n.t('common:api.masterDataField.standardizedCustomBonus13'),
  STANDARDIZED_CUSTOM_BONUS_14: i18n.t('common:api.masterDataField.standardizedCustomBonus14'),
  STANDARDIZED_CUSTOM_BONUS_15: i18n.t('common:api.masterDataField.standardizedCustomBonus15'),
  STANDARDIZED_CUSTOM_BONUS_16: i18n.t('common:api.masterDataField.standardizedCustomBonus16'),
  STANDARDIZED_CUSTOM_BONUS_17: i18n.t('common:api.masterDataField.standardizedCustomBonus17'),
  STANDARDIZED_CUSTOM_BONUS_18: i18n.t('common:api.masterDataField.standardizedCustomBonus18'),
  STANDARDIZED_CUSTOM_BONUS_19: i18n.t('common:api.masterDataField.standardizedCustomBonus19'),
  STANDARDIZED_CUSTOM_BONUS_20: i18n.t('common:api.masterDataField.standardizedCustomBonus20'),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_10: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment10'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_11: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment11'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_12: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment12'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_13: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment13'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_14: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment14'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_15: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment15'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_16: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment16'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_17: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment17'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_18: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment18'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_19: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment19'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_1: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment1'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_20: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment20'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_2: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment2'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_3: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment3'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_4: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment4'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_5: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment5'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_6: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment6'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_7: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment7'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_8: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment8'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_STATUTORY_PAYMENT_9: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomStatutoryPayment9'
  ),
  STANDARDIZED_EMPLOYEE_NON_STATUTORY_PAYMENT_TOTAL: i18n.t(
    'common:api.masterDataField.standardizedEmployeeNonStatutoryPaymentTotal'
  ),
  STANDARDIZED_EMPLOYEE_STATUTORY_PAYMENT_TOTAL: i18n.t(
    'common:api.masterDataField.standardizedEmployeeStatutoryPaymentTotal'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_10: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment10'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_11: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment11'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_12: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment12'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_13: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment13'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_14: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment14'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_15: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment15'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_16: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment16'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_17: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment17'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_18: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment18'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_19: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment19'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_1: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment1'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_20: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment20'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_2: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment2'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_3: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment3'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_4: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment4'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_5: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment5'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_6: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment6'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_7: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment7'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_8: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment8'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_STATUTORY_PAYMENT_9: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomStatutoryPayment9'
  ),
  STANDARDIZED_EMPLOYER_NON_STATUTORY_PAYMENT_TOTAL: i18n.t(
    'common:api.masterDataField.standardizedEmployerNonStatutoryPaymentTotal'
  ),
  STANDARDIZED_EMPLOYER_STATUTORY_PAYMENT_TOTAL: i18n.t(
    'common:api.masterDataField.standardizedEmployerStatutoryPaymentTotal'
  ),
  STANDARDIZED_NON_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.standardizedNonStatutoryPaymentTotal'),
  STANDARDIZED_OVERTIME: i18n.t('common:api.masterDataField.standardizedOvertime'),
  STANDARDIZED_STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.standardizedStatutoryPaymentTotal'),
  STANDARDIZED_TOTAL_ALLOWANCE: i18n.t('common:api.masterDataField.standardizedTotalAllowance'),
  STANDARDIZED_TOTAL_BOARD_COMPENSATION: i18n.t('common:api.masterDataField.standardizedTotalBoardCompensation'),
  STANDARDIZED_TOTAL_BONUS: i18n.t('common:api.masterDataField.standardizedTotalBonus'),
  STANDARDIZED_TOTAL_EMPLOYEE_AWARD: i18n.t('common:api.masterDataField.standardizedTotalEmployeeAward'),
  STANDARDIZED_TOTAL_PAYROLL_COST: i18n.t('common:api.masterDataField.standardizedTotalPayrollCost'),
  STANDARDIZED_TOTAL_REIMBURSEMENT: i18n.t('common:api.masterDataField.standardizedTotalReimbursement'),
  STANDARDIZED_TRAVEL_AND_COMMUTING: i18n.t('common:api.masterDataField.standardizedTravelAndCommuting'),
  STATUTORY_PAYMENT_TOTAL: i18n.t('common:api.masterDataField.statutoryPaymentTotal'),
  STATUTORY_PAYMENT_TOTAL_CURRENCY: i18n.t('common:api.masterDataField.statutoryPaymentTotalCurrency'),
  TOTAL_ALLOWANCE: i18n.t('common:api.masterDataField.totalAllowance'),
  TOTAL_ALLOWANCE_CURRENCY: i18n.t('common:api.masterDataField.totalAllowanceCurrency'),
  TOTAL_BOARD_COMPENSATION: i18n.t('common:api.masterDataField.totalBoardCompensation'),
  TOTAL_BOARD_COMPENSATION_CURRENCY: i18n.t('common:api.masterDataField.totalBoardCompensationCurrency'),
  TOTAL_BONUS: i18n.t('common:api.masterDataField.totalBonus'),
  TOTAL_BONUS_CURRENCY: i18n.t('common:api.masterDataField.totalBonusCurrency'),
  TOTAL_CASH_COMPENSATION: i18n.t('common:api.masterDataField.totalCashCompensation'),
  TOTAL_EMPLOYEE_AWARD: i18n.t('common:api.masterDataField.totalEmployeeAward'),
  TOTAL_EMPLOYEE_AWARD_CURRENCY: i18n.t('common:api.masterDataField.totalEmployeeAwardCurrency'),
  TOTAL_PAYROLL_COST: i18n.t('common:api.masterDataField.totalPayrollCost'),
  TOTAL_PAYROLL_COST_CURRENCY: i18n.t('common:api.masterDataField.totalPayrollCostCurrency'),
  TOTAL_REIMBURSEMENT: i18n.t('common:api.masterDataField.totalReimbursement'),
  TOTAL_REIMBURSEMENT_CURRENCY: i18n.t('common:api.masterDataField.totalReimbursementCurrency'),
  TRAVEL_AND_COMMUTING: i18n.t('common:api.masterDataField.travelAndCommuting'),
  TRAVEL_AND_COMMUTING_CURRENCY: i18n.t('common:api.masterDataField.travelAndCommutingCurrency'),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_1: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment1'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_2: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment2'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_3: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment3'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_4: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment4'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_5: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment5'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_6: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment6'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_7: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment7'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_8: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment8'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_9: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment9'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_10: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment10'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_11: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment11'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_12: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment12'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_13: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment13'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_14: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment14'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_15: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment15'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_16: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment16'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_17: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment17'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_18: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment18'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_19: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment19'
  ),
  STANDARDIZED_EMPLOYER_CUSTOM_NON_STATUTORY_PAYMENT_20: i18n.t(
    'common:api.masterDataField.standardizedEmployerCustomNonStatutoryPayment20'
  ),
  STANDARDIZED_CUSTOM_ALLOWANCE_1: i18n.t('common:api.masterDataField.standardizedCustomAllowance1'),
  STANDARDIZED_CUSTOM_ALLOWANCE_2: i18n.t('common:api.masterDataField.standardizedCustomAllowance2'),
  STANDARDIZED_CUSTOM_ALLOWANCE_3: i18n.t('common:api.masterDataField.standardizedCustomAllowance3'),
  STANDARDIZED_CUSTOM_ALLOWANCE_4: i18n.t('common:api.masterDataField.standardizedCustomAllowance4'),
  STANDARDIZED_CUSTOM_ALLOWANCE_5: i18n.t('common:api.masterDataField.standardizedCustomAllowance5'),
  STANDARDIZED_CUSTOM_ALLOWANCE_6: i18n.t('common:api.masterDataField.standardizedCustomAllowance6'),
  STANDARDIZED_CUSTOM_ALLOWANCE_7: i18n.t('common:api.masterDataField.standardizedCustomAllowance7'),
  STANDARDIZED_CUSTOM_ALLOWANCE_8: i18n.t('common:api.masterDataField.standardizedCustomAllowance8'),
  STANDARDIZED_CUSTOM_ALLOWANCE_9: i18n.t('common:api.masterDataField.standardizedCustomAllowance9'),
  STANDARDIZED_CUSTOM_ALLOWANCE_10: i18n.t('common:api.masterDataField.standardizedCustomAllowance10'),
  STANDARDIZED_CUSTOM_ALLOWANCE_11: i18n.t('common:api.masterDataField.standardizedCustomAllowance11'),
  STANDARDIZED_CUSTOM_ALLOWANCE_12: i18n.t('common:api.masterDataField.standardizedCustomAllowance12'),
  STANDARDIZED_CUSTOM_ALLOWANCE_13: i18n.t('common:api.masterDataField.standardizedCustomAllowance13'),
  STANDARDIZED_CUSTOM_ALLOWANCE_14: i18n.t('common:api.masterDataField.standardizedCustomAllowance14'),
  STANDARDIZED_CUSTOM_ALLOWANCE_15: i18n.t('common:api.masterDataField.standardizedCustomAllowance15'),
  STANDARDIZED_CUSTOM_ALLOWANCE_16: i18n.t('common:api.masterDataField.standardizedCustomAllowance16'),
  STANDARDIZED_CUSTOM_ALLOWANCE_17: i18n.t('common:api.masterDataField.standardizedCustomAllowance17'),
  STANDARDIZED_CUSTOM_ALLOWANCE_18: i18n.t('common:api.masterDataField.standardizedCustomAllowance18'),
  STANDARDIZED_CUSTOM_ALLOWANCE_19: i18n.t('common:api.masterDataField.standardizedCustomAllowance19'),
  STANDARDIZED_CUSTOM_ALLOWANCE_20: i18n.t('common:api.masterDataField.standardizedCustomAllowance20'),
  CUSTOM_ALLOWANCE_1: i18n.t('common:api.masterDataField.customAllowance1'),
  CUSTOM_ALLOWANCE_2: i18n.t('common:api.masterDataField.customAllowance2'),
  CUSTOM_ALLOWANCE_3: i18n.t('common:api.masterDataField.customAllowance3'),
  CUSTOM_ALLOWANCE_4: i18n.t('common:api.masterDataField.customAllowance4'),
  CUSTOM_ALLOWANCE_5: i18n.t('common:api.masterDataField.customAllowance5'),
  CUSTOM_ALLOWANCE_6: i18n.t('common:api.masterDataField.customAllowance6'),
  CUSTOM_ALLOWANCE_7: i18n.t('common:api.masterDataField.customAllowance7'),
  CUSTOM_ALLOWANCE_8: i18n.t('common:api.masterDataField.customAllowance8'),
  CUSTOM_ALLOWANCE_9: i18n.t('common:api.masterDataField.customAllowance9'),
  CUSTOM_ALLOWANCE_10: i18n.t('common:api.masterDataField.customAllowance10'),
  CUSTOM_ALLOWANCE_11: i18n.t('common:api.masterDataField.customAllowance11'),
  CUSTOM_ALLOWANCE_12: i18n.t('common:api.masterDataField.customAllowance12'),
  CUSTOM_ALLOWANCE_13: i18n.t('common:api.masterDataField.customAllowance13'),
  CUSTOM_ALLOWANCE_14: i18n.t('common:api.masterDataField.customAllowance14'),
  CUSTOM_ALLOWANCE_15: i18n.t('common:api.masterDataField.customAllowance15'),
  CUSTOM_ALLOWANCE_16: i18n.t('common:api.masterDataField.customAllowance16'),
  CUSTOM_ALLOWANCE_17: i18n.t('common:api.masterDataField.customAllowance17'),
  CUSTOM_ALLOWANCE_18: i18n.t('common:api.masterDataField.customAllowance18'),
  CUSTOM_ALLOWANCE_19: i18n.t('common:api.masterDataField.customAllowance19'),
  CUSTOM_ALLOWANCE_20: i18n.t('common:api.masterDataField.customAllowance20'),
  CUSTOM_ALLOWANCE_1_CURRENCY: i18n.t('common:api.masterDataField.customAllowance1Currency'),
  CUSTOM_ALLOWANCE_2_CURRENCY: i18n.t('common:api.masterDataField.customAllowance2Currency'),
  CUSTOM_ALLOWANCE_3_CURRENCY: i18n.t('common:api.masterDataField.customAllowance3Currency'),
  CUSTOM_ALLOWANCE_4_CURRENCY: i18n.t('common:api.masterDataField.customAllowance4Currency'),
  CUSTOM_ALLOWANCE_5_CURRENCY: i18n.t('common:api.masterDataField.customAllowance5Currency'),
  CUSTOM_ALLOWANCE_6_CURRENCY: i18n.t('common:api.masterDataField.customAllowance6Currency'),
  CUSTOM_ALLOWANCE_7_CURRENCY: i18n.t('common:api.masterDataField.customAllowance7Currency'),
  CUSTOM_ALLOWANCE_8_CURRENCY: i18n.t('common:api.masterDataField.customAllowance8Currency'),
  CUSTOM_ALLOWANCE_9_CURRENCY: i18n.t('common:api.masterDataField.customAllowance9Currency'),
  CUSTOM_ALLOWANCE_10_CURRENCY: i18n.t('common:api.masterDataField.customAllowance10Currency'),
  CUSTOM_ALLOWANCE_11_CURRENCY: i18n.t('common:api.masterDataField.customAllowance11Currency'),
  CUSTOM_ALLOWANCE_12_CURRENCY: i18n.t('common:api.masterDataField.customAllowance12Currency'),
  CUSTOM_ALLOWANCE_13_CURRENCY: i18n.t('common:api.masterDataField.customAllowance13Currency'),
  CUSTOM_ALLOWANCE_14_CURRENCY: i18n.t('common:api.masterDataField.customAllowance14Currency'),
  CUSTOM_ALLOWANCE_15_CURRENCY: i18n.t('common:api.masterDataField.customAllowance15Currency'),
  CUSTOM_ALLOWANCE_16_CURRENCY: i18n.t('common:api.masterDataField.customAllowance16Currency'),
  CUSTOM_ALLOWANCE_17_CURRENCY: i18n.t('common:api.masterDataField.customAllowance17Currency'),
  CUSTOM_ALLOWANCE_18_CURRENCY: i18n.t('common:api.masterDataField.customAllowance18Currency'),
  CUSTOM_ALLOWANCE_19_CURRENCY: i18n.t('common:api.masterDataField.customAllowance19Currency'),
  CUSTOM_ALLOWANCE_20_CURRENCY: i18n.t('common:api.masterDataField.customAllowance20Currency'),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_1: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment1'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_2: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment2'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_3: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment3'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_4: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment4'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_5: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment5'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_6: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment6'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_7: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment7'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_8: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment8'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_9: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment9'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_10: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment10'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_11: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment11'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_12: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment12'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_13: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment13'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_14: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment14'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_15: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment15'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_16: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment16'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_17: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment17'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_18: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment18'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_19: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment19'
  ),
  STANDARDIZED_EMPLOYEE_CUSTOM_NON_STATUTORY_PAYMENT_20: i18n.t(
    'common:api.masterDataField.standardizedEmployeeCustomNonStatutoryPayment20'
  ),
  BOARD_COMPENSATION_1_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation1Currency'),
  BOARD_COMPENSATION_2_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation2Currency'),
  BOARD_COMPENSATION_3_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation3Currency'),
  BOARD_COMPENSATION_4_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation4Currency'),
  BOARD_COMPENSATION_5_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation5Currency'),
  BOARD_COMPENSATION_6_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation6Currency'),
  BOARD_COMPENSATION_7_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation7Currency'),
  BOARD_COMPENSATION_8_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation8Currency'),
  BOARD_COMPENSATION_9_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation9Currency'),
  BOARD_COMPENSATION_10_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation10Currency'),
  BOARD_COMPENSATION_11_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation11Currency'),
  BOARD_COMPENSATION_12_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation12Currency'),
  BOARD_COMPENSATION_13_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation13Currency'),
  BOARD_COMPENSATION_14_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation14Currency'),
  BOARD_COMPENSATION_15_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation15Currency'),
  BOARD_COMPENSATION_16_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation16Currency'),
  BOARD_COMPENSATION_17_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation17Currency'),
  BOARD_COMPENSATION_18_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation18Currency'),
  BOARD_COMPENSATION_19_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation19Currency'),
  BOARD_COMPENSATION_20_CURRENCY: i18n.t('common:api.masterDataField.boardCompensation20Currency'),
  EMPLOYEE_AWARD_1_CURRENCY: i18n.t('common:api.masterDataField.employeeAward1Currency'),
  EMPLOYEE_AWARD_2_CURRENCY: i18n.t('common:api.masterDataField.employeeAward2Currency'),
  EMPLOYEE_AWARD_3_CURRENCY: i18n.t('common:api.masterDataField.employeeAward3Currency'),
  EMPLOYEE_AWARD_4_CURRENCY: i18n.t('common:api.masterDataField.employeeAward4Currency'),
  EMPLOYEE_AWARD_5_CURRENCY: i18n.t('common:api.masterDataField.employeeAward5Currency'),
  EMPLOYEE_AWARD_6_CURRENCY: i18n.t('common:api.masterDataField.employeeAward6Currency'),
  EMPLOYEE_AWARD_7_CURRENCY: i18n.t('common:api.masterDataField.employeeAward7Currency'),
  EMPLOYEE_AWARD_8_CURRENCY: i18n.t('common:api.masterDataField.employeeAward8Currency'),
  EMPLOYEE_AWARD_9_CURRENCY: i18n.t('common:api.masterDataField.employeeAward9Currency'),
  EMPLOYEE_AWARD_10_CURRENCY: i18n.t('common:api.masterDataField.employeeAward10Currency'),
  EMPLOYEE_AWARD_11_CURRENCY: i18n.t('common:api.masterDataField.employeeAward11Currency'),
  EMPLOYEE_AWARD_12_CURRENCY: i18n.t('common:api.masterDataField.employeeAward12Currency'),
  EMPLOYEE_AWARD_13_CURRENCY: i18n.t('common:api.masterDataField.employeeAward13Currency'),
  EMPLOYEE_AWARD_14_CURRENCY: i18n.t('common:api.masterDataField.employeeAward14Currency'),
  EMPLOYEE_AWARD_15_CURRENCY: i18n.t('common:api.masterDataField.employeeAward15Currency'),
  EMPLOYEE_AWARD_16_CURRENCY: i18n.t('common:api.masterDataField.employeeAward16Currency'),
  EMPLOYEE_AWARD_17_CURRENCY: i18n.t('common:api.masterDataField.employeeAward17Currency'),
  EMPLOYEE_AWARD_18_CURRENCY: i18n.t('common:api.masterDataField.employeeAward18Currency'),
  EMPLOYEE_AWARD_19_CURRENCY: i18n.t('common:api.masterDataField.employeeAward19Currency'),
  EMPLOYEE_AWARD_20_CURRENCY: i18n.t('common:api.masterDataField.employeeAward20Currency'),
  REIMBURSEMENT_1_CURRENCY: i18n.t('common:api.masterDataField.reimbursement1Currency'),
  REIMBURSEMENT_2_CURRENCY: i18n.t('common:api.masterDataField.reimbursement2Currency'),
  REIMBURSEMENT_3_CURRENCY: i18n.t('common:api.masterDataField.reimbursement3Currency'),
  REIMBURSEMENT_4_CURRENCY: i18n.t('common:api.masterDataField.reimbursement4Currency'),
  REIMBURSEMENT_5_CURRENCY: i18n.t('common:api.masterDataField.reimbursement5Currency'),
  REIMBURSEMENT_6_CURRENCY: i18n.t('common:api.masterDataField.reimbursement6Currency'),
  REIMBURSEMENT_7_CURRENCY: i18n.t('common:api.masterDataField.reimbursement7Currency'),
  REIMBURSEMENT_8_CURRENCY: i18n.t('common:api.masterDataField.reimbursement8Currency'),
  REIMBURSEMENT_9_CURRENCY: i18n.t('common:api.masterDataField.reimbursement9Currency'),
  REIMBURSEMENT_10_CURRENCY: i18n.t('common:api.masterDataField.reimbursement10Currency'),
  REIMBURSEMENT_11_CURRENCY: i18n.t('common:api.masterDataField.reimbursement11Currency'),
  REIMBURSEMENT_12_CURRENCY: i18n.t('common:api.masterDataField.reimbursement12Currency'),
  REIMBURSEMENT_13_CURRENCY: i18n.t('common:api.masterDataField.reimbursement13Currency'),
  REIMBURSEMENT_14_CURRENCY: i18n.t('common:api.masterDataField.reimbursement14Currency'),
  REIMBURSEMENT_15_CURRENCY: i18n.t('common:api.masterDataField.reimbursement15Currency'),
  REIMBURSEMENT_16_CURRENCY: i18n.t('common:api.masterDataField.reimbursement16Currency'),
  REIMBURSEMENT_17_CURRENCY: i18n.t('common:api.masterDataField.reimbursement17Currency'),
  REIMBURSEMENT_18_CURRENCY: i18n.t('common:api.masterDataField.reimbursement18Currency'),
  REIMBURSEMENT_19_CURRENCY: i18n.t('common:api.masterDataField.reimbursement19Currency'),
  REIMBURSEMENT_20_CURRENCY: i18n.t('common:api.masterDataField.reimbursement20Currency'),
};

const apiFinancialMetricsDataFieldTranslationMap: Record<FinancialDataFields, string> = {
  FINANCIALMETRICSID: i18n.t('common:api.masterDataField.financialMetricsId'),
  VERSION_ID: i18n.t('common:api.masterDataField.versionId'),
  REVENUE: i18n.t('common:api.masterDataField.revenue'),
  GROSS_PROFIT: i18n.t('common:api.masterDataField.grossProfit'),
  OPERATING_PROFIT: i18n.t('common:api.masterDataField.operatingProfit'),
  NET_INCOME: i18n.t('common:api.masterDataField.netIncome'),
  SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES: i18n.t(
    'common:api.masterDataField.sellingGeneralAndAdminitrationExpenses'
  ),
  TRAINING_COST: i18n.t('common:api.masterDataField.trainingCost'),
  ORGANIZATION_LEVEL_1: i18n.t('common:api.masterDataField.organizationLevel1'),
  FUNCTION_LEVEL_1: i18n.t('common:api.masterDataField.functionLevel1'),
  GRANULARITY: i18n.t('common:api.masterDataField.granularity'),
  COST_CENTER: i18n.t('common:api.masterDataField.costCenter'),
  LABOUR_COST: i18n.t('common:api.masterDataField.labourCost'),
};

export const apiMasterDataFieldTranslationMap: Partial<Record<DataTypes, Partial<Record<DataFields, string>>>> = {
  [DataTypes.EMPLOYEE]: apiEmployeeDataFieldTranslationMap,
  [DataTypes.ATTRITIONPREDICTION]: apiAttritionPredictionDataFieldTranslationMap,
  [DataTypes.JOB]: apiJobDataFieldTranslationMap,
  [DataTypes.APPLICATION]: apiApplicationDataFieldTranslationMap,
  [DataTypes.EVALUATION]: apiEvaluationDataFieldTranslationMap,
  [DataTypes.QUESTIONANSWER]: apiQuestionAnswerDataFieldTranslationMap,
  [DataTypes.TIMEANDATTENDANCEMONTHLY]: apiTimeAndAttendanceMonthlyDataFieldTranslationMap,
  [DataTypes.OFFER]: apiOfferDataFieldTranslationMap,
  [DataTypes.PAYROLL]: apiPayrollDataFieldTranslationMap,
  [DataTypes.FINANCIALMETRICS]: apiFinancialMetricsDataFieldTranslationMap,
  [DataTypes.INTERVIEW]: apiInterviewDataFieldTranslationMap,
  [DataTypes.ONAEMAILMETRICS]: apiOnaEmailMetricsDataFieldTranslationMap,
  [DataTypes.RECRUITERJOBS]: apiRecruiterDataFieldTranslationMap,
  [DataTypes.MANAGERENPS]: apiManagerEnpsDataFieldTranslationMap,
};

export const getTranslation = (field: DataFieldWithDataType): string => {
  //TODO: fix type
  const translations: any | undefined = apiMasterDataFieldTranslationMap[field.dataType];
  return translations?.[field.dataField as DataFields];
};

export const customDataViewFieldTranslationMap: Record<string, string> = {
  source: i18n.t('common:customDataViewFields.source'),
  domain: i18n.t('common:customDataViewFields.domain'),
  countEmailsOrMessage: i18n.t('common:customDataViewFields.countEmailsOrMessage'),
  reciprocityRatio: i18n.t('common:customDataViewFields.reciprocityRatio'),
  avgDailyVolumeEmails: i18n.t('common:customDataViewFields.avgDailyVolumeEmails'),
  avgResponseTime: i18n.t('common:customDataViewFields.avgResponseTime'),
  percentageResponse: i18n.t('common:customDataViewFields.percentageResponse'),
  collaborationScore: i18n.t('common:customDataViewFields.collaborationScore'),
  maxMonthlyRelationshipScore: i18n.t('common:customDataViewFields.maxMonthlyRelationshipScore'),
  lastSentMessage: i18n.t('common:customDataViewFields.lastSentMessage'),
  lastReceivedMessage: i18n.t('common:customDataViewFields.lastReceivedMessage'),
  numConnections: i18n.t('common:customDataViewFields.numConnections'),
  numRelationships: i18n.t('common:customDataViewFields.numRelationships'),
};

export const dataViewFieldsTranslationMap: Partial<Record<string, Partial<Record<DataFields, string>>>> = {
  ...apiMasterDataFieldTranslationMap,
  ...customDataViewFieldTranslationMap,
};

export const computedEmployeeFieldTranslations: Record<ComputedEmployeeFields, string> = {
  [ComputedEmployeeFields.MANAGER_NAME]: i18n.t('common:api.computedEmployeeFieldTranslations.managerName'),
  [ComputedEmployeeFields.DATE]: i18n.t('common:api.computedEmployeeFieldTranslations.date'),
  [ComputedEmployeeFields.TRAIN]: i18n.t('common:api.computedEmployeeFieldTranslations.train'),
  [ComputedEmployeeFields.none]: i18n.t('common:api.computedEmployeeFieldTranslations.none'),
  [ComputedEmployeeFields.MY_POPULATION]: i18n.t('common:api.computedEmployeeFieldTranslations.myPopulation'),
  [ComputedEmployeeFields.FILTERED_MY_POPULATION]: i18n.t(
    'common:api.computedEmployeeFieldTranslations.filteredMyPopulation'
  ),
};

// values

export const jobStatusValuesTranslationMap: Record<string, string> = {
  OPEN: i18n.t('common:filterTray.jobStatusValues.open'),
  CLOSED: i18n.t('common:filterTray.jobStatusValues.closed'),
  DRAFT: i18n.t('common:filterTray.jobStatusValues.draft'),
  ARCHIVED: i18n.t('common:filterTray.jobStatusValues.archived'),
};

export const applicationStatusValuesTranslationMap = {
  [ApplicationStatusValues.Active]: i18n.t('common:filterTray.applicationStatusValues.active'),
  [ApplicationStatusValues.Rejected]: i18n.t('common:filterTray.applicationStatusValues.rejected'),
  [ApplicationStatusValues.Hired]: i18n.t('common:filterTray.applicationStatusValues.hired'),
};

export const applicationCurrentStageValuesTranslationMap: Record<string, string> = {
  Applied: i18n.t('common:filterTray.applicationCurrentStageValues.applied'),
  Offered: i18n.t('common:filterTray.applicationCurrentStageValues.offered'),
  Accepted: i18n.t('common:filterTray.applicationCurrentStageValues.accepted'),
  Rejected: i18n.t('common:filterTray.applicationCurrentStageValues.rejected'),
  Screening: i18n.t('common:filterTray.applicationCurrentStageValues.screening'),
  Joined: i18n.t('common:filterTray.applicationCurrentStageValues.joined'),
  Interview: i18n.t('common:filterTray.applicationCurrentStageValues.interview'),
  '1st Interview': i18n.t('common:filterTray.applicationCurrentStageValues.firstInterview'),
  '1st Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.firstInterviewPassed'),
  '1st Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.firstInterviewFailed'),
  '2nd Interview': i18n.t('common:filterTray.applicationCurrentStageValues.secondInterview'),
  '2nd Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.secondInterviewPassed'),
  '2nd Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.secondInterviewFailed'),
  '3rd Interview': i18n.t('common:filterTray.applicationCurrentStageValues.thirdInterview'),
  '3rd Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.thirdInterviewPassed'),
  '3rd Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.thirdInterviewFailed'),
  '4th Interview': i18n.t('common:filterTray.applicationCurrentStageValues.fourthInterview'),
  '4th Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.fourthInterviewPassed'),
  '4th Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.fourthInterviewFailed'),
  '5th Interview': i18n.t('common:filterTray.applicationCurrentStageValues.fifthInterview'),
  '5th Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.fifthInterviewPassed'),
  '5th Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.fifthInterviewFailed'),
  'CEO Interview': i18n.t('common:filterTray.applicationCurrentStageValues.ceoInterview'),
  'CEO Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.ceoInterviewPassed'),
  'CEO Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.ceoInterviewFailed'),
  'Final Interview': i18n.t('common:filterTray.applicationCurrentStageValues.finalInterview'),
  'Final Interview Passed': i18n.t('common:filterTray.applicationCurrentStageValues.finalInterviewPassed'),
  'Final Interview Failed': i18n.t('common:filterTray.applicationCurrentStageValues.finalInterviewFailed'),
};

type TranslationMapWithUndefined<T extends string> = Record<T | typeof UndefinedValue, string>;
// I couldn't make UndefinedValue a required type in the object somehow

export const hireStatusValuesTranslationMap: Record<string, string> = {
  Accepted: i18n.t('common:filterTray.hireStatusValues.accepted'),
  ACCEPTED: i18n.t('common:filterTray.hireStatusValues.accepted'),
  REJECTED: i18n.t('common:filterTray.hireStatusValues.rejected'),
  Rejcted: i18n.t('common:filterTray.hireStatusValues.rejected'),
  UNRESOLVED: i18n.t('common:filterTray.hireStatusValues.unresolved'),
  DEPRECATED: i18n.t('common:filterTray.hireStatusValues.deprecated'),
};

export const genderLabelToDisplayLabelMap: TranslationMapWithUndefined<GenderValues> = {
  [GenderValues.Male]: i18n.t('common:commonValues.misc.male'),
  [GenderValues.Female]: i18n.t('common:commonValues.misc.female'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

export const managerOrICLabelToDisplayLabelMap: TranslationMapWithUndefined<ManagerOrIcValues> = {
  [ManagerOrIcValues.Manager]: i18n.t('common:commonValues.misc.manager'),
  [ManagerOrIcValues.Ic]: i18n.t('common:commonValues.misc.IC'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

export const maritalStatusLabelToDisplayLabelMap: TranslationMapWithUndefined<MartialStatusValues> = {
  [MartialStatusValues.Single]: i18n.t('common:filterTray.maritalStatusValues.single'),
  [MartialStatusValues.Married]: i18n.t('common:filterTray.maritalStatusValues.married'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

export const regionalLocalLabelToDisplayLabelMap: TranslationMapWithUndefined<RegionalOrLocalValues> = {
  [RegionalOrLocalValues.Regional]: i18n.t('common:filterTray.regionalLocalValues.regional'),
  [RegionalOrLocalValues.Local]: i18n.t('common:filterTray.regionalLocalValues.local'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

export const localNonLocalLabelToDisplayLabelMap: TranslationMapWithUndefined<LocalOrNonLocalValues> = {
  [LocalOrNonLocalValues.Local]: i18n.t('common:filterTray.localNonLocalValues.local'),
  [LocalOrNonLocalValues.NonLocal]: i18n.t('common:filterTray.localNonLocalValues.nonLocal'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

export const regretNonRegretLabelToDisplayLabelMap: TranslationMapWithUndefined<RegretNonRegretValues> = {
  [RegretNonRegretValues.Regret]: i18n.t('common:filterTray.regretNonRegretValues.regret'),
  [RegretNonRegretValues.NonRegret]: i18n.t('common:filterTray.regretNonRegretValues.nonRegret'),
  [UndefinedValue]: i18n.t('common:commonValues.missingValue'),
};

const timeUnitLabels: Record<Granularity, string> = {
  [Granularity.DAY]: i18n.t('common:commonValues.misc.day'),
  [Granularity.WEEK]: i18n.t('common:commonValues.misc.week'),
  [Granularity.MONTH]: i18n.t('common:commonValues.misc.month'),
  [Granularity.QUARTER]: i18n.t('common:commonValues.misc.quarter'),
  [Granularity.FINQUARTER]: i18n.t('common:commonValues.misc.finQuarter'),
  [Granularity.YEAR]: i18n.t('common:commonValues.misc.year'),
  [Granularity.FINYEAR]: i18n.t('common:commonValues.misc.finYear'),
};

export const getTimeUnitLabel = (granularity: Granularity) => {
  return timeUnitLabels[granularity];
};

export const getOperationTranslation = (operation: Operations) => {
  return i18n.t(`common:operations.${operation}`);
};
