import { action, observable, runInAction } from 'mobx';
import { DataFieldWithDataType } from '../../../common-types';
import { ApiReport } from '../api/api-interfaces';
import { ReportApiService } from '../api/report-service';
import { CompanySettingsStore } from '../company-settings-store';
import { CompanyStore } from '../company/company-store';
import { Dashboards, DataTypes, Domains, EmployeeDataFields as EDF } from '../constants/constants';
import { EmployeeDataStore } from '../employee-data/employee-data-store';
import { FilterStore } from '../filter/filter-store';
import { GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService } from '../services/GetNonNullFieldsForDataTypeService';
import { getDataViewFieldsFromSettings, isHiddenField } from './../company-data-view-settings';

export interface MappableFields {
  property: string;
  operation: string;
  values: string[];
}

export interface PanalytToClientFieldMap {
  [key: string]: string;
}
export enum DateRangeOptions {
  DEFAULT = 'default',
  DATESHORT = 'mmmYY',
}

export class ReportStore {
  @observable
  public reportList: ApiReport[] = []; // Should I make this readonly?

  // I'd prefer to use ApiReportList here, but I am copying this pattern from
  // FileManagementstore and it doesn't do it. Using ApiReportList here, instead of
  // ApiReport[] causes some TS error, but surely there must be a way around that?

  @observable
  public isLoadingReportList: boolean = false;

  // I guess I shoudl just remove the loading thing and check if the
  // reportList is empty or not

  @observable
  public selectedReport: ApiReport | null = null;

  @observable
  public isTimeSliderUpdated: boolean = false;

  @observable
  public dataViewFields: { [key in Dashboards]?: Set<DataFieldWithDataType> } = {};

  @observable
  public activeGroupByField: Partial<Record<DataTypes, DataFieldWithDataType>> = {};

  @observable
  public selectedDateRange: Record<DateRangeOptions, boolean> = {
    [DateRangeOptions.DEFAULT]: true,
    [DateRangeOptions.DATESHORT]: false,
  };

  @observable
  public reportFieldsOrder: DataFieldWithDataType[] = [];

  private filterStore: FilterStore;
  private companyStore: CompanyStore;
  private employeeDataStore: EmployeeDataStore;
  private companySettingsStore: CompanySettingsStore;
  private reportApiService: ReportApiService;

  constructor(
    filterStore: FilterStore,
    companyStore: CompanyStore,
    employeeDataStore: EmployeeDataStore,
    companySettingsStore: CompanySettingsStore,
    reportApiService: ReportApiService
  ) {
    this.filterStore = filterStore;
    this.companyStore = companyStore;
    this.employeeDataStore = employeeDataStore;
    this.companySettingsStore = companySettingsStore;
    this.reportApiService = reportApiService;
  }

  // Can be used to extend column selector with additional data types instead of only allowing to manipulate EMPLOYEE there
  public dataViewAdditionalDataTypesMap: Partial<Record<Dashboards, DataTypes[]>> = {
    [Dashboards.PAYROLL]: [DataTypes.PAYROLL],
    [Dashboards.SURVEY]: [DataTypes.QUESTIONANSWER],
  };

  private defaultFields = new Set([
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.EMPLOYEE_ID },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.FULL_NAME },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.ORGANIZATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.START_DATE },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.TENURE_GROUP },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.NATIONALITY_HIERARCHICAL_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EDF.AGE_GROUP },
  ]);

  @action
  public setSelectedDateRange = (id: DateRangeOptions) => {
    Object.keys(this.selectedDateRange).forEach((v) => (this.selectedDateRange[v as DateRangeOptions] = false));
    this.selectedDateRange[id] = true;
  };
  @action
  public getSelectedDateRange = (): DateRangeOptions => {
    return Object.keys(this.selectedDateRange)
      .filter((k) => this.selectedDateRange[k as DateRangeOptions])
      .shift() as DateRangeOptions;
  };
  @action
  public setActiveGroupByField = (field: DataFieldWithDataType | null, dataType: DataTypes) => {
    if (!field) {
      delete this.activeGroupByField[dataType];
    } else {
      this.activeGroupByField[dataType] = field;
    }
  };

  @action
  public setIsTimeSliderUpdated = (isTimeSliderUpdated: boolean) => {
    this.isTimeSliderUpdated = isTimeSliderUpdated;
  };

  public resetReportStore = () => {
    this.activeGroupByField = {};
  };

  @action
  public updateSelectedReportDimensions(dimensions: DataFieldWithDataType[]) {
    const { filters } = this.filterStore;
    if (this.selectedReport) {
      this.selectedReport.report.query.dimensions = dimensions.map((dim) => {
        return { property: dim.dataField, dataType: dim.dataType };
      });
      this.selectedReport.report.query.filterItems = filters;
      // I guess its fine to assign by value here?
    }
  }

  @action
  public async setSelectedReport(id: string) {
    // Should this be @computed?
    if (!this.reportList.length) {
      await this.loadReports();
    }
    const foundReport = this.reportList.find((report) => report.id === id);
    this.selectedReport = foundReport ? JSON.parse(JSON.stringify(foundReport)) : null;

    return this.selectedReport;

    // I am basically assuming in many such cases that there would not be any error
    // For example, here I am assuming that there when this function is called, reportList will definitely be set
    // Will we be later retuning to all this and adding error handling?
  }

  @action
  public setInitialDataViewFields = async (dashboard: Dashboards) => {
    const fields = this.dataViewFields[dashboard];
    if (fields && fields.size > 0) {
      return;
    }
    const { domain } = this.companyStore;
    const { nonNullFields } = this.employeeDataStore;
    const clonedNonNullFields = [...nonNullFields];
    const additionalNonNullDataTypes = this.dataViewAdditionalDataTypesMap[dashboard];
    if (additionalNonNullDataTypes) {
      for await (const dataType of additionalNonNullDataTypes) {
        const dataFields = await GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService(dataType);
        clonedNonNullFields.push(...dataFields);
      }
    }
    const isUsingEffectiveLeaverDate = this.companySettingsStore.isUsingEffectiveLeaverDate();

    const selectedFields: DataFieldWithDataType[] = getDataViewFieldsFromSettings({
      domain: domain as Domains,
      location: dashboard,
      defaultFields: this.defaultFields,
      nonNullFields: new Set(clonedNonNullFields) as Set<DataFieldWithDataType>,
      isJapaneseClient: this.companyStore.isJapaneseEnterpriseClient(),
      isUsingEffectiveLeaverDate,
    });

    this.dataViewFields[dashboard] = new Set(selectedFields);
  };

  @action
  public setDataViewFields = (dashboard: Dashboards, fields: DataFieldWithDataType[]) => {
    const { domain } = this.companyStore;
    this.dataViewFields[dashboard] = new Set(fields.filter((f) => !isHiddenField(f, domain as Domains)));
  };

  public getDataViewFields = (dashboard: Dashboards): DataFieldWithDataType[] =>
    Array.from(this.dataViewFields[dashboard] ?? this.defaultFields);

  @action
  public async loadReports() {
    runInAction(() => (this.isLoadingReportList = true)); // I am guessing I don't need a runInAction around this one, but do need it when I set it the second time?
    const result = await this.reportApiService.listReports(this.companyStore.domain);
    runInAction(() => {
      this.reportList = result.reports || [];
      this.isLoadingReportList = false;
    });
    // How are we supposed to do error handling in these kinds of places?
    // For ex, if we want to display a message on the mypanalyt page if this fails?
    // Is this simply wrapping the api call in try catch ?
  }

  @action
  public updateReportScriptActions = async () => {
    await this.updateReportsForJapaneseClients();
  };

  @action
  public async deleteReport(id: string) {
    return this.reportApiService.deleteReport(this.companyStore.domain, id);
  }

  // To be used as a script
  @action
  private async updateReportsForJapaneseClients() {
    const { updateReport } = this.reportApiService;
    const oldStandardReportDisplayName = '標準';
    const oldLeaversReportDisplayName = '退職者';
    const newStandardReport = {
      displayName: '従業員レポート',
      description: '従業員一覧からカスタムレポートの作成ができます。 ',
    };
    const newLeaversReport = {
      displayName: '退職者レポート',
      description: '退職者一覧からカスタムレポートの作成ができます',
    };

    const standardReport = this.reportList.find(
      (report) => report.report.displayName === oldStandardReportDisplayName
    ) as ApiReport;
    const leaversReport = this.reportList.find(
      (report) => report.report.displayName === oldLeaversReportDisplayName
    ) as ApiReport;
    const updatedStandardReport: ApiReport = {
      id: standardReport.id,
      report: {
        displayName: newStandardReport.displayName,
        description: newStandardReport.description,
        query: standardReport.report.query,
      },
    };
    const updatedLeaversReport: ApiReport = {
      id: leaversReport.id,
      report: {
        displayName: newLeaversReport.displayName,
        description: newLeaversReport.description,
        query: leaversReport.report.query,
      },
    };

    await Promise.all([
      updateReport(this.companyStore.domain, standardReport.id, updatedStandardReport),
      updateReport(this.companyStore.domain, leaversReport.id, updatedLeaversReport),
    ]);
  }
}
