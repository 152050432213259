import {
  ApiMasterDataAdvancedQuery,
  ApiMasterDataBatchQueryResponse,
  ApiMasterDataQuery,
  ApiMasterDataQueryResponse,
} from '../api/api-interfaces';
import { BenchmarkConfig, cachedMasterDataApiService } from '../api/master-data-service';
import { appCache, ICache } from '../cache/cache';
import { rootStore } from '../store/root-store';

export interface Service {
  executeQuery(
    domain: string,
    query: ApiMasterDataQuery,
    benchmarkConfig?: BenchmarkConfig
  ): Promise<ApiMasterDataQueryResponse>;

  executeQueriesInBatch(
    domain: string,
    queries: { queries: ApiMasterDataQuery[] },
    benchmarkConfig?: BenchmarkConfig | undefined
  ): Promise<ApiMasterDataBatchQueryResponse>;
}

export const GetResponseForMasterQueryService = async (
  query: ApiMasterDataQuery,
  benchmarkConfig?: BenchmarkConfig
): Promise<ApiMasterDataQueryResponse> => {
  return GetResponseForQueryService(query, benchmarkConfig) as Promise<ApiMasterDataQueryResponse>;
};

export const GetResponseForMasterQueriesService = async (
  queries: ApiMasterDataQuery[],
  benchmarkConfig?: BenchmarkConfig
): Promise<ApiMasterDataBatchQueryResponse> => {
  return GetResponseForQueriesService(
    queries,
    appCache,
    rootStore.masterDataApiService,
    benchmarkConfig
  ) as Promise<ApiMasterDataBatchQueryResponse>;
};

const GetResponseForQueriesService = async (
  queries: ApiMasterDataQuery[],
  cache: ICache,
  service: Service,
  benchmarkConfig?: BenchmarkConfig
) => {
  rootStore.loadingStateStore.loadStarted();
  try {
    const cacheKey = JSON.stringify({ queries, benchmarkConfig });
    const data = await cache.getFromCacheOrRequest(cacheKey, () => {
      return service.executeQueriesInBatch(rootStore.companyStore.domain, { queries }, benchmarkConfig);
    });
    return data;
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  } finally {
    rootStore.loadingStateStore.loadFinished();
  }
};

const GetResponseForQueryService = async (query: ApiMasterDataQuery, benchmarkConfig?: BenchmarkConfig) => {
  rootStore.loadingStateStore.loadStarted();
  try {
    return cachedMasterDataApiService.executeQuery(rootStore.companyStore.domain, query, benchmarkConfig);
  } catch (e) {
    // tslint:disable-next-line:no-console
    console.error(e);
  } finally {
    rootStore.loadingStateStore.loadFinished();
  }
};

export const GetResponseForAdvancedQueryService = async (
  query: ApiMasterDataAdvancedQuery,
  _benchmarkConfig?: BenchmarkConfig
) => {
  return cachedMasterDataApiService.executeAdvancedQuery(rootStore.companyStore.domain, query);
};
