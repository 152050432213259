import { action, computed, observable } from 'mobx';
import { DataFieldWithDataType } from '../../common-types';
import { ApiHierarchyItem, ApiMasterDataQuery, ApiMasterDataQueryResponseDataPoint } from './api/api-interfaces';
import { CompanySettingsStore } from './company-settings-store';
import { panalytFieldNameSortOrder } from './components/ReportView/FieldSortOrder';
import { DataTypes, EmployeeDataFields } from './constants/constants';
import { getPropertyFromDatapoint } from './filter/utils';
import { LoadingStateStore } from './loading-state-store';
import { GetResponseForMasterQueryService } from './services/GetResponseForQueryService';
import { sortBySorterArray } from './utilFunctions/sorters';

export class CompanyStructureDashboardStore {
  private loadingStateStore: LoadingStateStore;
  private companySettingsStore: CompanySettingsStore;

  constructor(loadingStateStore: LoadingStateStore, companySettingsStore: CompanySettingsStore) {
    this.loadingStateStore = loadingStateStore;
    this.companySettingsStore = companySettingsStore;
  }

  @observable
  public employeeFields: DataFieldWithDataType[] = [
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FULL_NAME },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.JOB_TITLE },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.LOCATION_LEVEL_1 },
    { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.ORGANIZATION_LEVEL_1 },
  ];

  @observable
  public employeeHierarchy: ApiHierarchyItem[] = [];

  @action
  public setEmployeeFields = (newEmployeeFields: DataFieldWithDataType[]) => {
    this.employeeFields = sortBySorterArray(
      newEmployeeFields,
      panalytFieldNameSortOrder.map((f) => f.dataField)
    ); //TODO: pass the whole field in
  };

  @computed
  public get EMPLOYEE_HIERARCHY_QUERY(): ApiMasterDataQuery {
    const queryDimensions: DataFieldWithDataType[] = [
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYEE_ID },
      { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.MANAGER_ID },
      ...this.employeeFields,
    ];
    return {
      dataType: DataTypes.EMPLOYEE,
      dimensions: queryDimensions.map((d) => d.dataField),
      filterItems: [
        {
          property: 'EMPLOYMENT_TEMPORALITY',
          operation: 'EQUAL',
          values: ['PRESENT', 'PAST'],
          dataType: 'EMPLOYEE',
        },
      ],
    };
  }

  @action
  public loadEmployeeHierarchy = async () => {
    const rootEmpId = this.companySettingsStore.rootEmpId();
    if (!rootEmpId) {
      return;
    }
    this.loadingStateStore.loadStarted();
    const result = await GetResponseForMasterQueryService(this.EMPLOYEE_HIERARCHY_QUERY);
    const rootDp = result.dataPoints?.find(
      (dp) => getPropertyFromDatapoint(dp, EmployeeDataFields.EMPLOYEE_ID) === rootEmpId
    );
    if (!rootDp) {
      this.loadingStateStore.loadFinished();
      return;
    }
    const employeeHierarchy: ApiHierarchyItem = this.createApiHierarchyItemFromDataPoint(rootDp, result.dataPoints);

    this.employeeHierarchy = [employeeHierarchy];
    this.loadingStateStore.loadFinished();
  };

  private createApiHierarchyItemFromDataPoint = (
    rootEmpDatapoint: ApiMasterDataQueryResponseDataPoint,
    allDatapoints: ApiMasterDataQueryResponseDataPoint[]
  ): ApiHierarchyItem => {
    const empId = getPropertyFromDatapoint(rootEmpDatapoint, EmployeeDataFields.EMPLOYEE_ID);
    const empName = getPropertyFromDatapoint(rootEmpDatapoint, EmployeeDataFields.FULL_NAME);
    const employeeFields: Record<string, string | number> = {};
    rootEmpDatapoint.dimensions.forEach((dim) => (employeeFields[dim.property] = dim.value));
    const childrenDps = allDatapoints.filter((dp) => {
      const managerID = getPropertyFromDatapoint(dp, EmployeeDataFields.MANAGER_ID);
      return managerID === empId;
    });
    const subItems: ApiHierarchyItem[] = childrenDps.map((childDp) => {
      return this.createApiHierarchyItemFromDataPoint(childDp, [...allDatapoints]);
    });

    return {
      name: empId,
      displayName: empName,
      employeeFields,
      subItems,
    };
  };
}
