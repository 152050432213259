import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Drawer, IconButton, Toolbar } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import whiteLogoUrl from '../../../../assets/image/panalyt_logo_white.png';
import { areBlockingDependenciesReady } from '../../../private-routes/pre-render-app-guards/DependenciesGuard';
import { rootStore } from '../../store/root-store';
import { EmployeeProfile } from '../employee-profile/EmployeeProfile';
import { FixedWidthGap, Flex, Spacer } from '../layout';
import NavigationTray from '../navigation-bar/NavigationTray';
import { styled } from '../styled';
import { Account } from './Account';
import { Domain } from './Domain';
import { IntercomHelp } from './IntercomHelp';
import LanguageSwitcher from './LanguageSwitcher';
import { MainContentGuard } from './MainContentGuard';
import { Search } from './Search';
import { SessionRecording } from './SessionRecording';
// import { SessionRecording } from './SessionRecording';

const drawerWidth = 250;

export const Header = styled(Flex)`
  align-items: center;
  padding: 0 8px;
  min-height: 35px;
  @media (min-width: 0px) and (orientation: landscape) {
    min-height: 35px;
  }
  @media (min-width: 600px) {
    min-height: 35px;
  }
`;
// I have changed min-heights of 56, 48, and 64 respectively for different devices to 35 each
// Might need to change later for different devices

export const StyledAppBar = styled(AppBar)`
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2) !important;
  height: 35px !important;
` as typeof AppBar;

const StyledLink = styled(Link)`
  position: absolute;
  z-index: 1;
  top: 35;
  left: 50%;
  transform: translate(-50%, 0);
`;

const StyledLinkWithReload = styled('div')`
  position: absolute;
  z-index: 1;
  top: 35;
  left: 50%;
  transform: translate(-50%, 0);
  cursor: pointer;
`;

const StyledDrawer = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;
  & .paper {
    width: ${drawerWidth}px;
    background: #00a599;
    overflow: visible;
  }
  & .paper1 {
    width: ${drawerWidth}px;
    background: #00a599;
  }
` as typeof Drawer;

const StyledToolbar = styled(Toolbar)`
  min-height: 0px;
` as typeof Toolbar;

export const Logo = styled('img')`
  display: block;
  height: 25px;
`;

const StyledIconButton = styled(IconButton)`
  padding: 5px;
  &.disabled {
  }
` as typeof IconButton;

interface ShellProps {
  reloadOnMyPanalytIconClick?: boolean;
  children?: React.ReactNode;
}

const canRenderShell = () => {
  const { isLoggedIn } = rootStore.authStore;
  const { domain } = rootStore.companyStore;
  const blockingDependenciesReady = areBlockingDependenciesReady();
  return isLoggedIn && domain && blockingDependenciesReady;
};

export const Shell: React.FC<React.PropsWithChildren<ShellProps>> = observer((props) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => setMenuOpen(!isMenuOpen);
  const { children } = props;

  const redirectToHome = () => {
    window.location.pathname = '/';
  };

  if (!canRenderShell()) {
    return <>{props.children}</>;
  }

  return (
    <Flex>
      <StyledAppBar position="fixed">
        <StyledToolbar>
          <StyledIconButton color="inherit" aria-label="Open menu" onClick={toggleMenu}>
            <MenuIcon className="intercom_appbar_nav" />
          </StyledIconButton>
          <Search />
          <Spacer />
          {props.reloadOnMyPanalytIconClick ? (
            <StyledLinkWithReload onClick={redirectToHome}>
              <Logo src={whiteLogoUrl} className="intercom_appbar_logo" />
            </StyledLinkWithReload>
          ) : (
            <StyledLink to="/my-panalyt">
              <Logo src={whiteLogoUrl} className="intercom_appbar_logo" />
            </StyledLink>
          )}
          <Spacer />
          <Domain />
          <Account />
          <LanguageSwitcher />
          <FixedWidthGap gap="8px" />
          <RenderSessionRecording />
          <IntercomHelp />
        </StyledToolbar>
      </StyledAppBar>
      <StyledDrawer anchor="left" open={isMenuOpen} onClose={toggleMenu} classes={{ paper: 'paper' }}>
        <NavigationTray toggleMenu={toggleMenu} />
      </StyledDrawer>
      <main>
        <Header />
        <MainContentGuard>
          <Flex>{children}</Flex>
        </MainContentGuard>
        <RenderEmployeeProfile />
      </main>
    </Flex>
  );
});

const RenderEmployeeProfile = observer(() => {
  const { getEmployeeProfileId, getEmployeeProfileOpen, handleClose } = rootStore.employeeProfileStore;
  const employeeProfileId = getEmployeeProfileId();
  const employeeProfileOpen = getEmployeeProfileOpen();
  return employeeProfileId ? (
    <EmployeeProfile open={employeeProfileOpen} onClose={handleClose} employeeId={employeeProfileId} />
  ) : null;
});

const RenderSessionRecording = observer(() => {
  return rootStore.enabledFeaturesService.isSessionRecordingEnabled() ? <SessionRecording /> : null;
});
