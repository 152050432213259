import React from 'react';
import { RouteProps } from 'react-router';
import { environmentService } from '../environment/environment-service';
import { rootStore } from '../store/root-store';
import { lazyWithRetry } from '../utilFunctions/pure-utils';
import '/node_modules/react-grid-layout/css/styles.css';
import '/node_modules/react-resizable/css/styles.css';
const Attrition = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/attrition/Attrition'));
const AttritionPrediction = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/attrition-prediction/AttritionPrediction')
);
const PeopleBalanceSheet = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ '../../pages/dashboard/balance-sheet/dashboards/people-balance-sheet/PeopleBalanceSheetDashboard'
    )
);

const RecruitmentBalanceSheet = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ '../../pages/dashboard/balance-sheet/dashboards/recruitment-balance-sheet/RecruitmentBalanceSheetDashboard'
    )
);
const Diversity = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/diversity/Diversity'));
const JoinersAndLeavers = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/joiners-leavers/JoinersAndLeavers')
);
const MedianBase = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/median-base/MedianBase')
);
const PeopleSnapshot = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/people-snapshot/PeopleSnapshot')
);
const TimeAndAttendance = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/time-and-attendance/TimeAndAttendance')
);
const SurveyDashboard = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/survey-dashboard/SurveyDashboard')
);
const Recruitment = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/recruitment/Recruitment')
);
const CompanyStructureDashboard = lazyWithRetry(
  () =>
    import(/* webpackPrefetch: true */ '../../pages/dashboard/company-structure-dashboard/company-structure-dashboard')
);

const Ona = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/ona-relational-analytics/Ona')
);
const OnaRelationshipToClients = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/ona-relationship-to-clients/OnaRelationshipToClients')
);
const OnaTrains = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/ona-trains/Ona'));

const Evaluation = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/evaluation/Evaluation')
);
const Movement = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/dashboard/movements/Movements'));
const RecruitmentFunnel = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/recruitment-funnel/RecruitmentFunnel')
);
const Payroll = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/dashboard/payroll-dashboard/Payroll')
);

const LoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/login-page'));
const CrextaLoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/crexta-login-page'));
const SamlLoginPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/saml-login-page'));
const ForgotPasswordPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/login/forgot-password-page')
);
const ResetPasswordPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/reset-password/reset-password')
);
const VerifyEmail = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/login/verify-email'));

const MyPanalytPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/my-panalyt/my-panalyt-page'));
const Alias = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/alias/Alias'));
const UserPermissions = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/admin/user/permissions/UserPermissions')
);
const EditRolePermissions = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/admin/user/permissions/EditRolePermissions')
);
const DataUpload = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/data-upload/DataUpload'));
const CompanyList = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/CompanyList')
);
const ManageDomainSettings = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/ManageDomainSettings')
);
const ManageDomainPreferences = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/ManageDomainPreferences')
);
const CompanyDashboards = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/CompanyDashboards')
);
const BackendApi = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/BackendApi')
);
const ReportsPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/reports/reports-page'));
const MyAccount = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../pages/admin/my-account/MyAccount'));
const ErrorFallback = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../ErrorFallback'));
const ErrorFallbackCrexta = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../ErrorFallbackCrexta'));
const DomainPreferences = lazyWithRetry(
  () =>
    import(/* webpackPrefetch: true */ '../../pages/panalyt-superadmin/company/domain-preferences/DomainPreferences')
);
const LoginErrorPage = lazyWithRetry(() => import(/* webpackPrefetch: true */ '../../LoginErrorPage'));

export interface DashboardRouteProps extends RouteProps {
  id: string;
  debug?: boolean;
  isEnabled?: () => boolean;
}

const domainsWithSamlLogin = ['valentinsaml.panalyt.com', 'jt.panalyt.com', 'jt2.panalyt.com'];

const getLoginPage = () => {
  if (domainsWithSamlLogin.some((domain) => window.location.origin.includes(domain))) {
    return SamlLoginPage;
  } else if (environmentService.isCrexta) {
    return CrextaLoginPage;
  } else {
    return LoginPage;
  }
};

export const publicRoutes: DashboardRouteProps[] = [
  {
    id: 'login',
    path: '/login',
    component: getLoginPage(),
  },
  {
    id: 'forgotpassword',
    path: '/forgotpassword',
    component: ForgotPasswordPage,
  },
  {
    id: 'reset',
    path: '/reset',
    component: ResetPasswordPage,
  },
  {
    id: 'verifyEmail',
    path: '/verify-email',
    component: VerifyEmail,
  },
  {
    id: 'samlError',
    path: '/login-error',
    component: LoginErrorPage,
  },
  {
    id: 'setup-error',
    path: '/setup-error',
    component: ErrorFallbackCrexta,
  },
];

export const dataUploadRoute = {
  id: 'adminDataUpload',
  path: '/admin/data-upload',
  component: DataUpload,
  isEnabled: () => rootStore.permissionsStore.canUploadData(),
};

export const pageRoutes: DashboardRouteProps[] = [
  {
    id: 'myPanalyt',
    path: '/my-panalyt',
    component: MyPanalytPage,
  },
  {
    id: 'adminAlias',
    path: '/admin/alias',
    component: Alias,
  },
  {
    id: 'userPermissions',
    path: '/admin/permissions',
    component: UserPermissions,
    isEnabled: () => rootStore.featureAccessService.canViewUserPermissions(),
  },
  {
    id: 'editRolePermissions',
    path: '/admin/edit-role-permissions',
    component: () => <EditRolePermissions isReadonly={false} />,
    isEnabled: () => rootStore.featureAccessService.canWriteRoleSettings(),
  },
  {
    id: 'viewRolePermissions',
    path: '/admin/view-role-permissions',
    // just adding another url to the same page for readonly view of permissions
    component: () => <EditRolePermissions isReadonly={true} />,
    isEnabled: () => rootStore.featureAccessService.canViewRoleSettings(),
  },
  dataUploadRoute,
  {
    id: 'reports',
    path: '/reports',
    component: ReportsPage,
  },
  {
    id: 'panalytSuperadminCompany',
    path: '/panalyt-superadmin/company-list',
    component: CompanyList,
  },
  {
    id: 'domainPreferencesAdminPage',
    path: '/panalyt-superadmin/manage-company/domain-preferences',
    component: DomainPreferences,
    isEnabled: () => rootStore.enabledFeaturesService.isDomainPreferencesEnabled(),
  },
  {
    id: 'panalytSuperadminManageDomainSettings',
    path: '/panalyt-superadmin/manage-domain-settings/:domain?',
    component: ManageDomainSettings,
  },
  {
    id: 'panalytSuperadminManageDomainPreferences',
    path: '/panalyt-superadmin/manage-domain-preferences/:domain?',
    component: ManageDomainPreferences,
  },
  {
    id: 'panalytSuperadminManageCompanyDashboards',
    path: '/panalyt-superadmin/company-dashboards/:domain?',
    component: CompanyDashboards,
  },
  {
    id: 'panalytSuperadminBackendApi',
    path: '/panalyt-superadmin/backend-api/:domain?',
    component: BackendApi,
  },
  {
    id: 'adminMyAccount',
    path: '/admin/my-account',
    component: MyAccount,
  },
  {
    id: 'somethingWentWrong',
    path: '/something-went-wrong',
    component: ErrorFallback,
  },
];

export const dashboardRoutes: DashboardRouteProps[] = [
  {
    id: 'peopleSnapshot',
    path: '/dashboard/people-snapshot/:pageNumber?',
    component: PeopleSnapshot,
  },
  {
    id: 'genderDiversity',
    path: '/dashboard/diversity-gender/:pageNumber?',
    component: Diversity,
  },
  {
    id: 'attrition',
    path: '/dashboard/attrition/:pageNumber?',
    component: Attrition,
  },
  {
    id: 'attritionPrediction',
    path: '/dashboard/attrition-prediction/:pageNumber?',
    component: AttritionPrediction,
  },
  {
    id: 'joinersAndLeavers',
    path: '/dashboard/joiners-leavers/:pageNumber?',
    component: JoinersAndLeavers,
  },
  {
    id: 'medianBase',
    path: '/dashboard/median-base/:pageNumber?',
    component: MedianBase,
  },
  {
    id: 'recruitment',
    path: '/dashboard/recruitment/:pageNumber?',
    component: Recruitment,
  },
  {
    id: 'recruitmentFunnel',
    path: '/dashboard/recruitment-funnel/:pageNumber?',
    component: RecruitmentFunnel,
  },
  {
    id: 'survey',
    path: '/dashboard/survey/:pageNumber?',
    component: SurveyDashboard,
  },
  {
    id: 'companyStructure',
    path: '/dashboard/company-structure',
    component: CompanyStructureDashboard,
  },
  {
    id: 'ona',
    path: '/dashboard/ona/:pageNumber?',
    component: Ona,
    debug: true,
  },
  {
    id: 'onaRelationshipToClients',
    path: '/dashboard/ona-relationship-to-clients/:pageNumber?',
    component: OnaRelationshipToClients,
    debug: true,
  },
  {
    id: 'onaTrains',
    path: '/dashboard/ona-trains/:pageNumber?',
    component: OnaTrains,
    debug: true,
  },
  {
    id: 'evaluation',
    path: '/dashboard/evaluation/:pageNumber?',
    component: Evaluation,
    debug: true,
  },
  {
    id: 'movement',
    path: '/dashboard/movement/:pageNumber?',
    component: Movement,
  },
  {
    id: 'payroll',
    path: '/dashboard/payroll/:pageNumber?',
    component: Payroll,
    debug: true,
  },
  {
    id: 'workHoursAndOt',
    path: '/dashboard/work-hours-and-ot/:pageNumber?',
    component: TimeAndAttendance,
  },
  {
    id: 'peopleBalanceSheet',
    path: '/dashboard/people-balance-sheet/:pageNumber?',
    component: PeopleBalanceSheet,
    debug: true,
  },
  {
    id: 'recruitmentBalanceSheet',
    path: '/dashboard/recruitment-balance-sheet/:pageNumber?',
    component: RecruitmentBalanceSheet,
    debug: true,
  },
];
