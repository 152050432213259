import { ForecastConfig } from './forecast-store';

export const nonForecastedResultStackId = 'nonForecastedResult';
export const forecastedResultStackId = 'forecastedResult';
export const byDimensionDatasetForForecastSorter = (a: any, b: any) => {
  if (a.stack === b.stack) {
    return 0;
  } else {
    return a.stack === nonForecastedResultStackId ? -1 : 1;
  }
};

export const isForecastEnabled = (forecastConfig: ForecastConfig | null) => forecastConfig;
