import { environmentService } from '../environment/environment-service';
import { ErrorTypes, trackAPIError, trackError } from '../sentry/sentry';
import { rootStore } from '../store/root-store';

export interface GraphQLErrorTags {
  request: string;
  [key: string]: string | null;
}

export enum GraphQlRequestErrorCode {
  query = 'query',
  Err_AuthorizationDenied = 'Err_AuthorizationDenied',
  Err_RoleNotFound = 'Err_RoleNotFound',
  Err_UserNotFound = 'Err_UserNotFound',
  Err_EmployeeNotFound = 'Err_EmployeeNotFound',
  Err_OrganizationPathNotFound = 'Err_OrganizationPathNotFound',
  Err_NoLatestVersionFound = 'Err_NoLatestVersionFound',
  Err_NoFirstVersionFound = 'Err_NoFirstVersionFound',
  Err_ReportNotFound = 'Err_ReportNotFound',
  Err_DashboardNotFound = 'Err_DashboardNotFound',
  Err_AliasNotFound = 'Err_AliasNotFound',
  Err_PermissionIdNotFound = 'Err_PermissionIdNotFound',
  Err_DomainSettingsNotFound = 'Err_DomainSettingsNotFound',
  Err_DomainSettingsVersionNotFound = 'Err_DomainSettingsVersionNotFound',
  Err_DomainPreferencesNotFound = 'Err_DomainPreferencesNotFound',
  Err_DomainPreferencesVersionNotFound = 'Err_DomainPreferencesVersionNotFound',
  Err_RolePreferencesNotFound = 'Err_RolePreferencesNotFound',
  Err_RolePreferencesVersionNotFound = 'Err_RolePreferencesVersionNotFound',
  Err_UserAlreadyExists = 'Err_UserAlreadyExists',
  Err_MetaDataError = 'Err_MetaDataError',
  Err_SameNameRoleAlreadyExists = 'Err_SameNameRoleAlreadyExists',
  Err_QueryExecutionError = 'Err_QueryExecutionError',
  Err_MultipleEmployeesForId = 'Err_MultipleEmployeesForId',
  Err_AmbiguousPopFilter = 'Err_AmbiguousPopFilter',
  Err_NoReadDataPermissions = 'Err_NoReadDataPermissions',
  Err_AmbiguousReadDataPermissions = 'Err_AmbiguousReadDataPermissions',
  Err_DeleteFileFailed = 'Err_DeleteFileFailed',
  Err_ListFilesFailed = 'Err_ListFilesFailed',
  Err_GetBucketFailed = 'Err_GetBucketFailed',
  Err_CreateBucketFailed = 'Err_CreateBucketFailed',
  Err_CreateFolderFailed = 'Err_CreateFolderFailed',
  Err_CreateFoldersFailed = 'Err_CreateFoldersFailed',
  Err_FilterInvalid = 'Err_FilterInvalid',
  Err_SendingEmailError = 'Err_SendingEmailError',
  Err_ExternalSystemUserCreationError = 'Err_ExternalSystemUserCreationError',
  Err_ResetPasswordError = 'Err_ResetPasswordError',
  Err_ValidationFailed = 'Err_ValidationFailed',
  Err_Unspecified = 'Err_Unspecified',
  Err_DomainAccessDeniedWrongAuthMethod = 'Err_DomainAccessDeniedWrongAuthMethod',
  Err_DomainAccessDeniedNotEnabled = 'Err_DomainAccessDeniedNotEnabled',
}

export interface QueryErrorEntity {
  code: GraphQlRequestErrorCode;
  errorReference: string;
}

//this function should only be used for queries. We need a different one with better error handling for non queries
export const handleGQLErrors = async <T>(result: Promise<T>): Promise<T | ''> => {
  const isPanalytSuperAdminAndNonProdEnv = rootStore.permissionsStore.canManageAllDomains && !environmentService.isProd;
  return result.catch((error) => {
    console.log(error);
    const errorReferences = error?.response?.errors?.map((e: any) => e.extensions?.errorReference);
    if (errorReferences?.[0]) {
      rootStore.errorStore.setErrorReference(errorReferences[0]);
    }
    if (isPanalytSuperAdminAndNonProdEnv) {
      // Not releasing to clients yet
      rootStore.errorStore.queryErrors.push({
        code: error?.response?.errors?.[0].extensions?.errorCode ?? 'query',
        errorReference: errorReferences?.[0],
      });
    }
    trackError(`Error(s) when executing graphql query`, { errorReferences });

    return Promise.reject();
  });
};

export const getGraphqlResult = <T>(result: T | null): T => {
  if (result === null) {
    throw new Error(`Unexpected state - null value found for graphql response. This should never happen as
    the function should throw an error before reaching this point, since null response only happens in case
    of errors`);
  } else {
    return result;
  }
};

export class GraphQLError {
  public error: Error;
  public combinedErrorRefs: string;
  constructor(error: Error, combinedErrorRefs: string) {
    this.error = error;
    this.combinedErrorRefs = combinedErrorRefs;
  }
}

export const getErrorCodeFromError = (error: any) => {
  return error?.response?.errors?.[0].extensions?.errorCode;
};

export const errorHandler = (error: any, tags?: GraphQLErrorTags, errorType?: ErrorTypes) => {
  const combinedErrorRefs = error?.response?.errors?.map((e: any) => e.extensions?.errorReference).join(',');
  const gqlError = new GraphQLError(error, combinedErrorRefs);
  rootStore.errorStore.queryErrors.push({
    code: getErrorCodeFromError(error),
    errorReference: combinedErrorRefs,
  });
  trackAPIError(error, {}, { combinedErrorRefs, ...tags }, errorType);
  return Promise.reject(gqlError);
};
