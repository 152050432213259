import { deepCopy } from '@firebase/util';
import memoize from 'fast-memoize';
import {
  AssumeRole,
  CreateReports,
  CreateRole,
  DeleteRole,
  EditRoleScope,
  ExportReports,
  GrantPermissions,
  Login,
  ManageCompany,
  ManageDomain,
  ManageUsers,
  ReadClientFile,
  ReadData,
  RequestPasswordChange,
  RevokePermissions,
  UpdatePassword,
  UpdateRoleName,
  UseFilters,
  ViewDashboards,
  ViewMetrics,
  ViewPermissions,
  ViewReports,
  ViewRoles,
  ViewRoleScope,
  WriteClientFile,
} from '../graphql/generated/graphql-sdk';

/**
 * Generally we should see if we can improve handling of specific types, such as domain which is a graphql-scalar but actually will always be a string.
 * This should be reflected in the generated typescript types, but currently it's "any"
 * It's probably not possible (see https://github.com/dotansimha/graphql-code-generator/pull/6609/files#diff-8b2afa90772d605b03086f34fa2820a948e750b4f8bbcf092b94af83afbdc3dcR241)
 * so we might want to remove the scalars from the schema itself and default to strings - which is not as clean but easier to handle without conversions
 */

export type RoleId = string; // UUID

/**
 * An assigned permission is a permission such as ViewDashbaords(swan, [dashboard1, dashboard2, ...]) that is assigned to a role.
 * The id is a unique id that can be used to refer to the permission and to e.g. request its deletion/revocation.
 */
export type AssignedPermission<T extends Permission> = {
  id: string; // UUID
  roleId: RoleId; // UUID
  permission: T;
};

/**
 * Since our permissions concept allows for recursion, we explicitly specify how deep we query and use the following types to describe that.
 * Recursion can happen through the grant-permissions-permission,
 * i.e. the ability for a role A to give role B the permission do give permissions to role B.
 * E.g. in the simpler case, a superadmin can grant an admin the permission to grant a user to see only specific data.
 * In the recursive case, a superadmin can grant an admin the permission to allow a role A to grant permissions to a role B.
 * We currently don't use this, but in theory the system supports it and therefore the types reflect it.
 */
export type Permission =
  | AssumeRole
  | CreateReports
  | CreateRole
  | DeleteRole
  | ExportReports
  | GrantablePermission
  | Login
  | ManageCompany
  | ManageDomain
  | ManageUsers
  | ReadData
  | RequestPasswordChange
  | RevokePermissions
  | UpdatePassword
  | UpdateRoleName
  | UseFilters
  | ViewMetrics
  | ViewDashboards
  | ViewPermissions
  | ViewReports
  | ViewRoles
  | ViewRoleScope
  | ViewMetrics
  | EditRoleScope
  | ReadClientFile
  | WriteClientFile;

export enum PermissionTypes {
  AssumeRole = 'AssumeRole',
  CreateReports = 'CreateReports',
  CreateRole = 'CreateRole',
  DeleteRole = 'DeleteRole',
  ExportReports = 'ExportReports',
  GrantPermissions = 'GrantPermissions',
  Login = 'Login',
  ManageCompany = 'ManageCompany',
  ManageDomain = 'ManageDomain',
  ManageUsers = 'ManageUsers',
  ReadData = 'ReadData',
  RequestPasswordChange = 'RequestPasswordChange',
  RevokePermissions = 'RevokePermissions',
  ViewReports = 'ViewReports',
  UpdatePassword = 'UpdatePassword',
  UpdateRoleName = 'UpdateRoleName',
  UseFilters = 'UseFilters',
  ViewDashboards = 'ViewDashboards',
  ViewPermissions = 'ViewPermissions',
  ViewRoles = 'ViewRoles',
  ViewMetrics = 'ViewMetrics',
  ReadClientFile = 'ReadClientFile',
  WriteClientFile = 'WriteClientFile',
  ViewRoleScope = 'ViewRoleScope',
  EditRoleScope = 'EditRoleScope',
}

// This generates a union type of the string-enums for us.
// We need this because if we use the enum in type signatures, typescripts starts to complain with "Type instantiation is excessively deep and possibly infinite"-errors.
type PermissionType = `${PermissionTypes}`;

// We use this to map the union type "Permission" to it's graphql __typename
export type PermissionTypeOf<T extends PermissionType> = T extends 'AssumeRole'
  ? AssumeRole
  : T extends 'CreateReports'
  ? CreateReports
  : T extends 'CreateRole'
  ? CreateRole
  : T extends 'DeleteRole'
  ? DeleteRole
  : T extends 'ExportReports'
  ? ExportReports
  : T extends 'GrantPermissions'
  ? GrantPermissions
  : T extends 'Login'
  ? Login
  : T extends 'ManageCompany'
  ? ManageCompany
  : T extends 'ManageDomain'
  ? ManageDomain
  : T extends 'ManageUsers'
  ? ManageUsers
  : T extends 'ReadData'
  ? ReadData
  : T extends 'RequestPasswordChange'
  ? RequestPasswordChange
  : T extends 'RevokePermissions'
  ? RevokePermissions
  : T extends 'UpdatePassword'
  ? UpdatePassword
  : T extends 'UpdateRoleName'
  ? UpdateRoleName
  : T extends 'UseFilters'
  ? UseFilters
  : T extends 'ViewDashboards'
  ? ViewDashboards
  : T extends 'ViewPermissions'
  ? ViewPermissions
  : T extends 'ViewReports'
  ? ViewReports
  : T extends 'ViewRoles'
  ? ViewRoles
  : T extends 'ViewMetrics'
  ? ViewMetrics
  : T extends 'ReadClientFile'
  ? ReadClientFile
  : T extends 'ViewRoleScope'
  ? ViewRoleScope
  : T extends 'EditRoleScope'
  ? EditRoleScope
  : T extends 'WriteClientFile'
  ? WriteClientFile
  : PermissionTypeOf<T>; // Using recursiveness here to fail compilation if a type-matching has been forgotten - it will result in the type becoming "any"

// This describes the permission(s) that can be granted to another role.
// The GrantPermissions-permission is of type GrantPermissionsL2 here to stop the nesting/recursion (which could be infinite in theory)
export type GrantablePermission =
  | AssumeRole
  | CreateReports
  | CreateRole
  | DeleteRole
  | ExportReports
  | GrantPermissionsL2
  | Login
  | ManageCompany
  | ManageDomain
  | ManageUsers
  | ReadData
  | RequestPasswordChange
  | RevokePermissions
  | UpdatePassword
  | UpdateRoleName
  | UseFilters
  | ViewDashboards
  | ViewPermissions
  | ViewReports
  | ViewRoles;

export type GrantPermissionsL1 = {
  __typename?: 'GrantPermissions';
  domain: any;
  excludedRoles: any[] | null;
  permissions: GrantablePermission[] | null;
  roles: any[] | null;
};

export type GrantPermissionsL2 = {
  __typename?: 'GrantPermissions';
  domain: any;
};

export interface Role {
  __typename?: 'Role';
  id: string;
  name: string;
  domain: string | null;
}

// Inspired by https://blog.simontest.net/extend-array-with-typescript-965cc1134b3
export class AssignedPermissions<T extends Permission> extends Array<AssignedPermission<T>> {
  constructor(args: Array<AssignedPermission<T>>) {
    if (typeof args === 'number') {
      // Cause javascript internally calls this with a number (to create an array with a predefined size)
      // @ts-ignore
      super(args);
    } else {
      super(...args);
    }
  }

  public filterByType<PT extends PermissionType>(type: PT): AssignedPermissions<PermissionTypeOf<PT>> {
    return this.filter((p) => p.permission.__typename === type) as AssignedPermissions<PermissionTypeOf<PT>>;
  }

  public findByType<PT extends PermissionType>(type: PT): AssignedPermission<PermissionTypeOf<PT>> | undefined {
    return this.find((p) => p.permission.__typename === type) as AssignedPermission<PermissionTypeOf<PT>>;
  }

  public validFor = memoize((domain: string): AssignedPermissions<T> => {
    return this.filter((p) => p.permission.domain === null || p.permission.domain === domain) as AssignedPermissions<T>;
  });

  public nonEmpty(): boolean {
    return !!this.length;
  }

  // Finds permissions that are domain specific and excludes permissions that are valid for multiple domains
  // We mostly need this to emulate the current usertype-permission behaviour. Eventually, the UI should deal with both permission-types
  public validForAndNotGlobal(domain: string): AssignedPermissions<T> {
    return this.filter((p) => p.permission.domain !== null && p.permission.domain === domain) as AssignedPermissions<T>;
  }
}

// These are the names of roles that have been created for every domain to keep compatibility with the user-types
// Eventually there will be no guarantee that these roles exist in the system
export enum CommonDomainRoles {
  EMPLOYEE = 'EMPLOYEE',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}

// need to ask valentin what's the best place to do this
export const getPermissionInputFromPermission = <T extends Permission>(permission: T) => {
  const permissionInput = deepCopy(permission);
  delete permissionInput['__typename'];
  return permissionInput;
};

export type TagObject = { tagType: string; tagValue: string };

// We except more types of tags (e.g. metric families) at some point
export enum ViewMetricsTagType {
  SINGLE = 'metric', // for this tag, we expect the value to be a single metricId
}
