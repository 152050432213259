import { observer } from 'mobx-react';
import React, { Suspense, useEffect, useState } from 'react';
import { Intercom } from 'react-live-chat-loader';
import { useLocation } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import LoadingOverlay from './common/components/utils/LoadingOverlay';
import { publicRoutes } from './common/routes';
import { defaultTheme } from './common/theme/default-theme';
import { PrivateRoutes } from './private-routes/PrivateRoutes';

export const RenderRoutes = observer(() => {
  const location = useLocation();
  const [isPublicRoute, setIsPublicRoute] = useState(true);
  useEffect(() => {
    setIsPublicRoute(publicRoutes.some((route) => route.path?.includes(location.pathname)));
  }, [location.pathname]);

  return (
    <Suspense fallback={<LoadingOverlay />}>
      {isPublicRoute && <Intercom color={defaultTheme.color.primary} containerClass="intercom-button" />}
      <Switch>
        <Redirect from="/" to="/my-panalyt" exact />
        {publicRoutes.map((route, i) => (
          <Route key={i} {...route} />
        ))}
        <PrivateRoutes />
      </Switch>
    </Suspense>
  );
});
