import groupBy from 'lodash.groupby';
import { PbsBackendMetrics } from '../../../pages/dashboard/balance-sheet/backend-metrics/metrics-definitions';
import {
  PbsCategories,
  PbsHierarchicalValue,
  PbsItems,
  recruitmentFunnelPbsItems,
} from '../../../pages/dashboard/balance-sheet/types';
import { sortBySorterArray } from '../../utilFunctions/sorters';

export const defaultRbsMetrics: (allowedMetrics: PbsItems[]) => PbsHierarchicalValue[] = (
  allowedPbsItems: PbsItems[]
) =>
  Object.entries(
    groupBy(
      PbsBackendMetrics.filter(
        (pbm) =>
          allowedPbsItems.includes(pbm.pbsItem) &&
          (recruitmentFunnelPbsItems as readonly PbsItems[]).includes(pbm.pbsItem)
      ),
      (pbm) => pbm.category
    )
  ).map(([category, metrics]) => {
    return {
      value: category as PbsCategories,
      subItems: sortBySorterArray([...new Set(metrics.map((m) => m.pbsItem))], recruitmentFunnelPbsItems),
    };
  });
