import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import { action, computed, observable } from 'mobx';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { SideBarLink } from '../../../pages/panalyt-superadmin/SideBar';
import { ReportBug } from '../../../pages/report-bug/ReportBug';
import { isEnabledNotIntro } from '../../dashboard-store';
import { rootStore } from '../../store/root-store';
import NavTraySection from './NavTraySection';

export const panalytSuperAdminConsoleItems: SideBarLink[] = [
  {
    id: 'companyList',
    name: 'common:navigation.companyList',
    link: '/panalyt-superadmin/company-list',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_company-list',
  },
  {
    id: 'manageDomainSettings',
    name: 'common:navigation.manageDomainSettings',
    link: '/panalyt-superadmin/manage-domain-settings',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_manage-domain-settings',
  },
  {
    id: 'manageDomainPreferences',
    name: 'common:navigation.manageDomainPreferences',
    link: '/panalyt-superadmin/manage-domain-preferences',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_manage-domain-preferences',
  },
  {
    id: 'companyDashboards',
    name: 'common:navigation.companyDashboards',
    link: '/panalyt-superadmin/company-dashboards',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_company-dashboards',
  },
  {
    id: 'domainSettings',
    name: 'common:navigation.domainSettings',
    link: '/panalyt-superadmin/manage-company/domain-preferences',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_domain-settings',
  },
  {
    id: 'backendApi',
    name: 'common:navigation.backendApi',
    link: '/panalyt-superadmin/backend-api',
    avatar: <SupervisedUserCircleIcon color="secondary" />,
    className: 'intercom_panalyt-super-admin_backend-api',
  },
];

const StyledLink = styled(Link as any)`
  text-decoration: none;
`;
const myPanalytLink = '/my-panalyt';

const NavTrayContainer = styled.div`
  // overflow: auto;
`;
// TODO: disabling overflow here as it is somehow causing menu in NavTrayItem to not appear(can check with Dashboard list on nav tray).
//  Ideally there should be overflow here as well, so investigate later and fix.

interface NavigationTrayProps {
  toggleMenu: () => void;
}

interface AdminConsoleItem {
  id: string;
  name: string;
  link: string;
  className: string;
  isEnabled?: () => boolean;
}

@observer
export default class NavigationTray extends Component<NavigationTrayProps> {
  @computed
  public get adminConsoleItems() {
    const userPermissions: AdminConsoleItem = {
      id: 'permissions',
      name: 'common:navigation.permissions',
      link: '/admin/permissions',
      className: 'intercom_nt_permissions',
      isEnabled: () => rootStore.featureAccessService.canViewUserPermissions(),
    };
    const myAccount: AdminConsoleItem = {
      id: 'myAccount',
      name: 'common:navigation.myAccount',
      link: '/admin/my-account',
      className: 'intercom_nt_myaccount',
    };
    const alias: AdminConsoleItem = {
      id: 'alias',
      name: 'common:navigation.alias',
      link: '/admin/alias',
      className: 'intercom_nt_alias',
      isEnabled: () => rootStore.enabledFeaturesService.isAliasEnabled(),
    };
    const dataUpload: AdminConsoleItem = {
      id: 'dataUpload',
      name: 'common:navigation.dataUpload',
      link: '/admin/data-upload',
      className: 'intercom_nt_dataUpload',
      isEnabled: () => rootStore.permissionsStore.canUploadData(),
    };

    const adminConsoleItems = [userPermissions, myAccount, alias, dataUpload].filter((item) =>
      item.isEnabled ? item.isEnabled() : true
    );

    return adminConsoleItems;
  }
  @observable
  private reportBugOpen = false;

  public componentDidMount() {
    !rootStore.dashboardStore.dashboards.length && rootStore.dashboardStore.getAllDashboards();
  }

  public render() {
    const { toggleMenu } = this.props;
    const dashboardSubItems = rootStore.dashboardStore.allowedDashboards.map((db) => ({
      ...db,
      name: db.nameKey,
      subItems: db.pages
        .map((p) => ({ ...p, name: p.nameKey }))
        .filter((p) => isEnabledNotIntro(p) && rootStore.permissionsStore.canSeeDashboardPage(db.id, p.pageId)),
      className: 'intercom_nt_db-item',
    }));
    return (
      <>
        <NavTrayContainer>
          <StyledLink to={myPanalytLink} onClick={toggleMenu}>
            <NavTraySection
              toggleMenu={toggleMenu}
              name="common:navigation.myPanalyt"
              color="rgb(38,166,154)"
              className="intercom_nt_mypanalyt"
            />
          </StyledLink>
          <NavTraySection
            toggleMenu={toggleMenu}
            name={'common:navigation.dashboards'}
            subItems={dashboardSubItems}
            color="#FCA720"
            openByDefault={true}
            className="intercom_nt_db"
          />
          {rootStore.permissionsStore.canSeeAdminMenu() && (
            <NavTraySection
              toggleMenu={toggleMenu}
              name={'common:navigation.admin'}
              subItems={this.adminConsoleItems}
              color="#93234E"
              className="intercom_nt_admin"
            />
          )}
          {rootStore.permissionsStore.canSeeSuperAdminMenu() && (
            <NavTraySection
              toggleMenu={toggleMenu}
              name={'common:navigation.superAdmin'}
              subItems={panalytSuperAdminConsoleItems.map(({ id, name, link }) => ({
                id,
                name,
                link,
              }))}
              color="#93234E"
              className="intercom_nt_admin"
            />
          )}
        </NavTrayContainer>
        <ReportBug open={this.reportBugOpen} onClose={this.handleReportBugClose} />
      </>
    );
  }

  @action
  private handleReportBugClose = () => {
    this.reportBugOpen = false;
  };
}
