import { appCache, ICache } from '../cache/cache';
import { Domains } from '../constants/constants';
import { AliasService, IAliasService } from './alias-service';
import { NewApiAlias, NewApiAliasForDataField, NewApiAliasForMetricGroup } from './api-interfaces';

export class CachedAliasService implements IAliasService {
  private cache: ICache;
  private aliasService: AliasService;

  constructor(underlying: AliasService, cache: ICache = appCache) {
    this.cache = cache;
    this.aliasService = underlying;
  }

  public clearCache() {
    this.cache.clear();
  }

  public getAliasesForDataFields(domain: string): Promise<NewApiAliasForDataField[]> {
    const cacheKey = JSON.stringify(['getAliasesForDataFields', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAliasForDataField[]>(cacheKey, () =>
      this.aliasService.getAliasesForDataFields(domain as Domains)
    );
  }

  public getAliasesForMetricGroup(domain: string): Promise<NewApiAliasForMetricGroup[]> {
    const cacheKey = JSON.stringify(['getAliasesForMetricGroup', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAliasForMetricGroup[]>(cacheKey, () =>
      this.aliasService.getAliasesForMetricGroup(domain as Domains)
    );
  }

  public getAliases(domain: string): Promise<NewApiAlias[]> {
    const cacheKey = JSON.stringify(['getAliases', domain]);
    return this.cache.getFromCacheOrRequest<NewApiAlias[]>(cacheKey, () =>
      this.aliasService.getAliases(domain as Domains)
    );
  }

  public async setAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.aliasService.setAliasForDataField(domain, alias);
  }

  public async setAliasForMetricGroup(domain: Domains, alias: NewApiAliasForMetricGroup): Promise<void> {
    return this.aliasService.setAliasForMetricGroup(domain, alias);
  }

  public async deleteAliasForDataField(domain: Domains, alias: NewApiAliasForDataField): Promise<void> {
    return this.aliasService.deleteAliasForDataField(domain, alias);
  }

  public async deleteAliasForMetricGroup(domain: Domains, alias: NewApiAliasForMetricGroup): Promise<void> {
    return this.aliasService.deleteAliasForMetricGroup(domain, alias);
  }
}
