import { action, observable, runInAction } from 'mobx';
import { ApiMasterDataQuery, ApiUser } from '../api/api-interfaces';
import { cachedMasterDataApiService } from '../api/master-data-service';
import { UserRolePreferences } from '../api/zod-schemas';
import { CompanyStore } from '../company/company-store';
import { environmentService } from '../environment/environment-service';
import { LanguageStore } from '../localization/language-store';
import { InitialDependencyStore } from '../startup/initial-dependency-store';
import { formatUserBasedOnDisplayNameAndLocale } from '../utilFunctions/formatters';

export type StateType = 'PENDING' | 'DONE' | 'ERROR';

export interface EmployeeUser {
  employeeId: string;
  memberId: string;
}

export type EmployeeUserOrNotFound = EmployeeUser | null;

export class UserStore {
  @observable
  public currentUser: ApiUser | null = null;

  @observable
  public currentUserFetched: boolean = false;

  @observable
  public userRolePreferences: UserRolePreferences | null = null;

  @observable
  public userRolePreferencesFetched: boolean = false;

  @observable
  public message: string = '';

  @observable
  public state: StateType | null = null;

  @observable
  public domain: string = '';

  @observable
  public currentEmployeeUser: EmployeeUser | null = null;

  public companyStore: CompanyStore;
  public languageStore: LanguageStore;
  public initialDependencyStore: InitialDependencyStore;

  constructor(
    companyStore: CompanyStore,
    initialDependencyStore: InitialDependencyStore,
    languageStore: LanguageStore
  ) {
    this.companyStore = companyStore;
    this.languageStore = languageStore;
    this.initialDependencyStore = initialDependencyStore;
  }

  @action
  public getCurrentEmployeeUser = async () => {
    if (this.currentUser && this.currentUser.email) {
      // this means its not an error, i.e. user found
      const employeeUserQuery: ApiMasterDataQuery = {
        dataType: 'EMPLOYEE',
        dimensions: ['EMPLOYEE_ID', 'MEMBER_ID'],
        filterItems: [
          {
            dataType: 'EMPLOYEE',
            operation: 'EQUAL',
            property: 'COMPANY_EMAIL',
            values: [this.currentUser.email],
          },
        ],
        limitedToPermittedPopulation: false,
      };

      const employeeUserResult = await cachedMasterDataApiService.executeQuery(
        this.companyStore.domain,
        employeeUserQuery
      );

      if (employeeUserResult && employeeUserResult.dataPoints && employeeUserResult.dataPoints.length) {
        this.currentEmployeeUser = {
          employeeId: employeeUserResult.dataPoints[0].dimensions[0].value,
          memberId: employeeUserResult.dataPoints[0].dimensions[1].value,
        };
      }
    }
  };

  @action
  public async loadUserInfo() {
    const user = this.initialDependencyStore.getUser();
    this.currentUser = user;
    this.currentUserFetched = true;
    return this.currentUser;
  }

  @action
  public async loadUserRolePreference() {
    const { domain } = this.companyStore;
    if (domain) {
      const userRolePreferences = this.initialDependencyStore.getLatestUserRolePreferences();
      runInAction(() => {
        this.userRolePreferences = userRolePreferences;
        this.userRolePreferencesFetched = true;
      });
    }
    return this.userRolePreferences;
  }

  @action
  public reset() {
    this.state = null;
    this.message = '';
  }

  //TODO: move this somewhere more appropriate
  public applyWindowIntercomSettings = (userRolePreferences: UserRolePreferences | null, email: string | null) => {
    if (window.intercomSettings && email) {
      console.debug(
        `Applying Intercom settings (window.intercomSettings, userRolePreferences, email)`,
        window.intercomSettings,
        JSON.parse(JSON.stringify(userRolePreferences)),
        email
      );
      const { selectedLanguage } = this.languageStore;
      const environmentName = environmentService.getEnvironment().name;
      const profile = userRolePreferences?.settings?.profile;

      const userId = `${email} - ${environmentName}`;
      window.intercomSettings = {
        ...window.intercomSettings,
        // @ts-ignore
        // intercomSettings is an object, but react-live-chat-loader types are convinced that it's a function
        name: profile ? formatUserBasedOnDisplayNameAndLocale(profile, selectedLanguage.id) : '',
        email: email,
        user_id: userId,
      };

      if (window.Intercom) {
        console.debug(
          `Updating Intercom settings (window.intercomSettings, userRolePreferences, email)`,
          window.intercomSettings,
          JSON.parse(JSON.stringify(userRolePreferences)),
          email
        );
        // Most likely Intercom isn't initialized at this point, with an only exception: if the user has clicked on Intercom icon on login page
        // If Intercom wasn't initialized, update call isn't needed because the latest intercomSettings config will be used on init
        window.Intercom('update', window.intercomSettings);
      } else {
        console.debug(`Couldn't update Intercom settings as Intercom wasn't initialized`);
      }
    } else {
      console.debug(
        `Couldn't apply Intercom settings (window.intercomSettings, userRolePreferences, email)`,
        window.intercomSettings,
        JSON.parse(JSON.stringify(userRolePreferences)),
        email
      );
    }
  };
}
