import { action, computed, observable } from 'mobx';
import { onaStore } from '../pages/dashboard/ona-relational-analytics/ona-store';
import { ApiDashboard, ApiDashboardPage, FAVORITE_DASHBOARDS_KEY } from './api/api-interfaces';
import { DashboardApiService } from './api/dashboard-service';
import { CompanyStore } from './company/company-store';
import { Dashboards, DataTypes, Domains } from './constants/constants';
import { localStore } from './local-store';
import { IPermissionsStore } from './permissions/permissions-store';

export const isEnabledNotIntro = (page: ApiDashboardPage) => page.enabled && page.pageId !== 'intro';
export class DashboardStore {
  private companyStore: CompanyStore;
  private permissionsStore: IPermissionsStore;
  private dashboardApiService: DashboardApiService;

  constructor(
    companyStore: CompanyStore,
    permissionsStore: IPermissionsStore,
    dashboardApiService: DashboardApiService
  ) {
    this.companyStore = companyStore;
    this.permissionsStore = permissionsStore;
    this.dashboardApiService = dashboardApiService;
  }

  @computed
  private get dashboardToLatestVersionDataTypeMap(): Partial<Record<Dashboards, DataTypes>> {
    return {
      [Dashboards.ATTRITION_PREDICTION]: DataTypes.ATTRITIONPREDICTION,
      [Dashboards.ONA_RELATIONAL_ANALYTICS]: onaStore.onaMetricsDataType,
      [Dashboards.ONA_METRICS_OVER_TIME]: onaStore.onaMetricsDataType,
      [Dashboards.ONA_RELATIONSHIP_TO_CLIENTS]: onaStore.onaMetricsDataType,
      [Dashboards.EVALUATION]: DataTypes.EVALUATION,
      [Dashboards.PAYROLL]: DataTypes.PAYROLL,
      [Dashboards.WORK_HOURS_AND_OT]: DataTypes.TIMEANDATTENDANCEMONTHLY,
      [Dashboards.RECRUITMENT_OVERVIEW]: DataTypes.APPLICATION,
      [Dashboards.SURVEY]: DataTypes.QUESTIONANSWER,
    };
  }

  public getLatestVersionDataTypeForDashboard = (dashboard: Dashboards) =>
    this.dashboardToLatestVersionDataTypeMap[dashboard] ?? DataTypes.EMPLOYEE;

  public getDashboard(id: Dashboards): ApiDashboard | undefined {
    return this.allowedDashboards.find((db) => db.id === id);
  }

  @computed
  public get allowedDashboards(): ApiDashboard[] {
    const { selectedCompany } = this.companyStore;
    const companyAllowedDashboards = selectedCompany?.allowedDashboards ?? [];
    if (!companyAllowedDashboards.length) {
      return [];
    }

    let allowedDashboards = this.permissionsStore.getAllowedDashboardsForUser(
      this.dashboards,
      companyAllowedDashboards
    );

    const favoriteDashboardsRating = this.getFavoriteDashboards();

    if (Object.keys(favoriteDashboardsRating).length) {
      const favoriteAllowedDashboards = allowedDashboards
        .filter((db) => favoriteDashboardsRating[db.id])
        .sort((a, b) => favoriteDashboardsRating[b.id] - favoriteDashboardsRating[a.id]);

      allowedDashboards = Array.from(new Set([...favoriteAllowedDashboards, ...allowedDashboards]));
    }

    return allowedDashboards;
  }

  @observable
  public dashboards: ApiDashboard[] = [];

  @observable
  public dashboardsFetched = false;

  @observable
  public isFullScreen: boolean = false;

  @action
  public setFullScreen = (isFullScreen: boolean) => {
    this.isFullScreen = isFullScreen;
  };

  public toggleFullScreen = () => {
    this.isFullScreen = !this.isFullScreen;
  };

  @action
  public async getAllDashboards() {
    const dashboards = await this.dashboardApiService.listDashboards();
    this.dashboards = dashboards.dashboards;
    this.dashboardsFetched = true;
    // TODO: Remove hacky code.
    if (
      ![Domains.TIKET, Domains.HOOSIERS, Domains.LOOOP, Domains.SWAN, Domains.SHIRATORI].includes(
        this.companyStore.domain as Domains
      )
    ) {
      const surveyDb = this.dashboards.find((db) => db.id === Dashboards.SURVEY);
      if (surveyDb) {
        surveyDb.pages = surveyDb.pages.filter((p) => p.pageId !== 'survey-questions');
      }
    }
    return dashboards;
  }

  public getFavoriteDashboards() {
    return JSON.parse(localStore.get(FAVORITE_DASHBOARDS_KEY) ?? JSON.stringify({}));
  }

  public addDashboardToFavoriteDashboards(dashboard: Dashboards) {
    const favoriteDashboards = this.getFavoriteDashboards();
    localStore.set(
      FAVORITE_DASHBOARDS_KEY,
      JSON.stringify({
        ...favoriteDashboards,
        [dashboard]: (favoriteDashboards[dashboard] ?? 0) + 1,
      })
    );
  }

  public getComingSoonPages = (dashboardId: string): ApiDashboardPage[] => {
    const defaultDashboardImageLink = 'assets/image/dashboardIcons/RecruitmentOverview.png';
    if (dashboardId === Dashboards.EVALUATION) {
      return [
        {
          pageId: 'coming-soon-evaluation-outcomes',
          pageNumber: 2,
          nameKey: 'pages:dashboards.evaluation.infoPageDetails.comingSoon.outcomes',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-evaluation-overtime',
          pageNumber: 3,
          nameKey: 'pages:dashboards.evaluation.infoPageDetails.comingSoon.overtime',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-evaluation-bias',
          pageNumber: 4,
          nameKey: 'pages:dashboards.evaluation.infoPageDetails.comingSoon.evaluationBias',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-evaluation-drivers',
          pageNumber: 5,
          nameKey: 'pages:dashboards.evaluation.infoPageDetails.comingSoon.drivers',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
      ];
    } else if (dashboardId === Dashboards.ATTRITION_PREDICTION) {
      return [
        {
          pageId: 'coming-soon-attritionprediction-cohort-risk',
          pageNumber: 4,
          nameKey: 'pages:dashboards.attritionPrediction.infoPageDetails.comingSoon.cohortRisk',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-attritionprediction-driver-analysis',
          pageNumber: 5,
          nameKey: 'pages:dashboards.attritionPrediction.infoPageDetails.comingSoon.driverAnalysis',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-attritionprediction-driver-definitions',
          pageNumber: 6,
          nameKey: 'pages:dashboards.attritionPrediction.infoPageDetails.comingSoon.driverDefinitions',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
      ];
    } else if (dashboardId === Dashboards.RECRUITMENT_FUNNEL) {
      return [
        {
          pageId: 'coming-soon-recruitmentfunnel-comparison',
          pageNumber: 2,
          nameKey: 'pages:dashboards.recruitmentFunnel.infoPageDetails.comingSoon.funnelComparison',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
        {
          pageId: 'coming-soon-recruitmentfunnel-fallout-reasons',
          pageNumber: 3,
          nameKey: 'pages:dashboards.recruitmentFunnel.infoPageDetails.comingSoon.falloutReasons',
          descriptionKey: '',
          imageLink: defaultDashboardImageLink,
          link: '',
          enabled: false,
        },
      ];
    }
    return [];
  };

  public getDashboardInfoContent = (dashboardId: string) => {
    const dashboard = this.allowedDashboards.find((db) => db.id === dashboardId);
    if (dashboard !== undefined) {
      const introPage = dashboard.pages.find((p) => p.pageId === 'intro');
      return {
        ...dashboard,
        nameKey: introPage?.nameKey ?? dashboard.nameKey,
        pages: [
          ...(dashboard.pages
            ?.filter((p) => isEnabledNotIntro(p))
            .map((item, index) => ({
              ...item,
              index: index + 1,
            })) ?? []),
          ...this.getComingSoonPages(dashboardId),
        ],
      };
    } else {
      return null;
    }
  };
}
