import { DataFieldWithDataType, toDataFieldWithDataType } from '../../../common-types';
import { HierarchicalFields } from '../api/api-interfaces';
import {
  ApplicationDataFields,
  DataTypes,
  EmployeeDataFields,
  EvaluationDataFields,
  JobDataFields,
  SurveyDataFields,
  TimeAndAttendanceMonthlyDataFields,
} from '../constants/constants';
import { rootStore } from '../store/root-store';
import { enumValues } from '../utilFunctions/pure-utils';

// nonFrontendQueryableFields are those that won't be used in queries
// from the frontend and therefore don't need permissions to be set on them

export const internalFields: Set<DataFieldWithDataType> = new Set([
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.PERMISSIONS },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.SUPERIOR_IDS },
  ...enumValues(DataTypes).flatMap((d) => {
    return [
      toDataFieldWithDataType(d, 'ID'),
      toDataFieldWithDataType(d, 'ENTITY_ID'),
      toDataFieldWithDataType(d, 'NAMESPACE'),
    ];
  }),
]);

export const nonFrontendQueryableFields: Set<DataFieldWithDataType> = new Set([
  ...Array.from(internalFields),
  ...HierarchicalFields,
]);

// UserVisible fields are those that are meant to be shown on dataview, column selector, etc
// excludes fields like EFFECTIVE_LEAVER_DATE_NORMALIZED, DATE_OF_BIRTH_YEAR_MONTH, etc
// which are used in queries internally
export const queryableButNonUserVisibleFields: Set<DataFieldWithDataType> = new Set([
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EMPLOYMENT_TEMPORALITY },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.VERSION_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.SUPERIOR_ID },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.EFFECTIVE_LEAVER_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.START_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.TERM_DATE_NORMALIZED_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.DATE_OF_BIRTH_YEAR_MONTH },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CALENDAR_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.CALENDAR_QUARTER },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FINANCIAL_YEAR },
  { dataType: DataTypes.EMPLOYEE, dataField: EmployeeDataFields.FINANCIAL_QUARTER },
  { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.VERSION_ID },
  { dataType: DataTypes.EVALUATION, dataField: EvaluationDataFields.EVALUATION_CYCLE_VIZ_TYPE },
  { dataType: DataTypes.APPLICATION, dataField: ApplicationDataFields.VERSION_ID },
  { dataType: DataTypes.JOB, dataField: JobDataFields.VERSION_ID },
  { dataType: DataTypes.QUESTIONANSWER, dataField: SurveyDataFields.VERSION_ID },
  { dataType: DataTypes.TIMEANDATTENDANCEMONTHLY, dataField: TimeAndAttendanceMonthlyDataFields.VERSION_ID },
]);

const nonUserVisibleFields = new Set([
  ...Array.from(queryableButNonUserVisibleFields),
  ...Array.from(nonFrontendQueryableFields),
]);

export const GetNonNullFieldsForDataTypeService = async (dataType: DataTypes): Promise<DataFieldWithDataType[]> => {
  const usedFieldsForDataType = (rootStore.domainDependencyStore.getAllUsedFields() || []).find(
    (entry) => entry.dataType.toString() === dataType.toString()
  );

  const converted = usedFieldsForDataType?.datafields.map((dataField) => {
    return { dataType, dataField };
  });

  if (!converted) {
    console.error(`Could not get non-null fields for dataType ${dataType}`);
    throw new Error(`Could not get non-null fields for dataType ${dataType}`);
  } else {
    return converted;
  }
};

export const GetUserVisibleNonConfidentialNonNullFieldsForDataTypeService = async (
  dataType: DataTypes
): Promise<DataFieldWithDataType[]> => {
  const usedFields = await GetNonNullFieldsForDataTypeService(dataType);
  const excludedFields = [...nonUserVisibleFields, ...nonFrontendQueryableFields, ...HierarchicalFields];
  const filteredUsedFields = usedFields.filter((f) => !excludedFields.deepCompareContains(f));
  return filteredUsedFields.filter((f) => rootStore.permissionsStore.isFieldAllowedForExecutorRole(f));
};
