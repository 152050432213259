import { useMutation, useQuery } from 'react-query';
import { queryClient } from '../../app';
import { errorHandler } from '../api/utils';
import { UserRolePreferences, UserRolePreferencesSettings } from '../api/zod-schemas';
import { rootStore } from '../store/root-store';
import { CommonMutationOptions } from './permissions-query-hooks';

export const useGetUserRolePreferences = () => {
  return useQuery(
    ['rolePreferences'],
    async () => {
      return (await rootStore.userRolePreferencesService.getLatestUserRolePreferences()) as UserRolePreferences;
    },
    { onError: (e) => errorHandler(e, { request: 'getLatestUserPreferences' }) }
  );
};

export const useSaveUserRolePreferences = (options?: CommonMutationOptions) => {
  return useMutation(
    ({ preferences }: { preferences: UserRolePreferencesSettings }) => {
      return rootStore.userRolePreferencesService.saveUserRolePreferences(preferences);
    },
    {
      onMutate: () => {
        options?.beforeCallback?.();
      },
      onSuccess: (_) => {
        queryClient.refetchQueries(['rolePreferences'], { exact: true });
        options?.successCallback?.();
      },
      onError: (e) => errorHandler(e, { request: 'saveUserPreferences' }),
    }
  );
};
