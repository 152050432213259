import { LiteYTEmbed } from '@justinribeiro/lite-youtube';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash.isequal';
import moment from 'moment';
import { DOMAttributes } from 'react';
import { median } from 'simple-statistics';
import { v1 } from 'uuid';
import {
  compareDimensionToDim,
  compareMeasureToDim,
  DataFieldWithDataType,
  filterToDataFieldWithDataType,
} from '../../../common-types';
import { PbsItems } from '../../pages/dashboard/balance-sheet/types';
import {
  $HierarchicalFilterSeparator$,
  ApiMasterDataQueryFilterItem,
  ApiMasterDataQueryResponseDataPointDimensionData,
  ApiMasterDataQueryResponseDataPointMeasureData,
  HierarchicalFields,
} from '../api/api-interfaces';
import { DataFields, DataTypes, Operations } from '../constants/constants';
import { getTranslation, UndefinedValue } from '../constants/systemValuesTranslation';
import { Granularity } from '../date-manager/date-manager-constants';
import { dateManagerService } from '../date-manager/date-manager-service';
import { MetricId } from '../graphql/generated/graphql-sdk';
import { TagObject, ViewMetricsTagType } from '../permissions/permissions';
import { FormatTypes, roundOffNumber } from './formatters';
import { isValidNumber } from './validators';

export interface Monoid<T> {
  combine: (r1: T, r2: T) => T;
  empty: T;
}

export const toUpperCase = (f: DataFieldWithDataType): DataFieldWithDataType => {
  return {
    dataType: f.dataType.toUpperCase() as DataTypes,
    dataField: f.dataField.toUpperCase() as DataFields,
  };
};

export const toHierarchicalField = (f: DataFieldWithDataType): DataFieldWithDataType => {
  const uppercasedField = toUpperCase(f);
  return {
    ...uppercasedField,
    dataField: uppercasedField.dataField.replace(/_LEVEL_\d{1,2}/g, '') as DataFields,
  };
};

type CustomElement<T> = Partial<T & DOMAttributes<T> & { children: any }>;
declare global {
  interface Array<T> {
    front(): T[];
    last(): T | undefined;
    first(): T | undefined;
    tail(): T[];
    getValueByIndex(index: number): T | undefined;
    filterAsync(callback: (el: T) => Promise<boolean>): Promise<T[]>;
    deepCompareContains(el: T): boolean; // deep comparison whereas includes tests for object reference equality
  }
  interface String {
    capitalizeFirstChar(): string;
  }

  interface Set<T> {
    deepCompareContains(el: T): boolean;
    toArray(): Array<T>;
  }

  namespace JSX {
    interface IntrinsicElements {
      ['lite-youtube']: CustomElement<LiteYTEmbed>;
    }
  }
}

// Get last element of array without removing it (unlike pop())
// [1,2,3] => last = 3
// [] => last = undefined
Array.prototype.last = function () {
  return this.length > 0 ? this[this.length - 1] : undefined;
};
// [1,2,3] => first = 1
// [] => first = undefined
Array.prototype.first = function () {
  return this.length > 0 ? this[0] : undefined;
};
// [1,2,3] => tail = [2,3]
// [] => tail = []
Array.prototype.tail = function () {
  return this.length > 0 ? this.slice(1) : [];
};
// [1,2,3] => front = [1,2]
// [] => front = []
Array.prototype.front = function () {
  return this.length > 0 ? this.slice(0, this.length - 1) : [];
};

// [1,2,3] => getValueByIndex(1) =  2
// [1,2,3] => getValueByIndex(4) =  undefined
Array.prototype.getValueByIndex = function (index) {
  return this.length > index && index >= 0 ? this[index] : undefined;
};

Array.prototype.filterAsync = async function <T>(callback: (el: T) => Promise<boolean>) {
  const fail = Symbol();
  return (await Promise.all(this.map(async (item) => ((await callback(item)) ? item : fail)))).filter(
    (i): i is T => i !== fail
  );
};

Array.prototype.deepCompareContains = function <T>(el: T) {
  return this.some((e) => isEqual(el, e));
};

// hello => Hello
// hi There => Hi There
String.prototype.capitalizeFirstChar = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

Set.prototype.deepCompareContains = function <T>(el: T) {
  return Array.from(this).deepCompareContains(el);
};

Set.prototype.toArray = function () {
  return Array.from(this);
};

export const getAverage = (values: number[]) => {
  if (!values.length) return 0;
  const total = values.reduce((acc, c) => acc + c, 0);
  return total / values.length;
};

export const getAverageHandlingNulls = (values: (number | undefined | null)[]) => {
  if (!values.length) return 0;
  const maybeValuesWithoutNulls: number[] = values.filter((v): v is number => Number.isFinite(v));
  const total = maybeValuesWithoutNulls.reduce((acc, c) => acc + c, 0);
  return ratioWithoutFormatting(total, maybeValuesWithoutNulls.length);
};

export const getMedian = (values: number[]) => {
  if (values.length) {
    return median(values);
  } else {
    return null;
  }
};

export const range = (start: number, end: number) => {
  // its start and end inclusive
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
};

// This is a decorator
export function logTimeTaken(_target: any, name: string, descriptor: PropertyDescriptor) {
  const original = descriptor.value;
  if (typeof original === 'function') {
    descriptor.value = function (...args: any) {
      try {
        const t1 = performance.now();
        const result = original.apply(this, args);
        const t2 = performance.now();
        const timeTaken = (t2 - t1) / 1000;
        console.log('Time taken to run', name, ':', timeTaken, ' seconds');
        return result;
      } catch (e) {
        console.log(`Error: ${e}`);
        throw e;
      }
    };
  }
  return descriptor;
}

// check for falsy values & empty obj except for 0
export const checkNotNull = (arg: any) => {
  if (!arg) return 0;
  return arg;
};

// check is query obj is empty
export const isQueryObjEmpty = (result: any) => {
  return isEmpty(result) || isEmpty(result?.dataPoints?.[0]);
};

export const allValuesEqual = <T>(obj: any, valuesToCheck: T[]): boolean => {
  return Object.keys(obj).every((key) => {
    const value = obj[key];
    if (Array.isArray(value)) {
      return value.every((v) => valuesToCheck.includes(v));
    } else if (valuesToCheck.some((v) => v === null) && value === null) {
      return true;
    } else if (value === null) {
      return false;
    } else if (typeof value === 'object') {
      return allValuesEqual(value, valuesToCheck);
    } else {
      return valuesToCheck.includes(value);
    }
  });
};

export const getRandomDate = (rangeStart: string, rangeEnd: string) => {
  const { formatDateApi, parseApiDate } = dateManagerService;
  const diff = parseApiDate(rangeStart).diff(parseApiDate(rangeEnd), Granularity.DAY);
  const randomOffset = Math.random() * diff;
  const randomDate = formatDateApi(parseApiDate(rangeStart).add(randomOffset, Granularity.DAY));
  return randomDate;
};

export const deepClone = <T extends Object>(obj: T | null): T =>
  obj === null ? null : JSON.parse(JSON.stringify(obj));

export const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const sum = (array: number[]) => array.reduce((acc, curr) => acc + (curr ?? 0), 0);

export const sumAny = (array: any[]) => sum(array.map((a) => (isValidNumber(a) ? Number(a) : 0)));

export const sumBy = <T>(array: T[], valueGetter: (v: T) => number) => sum(array.map((v) => valueGetter(v)));

export const removeDuplicates = (arr: any[]) =>
  Array.from(new Map(arr.map((item) => [JSON.stringify(item), item])).values());

export const ratioWithoutFormatting = (numerator: any, denominator: number) => {
  if (!checkNotNull(numerator) || !checkNotNull(denominator)) return 0;
  let ratio = denominator > 0 ? (isValidNumber(numerator) ? Number(numerator) : 0) / denominator : 0;
  return ratio;
};

export const ratioNumber = (numerator: any, denominator: number, formatType: FormatTypes) => {
  let ratio = ratioWithoutFormatting(numerator, denominator);
  ratio = Number(roundOffNumber(ratio, formatType));
  return ratio;
};

export const percentage = (numerator: number, denominator: number) => {
  if (!checkNotNull(numerator) || !checkNotNull(denominator)) return 0;
  return ratioNumber(numerator * 100, denominator, FormatTypes.PERCENTAGE);
  // TODO(Formatting: Eventually I think rounding off should only happen in formatNumber.
  // Maybe we should remove all rounding from services, although I'm not sure)
};

export const deltaPercentage = (start: number, end: number) => {
  return percentage(end - start, start);
};

export const getNumericalValue = (val: any) => {
  return isValidNumber(val) ? Number(val) : val;
};

export const getTodayDate = () => dateManagerService.formatDateApi(moment());

// https://www.typescriptlang.org/docs/handbook/advanced-types.html#index-types
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
  return o[propertyName]; // o[propertyName] is of type T[K]
}

export function getPromiseForOptionalValue(value: any, getter: () => Promise<any>): Promise<any> {
  if (!value) {
    return getter();
  }

  if (!Object.keys(value).length) {
    return getter();
  }

  return Promise.resolve();
}

export const getDimensionValue = (
  dimensions: ApiMasterDataQueryResponseDataPointDimensionData[],
  property: DataFields
): string | undefined => {
  return dimensions.find((d) => d.property === property)?.value;
};

export const uuid = () => v1();

export const isHierarchical = (filterProperty: DataFieldWithDataType): boolean => {
  return HierarchicalFields.deepCompareContains(filterProperty);
};

export const isFieldFromHierarchicalFamily = (field: DataFieldWithDataType): boolean => {
  const flattenedField = {
    dataType: field.dataType,
    dataField: field.dataField.split('_LEVEL_')[0],
  } as DataFieldWithDataType;
  return isHierarchical(flattenedField);
};

export const getBaseField = (field: DataFieldWithDataType): DataFieldWithDataType => {
  // For hierarchical field, this function will return the base field i.e. LOCATION for LOCATION_LEVEL_1
  // For non hierarchical fields it will simply return the provided field
  return {
    dataType: field.dataType,
    dataField: isFieldFromHierarchicalFamily(field) ? field.dataField.split('_LEVEL_')[0] : field.dataField,
  } as DataFieldWithDataType;
};

export const hasUndefined = (filter: ApiMasterDataQueryFilterItem) => {
  return isHierarchical(filterToDataFieldWithDataType(filter))
    ? filter.values[0].some((s: any) => s === undefined)
    : filter.values[0] === undefined;
};

const FILTER_KEY_SEPARATOR = '$FILTER_KEY_SEPARATOR$';
export const getFilterKey = (dataType: DataTypes, propertyBase: DataFields) => {
  return `${dataType}${FILTER_KEY_SEPARATOR}${propertyBase}`;
};

const DATAFIELD_WITH_DATATYPE_TAG_PREFIX = 'df';
const METRIC_TAG_PREFIX = 'metric';

export const getFilterFieldFromTag = (tag: string): DataFieldWithDataType => {
  const colonIndex = tag.indexOf(':');
  const dotIndex = tag.indexOf('.');
  const prefixIndex = tag.indexOf(DATAFIELD_WITH_DATATYPE_TAG_PREFIX);
  // TODO: Improve this using regex later and add support for multiple tags
  const validTag = prefixIndex === 0 && colonIndex !== -1 && dotIndex !== -1 && dotIndex > colonIndex;
  if (validTag) {
    const [dataType, dataField] = tag.split(':')[1].split('.');
    return {
      dataType,
      dataField,
    } as DataFieldWithDataType;
  } else {
    throw new Error(`Invalid tag found in getFilterFieldFromTag function - ${tag}`);
  }
};

export const splitViewMetricTag = (tag: string): TagObject => {
  const splitted = tag.split(':');
  if (splitted.first() && splitted.last()) {
    return { tagType: splitted.first() as string, tagValue: splitted.last() as string };
  } else {
    throw new Error(`Error parsing tag - ${tag}`);
  }
};

export const getMetricFromTag = (tag: string): PbsItems => {
  const colonIndex = tag.indexOf(':');
  const prefixIndex = tag.indexOf(METRIC_TAG_PREFIX);
  // TODO: Improve this using regex later and add support for multiple tags
  const validTag = prefixIndex === 0 && colonIndex !== -1;
  if (validTag) {
    return tag.split(':')[1] as PbsItems;
  } else {
    throw new Error(`Invalid tag found in getFilterFieldFromTag function - ${tag}`);
  }
};

export const getTagFromFilterField = ({ dataType, dataField }: DataFieldWithDataType) => {
  return `${DATAFIELD_WITH_DATATYPE_TAG_PREFIX}:${dataType}.${dataField}`;
};

export const getSingleMetricTagFromMetric = (metric: MetricId) => {
  return `${ViewMetricsTagType.SINGLE}:${metric}`;
};

export const getValue = (
  dimensions: ApiMasterDataQueryResponseDataPointDimensionData[],
  dimension: DataFieldWithDataType
) => dimensions?.find((d) => compareDimensionToDim(d, dimension))?.value ?? null;

export const getValueForMeasure = (
  measures: ApiMasterDataQueryResponseDataPointMeasureData[],
  dimension: DataFieldWithDataType,
  operation?: Operations
) => {
  return measures.find((m) => compareMeasureToDim(m, dimension) && (operation ? operation === m.operation : true))
    ?.value;
};

export const getValueOr = (
  dimensions: ApiMasterDataQueryResponseDataPointDimensionData[],
  dimension: DataFieldWithDataType,
  fallbackValue = UndefinedValue
) => getValue(dimensions, dimension) ?? fallbackValue;

export const getDimensionFromLabel = (label: string, baseDim: DataFieldWithDataType) => {
  const levels = label.split($HierarchicalFilterSeparator$);
  const hierarchical = isHierarchical(baseDim);
  return hierarchical ? `${baseDim.dataField}_LEVEL_${levels.length}` : baseDim.dataField;
};

export const consoleConditional = (predicate: boolean, ...args: any[]) => {
  if (predicate) {
    console.log(...args);
  }
};

export const getNextGradientColor = (color: string, gradientChangeAmount: number = 15): string => {
  var usePound = false;

  if (color[0] === '#') {
    color = color.slice(1);
    usePound = true;
  }

  var num = parseInt(color, 16);

  var r = (num >> 16) + gradientChangeAmount;

  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + gradientChangeAmount;

  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + gradientChangeAmount;

  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

// Yes, this is EXACTLY why nobody likes mathematicians who also do programming.
// https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export const shadeRGBColor = (p: number, color: string) => {
  var i = parseInt,
    r = Math.round,
    [a, b, c, d] = color.split(','),
    n = p < 0,
    t = n ? 0 : 255 * p,
    P = n ? 1 + p : 1 - p;
  return (
    'rgb' +
    (d ? 'a(' : '(') +
    r(i(a[3] == 'a' ? a.slice(5) : a.slice(4)) * P + t) +
    ',' +
    r(i(b) * P + t) +
    ',' +
    r(i(c) * P + t) +
    (d ? ',' + d : ')')
  );
};

export const countNumberOfDigits = (value: number | string) => {
  if (isNaN(Number(value))) return 0;
  return Number(value).toFixed(0).split('').length;
};

export const mapValsDeep = async (
  obj: Record<string, any>,
  callback: (val: string | number, path: string[]) => any
) => {
  const mapValuesDeep = (
    await import(
      /* webpackChunkName: "deepdash" */
      /* webpackMode: "lazy" */
      'deepdash-es/standalone'
    )
  ).mapValuesDeep;

  return mapValuesDeep(obj, (val, key, parentVal, { path }) => callback(val, path as string[]), {
    leavesOnly: true,
    pathFormat: 'array',
  });
};

export const convertMinsToHrsMins = (mins: number): string => {
  let h = Math.floor(mins / 60);
  let m = mins % 60;
  const hoursValue = h < 10 ? '0' + h : h; // (or alternatively) h = String(h).padStart(2, '0')
  const minsValue = m < 10 ? '0' + m : m; // (or alternatively) m = String(m).padStart(2, '0')
  return `${hoursValue}:${minsValue}`;
};

export const findDeep = (array: any[], value: string | number, property: string, childrenPath: string): any => {
  let result;
  array.some(
    (item) =>
      (item[property] === value && (result = item)) ||
      (result = findDeep(item[childrenPath] || [], value, property, childrenPath))
  );
  return result;
};

export const mapObjToObj = <OldKey extends string, OldVal, NewKey extends string, NewVal>(
  obj: Record<OldKey, OldVal>,
  mapper: (oldEntry: [OldKey, OldVal]) => [NewKey, NewVal]
) => {
  return Object.fromEntries((Object.entries(obj) as [OldKey, OldVal][]).map(mapper)) as Record<NewKey, NewVal>;
};

// https://web.dev/optimize-long-tasks/
export const yieldToMain = () => {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
};

export const getSubDomain = () => {
  return window.location.host.split('')[0];
};

export const isDataFieldMatchedInSearch = (
  dataFieldWithDataType: DataFieldWithDataType,
  searchValue: string,
  { alias }: { alias?: string | null }
) => {
  const translation = getTranslation(dataFieldWithDataType) ?? '';
  const searchText = searchValue.toLowerCase();
  const isMatchedInSearch =
    alias?.toLowerCase()?.includes(searchText) ||
    dataFieldWithDataType.dataField.toLowerCase().includes(searchText) ||
    translation.toLowerCase().includes(searchText);
  return isMatchedInSearch;
};
