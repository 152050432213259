import { Domains } from '../../../common/constants/constants';
import { PbsCategories, PbsItems } from './types';

export const allPbsMetricsConfig: Record<PbsItems, { defaultCategory: PbsCategories; domains?: Domains[] }> = {
  // Financial Metrics
  [PbsItems.REVENUE]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.GROSS_PROFIT]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.OPERATING_PROFIT]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.NET_INCOME]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.TRAINING_COST]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.INDIRECT_INVESTMENT_RATIO]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.REVENUE_QUARTERLY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.GROSS_PROFIT_QUARTERLY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.OPERATING_PROFIT_QUARTERLY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.NET_INCOME_QUARTERLY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY]: {
    defaultCategory: PbsCategories.FINANCIAL_METRICS,
  },
  [PbsItems.TRAINING_COST_QUARTERLY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },

  [PbsItems.REVENUE_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.GROSS_PROFIT_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.OPERATING_PROFIT_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.NET_INCOME_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_WHOLECOMPANY]: {
    defaultCategory: PbsCategories.FINANCIAL_METRICS,
  },
  [PbsItems.TRAINING_COST_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.REVENUE_QUARTERLY_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.GROSS_PROFIT_QUARTERLY_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.OPERATING_PROFIT_QUARTERLY_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.NET_INCOME_QUARTERLY_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES_QUARTERLY_WHOLECOMPANY]: {
    defaultCategory: PbsCategories.FINANCIAL_METRICS,
  },
  [PbsItems.TRAINING_COST_QUARTERLY_WHOLECOMPANY]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },

  [PbsItems.SGA_PAYROLL]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.COGS_PAYROLL]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.SGA_POP]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.COGS_POP]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.LABOR_COST]: { defaultCategory: PbsCategories.FINANCIAL_METRICS, domains: [Domains.DIGITAL_HOLDINGS] },
  [PbsItems.TOTAL_BONUS_PERC_OF_TOTAL_CASH_COMPENSATION]: {
    defaultCategory: PbsCategories.FINANCIAL_METRICS,
  },
  [PbsItems.TOTAL_LABOR_COST_TO_SALES]: {
    defaultCategory: PbsCategories.FINANCIAL_METRICS,
    domains: [Domains.PATAGONIA],
  },
  [PbsItems.HUMAN_CAPITAL_ROI]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },

  // Growth
  [PbsItems.PM0011_NewMngCalc]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.PM0015_NewMngDef]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.MANAGERS_CALCULATED]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.MANAGERS_DEFINED]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.NON_REGULAR_TYPE_EMP]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.REGULAR_TYPE_EMP]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.FTE]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.FTEQUIVALENT]: {
    defaultCategory: PbsCategories.GROWTH,
  },
  [PbsItems.TRAINING_TAKEN]: {
    defaultCategory: PbsCategories.GROWTH,
    domains: [Domains.SHIRATORI, Domains.NSSOL, Domains.JT, Domains.CAPLAN, Domains.DIGITAL_HOLDINGS],
  },
  [PbsItems.TRAINEE]: {
    defaultCategory: PbsCategories.GROWTH,
    domains: [Domains.SHIRATORI, Domains.NSSOL, Domains.JT, Domains.CAPLAN, Domains.DIGITAL_HOLDINGS],
  },
  [PbsItems.MANAGEMENT_PROMOTION]: {
    defaultCategory: PbsCategories.GROWTH,
    domains: [Domains.JT],
  },
  // MSAD specific
  [PbsItems.EMPLOYEE_COMPLETED_BASIC_DIGITAL_TRAINING]: {
    defaultCategory: PbsCategories.GROWTH,
    domains: [Domains.MSAD],
  },
  // MSAD specific
  [PbsItems.DIGITAL_EXPERTS]: { defaultCategory: PbsCategories.GROWTH, domains: [Domains.MSAD] },

  // Org Agility
  [PbsItems.AGE]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.SPAN_OF_CONTROL]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.INTERNAL_MOB_RATE_LEVEL_1]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.INTERNAL_MOB_RATE_LEVEL_2]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.REGRET_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.VOLUNTARY_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.ONE_YEAR_TENURE_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.THREE_MONTH_TENURE_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.THREE_MONTH_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.ONE_YEAR_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.THREE_YEAR_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.THIRD_YEAR_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.STARTING_POP_ATTRITION]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.RETENTION_RATE]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.DEPTH_OF_CONTROL]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.ACTUAL_TENURE]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.TENURE_IN_JOB_GRADE_LEVEL_1]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.TENURE_IN_JOB_GRADE_LEVEL_2]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.TENURE_IN_ORG_LEVEL_1]: { defaultCategory: PbsCategories.ORG_AGILITY },
  [PbsItems.TENURE_IN_ORG_LEVEL_2]: { defaultCategory: PbsCategories.ORG_AGILITY },
  // MSAD specific
  [PbsItems.EMPLOYEE_WITH_EXTERNAL_EXPERIENCE]: { defaultCategory: PbsCategories.ORG_AGILITY, domains: [Domains.MSAD] },

  // Div and Fairness
  [PbsItems.FEM_HC]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.GENDER_GAP]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.GENDER_PAY_GAP_TOTAL_COMP]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.FEMALE_MANAGERS_CALCULATED]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.FEMALE_MANAGERS_DEFINED]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.MEDIAN_BASE]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.INTERNAL_COMPA_RATIO]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  [PbsItems.DISABILITY]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS },
  // MSAD specific
  [PbsItems.POTENTIAL_FEMALE_LEADER]: { defaultCategory: PbsCategories.DIV_AND_FAIRNESS, domains: [Domains.MSAD] },

  // Org Health
  [PbsItems.POP_PAY_GAP]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.CEO_PAY_GAP_BASE_AVG]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0214_AvgEmp45HrsOTperMth]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.DAYS_IN_OFFICE]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PAID_LEAVES_TAKEN]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.ANNUAL_PAID_LEAVES_TAKEN]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.JAPAN_5_ANNUAL_LEAVE_DAYS_RULE]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0222_OtHours]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0220_HoursWorkedHoliday]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0221_HoursWorkedLegalHoliday]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0224_TotalHolidayOt]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.PM0225_TotalOt]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.DAYS_OF_REMOTE_WORK]: { defaultCategory: PbsCategories.ORG_HEALTH },
  // MSAD specific
  [PbsItems.HIGHLY_STRESSED_EMPLOYEE]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.MSAD] },
  [PbsItems.MSAD_PARENTAL_LEAVES_UTILIZATION]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.MSAD] },
  [PbsItems.MSAD_SURVEY_ENGAGEMENT_SCORE]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.MSAD] },
  [PbsItems.MARUDAI_COMPLAINTS]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.MARUDAI] },
  [PbsItems.DISCIPLINARY_ACTION]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.JT] },
  [PbsItems.JT_OCCUPATIONAL_ACCIDENTS]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.JT] },
  [PbsItems.JT_KILLED_AT_WORK]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.JT] },
  [PbsItems.PARENTAL_LEAVES_TAKEN]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.JT] },
  [PbsItems.PARENTAL_LEAVES_TAKEN_UTILIZATION]: { defaultCategory: PbsCategories.ORG_HEALTH },
  [PbsItems.DAYS_OF_PARENTAL_LEAVES_TAKEN]: { defaultCategory: PbsCategories.ORG_HEALTH, domains: [Domains.JT] },

  // People Profit
  [PbsItems.TOTAL_WORKFORCE_COST]: { defaultCategory: PbsCategories.PEOPLE_PROFIT },
  [PbsItems.TRAVEL_AND_COMMUTING]: { defaultCategory: PbsCategories.PEOPLE_PROFIT },
  [PbsItems.OVERTIME_PAY]: { defaultCategory: PbsCategories.PEOPLE_PROFIT },
  [PbsItems.TOTAL_COMPENSATION]: { defaultCategory: PbsCategories.PEOPLE_PROFIT },
  [PbsItems.EMPLOYER_STATUTORY_PAYMENT]: { defaultCategory: PbsCategories.PEOPLE_PROFIT },

  // Survey
  // All tiket specific
  [PbsItems.SURVEY_ENGAGEMENT_SCORE]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.SURVEY_COMPANY_MOOD_SCORE]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.ENPS]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.EMPLOYEE_WITH_PTM_CASES_ALL]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.EMPLOYEE_WITH_PTM_CASES_OPEN]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.EMPLOYEE_WITH_PTM_CASES_IN_PROGRESS]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.EMPLOYEE_WITH_PTM_CASES_CLOSED]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.LEAVERS_WITH_PTM_CASE_WITHIN_1YEAR]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.RECURRING_PTM_CASE_WITHIN_1YEAR]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },
  [PbsItems.PTM_CASES_UNIQUE]: { defaultCategory: PbsCategories.SURVEY, domains: [Domains.TIKET] },

  // Recruitment Funnel
  [PbsItems.APPLICATION_AT_APPLIED_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION },
  [PbsItems.APPLICATION_AT_APPLIED_STAGE_PASSED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION },
  [PbsItems.APPLICATION_AT_APPLIED_STAGE_REJECTED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION },
  [PbsItems.APPLICATION_AT_APPLIED_STAGE_WITHDRAWN]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_APPLICATION },
  [PbsItems.APPLICATION_AT_SCREENING_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_SCREENING },
  [PbsItems.APPLICATION_AT_SCREENING_STAGE_PASSED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_SCREENING },
  [PbsItems.APPLICATION_AT_SCREENING_STAGE_REJECTED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_SCREENING },
  [PbsItems.APPLICATION_AT_SCREENING_STAGE_WITHDRAWN]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_SCREENING },
  [PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW },
  [PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_PASSED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_REJECTED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_1ST_INTERVIEW_STAGE_WITHDRAWN]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_1ST_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW },
  [PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_PASSED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_REJECTED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_2ND_INTERVIEW_STAGE_WITHDRAWN]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_2ND_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW },
  [PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_PASSED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_REJECTED]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_3RD_INTERVIEW_STAGE_WITHDRAWN]: {
    defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_3RD_INTERVIEW,
  },
  [PbsItems.APPLICATION_AT_OFFERED_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_OFFERED },
  [PbsItems.APPLICATION_AT_OFFERED_STAGE_PASSED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_OFFERED },
  [PbsItems.APPLICATION_AT_OFFERED_STAGE_REJECTED]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_OFFERED },
  [PbsItems.APPLICATION_AT_OFFERED_STAGE_WITHDRAWN]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_OFFERED },
  [PbsItems.APPLICATION_AT_JOINED_STAGE]: { defaultCategory: PbsCategories.RECRUITMENT_FUNNEL_JOINED },

  // HR Basic Metrics
  [PbsItems.NUM_EMP]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
  },
  [PbsItems.NUM_JOINERS]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
  },
  [PbsItems.NUM_LEAVERS_TERM_DATE]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
  },
  [PbsItems.KHNEOCHEM_CUSTOM_FEMALE]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
    domains: [Domains.KHNEOCHEM],
  },
  [PbsItems.KHNEOCHEM_CUSTOM_FEMALE_MANAGERS]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
    domains: [Domains.KHNEOCHEM],
  },

  // Japan Overtime
  [PbsItems.PM0062_OT36720RuleEmployees]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
  },
  [PbsItems.PM0063_OT361Month100RuleEmp]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
  },
  [PbsItems.PM0074_OT3645HrsRuleEmp]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
  },
  [PbsItems.PM0075_OT36EmpTrackingGroup]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
  },
  [PbsItems.PM0197_CustomPaidLeave1Ah]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
    domains: [Domains.PATAGONIA],
  },
  [PbsItems.PM0212_AbsentDays]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
  },
  [PbsItems.PM0213_AvgWorkHoursPerPerson]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
  },
  [PbsItems.PM0215_MonthlyOT]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
  },
  [PbsItems.PM0217_StatutoryAnnualPaidLeave]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
  },
  [PbsItems.PM0228_BonusCoef]: {
    defaultCategory: PbsCategories.PEOPLE_PROFIT,
  },
  [PbsItems.PM0230_OT361Month75RuleEmp]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
    domains: [Domains.CAPLAN],
  },
  [PbsItems.PM0288_OT362to6MonthAvg80RuleEmp]: {
    defaultCategory: PbsCategories.JAPAN_OVERTIME,
  },
  [PbsItems.PM0295_TotalCashCompFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0296_TotalBonusFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0297_RecruitmentCostYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0298_RetirementAllowanceFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0299_EmployerNonStatutoryPaymentFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0300_EmployerStatutoryPaymentFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0301_BoardCompensationFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0314_TravelCommutingFinanceYearly]: { defaultCategory: PbsCategories.FINANCIAL_METRICS },
  [PbsItems.PM0315_CustomStatutoryAnnualPaidLeaveNec]: {
    defaultCategory: PbsCategories.ORG_HEALTH,
    domains: [Domains.NECNSI],
  },
  [PbsItems.PM0316_CustomIndividuals1ProperNec]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
    domains: [Domains.NECNSI],
  },
  [PbsItems.PM0317_CustomIndividuals2FinancialReportingNec]: {
    defaultCategory: PbsCategories.HR_BASIC_METRICS,
    domains: [Domains.NECNSI],
  },
  [PbsItems.PM0318_CustomAttritionNikki]: {
    defaultCategory: PbsCategories.ORG_AGILITY,
    domains: [Domains.NIKKI],
  },
  [PbsItems.PM0319_CustomAttritionAuFh]: {
    defaultCategory: PbsCategories.ORG_AGILITY,
    domains: [Domains.AU_FINANCIAL_HOLDINGS],
  },
  [PbsItems.PM0320_GenderPayGapMToFTotalCompAvgJpGov]: {
    defaultCategory: PbsCategories.DIV_AND_FAIRNESS,
  },
};

export const allPbsMetrics = Object.entries(allPbsMetricsConfig).map(([metric, { defaultCategory, domains }]) => {
  return {
    metric,
    defaultCategory,
    domains: domains as Domains[] | undefined,
  } as {
    metric: PbsItems;
    defaultCategory: PbsCategories;
    domains?: Domains[];
  };
});

export const cypressPbsMetrics: PbsItems[] = [
  // Growth
  PbsItems.NUM_EMP,
  PbsItems.NUM_JOINERS,
  PbsItems.NUM_LEAVERS_TERM_DATE,
  // Org Agility
  PbsItems.AGE,
  PbsItems.SPAN_OF_CONTROL,
  PbsItems.INTERNAL_MOB_RATE_LEVEL_1,
  PbsItems.INTERNAL_MOB_RATE_LEVEL_2,
  // Div and Fairness
  PbsItems.FEM_HC,
  PbsItems.GENDER_GAP,
  // Org Health
  PbsItems.POP_PAY_GAP,
  PbsItems.CEO_PAY_GAP_BASE_AVG,
];

export const defaultPbsMetrics: PbsItems[] = [
  // Financial Metrics
  PbsItems.REVENUE,
  PbsItems.GROSS_PROFIT,
  PbsItems.OPERATING_PROFIT,
  PbsItems.NET_INCOME,
  PbsItems.SELLING_GENERAL_AND_ADMINISTRATION_EXPENSES,
  PbsItems.TRAINING_COST,
  PbsItems.SGA_PAYROLL,
  PbsItems.SGA_POP,
  PbsItems.COGS_PAYROLL,
  PbsItems.COGS_POP,
  PbsItems.INDIRECT_INVESTMENT_RATIO,
  // Growth
  PbsItems.NUM_EMP,
  PbsItems.NUM_JOINERS,
  PbsItems.NUM_LEAVERS_TERM_DATE,
  PbsItems.MANAGERS_CALCULATED,
  PbsItems.MANAGERS_DEFINED,
  PbsItems.PM0011_NewMngCalc,
  PbsItems.PM0015_NewMngDef,
  PbsItems.NON_REGULAR_TYPE_EMP,
  // Org Agility
  PbsItems.AGE,
  PbsItems.SPAN_OF_CONTROL,
  PbsItems.INTERNAL_MOB_RATE_LEVEL_1,
  PbsItems.INTERNAL_MOB_RATE_LEVEL_2,
  PbsItems.ATTRITION,
  PbsItems.THREE_MONTH_TENURE_ATTRITION,
  PbsItems.ONE_YEAR_TENURE_ATTRITION,
  PbsItems.REGRET_ATTRITION,
  PbsItems.VOLUNTARY_ATTRITION,
  PbsItems.DEPTH_OF_CONTROL,
  PbsItems.STARTING_POP_ATTRITION,
  PbsItems.RETENTION_RATE,
  PbsItems.ACTUAL_TENURE,
  // Div and Fairness
  PbsItems.FEM_HC,
  PbsItems.GENDER_GAP,
  PbsItems.GENDER_PAY_GAP_TOTAL_COMP_AVG,
  PbsItems.FEMALE_MANAGERS_CALCULATED,
  PbsItems.FEMALE_MANAGERS_DEFINED,
  PbsItems.MEDIAN_BASE,
  PbsItems.INTERNAL_COMPA_RATIO,
  // Org Health
  PbsItems.POP_PAY_GAP,
  PbsItems.CEO_PAY_GAP_BASE_AVG,
  PbsItems.PM0215_MonthlyOT,
  PbsItems.PM0214_AvgEmp45HrsOTperMth,
  PbsItems.PM0213_AvgWorkHoursPerPerson,
  PbsItems.DAYS_IN_OFFICE,
  PbsItems.PM0217_StatutoryAnnualPaidLeave,
  PbsItems.PM0197_CustomPaidLeave1Ah,
  PbsItems.PM0212_AbsentDays,
  // People Profit
  PbsItems.TOTAL_WORKFORCE_COST,
  PbsItems.TRAVEL_AND_COMMUTING,
  PbsItems.OVERTIME_PAY,
  PbsItems.TOTAL_COMPENSATION,
];

export const CohortPbsItems = [
  PbsItems.JT_KILLED_AT_WORK,
  PbsItems.MARUDAI_COMPLAINTS,
  PbsItems.JT_OCCUPATIONAL_ACCIDENTS,
];
