import { Button, Grid, Typography } from '@mui/material';
import React from 'react';
import { Trans } from 'react-i18next';
import styled from 'styled-components';
import somethingWentWrongUrl from '../../../../assets/image/SomethingWentWrong.png';
import { defaultTheme } from '../../theme/default-theme';
import { GridWithHeight } from '../layout';

const GridContainer = styled(Grid)`
  height: 95vh;
  align-content: center;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: #00a599;
  &:hover {
    background-color: rgba(27, 113, 107);
  }
`;

const ErrorReference = styled.span`
  color: ${defaultTheme.color.primary};
`;

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
  text-align: center;
`;

export type ErrorReason =
  | 'noOldestStartDateFound'
  | 'insufficientPermissions'
  | 'generalDomainDependenciesError'
  | 'networkError'
  | 'genericError';

export default function MainContentFallbackComponent(reason: ErrorReason) {
  // Defaults that will (or should) always be overwritten
  // Currently we use somethingWentWrongUrl for all pictures because we have no other error-picture that can be used on a white background
  var errorImage = somethingWentWrongUrl;
  var errorTextHeadline = 'networkError';
  var errorText: string = 'networkErrorExplanation';

  switch (reason) {
    case 'noOldestStartDateFound':
      errorImage = somethingWentWrongUrl; //ErrorSign;
      errorTextHeadline = 'noDataError';
      errorText = 'noDataErrorExplanation';
      break;
    case 'insufficientPermissions':
      errorImage = somethingWentWrongUrl;
      errorTextHeadline = 'insufficientPermissions';
      errorText = 'insufficientPermissionsExplanation';
      break;
    case 'generalDomainDependenciesError':
      errorImage = somethingWentWrongUrl;
      errorTextHeadline = 'domainDependenciesError';
      errorText = 'domainDependenciesErrorExplanation';
      break;
    case 'networkError':
      errorImage = somethingWentWrongUrl;
      errorTextHeadline = 'networkError';
      errorText = 'networkErrorExplanation';
      break;
    case 'genericError':
      errorImage = somethingWentWrongUrl;
      errorTextHeadline = 'networkError';
      errorText = 'networkErrorExplanation';
      break;
  }

  return (
    <GridContainer container justifyContent="center">
      <GridWithHeight item container xs={12} height={'60vh'} justifyContent="center" alignContent="center">
        <Grid item xs={5} justifyContent="center" alignContent="center" maxHeight={'60vh'}>
          <img src={errorImage} width={'100%'} height={'100%'} />
        </Grid>
      </GridWithHeight>
      <GridWithHeight justifyContent="center" height={'5vh'} item container xs={12}>
        <Typography variant="h5" style={{ color: 'palevioletred' }}>
          <Trans i18nKey={`pages:errorBoundary.${errorTextHeadline}`} />
        </Typography>
      </GridWithHeight>
      <MessageContainer>
        <Typography variant="h5">
          <Trans i18nKey={`pages:errorBoundary.${errorText}`} />
        </Typography>
      </MessageContainer>
      <Button />
    </GridContainer>
  );
}
