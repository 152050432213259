import SearchIcon from '@mui/icons-material/Search';
import {
  Avatar,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router';
import styled from 'styled-components';
import { rootStore } from '../../store/root-store';
import { sortAvailableDomains } from '../../utilFunctions/sorters';
import { WhiteTextField } from '../common-styled-components';
import { getDomainsWithDisplayName } from '../shell/Domain';
import Loading from './Loading';

const StyledDialogContent = styled(DialogContent)`
  && {
    padding: 0px;
  }
` as typeof DialogContent;

const StyledDialog = styled(Dialog)`
  & .paper {
    background: ${(props) => props.theme.color.primary};
    width: 50vw;
    height: 80vh;
  }
` as typeof Dialog;

const StyledAvatar = styled(Avatar)`
  border-radius: 0;
  background: transparent;
` as typeof Avatar;

export const DomainSelector = observer(() => {
  useEffect(() => {
    rootStore.companyStore.loadAvailableDomains();
  }, []);
  const { t } = useTranslation();
  const [searched, setSearched] = useState('');
  const onDomainSelect = (domain: string) => () => {
    rootStore.companyStore.setDomain(domain);
    rootStore.permissionsStore.stopRoleSimulation();
  };
  const { selectedCompany } = rootStore.companyStore;
  if (selectedCompany) {
    return <Redirect to="my-panalyt" />;
  }

  const domainsWithDisplayName = getDomainsWithDisplayName(rootStore.companyStore.availableDomains);
  const sortedDomains = domainsWithDisplayName.slice().sort(sortAvailableDomains);
  const filteredDomains = sortedDomains.filter((d) => {
    const textToSearch = [d.domain, d.domainOfPartner, d.name, d.alias].join('   ');
    return textToSearch.toLowerCase().includes(searched.toLowerCase());
  });

  return (
    <StyledDialog aria-labelledby="simple-dialog-title" open classes={{ paper: 'paper' }} data-cy="domain_selector">
      <DialogTitle id="simple-dialog-title">
        <Typography variant="h6" color="textSecondary">
          {t('common:shell.selectDomain')}
        </Typography>
      </DialogTitle>
      <StyledDialogContent>
        {!sortedDomains.length ? (
          <Grid container justifyContent={'center'} alignItems={'center'} sx={{ height: '100%' }}>
            <Loading color="secondary" />
          </Grid>
        ) : (
          <Grid container direction="column" alignItems="center">
            <Grid item container justifyContent={'center'} alignItems={'flex-end'}>
              <SearchIcon sx={{ color: '#fff' }} />
              <WhiteTextField
                sx={{ width: '50%' }}
                value={searched}
                onChange={(e) => setSearched(e.target.value)}
                autoFocus
              />
            </Grid>
            <List data-cy="domain_selector_list" sx={{ width: '100%' }}>
              {filteredDomains.map(({ domain, domainDisplayNameComponent, domainDisplayNameText, name, logoURL }) => (
                <ListItem
                  button
                  onClick={onDomainSelect(domain)}
                  key={domain}
                  selected={domain === rootStore.companyStore.domain}
                >
                  <ListItemAvatar>
                    {logoURL ? (
                      <StyledAvatar src={logoURL} />
                    ) : (
                      <StyledAvatar>
                        {domainDisplayNameText && domainDisplayNameText.charAt(0).toUpperCase()}
                      </StyledAvatar>
                    )}
                  </ListItemAvatar>
                  <ListItemText disableTypography>
                    <Typography variant="body1" color="textSecondary">
                      {domainDisplayNameComponent}
                    </Typography>
                  </ListItemText>
                </ListItem>
              ))}
            </List>
          </Grid>
        )}
      </StyledDialogContent>
    </StyledDialog>
  );
});
